import { Button, Col, Form, FormControl, FormLabel, InputGroup, Modal, Row } from "react-bootstrap"
import { Formik } from "formik"
import styles from '../styles'
import SelectComponent from "../../selectComponent"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { FC } from "react"
import { ModalRegisterWhatsappInterface } from "./indexModel"
import { Brokers } from "../../../../../core/enums/brokers";

const ModalRegisterWhastapp: FC<ModalRegisterWhatsappInterface> = (props) => {

    return (
        <Modal show={props.show} onHide={props.clearInput} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Adicionar novo número</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    // enableReinitialize={true}
                    initialValues={{
                        nameAccount: "",
                        phoneNumber: "",
                        tokenAuth: "",
                        sid: "",
                        botNlp: "",
                        apiUrl: "",
                        urlBot: "",
                        postBackUrl: "",
                        senderSid: ""
                        
                    }}
                    validate={(values) => {
                        let errors = {};

                        props.setNameAccount(values.nameAccount)
                        props.setPhoneNumber(values.phoneNumber)
                        props.setTokenAuth(values.tokenAuth)
                        props.setSid(values.sid)
                        props.setBotNlp(values.botNlp)
                        props.setApiUrl(values.apiUrl)
                        props.setUrlBot(values.urlBot)
                        props.setPostBackUrl(values.postBackUrl)
                        props.setSenderSid(values.senderSid)

                        if (!values.nameAccount) {
                            errors['nameAccount'] = 'Preencha o nome da conta corretamente'
                            props.setShowFeedbackNameAccount(true)
                        } else {
                            props.setShowFeedbackNameAccount(false)
                        }

                        if (!values.phoneNumber) {
                            errors['phoneNumber'] = 'Preencha o número corretamente'
                            props.setShowFeedbackPhoneNumber(true)
                        } else {
                            props.setShowFeedbackPhoneNumber(false)
                        }

                        if (!values.tokenAuth) {
                            errors['tokenAuth'] = 'Preencha o token de autorização corretamente'
                            props.setShowFeedbackTokenAuth(true)
                        } else {
                            props.setShowFeedbackTokenAuth(false)
                        }

                        if (!values.sid) {
                            errors['sid'] = 'Preencha o sid corretamente'
                            props.setShowFeedbackSid(true)
                        } else {
                            props.setShowFeedbackSid(false)
                        }




                        // if (!values.apiUrl) {
                        //     errors['apiUrl'] = 'Preencha o URL da API corretamente'
                        //     setShowFeedbackApiUrl(true)
                        // } else {
                        //     setShowFeedbackApiUrl(false)
                        // }

                        if (props.hasBot) {

                            if (values.botNlp && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(values.botNlp)) {
                                errors['botNlp'] = 'Prencha com um ID válido'
                                props.setShowFeedbackBotNlp(true)
                            } else {
                                props.setShowFeedbackBotNlp(false)
                            }

                            if (!values.urlBot) {
                                errors['urlBot'] = 'Preencha o URL do Bot corretamente'
                                props.setShowFeedbackUrlBot(true)
                            } else {
                                props.setShowFeedbackUrlBot(false)
                            }

                            if (!values.postBackUrl) {
                                errors['postBackUrl'] = 'Preencha o PostBackUrl corretamente'
                                props.setShowFeedbackPostBackUrl(true)
                            } else {
                                props.setShowFeedbackPostBackUrl(false)
                            }
                        } else {

                        }

                        if (!values.senderSid && props.selectedBroker[0]?.value === Brokers.Twilio) { /* só adiciona o erro caso o broker seja twilio E o campo esteja vazio*/
                            errors['senderSid'] = 'Preencha o sender sid corretamente'
                            props.setShowFeedbackSenderSid(true)
                        }
                        else {
                            props.setShowFeedbackSenderSid(false)
                        }

                        return errors

                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            cnpj: props.company_data.company_cnpj,
                            hasBot: props.hasBot,
                        }

                        if (props.nameAccount !== '') {
                            JsonSend['subAccountName'] = props.nameAccount
                        }

                        if (props.phoneNumber !== '') {
                            JsonSend['phoneNumber'] = props.phoneNumber.toString()
                        }

                        if (props.tokenAuth !== '') {
                            JsonSend['authToken'] = props.tokenAuth
                        }

                        if (props.sid !== '') {
                            JsonSend['sid'] = props.sid
                        }


                        if (props.apiUrl !== '') {
                            JsonSend['apiUrl'] = props.apiUrl
                        }

                        JsonSend['brokerId'] = props.onSelectBroker[0]?.value

                        if(props.selectedBroker[0]?.value === Brokers.Twilio) {
                            JsonSend['sender_sid'] = props.senderSid;
                        }
                        else {
                            JsonSend['sender_sid'] = null;
                        }

                        if (props.hasBot) {
                            if (props.botNlp !== '') {
                                JsonSend['nlp_id'] = props.botNlp
                            }

                            if (props.urlBot !== '') {
                                JsonSend['urlBot'] = props.urlBot
                            }

                            if (props.postBackUrl !== '') {
                                JsonSend['postBackUrl'] = props.postBackUrl
                            }

                        } else {
                            JsonSend['nlp_id'] = undefined
                            JsonSend['urlBot'] = undefined
                            JsonSend['postBackUrl'] = undefined
                        }

                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + props.values.token.value
                        };
                        const router = '/channel-whatsapp/save-whatsapp-keys'
                        const config = { headers }

                        await props.AppRequesterConst.Post(
                            router, JsonSend, config,
                            (response: Object) => { },
                            (data: { status: number }) => {
                                if (data.status === 200) {
                                    props.dispatch(setShowAlertFeedback({ message: "Whatsapp key cadastrado com sucesso.", visibility: true, signalIcon: true }))
                                    props.clearInput()
                                    props.getWhatsAppById()
                                }
                            },
                            (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
                                // console.log(error.response.data.message, "Aqui")
                                if (error.response.data.code_cxpress === 212) {
                                    props.dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))

                                } else if (error?.response?.status === 422) {
                                    if (error?.response?.data?.message[0]?.path[0] === 'nlp_id') {
                                        props.dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp é inválido.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.message[0]?.path[0] === 'brokerId') {
                                        props.dispatch(setShowAlertFeedback({ message: 'O Broker não foi informado.', visibility: true, signalIcon: false }))
                                    }
                                } else if (error?.response?.data?.code_cxpress === 216) {
                                    props.dispatch(setShowAlertFeedback({ message: 'Bot não encontrado', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 217) {
                                    props.dispatch(setShowAlertFeedback({ message: 'Bot está cadastrado em uma empresa diferente', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 218) {
                                    props.dispatch(setShowAlertFeedback({ message: 'Canal do bot não é whatsapp', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 219) {
                                    props.dispatch(setShowAlertFeedback({ message: 'Prompt não foi criado', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 220) {
                                    props.dispatch(setShowAlertFeedback({ message: 'Bot está associado a outra whatsapp key', visibility: true, signalIcon: false }))
                                }
                                else {
                                    props.dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                                }


                            }, props.navigate, props.dispatch, props.setIsLoading
                        )

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={props.validated}>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="name">
                                        Nome da conta <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="nameAccount"
                                            isInvalid={props.showFeedbackNameAccount}
                                            className="form-control-Default"
                                            placeholder="Informe o nome da conta"
                                            aria-label="Informe o nome da conta"
                                            aria-describedby="Informe o nome da conta"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackSubAccountName">
                                            {errors.nameAccount}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label htmlFor="number">
                                        Número <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="phoneNumber"
                                            isInvalid={props.showFeedbackPhoneNumber}
                                            className="form-control-Default"
                                            placeholder="Informe o número"
                                            aria-label="Informe o número"
                                            aria-describedby="Informe o número"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackPhoneNumber">
                                            {errors.phoneNumber}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Form.Label htmlFor="token">
                                    Token de autorização <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        id="tokenAuth"
                                        isInvalid={props.showFeedbackTokenAuth}
                                        className="form-control-Default"
                                        placeholder="Informe o token de autorização"
                                        aria-label="Informe o token de autorização"
                                        aria-describedby="Informe o token de autorização"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackTokenAuth">
                                        {errors.tokenAuth}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel htmlFor="sid">
                                    Sid <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="sid"
                                        isInvalid={props.showFeedbackSid}
                                        className="form-control-Default"
                                        placeholder="Informe o sid"
                                        aria-label="Informe o sid"
                                        aria-describedby="Informe o sid"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackSid">
                                        {errors.sid}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    URL da API
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="apiUrl"
                                        isInvalid={props.showFeedbackApiUrl}
                                        className="form-control-Default"
                                        placeholder="Informe o URL da API"
                                        aria-describedby="Informe o URL da API"
                                        aria-label="Informe o URL da API"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackApiUrl">
                                        {errors.apiUrl}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left">
                                <FormLabel>
                                    Broker <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                </FormLabel>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={props.optionsBroker}
                                    disableSearch={true}
                                    setOptions={props.setOptionsBroker}
                                    selecteds={props.selectedBroker}
                                    setSelecteds={props.setSelectedBrokers}
                                    singleSelect={true}
                                    title={"Broke"}
                                    overrideStrings={{
                                        "allItemsAreSelected": "",
                                        "clearSearch": "Limpar pesquisa",
                                        "clearSelected": "Limpar broker selecionado",
                                        "noOptions": "Sem broker",
                                        "search": "Pesquisar broker",
                                        "selectAll": "Selecionar todos os brokers",
                                        "selectAllFiltered": "Selecionar todos os brokers (Filtrado)",
                                        "selectSomeItems": "Selecione o broker",
                                        "create": "Criar"
                                    }}
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    onSelect={(value) => props.selectBrokerId(value)}
                                    filtering={false}
                                    hasAllOption={false}
                                />
                            </Row>
                            {
                                props.selectedBroker[0]?.value === Brokers.Twilio ?
                                    <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                        <FormLabel htmlFor="sid">
                                            Sender Sid <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                        </FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                id="senderSid"
                                                isInvalid={props.showFeedbackSenderSid}
                                                className="form-control-Default"
                                                placeholder="Informe o sender sid"
                                                aria-label="Informe o sender sid"
                                                aria-describedby="Informe o sender sid"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackSenderSid">
                                                {errors.senderSid}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    :
                                    <></>
                            }
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="basic-url">Possui bot? &nbsp; &nbsp;</Form.Label>
                                    <Form.Check name="hasBot" label={'Não'} type={'radio'} checked={!props.hasBot} onChange={() => { props.setHasbot(false) }} inline />
                                    <Form.Check name="hasBot" label={'Sim'} type={'radio'} checked={props.hasBot} onChange={() => { props.setHasbot(true) }} inline />
                                </Col>
                            </Row>
                            {props.hasBot ?
                                <>
                                    <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                        <FormLabel>
                                            ID do Bot NLP
                                        </FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                id="botNlp"
                                                isInvalid={props.showFeedbackBotNlp}
                                                className="form-control-Default"
                                                placeholder="Informe o ID do Bot NLP"
                                                aria-label="Informe o ID do Bot NLP"
                                                aria-describedby="Informe o ID do Bot NLP"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackBotNlp">
                                                {errors.botNlp}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                        <FormLabel>
                                            URL Bot <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                        </FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                id="urlBot"
                                                isInvalid={props.showFeedbackUrlBot}
                                                className="form-control-Default"
                                                placeholder="URL Bot"
                                                aria-label="URL Bot"
                                                aria-describedby="URL Bot"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackUrlBot">
                                                {errors.urlBot}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                        <Form.Label>
                                            PostBackUrl <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                        </Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                id="postBackUrl"
                                                isInvalid={props.showFeedbackPostBackUrl}
                                                className="form-control-Default"
                                                placeholder="PostBackUrl"
                                                aria-label="PostBackUrl"
                                                aria-describedby="PostBackUrl"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackPostBackUrl">
                                                {errors.postBackUrl}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                </>
                                :
                                <></>
                            }
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.clearInput()}>Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit">Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ModalRegisterWhastapp