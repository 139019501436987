import { FC, KeyboardEvent, useCallback, useEffect, useRef, useState, ClipboardEvent } from "react";
import ChatEditorComponent from ".";
import { useViewTicketContext } from "../../../../../../core/context/view-ticket-context";
import { Channels } from "../../../../../../core/enums/channels";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconInfoCircle, IconMail, IconMessage2, IconMessage2Off, IconMessages, IconMicrophone, IconPaperclip, IconPhoneOutgoing, IconWriting } from "@tabler/icons-react";
import { ChatEditorConfigMessageTypeInterface, ChatEditorControllerInterface, HandleKeyCommand, ToolbarPlugin } from "./indexModel";
import { FaSmile, FaPaw, FaUtensils, FaFutbol, FaPlane, FaBell, FaHeart, FaFlag } from 'react-icons/fa';

// ChatEditor
import { EditorState, getDefaultKeyBinding, RichUtils, Modifier, convertToRaw, DraftHandleValue, convertFromHTML, ContentState } from 'draft-js';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import '@draft-js-plugins/emoji/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import PluginEditor from "@draft-js-plugins/editor";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolbarColorPickerController from './components/toolbarColorPicker/indexController';
import { useTranslation } from "react-i18next";
import ToolbarLinkifyController from "./components/toolbarLinkify/indexController";
import { QuickMessageInterface, SelectedTemplateMessageType, WhatsappMessageInterface } from "./components/popupTemplateMessages/indexModel";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getNameUser } from "../../../../../../store/user";
import { getGeneralVariables, setGeneralVariables } from "../../../../../../store/internal";

const ChatEditorComponentController: FC<ChatEditorControllerInterface> = (props) => {
  const { t } = useTranslation('ChatEditorComponent');
  const dispatch = useDispatch();

  const {
    currentConsumer,
    currentTicket,
    ticketIsBlocked,
    replyMessage,
    setReplyMessage,
    isLoadingRequest,
  } = useViewTicketContext();

  const internalNote: ChatEditorConfigMessageTypeInterface = {
    icon: <IconWriting />, id: 'internal-note', label: t('select_message_type.internal_note')
  }
  const publicResponse: ChatEditorConfigMessageTypeInterface = {
    icon: <IconMessage2 />, id: 'public-response', label: t('select_message_type.public_response')
  }

  const values: TokenInterface = {
    user: {
      name: useSelector(getNameUser),
    },
  };

  const chatEditorConfigRef = useRef<HTMLDivElement>(null); 
  const chatEditorTextRef = useRef<PluginEditor>(null); 
  const [showSelectMessageType, setShowSelectMessageType] = useState(false);
  const [selectedMessageType, setSelectedMessageType] = useState<ChatEditorConfigMessageTypeInterface>(publicResponse);
  const [currentChannel, setCurrentChannel] = useState<string>();
  const [blockSelectMessageType, setBlockSelectMessageType] = useState(false);
  const editorStateTempRef = useRef<EditorState>(EditorState.createEmpty());
  
  // Scenes
  const [showColorPickerText, setShowColorPickerText] = useState(false);
  const [showColorPickerBackground, setShowColorPickerBackground] = useState(false);
  const [showLinkify, setShowLinkify] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  
  // FileUploader
  const [showFileUploader, setShowFileUploader] = useState(false);
  const attachmentIconRef = useRef<HTMLSpanElement>(null);
  const [filesPasted, setFilesPasted] = useState<File[]>([]);

  // AudioRecorder
  const [showAudioRecorder, setShowAudioRecorder] = useState(false);
  const audioRecorderIconRef = useRef<HTMLSpanElement>(null);

  // States DraftJS
  const [isDraftInitialized, setIsDraftInitialized] = useState(false);
  const [plugins, setPlugins] = useState<ToolbarPlugin>();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);

  // PopupRecipientsEmail
  const [showPopupRecipientsEmail, setShowPopupRecipientsEmail] = useState(false);
  const [quantityMoreEmails, setQuantityMoreEmails] = useState(0);
  const [listCC, setListCC] = useState<string[]>([]);
  const [listCCO, setListCCO] = useState<string[]>([]);

  // PopupTemplateMessages
  const [showPopupTemplateMessages, setShowPopupTemplateMessages] = useState(false);
  const [hasTemplateMessageSelected, setHasTemplateMessageSelected] = useState(false);
  const [selectedTemplateMessage, setSelectedTemplateMessage] = useState<SelectedTemplateMessageType>({ quick: null, whatsapp: null });
  const [hasTemplateMessageAttachment, setHasTemplateMessageAttachment] = useState(false);
  const generalVariables = useSelector(getGeneralVariables);
  
  useEffect(() => {
    initializeDraftJS();

    if (props.type === 'create-ticket') {
      selectMessageType(publicResponse);
      setBlockSelectMessageType(true);
    }
  }, []);

  useEffect(() => {
    if (currentConsumer) {
      const channels = [];
      currentConsumer.channels?.forEach(item => {
        channels.push(getChannelInfo(item.id));
      });
    }
  }, [currentConsumer]);

  useEffect(() => {
    if (currentTicket) {
      setCurrentChannel(currentTicket.channel_id);
      setHasTemplateMessageSelected(false);
      setHasTemplateMessageAttachment(false);
      setSelectedTemplateMessage({ quick: null, whatsapp: null });
      setReplyMessage(null);
      setShowAudioRecorder(false);
      setShowFileUploader(false);

      if (currentTicket.channel_id === Channels.Telefonia) {
        selectMessageType(internalNote);
        setBlockSelectMessageType(true);
      } else {
        selectMessageType(publicResponse);
        setBlockSelectMessageType(false);
      }
    }
  }, [currentTicket]);

  useEffect(() => {
    let quantityEmails = 0;
    if (props.type === 'view-ticket') {
      quantityEmails += listCC?.length || 0;
      quantityEmails += listCCO?.length || 0;
    } else {
      quantityEmails += props.listCC?.length || 0;
      quantityEmails += props.listCCO?.length || 0;
    }
    setQuantityMoreEmails(quantityEmails);
  }, [listCC, listCCO, props.listCC, props.listCCO]);

  useEffect(() => {
    if (showSelectMessageType) {
      const handleClickOutside = (event: any) => {
        if (chatEditorConfigRef?.current && !chatEditorConfigRef?.current?.contains(event.target)) {
          setShowSelectMessageType(false);
        }
      }
  
      if (chatEditorConfigRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showSelectMessageType]);

  useEffect(() => {
    setIsEditorEmpty(!props.editorState.getCurrentContent().hasText() && props.editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled');
  }, [props.editorState]);

  useEffect(() => {
    if (props.isLoadingViewTicket) {
      chatEditorTextRef.current?.blur();
    } else {
      chatEditorTextRef.current?.focus();
    }
  }, [props.isLoadingViewTicket]);

  useEffect(() => {
    if (props.audioRecordedFiles?.length > 0) {
      setShowAudioRecorder(false);
      setShowFileUploader(true);
    }
  }, [props.audioRecordedFiles]);

  useEffect(() => {
    if (props.triggerTemplate) {
      setSelectedTemplateMessage({ quick: null, whatsapp: props.triggerTemplate });

      setHasTemplateMessageSelected(true);
      setSelectedTemplateMessage({ quick: null, whatsapp: props.triggerTemplate });

      setHasTemplateMessageAttachment(!!props.triggerTemplate?.image_url);

      props.setFiles([]);
      props.setHasFileUploader(false);
    } else {
      setSelectedTemplateMessage({ quick: null, whatsapp: null });
      props.setFiles([]);
      props.setHasFileUploader(false);
      setHasTemplateMessageAttachment(false);
      setHasTemplateMessageSelected(false);
    } 
  }, [props.triggerTemplate]);

  useEffect(() => {
    if (props.existentHTML) {
      const blocksFromHtml = htmlToDraft(props.existentHTML);

      const { contentBlocks, entityMap } = blocksFromHtml;
      
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      props.setEditorState(EditorState.createWithContent(contentState));
    }
  }, [props.existentHTML]);

  useEffect(() => {
    if (props.type === 'create-ticket' && props.createSelectedChannel?.id) {
      handleClearTemplateMessage();
      props.setEditorState(EditorState.createEmpty());
    }
  }, [props.type, props.createSelectedChannel?.id]);

  useEffect(() => {
    if (props.type !== 'message-trigger') {
      if (props.statusChatEditor?.blocked_type) {
        editorStateTempRef.current = props.editorState;
        props.setEditorState(EditorState.createEmpty());
      } else {
        if (editorStateTempRef.current) {
          props.setEditorState(editorStateTempRef.current);
          editorStateTempRef.current = EditorState.createEmpty();
        }
      }
    }
  }, [props.type, props.statusChatEditor?.blocked_type]);

  const initializeDraftJS = useCallback(() => {
    // Toolbar
    const toolbarPlugin = createToolbarPlugin();

    // Emojis
    const emojiPlugin = createEmojiPlugin({
      theme: defaultTheme,
      useNativeArt: true,
      selectPopperOptions: { placement: 'top', strategy: props.type === 'view-ticket' ? 'absolute' : 'fixed' },
      selectGroups: [
        { title: getEmojiTranslation('people'), categories: ['people'], icon: <FaSmile /> },
        { title: getEmojiTranslation('nature'), categories: ['nature'], icon: <FaPaw /> },
        { title: getEmojiTranslation('food'), categories: ['food'], icon: <FaUtensils /> },
        { title: getEmojiTranslation('activity'), categories: ['activity'], icon: <FaFutbol /> },
        { title: getEmojiTranslation('travel'), categories: ['travel'], icon: <FaPlane /> },
        { title: getEmojiTranslation('objects'), categories: ['objects'], icon: <FaBell /> },
        { title: getEmojiTranslation('symbols'), categories: ['symbols'], icon: <FaHeart /> },
        { title: getEmojiTranslation('flags'), categories: ['flags'], icon: <FaFlag /> },
      ]
    });

    // Links - Permitir renderização de links no editor
    const linkPlugin = createLinkPlugin();

    setPlugins({ toolbarPlugin, emojiPlugin, linkPlugin });

    setIsDraftInitialized(true);
  }, []);

  const getEmojiTranslation = (title: 'people' | 'nature' | 'food' | 'activity' | 'travel' | 'objects' | 'symbols' | 'flags') => {
    return t(`editor_toolbar.emoji_popup.${title}`);
  }

  const handleToolbarClick = (plugin?: string) => {
    if (!hasTemplateMessageSelected || (props.type === 'message-trigger')) {
      if (plugin === 'audioRecorder') {
        if (props.type === 'view-ticket') {
          handleShowAudioRecorder();
        } else if (['create-ticket', 'message-trigger'].includes(props.type) && props.createSelectedChannel?.id) {
          handleShowAudioRecorder();
        }
      } else if (plugin === 'fileUploader') {
        if (props.type === 'view-ticket') {
          setShowFileUploader(!showFileUploader);
          handleShowAudioRecorder(false);
        } else if (['create-ticket', 'message-trigger'].includes(props.type) && props.createSelectedChannel?.id) {
          setShowFileUploader(!showFileUploader);
          handleShowAudioRecorder(false);
        }
      } else if (plugin === 'emojis') {
        setShowEmojis(!showEmojis);
        handleShowAudioRecorder(false);
      } else if (plugin === 'link') {
        handleShowAudioRecorder(false);
      } else if (plugin === 'color-text') {
        setShowColorPickerText(!showColorPickerText);
        handleShowAudioRecorder(false);
      } else if (plugin === 'color-background') {
        setShowColorPickerBackground(!showColorPickerBackground);
        handleShowAudioRecorder(false);
      } else {
        handleShowAudioRecorder(false);
      }
    }
  }

  const getToolbar = (): JSX.Element => {
    const { toolbarPlugin, emojiPlugin, linkPlugin } = plugins;

    const { Toolbar } = toolbarPlugin;
    const { EmojiSelect } = emojiPlugin;

    const colorPickerActiveCss = showColorPickerText ? 'span-toolbar-active' : '';
    const fileUploaderActiveCss = showFileUploader ? 'span-toolbar-active' : '';
    const fileUploaderDisabledCss = ['create-ticket', 'message-trigger'].includes(props.type) && !props.createSelectedChannel?.id ? 'span-toolbar-disabled' : '';
    const linkifyActiveCss = showLinkify ? 'span-toolbar-active' : '';
    const audioRecorderActiveCss = showAudioRecorder ? 'span-toolbar-active' : '';
    const audioRecorderDisabledCss = ['create-ticket', 'message-trigger'].includes(props.type) && !props.createSelectedChannel?.id ? 'span-toolbar-disabled' : '';

    if (toolbarPlugin && emojiPlugin && linkPlugin) {
      if (!hasTemplateMessageSelected || (props.type === 'message-trigger')) {
        return (
          <Toolbar>
            {(externalProps) => (
              <>
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('bold')) &&
                <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip-bold">{t('editor_toolbar.bold')}</Tooltip>}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('bold')}><BoldButton {...externalProps} /></span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('italic')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.italic')}</Tooltip>}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('italic')}><ItalicButton {...externalProps} /></span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('underline')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.underline')}</Tooltip>}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('underline')}><UnderlineButton {...externalProps} /></span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('text-color')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.text_color')}</Tooltip>} show={showColorPickerText ? false : undefined}>
                  <span className={`field-toolbar-action ${colorPickerActiveCss}`} onClick={() => handleToolbarClick('color-text')}>
                    <ToolbarColorPickerController 
                      type='text'
                      editorState={props.editorState} 
                      setEditorState={props.setEditorState} 
                      showColorPicker={showColorPickerText}
                      setShowColorPicker={setShowColorPickerText}
                    />
                  </span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('background-color')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.background_color')}</Tooltip>} show={showColorPickerBackground ? false : undefined}>
                  <span className={`field-toolbar-action ${colorPickerActiveCss}`} onClick={() => handleToolbarClick('color-background')}>
                    <ToolbarColorPickerController 
                      type='background'
                      editorState={props.editorState} 
                      setEditorState={props.setEditorState} 
                      showColorPicker={showColorPickerBackground}
                      setShowColorPicker={setShowColorPickerBackground}
                    />
                  </span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('list-ordered')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.ordered_list')}</Tooltip>}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('list')}><OrderedListButton {...externalProps} /></span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('list-unordered')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.unordered_list')}</Tooltip>}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('list')}><UnorderedListButton {...externalProps} /></span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('link')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.insert_link')}</Tooltip>} show={showLinkify ? false : undefined}>
                  <span className={`field-toolbar-action ${linkifyActiveCss}`} onClick={() => handleToolbarClick('link')}>
                    <ToolbarLinkifyController 
                      editorState={props.editorState} 
                      setEditorState={props.setEditorState}
                      showLinkify={showLinkify}
                      setShowLinkify={setShowLinkify}
                    />
                  </span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('file-uploader')) &&
                <OverlayTrigger overlay={<Tooltip>{t(fileUploaderDisabledCss ? 'editor_toolbar.select_channel' : 'editor_toolbar.attach_files')}</Tooltip>}>
                  <span className={`field-toolbar-action ${fileUploaderActiveCss} ${fileUploaderDisabledCss}`} onClick={() => handleToolbarClick('fileUploader')} ref={attachmentIconRef}>
                    <div className='toolbar-attachment'>
                      <IconPaperclip />
                    </div>
                  </span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('audio-recorder')) &&
                <OverlayTrigger overlay={<Tooltip>{t(audioRecorderDisabledCss ? 'editor_toolbar.select_channel' : 'editor_toolbar.record_audio')}</Tooltip>}>
                  <span className={`field-toolbar-action ${audioRecorderActiveCss} ${audioRecorderDisabledCss}`} onClick={() => handleToolbarClick('audioRecorder')} ref={audioRecorderIconRef}>
                    <div className='toolbar-audio-recorder'>
                      <IconMicrophone />
                    </div>
                  </span>
                </OverlayTrigger>
              }
              { (!props.hiddenToolbarItems || !props.hiddenToolbarItems?.includes('emoji')) &&
                <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.emoji')}</Tooltip>} show={showEmojis ? false : undefined}>
                  <span className="field-toolbar-action" onClick={() => handleToolbarClick('emojis')}><EmojiSelect {...externalProps} /></span>
                </OverlayTrigger>
              }
              </>
            )}
          </Toolbar>
        );
      } else {
        return (
          <div className="chat-editor-field-toolbar-disabled-message">
            <span className="chat-editor-field-toolbar-disabled-text">
              <span className="chat-editor-field-toolbar-disabled-message-text-value">
              {t('editor_toolbar.faq_selected')}
              </span>
              <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.faq_selected_tooltip')}</Tooltip>}>
                <IconInfoCircle />
              </OverlayTrigger>
            </span>
            <OverlayTrigger overlay={<Tooltip>{t('editor_toolbar.faq_remove')}</Tooltip>}>
              <span className="chat-editor-field-template-message-clear" onClick={() => handleClearTemplateMessage()}>
                <IconMessage2Off />
              </span>
            </OverlayTrigger>
          </div>
        );
      }
    }
  }

  const handleEditorChange = useCallback((newState: EditorState) => {
    props.setEditorState(newState);
  }, []);

  const selectMessageType = (item: ChatEditorConfigMessageTypeInterface) => {
    setSelectedMessageType(item);
    setShowSelectMessageType(false);
  }

  const getChannelInfo = (channel_id: string) => {
    if (channel_id === Channels.Email) {
      return { icon: <IconMail />, id: channel_id, label: t('channels.email') };
    } else if (channel_id === Channels.WhatsApp) {
      return { icon: <IconBrandWhatsapp />, id: channel_id, label: t('channels.whatsapp') };
    } else if (channel_id === Channels.Facebook) {
      return { icon: <IconBrandFacebook />, id: channel_id, label: t('channels.facebook') };
    } else if (channel_id === Channels.Instagram) {
      return { icon: <IconBrandInstagram />, id: channel_id, label: t('channels.instagram') };
    } else if (channel_id === Channels.Webchat) {
      return { icon: <IconMessages />, id: channel_id, label: t('channels.webchat') };
    } else if (channel_id === Channels.Telefonia) {
      return { icon: <IconPhoneOutgoing />, id: channel_id, label: t('channels.telephony') };
    }
  }

  const openSelectMessageType = () => {
    if (!blockSelectMessageType) {
      setShowSelectMessageType(!showSelectMessageType);
    }
  }

  const focusEditor = () => {
    chatEditorTextRef.current?.focus();
  }

  const isLoadingFileUpload = () => {
    return props.files?.map(item => !!item?.isLoading).includes(true) || false;
  }

  const submit = () => {
    if ((!isEditorEmpty || props.hasFileUploader) && !isLoadingFileUpload() && !isLoadingRequest()) {
      const contentState = props.editorState.getCurrentContent();
      const html = draftToHtml(convertToRaw(contentState));
      
      // Regex para alterar o target de links para abrir em nova aba
      const regexLinkTarget = /target="_self"/g;
      const formattedHtml = html.replace(regexLinkTarget, 'target="_blank"').replace(/\n$/, '');

      const filesUploaded = props.files?.filter(file => !!file.bucketId).map(file => file.bucketId) || [];
      const filesNotUploaded = props.files?.filter(file => !file.bucketId) || [];

      if (Object.hasOwn(props, 'sendMessage')) {
        const formSocket = {
          content: !isEditorEmpty ? formattedHtml : undefined, 
          files: filesUploaded, 
          is_internal: selectedMessageType?.id === 'internal-note',
        };

        if (selectedTemplateMessage?.quick?.id) {
          formSocket['id_quick_message'] = selectedTemplateMessage.quick.id;

          if (selectedTemplateMessage.quick.quick_message_attachment) {
            formSocket['template_message_attachment'] = [selectedTemplateMessage.quick.quick_message_attachment];
          }
        } else if (selectedTemplateMessage?.whatsapp?.id) {
          formSocket['id_template_message'] = selectedTemplateMessage.whatsapp.id;
          if (selectedTemplateMessage.whatsapp.image_url) {
            formSocket['template_message_attachment'] = [selectedTemplateMessage.whatsapp.image_url];
          }
        }
        if(generalVariables.length > 0) {
          formSocket['template_params'] = generalVariables;
        }
        // Limpando as variaveis gerais do templateMessage para evitar adicionar no final ao invés de substituir
        dispatch(setGeneralVariables([]));

        props.sendMessage(formSocket);
      }

      const currentContent = ContentState.createFromText('');
      const newEditorState = EditorState.push(props.editorState, currentContent, 'remove-range');
      props.setEditorState(EditorState.moveFocusToEnd(newEditorState));

      props.setFiles(filesNotUploaded);

      if (hasTemplateMessageSelected) {
        handleClearTemplateMessage(true);
      }

      if (filesNotUploaded.length === 0) {
        props.setHasFileUploader(false);
      }

      setReplyMessage(null);
    }
  };

  const customStyleFn = (styleSet: any) => {
    const styles: any = {};
    styleSet.forEach((style: string) => {
      if (style.startsWith('color-')) {
        styles.color = style.replace('color-', '');
      }
      if (style.startsWith('bgcolor-')) {
        styles.backgroundColor = style.replace('bgcolor-', '');
      }
    });
    return styles;
  };

  const handleKeyCommand = (command: HandleKeyCommand, editorState: EditorState): DraftHandleValue => {
    if (command === 'tab') {
      const submitButton = document.getElementById('chat-editor-field-send');
      if (submitButton) {
        submitButton.focus();
      }
      return 'handled';
    } else if (command === 'newline') {
      // Inserir uma quebra de linha
      const newState = RichUtils.insertSoftNewline(editorState);
      props.setEditorState(newState);
      return 'handled';
    } else if (command === 'new-list-item') {
      // Inserir uma quebra de linha ou nova linha na lista de itens
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const blockType = currentContent.getBlockForKey(selection.getStartKey()).getType();

      if (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') {
        const newBlock = Modifier.splitBlock(currentContent, selection);
        const newEditorState = EditorState.push(editorState, newBlock, 'split-block');
        props.setEditorState(newEditorState);
        return 'handled';
      } else {
        const newState = RichUtils.insertSoftNewline(editorState);
        props.setEditorState(newState);
        return 'handled';
      }
    } else if (command === 'submit') {
      // Executar ação de enviar (exemplo de implementação)
      submit();
      return 'handled';
    }
    return 'not-handled';
  };

  // Função para obter o comando de teclado personalizado
  const keyBindingFn = (e: KeyboardEvent) => {
    const key = e.key.toLocaleLowerCase();
    if (key === 'enter') {
      if (e.shiftKey) { // Shift + Enter para quebra de linha
        return 'newline';
      } else if (e.ctrlKey || e.metaKey) { // Ctrl/Meta + Enter para quebra de linha com adição de item em lista
        return 'new-list-item';
      } else { // Somente enter para envio de mensagem
        if (props.type === 'view-ticket') {
          return 'submit';
        } else {
          return 'newline';
        }
      }
    } else if (key === 'tab') { // Tab para alterar o foco para o botão de enviar
      return 'tab';
    }

    return getDefaultKeyBinding(e);
  };

  // const handleOnPaste = (e: ClipboardEvent<HTMLDivElement>) => {
  //   try {
  //     e.preventDefault();
  //     const clipboardItems = e.clipboardData.items;

  //     if (clipboardItems.length === 0) {
  //       return;
  //     }

  //     const file = clipboardItems[0];

  //     if (file?.kind === 'file') {
  //       setShowFileUploader(true);
  //       const blob = file.getAsFile();
  //       if (blob.type) {
  //         setFilesPasted([blob]);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleOnPaste = (e: ClipboardEvent<HTMLDivElement>) => {
    try {
      e.preventDefault();
      const clipboardItems = e.clipboardData.items;

      if (clipboardItems.length === 0) {
        return;
      }

      const files: File[] = [];

      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];

        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file.type) {
            files.push(file);
          }
        }
      }

      if (files.length > 0) {
        setShowFileUploader(true);
        setFilesPasted(files);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleShowAudioRecorder = (value?: boolean) => {
    if (!showAudioRecorder && props.setTicketChatEditorContainerHeight) {
      props.setTicketChatEditorContainerHeight(210);
    }
    if (value !== undefined) {
      setShowAudioRecorder(value);
    } else {
      setShowAudioRecorder(!showAudioRecorder);
    }
  }

  const handleShowMoreEmails = () => {
    setShowPopupRecipientsEmail(true);
  }

  const templateMessageVisibility = (): { popup: boolean; quick: boolean; whatsapp: boolean; } => {
    const visibility = { popup: false, quick: false, whatsapp: false };
    if (props.type === 'create-ticket') {
      if (props.createSelectedChannel?.id) {
        if (props.createSelectedChannel.id === Channels.WhatsApp) {
          visibility.popup = true;
          visibility.whatsapp = true;
        } else if (props.createSelectedChannel.id === Channels.Email) {
          visibility.popup = true;
          visibility.quick = true;
        }
      }
    } else {
      if (currentChannel !== Channels.Telefonia && !ticketIsBlocked) {
        visibility.popup = true;
        if (currentChannel === Channels.WhatsApp) {
          visibility.whatsapp = true;
  
          if (currentTicket?.consumer_last_message) {
            visibility.quick = true;
          }
        } else {
          visibility.quick = true;
        }
      }
    }

    return visibility;
  }

  const replacePlaceholders = (message, tags) => {
    let updatedMessage = message;
    if (!tags || !Array.isArray(tags)) return updatedMessage;

    // Cria um mapa para associar alias com os dados correspondentes
    const tagMap = tags.reduce((map, tag) => {
      map[tag.alias] = tag;
      return map;
    }, {});

    // Encontra todos os placeholders no formato {{alias}}
    const placeholders = updatedMessage.match(/{{.*?}}/g);

    if (placeholders) {
      placeholders.map((placeholder) => {
        const alias = placeholder.replace(/{{|}}/g, ""); // Remove as chaves para obter o alias
        const tag = tagMap[alias];

        if (tag) {
          switch (tag.tag) {
            case "consumer.name":
              if (currentConsumer?.name !== null && currentConsumer?.name !== undefined) {
                updatedMessage = updatedMessage.replace(placeholder, currentConsumer.name);
              } else {
                updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
              }
              break;
            case "consumer.phone":
              if (currentConsumer?.phone !== null && currentConsumer?.phone !== undefined) {
                updatedMessage = updatedMessage.replace(placeholder, currentConsumer.phone);
              } else {
                updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
              }
              break;
            case "consumer.email":
              if (currentConsumer?.email !== null && currentConsumer?.email !== undefined) {
                updatedMessage = updatedMessage.replace(placeholder, currentConsumer.email);
              } else {
                updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
              }
              break;
            case "employee.name":
              if (values.user?.name !== null && values.user?.name !== undefined) {
                updatedMessage = updatedMessage.replace(placeholder, values.user.name);
              } else {
                updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
              }
              break;
            default:
              updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
          }
        } else {
          updatedMessage = updatedMessage.replace(placeholder, `{{${alias}}}`);
        }
      });
    }

    return updatedMessage;
  };

  const handleClickTemplateMessage = (quickMessage: QuickMessageInterface, whatsappMessage: WhatsappMessageInterface) => {
    const urlRegex = /(https?:\/\/[^\s]+[^\s.,!?(){}[\]<>:;])/g;
    let message = quickMessage ? quickMessage.message || '' : whatsappMessage.message || '';
    let item = quickMessage ? quickMessage : whatsappMessage;

    message = replacePlaceholders(item?.message, item?.tags)
    // Replace URLs with anchor tags
    message = message.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    const blocksFromHTML = convertFromHTML(message);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    props.setEditorState(EditorState.createWithContent(contentState));
    setHasTemplateMessageSelected(true);
    setSelectedTemplateMessage({ quick: quickMessage, whatsapp: whatsappMessage });
    setHasTemplateMessageAttachment(!!quickMessage?.quick_message_attachment || !!whatsappMessage?.image_url);

    if (Object.hasOwn(props, 'setCreateSelectedTemplateMessage')) {
      props.setCreateSelectedTemplateMessage({ quickMessage: quickMessage, whatsappMessage: whatsappMessage });
    }

    props.setFiles([]);
    props.setHasFileUploader(false);
  }

  const handleClearTemplateMessage = (sended?: boolean) => {
    if (!sended) {
      props.setEditorState(EditorState.createEmpty());
    }
    setHasTemplateMessageSelected(false);
    setSelectedTemplateMessage({ quick: null, whatsapp: null });
    setHasTemplateMessageAttachment(false);

    if (Object.hasOwn(props, 'setCreateSelectedTemplateMessage')) {
      props.setCreateSelectedTemplateMessage({ quickMessage: null, whatsappMessage: null });
    }

    props.setFiles([]);
    props.setHasFileUploader(false);
  }

  const getCurrentConsumer = () => {
    if (props.type === 'view-ticket') {
      return currentConsumer;
    } else if (props.type === 'create-ticket') {
      return props.createSelectedConsumer;
    } else {
      return null;
    }
  }

  const getCurrentChannel = () => {
    if (props.type === 'view-ticket') {
      return currentChannel || null;
    } else if (props.type === 'create-ticket' || props.type === 'message-trigger') {
      return props.createSelectedChannel?.id || null;
    } else {
      return null;
    }
  }
  
  const cancelReplyMessage = () => {
    setReplyMessage(null);
  }

  const formatReplyMessage = (message: string) => {
    if (message) {
      const slicedMessage = message.slice(0, 300);
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = slicedMessage;
      const decodedMessage = tempDiv.innerText;
    
      const cleanedMessage = decodedMessage
        .replace(/\n/g, " ")       // Remove quebras de linha
        .replace(/\s+/g, " ")     // Substitui múltiplos espaços por um espaço único
        .trim();                  // Remove espaços no início e no final
    
      if (cleanedMessage.length === 0) {
        if (replyMessage.attachments.length > 0) {
          return t('editor_info.attachment_message');
        }
      } else {
        return cleanedMessage;
      }
    }
  }

  const getChatPlaceholderI18nKey = () => {
    if (props.statusChatEditor?.blocked) {
      if (props.statusChatEditor?.blocked_type === 'not_consumer_message') {
        return 'editor_info.chat_placeholder_disabled_not_consumer_message';
      } else if (props.statusChatEditor?.blocked_type === 'last_consumer_message_24h') {
        return 'editor_info.chat_placeholder_disabled_last_consumer_message_24h';
      } else if (props.statusChatEditor?.blocked_type === 'whatsapp_only_audio') {
        return 'editor_info.chat_placeholder_disabled_whatsapp_only_audio';
      }
    } else {
      return 'editor_info.chat_placeholder';
    }
  }

  return (
    <ChatEditorComponent 
      t={t}
      type={props.type}
      chatEditorConfigRef={chatEditorConfigRef}
      chatEditorTextRef={chatEditorTextRef}
      showSelectMessageType={showSelectMessageType}
      setShowSelectMessageType={setShowSelectMessageType}
      selectedMessageType={selectedMessageType}
      selectMessageType={selectMessageType}
      internalNote={internalNote}
      publicResponse={publicResponse}
      currentChannel={getCurrentChannel()}
      currentConsumer={getCurrentConsumer()}
      blockSelectMessageType={blockSelectMessageType}
      openSelectMessageType={openSelectMessageType}
      focusEditor={focusEditor}
      editorState={props.editorState}
      setEditorState={props.setEditorState}
      isEditorEmpty={isEditorEmpty}
      statusChatEditor={props.statusChatEditor}
      getChatPlaceholderI18nKey={getChatPlaceholderI18nKey}
      isDraftInitialized={isDraftInitialized}
      handleEditorChange={handleEditorChange}
      plugins={[ plugins?.toolbarPlugin, plugins?.emojiPlugin, plugins?.linkPlugin ]}
      customStyleFn={customStyleFn}
      handleKeyCommand={handleKeyCommand}
      keyBindingFn={keyBindingFn}
      submit={submit}
      isLoadingRequest={isLoadingRequest()}
      getToolbar={getToolbar}
      files={props.files}
      setFiles={props.setFiles}
      showFileUploader={showFileUploader}
      setShowFileUploader={setShowFileUploader}
      hasFileUploader={props.hasFileUploader}
      setHasFileUploader={props.setHasFileUploader}
      handleOnPaste={handleOnPaste}
      attachmentIconRef={attachmentIconRef}
      filesPasted={filesPasted}
      setFilesPasted={setFilesPasted}
      showAudioRecorder={showAudioRecorder}
      handleShowAudioRecorder={handleShowAudioRecorder}
      audioRecorderIconRef={audioRecorderIconRef}
      audioRecordedFiles={props.audioRecordedFiles}
      setAudioRecordedFiles={props.setAudioRecordedFiles}
      currentAudioInfo={props.currentAudioInfo}
      setCurrentAudioInfo={props.setCurrentAudioInfo}
      showPopupRecipientsEmail={showPopupRecipientsEmail}
      setShowPopupRecipientsEmail={setShowPopupRecipientsEmail}
      listCC={props.type === 'view-ticket' ? listCC : props.listCC}
      setListCC={props.type === 'view-ticket' ? setListCC : props.setListCC}
      listCCO={props.type === 'view-ticket' ? listCCO : props.listCCO}
      setListCCO={props.type === 'view-ticket' ? setListCCO : props.setListCCO}
      handleShowMoreEmails={handleShowMoreEmails}
      quantityMoreEmails={quantityMoreEmails}
      showPopupTemplateMessages={showPopupTemplateMessages}
      setShowPopupTemplateMessages={setShowPopupTemplateMessages}
      templateMessageVisibility={templateMessageVisibility}
      handleClickTemplateMessage={handleClickTemplateMessage}
      hasTemplateMessageSelected={hasTemplateMessageSelected}
      handleClearTemplateMessage={handleClearTemplateMessage}
      selectedTemplateMessage={selectedTemplateMessage}
      hasTemplateMessageAttachment={hasTemplateMessageAttachment}
      editorDisabled={props.editorDisabled}
      fileUploadSpecificTypes={props.fileUploadSpecificTypes}
      hasTriggerTemplate={!!props.triggerTemplate}
      replyMessage={replyMessage}
      cancelReplyMessage={cancelReplyMessage}
      formatReplyMessage={formatReplyMessage}
      isLoadingFileUpload={isLoadingFileUpload}
    />
  );
}

export default ChatEditorComponentController;