import { FC, useEffect, useState } from "react"
import ConstsRouters from "../../../routes/constsRouter"
import { IHomeViewClientController, TabKeyType } from "./indexModel"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppRequesterController } from "../../../services/appRequester/appRequesterController"
import BackendConstants from "../../../core/constants/backend-constants"
import { TokenInterfaceControlPanel } from "../../../services/requestsInterfacesModel"
import { IconUser, IconFileDescription, IconHome, IconHeadset, IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconPhone, IconWorldWww, IconHistory, IconUserCheck, IconUserX, IconUserBolt, IconUserPause, IconUserQuestion } from "@tabler/icons-react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { ContractStatus } from "../../../core/enums/contract-status"
import constsApi from "../../../services/constsApi"
import { getToken } from "../../../store/token"
import { getUserControlPanelPermissions } from "../../../store/user_controlpanel"
import ViewClient from "."

const HomeViewClientController: FC<IHomeViewClientController> = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { state } = useLocation()
    const AppRequesterConst = new AppRequesterController()

    const [isLoading, setIsLoading] = useState(false)
    const [currentParams, setCurrentParams] = useState({})
    const [currentState, setCurrentState] = useState("")
    const [currentCompany, setCurrentCompany] = useState(undefined)
    const [currentVozxpress, setCurrentVozxpress] = useState([])
    const [currentWhatsAppById, setCurrentWhatsAppId] = useState("")
    const [aux, setAux] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [activeTab, setActiveTab] = useState("cliente");
    const [currentInstagramPages, setCurrentInstagramPages] = useState([])
    const [currentFacebookPages, setCurrentFacebookPages] = useState("")
    const [currentCompanyPages, setCurrentCompanyPages] = useState("")
    const [dataCompany, setDataCompany] = useState(null)
    const [currentAgents, setCurrentAgents] = useState([]);
    const [isActiveCompany, setIsActiveCompany] = useState(false);
    const [statusIcon, setStatusIcon] = useState('');
    const [employeesWithoutAccount, setEmployeesWithoutAccount] = useState([])
    const [employeesAccount, setEmployeesAccount] = useState([])
    const [accountsVx, setAccountsVx] = useState([])
    const [clientName, setClientName] = useState(state?.name)

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const userPermissions: { id: string, created_at: string }[] = useSelector(getUserControlPanelPermissions);
    
    useEffect(() => {
        getCompanyById()
        getWhatsAppById()
        getInstagramById()
        getFacebookById()
        getCompany()
        getCompanyEmployee()
    }, [])

    useEffect(() => {
        setAux([])
        const controller = new AbortController()

        if (values === null) {

        } else {
            setCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllInformationsToPagination(currentParams, { limit: currentLimit, page: currentPage }, controller)
                    return state
                })
                return params
            })
        }

        return () => {
            controller.abort()
        }

    }, [currentPage, currentState])

    useEffect(() => {
        getContractStatusIcon(statusIcon)
    }, [statusIcon])

    const getAllInformationsToPagination = (currentParams, params: { limit: number, page: number }, controller: AbortController) => {
        getVoxpress(params, controller)
        //getAllEmployees(params, controller)
    }
    
    const updateListAfterDelete = () => {
        setAux([])
        setCurrentPage(1)

        setCurrentState("deleting")

        if (currentState == "deleting") {
            setCurrentState("")
        }
    }

    const updateListFunction = () => {
        setCurrentPage(currentPage + 1)
    }

    const callback = () => {
        setAux([])
        setCurrentPage(1)
        const controller = new AbortController()
        getAllInformationsToPagination(currentParams, { limit: currentLimit, page: currentPage }, controller)
    }

    const callbackAgents = () => {
        setAux([])
        setCurrentPage(1)
        // const controller = new AbortController()
        //getAllEmployees({ limit: currentLimit, page: currentPage }, controller)
    }

    const getCompanyById = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/contract/panel/company/${state.id}`, { headers },
            (response: Object) => {},
            (data: any) => {
                if (data.status === 200) {
                    setCurrentCompany(data.data.contract)
                    setIsActiveCompany(!!data.data.contract?.owner_email_confimation_at);
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { }
        )
    }
    const getFacebookById = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/channel-facebook/list-pages-by-company/${state.cnpj}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if ((data.status === 200 || data.status === 204) && data.data?.keys?.length > 0) {
                    setCurrentFacebookPages(data.data.keys)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const getVoxpress = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };
        const signal = controller.signal;

        await AppRequesterConst.Get(
            `/panel/vozxpress/account/${state.id}/list`, { headers, signal, params: params },
            (response: Object) => {},
            (data: any) => {
                if(data?.status === 200 || data?.status === 204) {
                    setAux((state) => {
                        let aux = state.concat(data.data.vozxpressAccounts);
                        aux = aux.map(item => ({...item, integration: true }));
                        setAux(aux)
                        setCurrentVozxpress(aux)
                        return state
                    })
                } else if (aux.length === 0) {
                    setCurrentVozxpress(undefined)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { }
        )
    }

    const getAccountsVx = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/panel/vozxpress/list-account-VX/${state?.user_id_vx}?company_id=${state.id}`, { headers },
            (response: Object) => {},
            (data: any) => {
                if(data.status === 200) {
                    setAccountsVx([])
                    let accounts = data.data.accountsVX.filter(i => !i.integrated);
                    setAccountsVx(accounts)
                }
                
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { }
        )
    }

    const getAgentsWithAccount = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/panel/vozxpress/account/${state.id}/employees-accounts`, { headers },
            (response: Object) => {},
            (data: any) => {
                if(data.status === 200) {
                    setEmployeesAccount([])
                    let agents = data.data.employees;
                    setEmployeesAccount(agents)
                }
                
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { }
        )
    }

    const getWhatsAppById = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `channel-whatsapp/whatsapp-keys/${state.id}`, { headers },
            (response: Object) => {},
            (data: any) => {
                if(data.status === 200) {
                    setCurrentWhatsAppId(data.data.whatsAppKeys)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }
    const getInstagramById = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }


        await AppRequesterConst.Get(
            `/channel-instagram/list-pages-by-company/${state.cnpj}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if ((data.status === 200 || data.status === 204) && data.data?.keys?.length > 0) {
                    setCurrentInstagramPages(data?.data.keys)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    // const getAllEmployees = async (params: { limit: number; page: number; }, controller: AbortController) => {
    //     const headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         "Authorization": "Bearer " + values.token.value
    //     }
    //     const signal = controller.signal;

    //     await AppRequesterConst.Get(
    //         `/panel/company/${state.id}/employee`, { headers, signal, params: params },
    //         (response: Object) => { },
    //         (data) => {
    //             if (data.status === 200) {
    //                 setCurrentAgents(data.data.data);
    //             }
    //         },
    //         (error: { response: { status: number; }; }) => {
    //             if (error.response !== undefined) {
    //                 if (error.response.status === 400) {
    //                 }
    //                 if (error.response.status === 401) {
    //                 }
    //             }
    //         }, navigate, dispatch, setIsLoading, { values: values }
    //     );
    // }

    const getCompany = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };
        await AppRequesterConst.Get(
            `/panel/company/${state.id}`, { headers },
            (response: Object) => {
            },
            (data: any) => {
                if (data.status === 200) {
                    setCurrentCompanyPages(data?.data?.company)
                } else if (aux.length === 0) {

                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const getCompanyEmployee = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/panel/company/${state.id}/employee`, { headers },
            (response: Object) => {},
            (data: any) => {
                data.data.data.filter((item) => item.company_id === state.id).map((item, index) => {
                    setDataCompany(item)
                })
            },
            (error: { response: { status: number } }) => {

            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    const showTab = (tabKey: TabKeyType): boolean => {
        const permissions = userPermissions.map(item => item.id);

        if (permissions.includes(constsApi.permissions_controlpanel.master)) {
            return true;
        } else if (permissions.includes(constsApi.permissions_controlpanel.read_all)) {
            return tabKey === 'historico' ? false : true;
        } else {
            switch (tabKey) {
                case 'cliente':
                    return true;
                case 'contrato':
                    return permissions.includes(constsApi.permissions_controlpanel.contract);
                case 'empresa':
                case 'vozxpress':
                case 'domain':
                    return permissions.includes(constsApi.permissions_controlpanel.company);
                case 'agentes':
                    return permissions.includes(constsApi.permissions_controlpanel.agents);
                case 'facebook':
                case 'instagram':
                case 'whatsapp':
                    return permissions.includes(constsApi.permissions_controlpanel.keys);
                default: 
                    return false;
            }
        }
    }

    const opcoes = [
        { 
            tabkey: 'cliente', title: 'Dados da conta', show: showTab('cliente'),
            icon: <IconUser size={20} color="black" />, iconActive: <IconUser size={20} color="white" /> 
        },
        { 
            tabkey: 'contrato', title: 'Contrato', show: showTab('contrato'),
            icon: <IconFileDescription size={20} color="black" />, iconActive: <IconFileDescription size={20} color="white" /> 
        },
        { 
            tabkey: 'empresa', title: 'Empresa', show: showTab('empresa'),
            icon: <IconHome size={20} color="black" />, iconActive: <IconHome size={20} color="white" /> 
        },
        { 
            tabkey: 'agentes', title: 'Agentes', show: showTab('agentes'),
            icon: <IconHeadset size={20} color="black" />, iconActive: <IconHeadset size={20} color="white" /> 
        },
        { 
            tabkey: 'facebook', title: 'Facebook', show: showTab('facebook'),
            icon: <IconBrandFacebook size={20} color="black" />, iconActive: <IconBrandFacebook size={20} color="white" /> 
        },
        { 
            tabkey: 'instagram', title: 'Instagram', show: showTab('instagram'),
            icon: <IconBrandInstagram size={20} color="black" />, iconActive: <IconBrandInstagram size={20} color="white" /> 
        },
        { 
            tabkey: 'whatsapp', title: 'WhatsApp', show: showTab('whatsapp'),
            icon: <IconBrandWhatsapp size={20} color="black" />, iconActive: <IconBrandWhatsapp size={20} color="white" /> 
        },
        { 
            tabkey: 'vozxpress', title: 'Vozxpress', show: showTab('vozxpress'),
            icon: <IconPhone size={20} color="black" />, iconActive: <IconPhone size={20} color="white" /> 
        },
        { 
            tabkey: 'domain', title: 'Domínios', show: showTab('domain'),
            icon: <IconWorldWww size={20} color="black" />, iconActive: <IconWorldWww size={20} color="white" /> 
        },
        { 
            tabkey: 'historico', title: 'Histórico', show: showTab('historico'),
            icon: <IconHistory size={20} color="black" />, iconActive: <IconHistory size={20} color="white" /> 
        }
    ];

    const getContractStatusIcon = (contract_status_id: string) => {
        const default_style = {
            borderRadius: '50%', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }

        switch (contract_status_id) {
            case ContractStatus.Active:
                return (
                    <OverlayTrigger key={contract_status_id} placement="top" overlay={<Tooltip>Empresa ativa</Tooltip>}>
                        <div style={{ backgroundColor: '#E0F3E8', ...default_style }}><IconUserCheck size={30} color='#46AC6F' /></div>
                    </OverlayTrigger>
                );
            case ContractStatus.Inactive:
                return (
                    <OverlayTrigger key={contract_status_id} placement="top" overlay={<Tooltip>Empresa inativa</Tooltip>}>
                        <div style={{ backgroundColor: '#E8E8E8', ...default_style }}><IconUserX size={30} color='#707070' /></div>
                    </OverlayTrigger>
                );
            case ContractStatus.Trial:
                return (
                    <OverlayTrigger key={contract_status_id} placement="top" overlay={<Tooltip>Período grátis</Tooltip>}>
                        <div style={{ backgroundColor: '#EEF3FF', ...default_style }}><IconUserBolt size={30} color='#0C2461' /></div>
                    </OverlayTrigger>
                );
            case ContractStatus.EndTrial:
                return (
                    <OverlayTrigger key={contract_status_id} placement="top" overlay={<Tooltip>Fim da gratuidade</Tooltip>}>
                        <div style={{ backgroundColor: '#FFE5CF', ...default_style }}><IconUserPause size={30} color='#FF8119' /></div>
                    </OverlayTrigger>
                );
            default:
                return (
                    <OverlayTrigger key={contract_status_id} placement="top" overlay={<Tooltip>Status desconhecido</Tooltip>}>
                        <div style={{ backgroundColor: '#E8E8E8', ...default_style }}><IconUserQuestion size={30} color='#707070' /></div>
                    </OverlayTrigger>
                );
        }
    };

    const isEditAllowed = (tabKey: TabKeyType) => {
        const permissions = userPermissions.map(item => item.id);

        if (permissions.includes(constsApi.permissions_controlpanel.master)) {
            return true;
        } else if (permissions.includes(constsApi.permissions_controlpanel.read_all)) {
            return false;
        } else {
            switch (tabKey) {
                case 'cliente':
                    return true;
                case 'contrato':
                    return permissions.includes(constsApi.permissions_controlpanel.contract);
                case 'empresa':
                case 'vozxpress':
                case 'domain':
                    return permissions.includes(constsApi.permissions_controlpanel.company);
                case 'agentes':
                    return permissions.includes(constsApi.permissions_controlpanel.agents);
                case 'facebook':
                case 'instagram':
                case 'whatsapp':
                    return permissions.includes(constsApi.permissions_controlpanel.keys);
                default: 
                    return false;
            }
        }
    }

    const listOfHomeClients = () => {
        navigate(ConstsRouters.routers.controlPanelclients.path)
    }

    const formatCNPJ = (cnpj: string) => {
        const result = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        return result
    }

    return <ViewClient 
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        listOfHomeClients={listOfHomeClients} 
        getContractStatusIcon={getContractStatusIcon} 
        statusIcon={statusIcon} 
        state={state} 
        handleTabChange={handleTabChange} 
        opcoes={opcoes} 
        activeTab={activeTab} 
        currentCompany={currentCompany} 
        currentWhatsAppById={currentWhatsAppById} 
        aux={aux} 
        currentFacebookPages={currentFacebookPages} 
        currentInstagramPages={currentInstagramPages} 
        updateListFunction={updateListFunction} 
        currentAgents={currentAgents} 
        getWhatsAppById={getWhatsAppById} 
        isEditAllowed={isEditAllowed} 
        updateListAfterDelete={updateListAfterDelete} 
        values={values} 
        getFacebookById={getFacebookById} 
        getInstagramById={getInstagramById} 
        callback={callback} 
        callbackAgents={callbackAgents} 
        dataCompany={dataCompany} 
        isActiveCompany={isActiveCompany} 
        currentCompanyPages={currentCompanyPages} 
        getCompanyById={getCompanyById} 
        getCompany={getCompany} 
        setStatusIcon={setStatusIcon} 
        employeesAccount={employeesAccount}
        accountsVx={accountsVx}
        clientName={clientName}
        setClientName={setClientName}
    />
}

export default HomeViewClientController;