import { Formik } from "formik"
import { Button, Col, Form, FormLabel, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import DatePicker, { DateObject } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br";
import UserService from "../../../../../services/user-service"
import { DataCompanyInterface } from "../../../../../services/requestsInterfacesModel"
import { setShowAlertFeedback } from "../../../../../store/internal"
import InputServiceControllerNew from "./inputServiceNew/indexController"
import ServiceModalNewController from "./createTimeModalNew/indexController"
import { IconCalendar } from '@tabler/icons-react'

const ScheduleServiceTabNew = ({
    openingHours,
    show,
    handleClose,
    handleShow,
    t,
    setItems,
    items,
    setIndex,
    index,
    setStartTime,
    setEndTime,
    setDayOfWeek,
    dayOfWeek,
    setMondayCheck,
    setTuesdayCheck,
    setWednesdayCheck,
    setThursdayCheck,
    setFridayCheck,
    setSaturdayCheck,
    setSundayCheck,
    setScheduleDays,
    scheduleDays,
    datePickerRef,
    hasBot,
    transferService,
    disableTransferService,
    enableTransferService,
    handleMessageOffService,
    handleMessageOnService,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    mondayCheck,
    tuesdayCheck,
    wednesdayCheck,
    thursdayCheck,
    fridayCheck,
    saturdayCheck,
    sundayCheck,
    values,
    messageOffService,
    messageOnService,
    AppRequesterConst,
    dispatch,
    navigate,
    props,
    handleClickDeleteDataBase,
    handleCloseSave,
    warning,
    setMessageOffService,
    setMessageOnService,
    setShowFeedbackMessageOffService,
    setShowFeedbackMessageOnService,
    showFeedbackMessageOffService,
    showFeedbackMessageOnService,
    setInputId,
    reOpeningHours,
    updateForm,
    blockButtom
}) => {

    return (  
        <div className="mt-2">
            { updateForm  &&
                <Formik 
                    initialValues={{ 
                        id: "",
                        non_attendance_days: [],
                        // opening_hours: {
                        //     monday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     tuesday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     wednesday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     thursday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     friday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     saturday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     },
                        //     sunday: {
                        //         schedules: [],
                        //         is_enabled: null
                        //     }

                        // }, 
                        ticket_agent_automatic_distribution: null, 
                        distribute_ticket_without_sector: null, 
                        country: "", 
                        document_number: "", 
                        enable_nps_abandoned: null,
                        messageOffService: messageOffService,
                        messageOnService: messageOnService
                    }}
                    validate={(values) => {
                        let errors = {}
                        // validandno messagem fora
                        setMessageOffService((messageOff) => {
                            if (messageOff?.length > 255) {
                                setShowFeedbackMessageOffService(true);
                                errors['messageOffService'] = t('home_departments.schedule_service_tab.validate.message_off_service');
                            } else if (messageOff === "" || messageOff === null) {
                                setShowFeedbackMessageOffService(true);
                                errors['messageOffService'] = t('home_departments.schedule_service_tab.empty_field');
                            } else {
                                setShowFeedbackMessageOffService(false);
                            }
                            return messageOff;
                        });

                        // validando messagem dentro
                        setMessageOnService((messageOn) => {
                            if (messageOn?.length > 500) {
                                setShowFeedbackMessageOnService(true);
                                errors['messageOnService'] = t('home_departments.schedule_service_tab.validate.message_on_service');
                            } else if(messageOn === "" || messageOn === null) {
                                setShowFeedbackMessageOnService(true);
                                errors['messageOnService'] = t('home_departments.schedule_service_tab.empty_field');
                            } else {
                                setShowFeedbackMessageOnService(false);
                            }
                            return messageOn;
                        });
                    }}
                    onSubmit={async (valuesForm) => {

                        // Vai pegar os dias sem atendimento no mês
                        let non_attendance_days = [];
                        scheduleDays?.map((day) => {
                            let formatedDay = day.format("YYYY-MM-DD");

                            // Se o formato da data estiver errado a data não será enviada
                            if (formatedDay.length !== 0) {
                                non_attendance_days.push(formatedDay)
                            }
                        })

                        const JsonSend = {
                            "id": values.company.id,
                            "non_attendance_days": non_attendance_days,
                            // "opening_hours": {
                            //     "monday": {
                            //         "schedules": monday,
                            //         "is_enabled": mondayCheck
                            //     },
                            //     "tuesday": {
                            //         "schedules": tuesday,
                            //         "is_enabled": tuesdayCheck
                            //     },
                            //     "wednesday": {
                            //         "schedules": wednesday,
                            //         "is_enabled": wednesdayCheck
                            //     },
                            //     "thursday": {
                            //         "schedules": thursday,
                            //         "is_enabled": thursdayCheck
                            //     },
                            //     "friday": {
                            //         "schedules": friday,
                            //         "is_enabled": fridayCheck
                            //     },
                            //     "saturday": {
                            //         "schedules": saturday,
                            //         "is_enabled": saturdayCheck
                            //     },
                            //     "sunday": {
                            //         "schedules": sunday,
                            //         "is_enabled": sundayCheck
                            //     }
                            // },
                            "use_opening_hours": openingHours,
                            "message_opening_hours": messageOffService,
                            "message_in_attendance": messageOnService,
                            "transfer_outside_opening_hours": transferService

                        }

                        // console.log(JsonSend, "chegando aqui")

                        const headers = UserService.getHeaders();

                        await AppRequesterConst.Put(
                            '/company', JsonSend, { headers },
                            () => { },
                            (data: DataCompanyInterface) => {
                                if (data.status === 200) {
                                    dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved') }))
                                }
                            },
                            (error: any) => {
                                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }));
                            }, navigate, dispatch, props.setIsLoading
                        )
                    }}
                >
                    {({
                        values,
                        errors,
                        // touched,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                            <>
                                <ServiceModalNewController t={t} show={show} onHide={() => handleClose()} key={dayOfWeek} setItems={setItems} items={items} setIndex={setIndex} index={index} setStartTime={setStartTime} setEndTime={setEndTime} dayOfWeek={dayOfWeek} removeItemDataBse={handleClickDeleteDataBase} handleCloseSave={handleCloseSave} warning={warning} setInputId={setInputId} blockButtom={blockButtom}/>
                                <Form onSubmit={handleSubmit} style={{ overflowX: 'hidden', overflowY: 'auto', height: '68vh' }}>
                                    <Row>
                                        <div style={{ flexDirection: 'column', display: 'flex' }}>
                                            <Row>
                                                <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('home_departments.schedule_service_tab.days_on_service')}</p>
                                                <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('home_departments.schedule_service_tab.days_on_service_subtitle')}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                    <Row>
                                        <InputGroup className='d-flex align-items-center'>
                                            <Form.Check
                                                type="checkbox"
                                                id="activeSchedule"
                                                defaultChecked={openingHours}
                                                onChange={handleChange}
                                                className="form-check-input-2 d-flex align-items-center"
                                                style={{ marginRight: '15px' }}
                                                onClick={reOpeningHours}
                                            />
                                            <FormLabel className='ms-1' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', margin: '0px', userSelect: 'none' }}>
                                                {t('home_departments.schedule_service_tab.enable_service_time')}
                                            </FormLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <div style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px', borderRadius: '5px', border: '2px solid #f9f9f9', width: '65vw' }}>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.sun')} setDayOfWeek={setDayOfWeek} dayActivated={sundayCheck} dayData={sunday} activeDay={setSundayCheck} id='sunday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.mon')} setDayOfWeek={setDayOfWeek} dayActivated={mondayCheck} dayData={monday} activeDay={setMondayCheck} id='monday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.tue')} setDayOfWeek={setDayOfWeek} dayActivated={tuesdayCheck} dayData={tuesday} activeDay={setTuesdayCheck} id='tuesday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.wed')} setDayOfWeek={setDayOfWeek} dayActivated={wednesdayCheck} dayData={wednesday} activeDay={setWednesdayCheck} id='wednesday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.thu')} setDayOfWeek={setDayOfWeek} dayActivated={thursdayCheck} dayData={thursday} activeDay={setThursdayCheck} id='thursday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.fri')} setDayOfWeek={setDayOfWeek} dayActivated={fridayCheck} dayData={friday} activeDay={setFridayCheck} id='friday' disabled={openingHours} handleShow={() => handleShow()}/>
                                                <InputServiceControllerNew t={t} dayOfWeek={t('home_departments.schedule_service_tab.days.sat')} setDayOfWeek={setDayOfWeek} dayActivated={saturdayCheck} dayData={saturday} activeDay={setSaturdayCheck} id='saturday' disabled={openingHours} handleShow={() => handleShow()}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t('home_departments.schedule_service_tab.days_off_service')}</p>
                                        <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('home_departments.schedule_service_tab.days_off_service_subtitle')}</p>
                                        </Col>
                                    </Row>
                                    <Row className='TicketsRowCollums'>
                                        <Col md={5} className='d-flex align-items-center' style={{ marginLeft: "3px" }}>
                                            {/* <InputGroup className="mb-3" style={{ width: '6.5vw' }}> */}
                                                {/* <Col> */}
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        plugins={[
                                                            <DatePanel header={t('home_departments.schedule_service_tab.dates_days')} sort="date" />
                                                        ]} 
                                                        value={scheduleDays}
                                                        multiple
                                                        onChange={(selectedDates: DateObject[]) => { 
                                                            setScheduleDays(selectedDates); 
                                                        }}
                                                        locale={gregorian_pt_br}
                                                        ref={datePickerRef}
                                                        calendarPosition='top-left'
                                                        containerClassName='calendaryStyle'
                                                        weekDays={[t('home_departments.schedule_service_tab.days_calendar.sun'), t('home_departments.schedule_service_tab.days_calendar.mon'), t('home_departments.schedule_service_tab.days_calendar.tue'), t('home_departments.schedule_service_tab.days_calendar.wed'), t('home_departments.schedule_service_tab.days_calendar.thu'), t('home_departments.schedule_service_tab.days_calendar.fri'), t('home_departments.schedule_service_tab.days_calendar.sat')]}
                                                        months={[t('home_departments.schedule_service_tab.months.jan'), t('home_departments.schedule_service_tab.months.feb'), t('home_departments.schedule_service_tab.months.mar'), t('home_departments.schedule_service_tab.months.apr'), t('home_departments.schedule_service_tab.months.may'), t('home_departments.schedule_service_tab.months.jun'), t('home_departments.schedule_service_tab.months.jul'), t('home_departments.schedule_service_tab.months.aug'), t('home_departments.schedule_service_tab.months.sep'), t('home_departments.schedule_service_tab.months.oct'), t('home_departments.schedule_service_tab.months.nov'), t('home_departments.schedule_service_tab.months.dec')]}
                                                        monthYearSeparator=" "
                                                        renderButton={(direction, handleClick) => (
                                                            <>
                                                                {direction === 'right'?
                                                                    <button className={'react-datepicker__navigation react-datepicker__navigation--next calendar-month-button'} onClick={handleClick}>
                                                                        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next calendar-month-icon"></span>
                                                                    </button>:
                                                                    <button className='react-datepicker__navigation react-datepicker__navigation--previous calendar-month-button' onClick={handleClick}>
                                                                        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous calendar-month-icon"></span>
                                                                    </button>
                                                                }
                                                            </>
                                                        )}
                                                        // render={<InputIcon style={{ width: '150px', height: '25px'  }}/>}
                                                        mapDays={({ date, selectedDate, isSameDate }) => {
                                                            let props = { style: {}};
                                                            
                                                            (selectedDate as DateObject[]).map((selected) => {
                                                                if (isSameDate(date, selected as DateObject)) 
                                                                    props.style = { 
                                                                        ...props.style, 
                                                                        backgroundColor: "#4A69BD", 
                                                                        color: '#fff',
                                                                        borderRadius: "3px",
                                                                    }
                                                            });
                                                        
                                                            return props;
                                                        }}
                                                        highlightToday={false}
                                                        // disabled={!values.activeSchedule}
                                                    ></DatePicker>
                                                    {/* <IconCalendar stroke={2} style={{ marginLeft: "3px" }}/> */}
                                                {/* </Col> */}
                                            {/* </InputGroup> */}
                                        </Col>
                                    </Row>
                                    {hasBot === true && 
                                        <Row className="mt-3">
                                            <Col>
                                                <div className="switch-input">
                                                    <span style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t('home_departments.schedule_service_tab.transfer_service')}</span>
                                                    {
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    {transferService ?
                                                                        t('home_departments.schedule_service_tab.transfer_service_tooltip_active') :
                                                                        t('home_departments.schedule_service_tab.transfer_service_tooltip_inactive')
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </OverlayTrigger>
                                                    }
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={transferService}
                                                            onChange={transferService ? () => disableTransferService() : () => enableTransferService()}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="mt-3">
                                        <FormLabel style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                                            {t('home_departments.schedule_service_tab.off_service_form.message')}
                                        </FormLabel>
                                        <InputGroup className='d-flex align-items-center' style={{ width: '60%' }}>
                                            <Form.Control className="form-control-Default-text-area"
                                                isInvalid={showFeedbackMessageOffService}
                                                placeholder={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                aria-label={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                aria-describedby={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                as="textarea"
                                                id="message_off_service"
                                                name="message_off_service"
                                                onChange={(e) => { handleMessageOffService(e, handleChange) }}
                                                onBlur={handleBlur}
                                                defaultValue={values.messageOffService}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" id="feedbackOffService">
                                                {errors.messageOffService}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    <Row className="mt-3">
                                        <FormLabel style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                                            {t('home_departments.schedule_service_tab.on_service_form.message')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('home_departments.schedule_service_tab.on_service_form.tooltip_message')}
                                                        </Tooltip>
                                                    }
                                            >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                        </FormLabel>
                                        <InputGroup className='d-flex align-items-center' style={{ width: '60%' }}>
                                            <Form.Control className="form-control-Default-text-area"
                                                isInvalid={showFeedbackMessageOnService}
                                                placeholder={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                aria-label={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                aria-describedby={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                as="textarea"
                                                id="message_on_service"
                                                name="message_on_service"
                                                onChange={(e) => handleMessageOnService(e, handleChange) }
                                                onBlur={handleBlur}
                                                defaultValue={values.messageOnService}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" id="feedbackOnService">
                                                {errors.messageOnService}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    <Row style={{
                                            textAlign: 'left',
                                            font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            opacity: '1',
                                        }}  className="mt-3 d-flex"
                                    >
                                        <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                            <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('home_departments.schedule_service_tab.cancel')}</Button>
                                        </Col>
                                        <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                            <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('home_departments.schedule_service_tab.save')}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                    )}
                </Formik>
            }
        </div>
    )
}

export default ScheduleServiceTabNew