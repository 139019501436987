import { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getShowEditTicketsModal, getOrderByList, getMute } from '../../../../../store/internal'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { IModalListConsumersController } from "./indexModel";
import { useTranslation } from "react-i18next";

import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../store/company";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import ModalListConsumers from ".";
import UserService from "../../../../../services/user-service";

const ModalListConsumersController: FC<IModalListConsumersController> = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appRequesterConst = new AppRequesterController();

    const [consumers, setConsumers] = useState([]);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getOrderByList: useSelector(getOrderByList),
        }, mute: {
            value: useSelector(getMute)
        }

    };

    useEffect(() => {
        const controller = new AbortController();

        if (props.visibility) {
            getAllConsumers(props.params, controller);
        } else {
            setConsumers([]);
        }

        return () => {
            controller.abort();
        }
    }, [props.params, props.visibility]);

    const hide = () => {
        props.setVisibility(false);
    }

    const getAllConsumers = async (params: { status: string; page: number; limit: number; is_all_status: boolean; }, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        if (params?.status) {
            delete params.status;
        }

        if (params?.page) {
            delete params.page;
        }

        if (params?.limit) {
            delete params.limit;
        }

        if (params?.is_all_status) {
            delete params.is_all_status;
        }

        await appRequesterConst.Get(
            '/consumer/dashboard/get-consumers', { headers, signal, params: params },
            (response: Object) => { },
            (data/* : ConsumerData */) => {
                if (data.status === 200 && data?.data?.length > 0) {
                    setConsumers((state) => {
                        return state.concat(data.data);
                    });
                } else {
                    setConsumers([]);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const exportConsumersCsv = async () => {
        let consumersId = [];

        consumers.map((consumer) => {
            consumersId.push(consumer.user_id);
        });

        const JsonSend = {
            "users": consumersId,
        };

        const headers = UserService.getHeaders()

        appRequesterConst.Post(
            '/consumer/csv/export-consumers',
            JsonSend,
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                if (data.status === 200 && data.data) {
                    var decoder = new TextDecoder();
                    var uintData = Uint8Array.from(data.data.content.data);
                    var arrayBufferData = uintData.buffer;
                    var decoded = decoder.decode(arrayBufferData);

                    const blob = new Blob([decoded], { type: "text/csv;charset=utf-8;" });

                    var hiddenElement = document.createElement('a');

                    if (hiddenElement.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        hiddenElement.setAttribute("href", url);
                        hiddenElement.setAttribute("download", data.data.fileName);
                        hiddenElement.style.visibility = "hidden";
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        document.body.removeChild(hiddenElement);
                    }
                }
            },
            (error: Object) => {
            },
            navigate,
            dispatch,
            setIsLoading
        );
    }

    return <ModalListConsumers 
        modalShow={props.visibility}
        hide={hide}
        t={t}
        consumers={consumers}
        setConsumers={setConsumers}
        exportConsumersCsv={exportConsumersCsv} 
        isLoading={isLoading}    
    />
}

export default ModalListConsumersController;