import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalEditBroker from '.';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../../store/token';
import { useTranslation } from 'react-i18next';
import { AppRequesterControlPanelController } from '../../../../../services/appRequesterControlPanel/appRequesterControlPanelController';
import { Brokers } from '../../../../../core/enums/brokers';
import { useDropzone } from 'react-dropzone';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { Channels } from '../../../../../core/enums/channels';
import { verifyCode } from '../../../../../services/codeCxpressInterface';
import { Spinner } from 'react-bootstrap';

const ModalEditBrokerController = ({ show, onHide, currentData, currentWhatsappId }) => {

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [validated, setValidated] = useState(false)

    const [hasBot, setHasbot] = useState(false)
    const [selectedBroker, setSelectedBrokers] = useState([])

    const [onSelectBroker, setOnSelectBroker] = useState([])

    const [Webhook, setWebhook] = useState("")
    const [description, setDescription] = useState("")
    const [email, setEmail] = useState("")
    const [addres, setAddres] = useState("")
    const [urls, setUrls] = useState(['']);

    const [showFeedbackWebhook, setShowFeedbackWebhook] = useState(false)

    const [showFeedbackAddres, setShowFeedbackAddres] = useState(false)
    const [showFeedbackDescription, setShowFeedbackDescription] = useState(false)
    const [showFeedbackEmail, setShowFeedbackEmail] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const AppRequesteConst = new AppRequesterControlPanelController()

    const [optionsBroker, setOptionsBroker] = useState([
        { label: "Twilio", value: Brokers.Twilio },
        { label: "Positus", value: Brokers.Positus },
        { label: "Meta", value: Brokers.Meta },
        { label: "Smarters", value: Brokers.Smarters }
    ])

    useEffect(() => {
        setShowFeedbackEmail(false)
        setShowFeedbackWebhook(false)
        setShowFeedbackAddres(false)
        setShowFeedbackDescription(false)
        setWebhook("")
        setAddres("")
        setEmail("")
        setDescription("")
        setUrls([''])
    }, [])

    useEffect(() => {
        if (currentData) {
            setWebhook(currentData?.broker_config?.webhook)
            setAddres(currentData?.broker_config?.address)
            setEmail(currentData?.broker_config?.email)
            setDescription(currentData?.broker_config?.description)
            setUrls(currentData.broker_config?.websites || [''])
            // setSelectBrokerId(currentWhatsappData?.whatsAppKey?.broker_id)
        }
    }, [currentData])


    const [errors, setErrors] = useState(['']);

    const handleChangeUrl = (index, event) => {
        const newUrls = [...urls];
        newUrls[index] = event.target.value;
        setUrls(newUrls);
    };

    const addUrl = () => {
        if (urls.length < 2) {
            setUrls([...urls, '']);
            setErrors([...errors, '']);
        }
    };

    const removeUrl = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        const newErrors = errors.filter((_, i) => i !== index);
        setUrls(newUrls);
        setErrors(newErrors);
    };

    const validateUrl = (url) => {
        return !/^(http:\/\/|https:\/\/|$)/i.test(url)
    }

    const [files, setFiles] = useState([]);

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.path !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        setIsClicked(false);
        setFiles(newFiles);
    }

    const [isClicked, setIsClicked] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [file_id, setFile_id] = useState(null as string || null);
    const [errorSelectRecipients, setErrorSelectRecipients] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxSize: 5242880,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png']
        },
        noClick: isClicked,
        noDrag: isClicked,
        onDrop: async (file) => {
            let img = new Image();
            img.src = URL.createObjectURL(file[0]);
            await img.decode()
            if (img.height < 640 || img.width < 640) {
                dispatch(setShowAlertFeedback({ message: "Imagem deve ser no mínimo 640 x 640", visibility: true, signalIcon: false }));
                return
            }

            const formData = new FormData()

            formData.append("file", file[0])
            formData.append("channel_id", Channels.WhatsApp);

            const headers = {
                "Content-Type": "multipart/form-data; charset=utf-8",
                "Authorization": "Bearer " + values.token.value
            };


            AppRequesteConst.Post(
                '/panel/upload/attachment', formData, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: { id: string; }; }) => {
                    dispatch(setShowAlertFeedback({ message: "Upload feito com sucesso", visibility: true, signalIcon: true }));
                    setDeleted(false)
                    setIsClicked(true)
                    setFile_id(data.data.id);
                    setFiles(file)
                },
                (error: { response: { status: number; data: { message: any[], code_cxpress: number; }; }; }) => {
                    setIsClicked(true);

                    if (error.response?.data?.message && error.response.data.message[0]) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                    }

                    if (error.response.status === 422) {
                        dispatch(setShowAlertFeedback({ message: "Erro no upload do arquivo", visibility: true, signalIcon: false }));
                    }
                    else if (error.response.status === 400) {
                        dispatch(setShowAlertFeedback({ message: "A template não possui todos os parâmetros necessários.", visibility: true, signalIcon: false }));
                        deleteFile(files)
                        setFiles([])
                        setDeleted(true)
                    }
                    else {
                        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado", visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading,
            );
        },
        onDropRejected: (reject) => {
            if (reject[0].errors[0].code === "file-too-large") {
                dispatch(setShowAlertFeedback({ visibility: true, message: "O tamanho do arquivo excede 5MB.", signalIcon: false }));
            }
        },
    });

    const showFiles = files.map((file, index) => {
        if (!deleted) {
            return (
                <span key={index}
                    style={{
                        background: '#E8E8E8 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '0.5vw',
                        marginBottom: '1vh',
                        whiteSpace: 'pre', display: 'inline-block'
                    }}>
                    <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                    <span
                        style={{
                            textAlign: 'left',
                            font: 'var(--px10_13-font-Roboto)',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                            opacity: '1',
                        }}
                    >
                        {file.name}
                    </span>
                    {file ?
                        <svg name={file.name} onClick={() => deleteFile(file.name)} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
                            <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
                            <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg>
                        :
                        <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
                        </Spinner>
                    }
                </span>
            )
        }
    });

    return <ModalEditBroker
        show={show}
        onHide={onHide}
        currentWhatsappId={currentWhatsappId}
        Webhook={Webhook}
        description={description}
        addres={addres}
        email={email}
        setWebhook={setWebhook}
        setAddres={setAddres}
        setDescription={setDescription}
        setEmail={setEmail}
        setShowFeedbackEmail={setShowFeedbackEmail}
        validateUrl={validateUrl}
        setShowFeedbackWebhook={setShowFeedbackWebhook}
        urls={urls}
        file_id={file_id}
        AppRequesteConst={AppRequesteConst}
        values={values}
        dispatch={dispatch}
        setIsLoading={setIsLoading}
        navigate={navigate}
        validated={validated}
        showFeedbackWebhook={showFeedbackWebhook}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        deleted={deleted}
        setErrorSelectRecipients={setErrorSelectRecipients}
        showFiles={showFiles}
        errorSelectRecipients={errorSelectRecipients}
        showFeedbackAddres={showFeedbackAddres}
        showFeedbackDescription={showFeedbackDescription}
        showFeedbackEmail={showFeedbackEmail}
        handleChangeUrl={handleChangeUrl}
        addUrl={addUrl}
        removeUrl={removeUrl}

    />
};

export default ModalEditBrokerController;