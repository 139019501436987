import { useNavigate } from "react-router-dom"
import ModalViewConsumer from "."
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import UserService from "../../../../services/user-service"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TokenInterface } from "../../../../services/requestsInterfacesModel"
import { getIdCompany } from "../../../../store/company"
import { getIdEmployee } from "../../../../store/employee"
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user"
import { getToken } from "../../../../store/token"
import BackendConstants from "../../../../core/constants/backend-constants"
import { Button, Popover } from "react-bootstrap"
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../store/internal"
import { verifyCode } from "../../../../services/codeCxpressInterface"
import { useTranslation } from "react-i18next"
import { ModalViewConsumerInterface } from "./indexModel"

const ModalViewConsumerController = ({
    handleClose,
    handleShow,
    show,
    currentAgentId,
    setCurrentAgentId
}) => {

    const [isLoading, setIsLoading] = useState(false)
    // const [currentParams, setCurrentParams] = useState({})
    // const [currentState, setCurrentState] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    // const [currentLimit, setCurrentLimit] = useState(BackendConstants.limit)
    const [consumer, setConsumer] = useState<any>([])
    const [currentHasMoreInformation, SetCurrentHasMoreInformation] = useState(true)
    const [currentConsumerId, setCurrentConsumerId] = useState("")
    const [currentConsumerName, setCurrentConsumerName] = useState("")
    const [consumerInfoById, setConsumerInfoById] = useState<any>()
    

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const values: TokenInterface ={ 
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        }
    }

    const AppRequesterConst = new AppRequesterController()
    const controller = new AbortController()
    const headers = UserService.getHeaders()

    let currentAgentIdConsumer = currentAgentId

    useEffect(() => {
        if (currentAgentIdConsumer) {
            getAllConsumer(controller)
        } 
    }, [currentAgentIdConsumer])

    useEffect(() => {
        if (currentConsumerId) {
            getConsumerById(currentConsumerId)
        }
    }, [currentConsumerId])

    useEffect(() => {
        if (show == false && show != undefined) {
            setConsumer([])
            setCurrentAgentId('')
        }
    }, [show])

    const updateListFunction = () => {
        setCurrentPage(currentPage + 1)
    }

    const desassociateConsumer = () => {
        handleClose()
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_agents.modal_consumer.disassociate_consumer_modal.title_disassociate"), "body": t("home_agents.modal_consumer.disassociate_consumer_modal.body"), "id": ' ' + currentConsumerName + '?', "buttonReturnText": t("home_agents.modal_consumer.disassociate_consumer_modal.button_return"), "buttonConfirmationText": t("home_agents.modal_consumer.disassociate_consumer_modal.button_confirm") }, functionConfirmation: () => desassociateConsumerRequisition(consumerInfoById) }))
    }

    const popover = () => {
        return (
            <Popover style={{ backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    <Button className="m-1" style={{ zIndex: "500", backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => desassociateConsumer()}>{t("home_agents.modal_consumer.disassociate_consumer_modal.title_disassociate")}</Button>
                </Popover.Body>
            </Popover>
        )
    }

    const setConsumerInfo = (e: { currentTarget: { id: string } }) => {
        setCurrentConsumerName(e.currentTarget.id.split("*ID*")[0])
        setCurrentConsumerId(e.currentTarget.id.split("*ID*")[1])
    }

    const desassociateConsumerRequisition = async (consumer: { address: string, tags: any [], created_by_employee: string, custom_fields: { custom_field_id: string, value: string []} [], document_number: string, email: string, employee_user_id: string, facebook_user: string, id: string, instagram_user: string, lastname: string, name: string, observations: string, phone: string, twitter: string, user_id: string, webchat: string, whatsapp: string, youtube: string }) => {
        let JsonSend = {
            address: consumer?.address ? consumer.address : undefined,
            custom_fields: consumer?.custom_fields? consumer.custom_fields.map((customField) => {
                return { custom_field_id: customField.custom_field_id, value: customField.value }
            }): [],
            tags: consumer?.tags && consumer.tags[0]? consumer.tags.map((tag) => { return tag.id; }): [],
            document_number: consumer?.document_number ? consumer.document_number : undefined,
            email:  consumer?.email ? consumer.email : undefined,
            employee_user_id: undefined,
            facebook_user: consumer?.facebook_user ? consumer.facebook_user : undefined,
            id: consumer?.user_id,
            instagram_user: consumer?.instagram_user ? consumer.instagram_user : undefined,
            lastname: consumer?.lastname ? consumer.lastname : undefined,
            name: consumer?.name ? consumer.name : undefined,
            observations: consumer?.observations ? consumer.observations : undefined,
            phone: consumer?.phone ? consumer.phone : undefined,
            twitter: consumer?.twitter ? consumer.twitter : undefined,
            webchat: consumer?.webchat ? consumer.webchat : undefined,
            whatsapp: consumer?.whatsapp ? consumer.whatsapp : undefined,
            youtube: consumer?.youtube ? consumer.youtube : undefined
        };

        await AppRequesterConst.Put(
            `/consumer`, JsonSend, { headers },
            (response: Object) => {
                return response;
            },
            (data: any) => {
                dispatch(setShowAlertFeedback({ message: t("home_agents.modal_consumer.disassociate_consumer_modal.message_success"), visibility: true, signalIcon: true }))
                getAllConsumer(controller)
                setConsumerInfoById([])
                handleShow()
            },
            (error: { response: { status: number; data: { message: { message: string, path: [] }, code_cxpress: number } } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else if (error.response.status === 500) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading
        )
    }

    const getAllConsumer = async (controller: AbortController) => {
        
        const signal = controller.signal

        
            await AppRequesterConst.Get(
                `/consumer/?employee_user_ids=${currentAgentIdConsumer}`, { headers, signal },
                (response: Object) => {},
                (data: any) => {
                    if (data.data && data.status == 200) {
                        setConsumer(data.data)
                    }
                },
                (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                    if (error.response.status == 400) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                    } else if (error.response.status == 500) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                    } else {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                    }
                }, navigate, dispatch, setIsLoading, { values: values }
            )
        
    }

    const getConsumerById = async (userId: string) => {
        await AppRequesterConst.Get(
            `/consumer/${userId}`, { headers },
            (response: Object) => {},
            (data: any) => {
                if (data.data && data.status == 200) {
                    setConsumerInfoById(data.data.consumers[0])
                }
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                if (error.response.status == 400) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else if (error.response.status == 500) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading, { values: values, finallyFunction: () => { setCurrentConsumerId(""); } }
        )
    } 

    return (
        <ModalViewConsumer
            show={show}
            handleClose={handleClose}
            data={consumer}
            updateListFunction={updateListFunction}
            HasMoreInformation={currentHasMoreInformation}
            popover={popover}
            setConsumerInfo={setConsumerInfo}
            currentConsumerId={currentConsumerId}
            currentConsumerName={currentConsumerName}
        />
    )
}

export default ModalViewConsumerController