import { useDispatch } from "react-redux";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import UserService from "../../../../../services/user-service";
import ChangeSector from "./index";
import { useNavigate } from "react-router-dom";
import { setShowAlertFeedback } from "../../../../../store/internal";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const ChangeSectorController = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const AppRequesterConst = new AppRequesterController();
  
  const [/*isLoading*/, setIsLoading] = useState(false);

  const handleCloseModal = async () => {
    await props.reloadTicketFunction();
    props.onHide();
  }

  const updateTicket = async () => {
    const headers = UserService.getHeaders();

    const jsonSend = {...props.currentJsonSend, confirmation_update: true};
    await AppRequesterConst.Put(
      '/ticket', jsonSend, { headers },
      (response: Object) => { },
      (data: any) => {
        dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success"), visibility: true, signalIcon: true }));
      },
      (error: { response: { status: number, data: { message: string, code_cxpress: number } } }) => {
        if (error?.response.status === 400) {
          if(error.response.data?.code_cxpress === 703) {
            dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code703"), visibility: true, signalIcon: false }));
          } else if(error.response.data?.code_cxpress === 726) {
            dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code726"), visibility: true, signalIcon: false }));
          } else if(error.response.data?.code_cxpress === 739) {
            dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code739"), visibility: true, signalIcon: false }));
          } else if(error.response.data?.code_cxpress >= 751 && error.response.data.code_cxpress <= 758) {
            dispatch(setShowAlertFeedback({ message: t(`home_tickets.view_ticket.error400.code${error.response.data.code_cxpress}`), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
          }
        }
        if (error?.response.status === 401) {

        }
        if (error?.response.status === 403) {

        }
        if (error?.response.status === 422) {
          dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.without_subject"), visibility: true, signalIcon: false }));
        }
        if (error?.response.status === 500) {
          dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
        }
      },
      navigate, dispatch, setIsLoading,
      async () => {
        await props.reloadTicketFunction();
        props.onHide();
      },
  );
  }

  return (
    <ChangeSector 
      show={props.show}
      onHide={props.onHide}
      moreInfo={props.moreInfo}
      currentJsonSend={props.currentJsonSend}
      updateTicket={updateTicket}
      handleCloseModal={handleCloseModal}
    />
  )
}

export default ChangeSectorController;