import './app.scss';
import Routers from './routes/index';
import { /*useContext,*/ useEffect, useState } from 'react';
// import { SocketContext } from './core/context/socket-context';
// import { useSelector } from 'react-redux';
// import { TokenInterface } from './services/requestsInterfacesModel';
// import { getIdUser, getNameUser, getProfileId } from './store/user';
// import { getToken } from './store/token';
// import { getExtension, getPassword, getOwner_id } from './store/callcenter';
// import UserService from './services/user-service';
// import AlertPositiveNegativeController from './components/alertPositiveNegative/indexController';
// import { getShowAlertFeedback } from './store/internal';
// import AlertDisconnectSocket from './components/alertDisconnectSocket/indexController';
import AppContextProviders from './core/context';
import AppLoadingController from './components/appLoading/indexController';
import SocketWrapper from './sockets/socketWrapper';
import SingleTabDetectionController from './pages/singleTabDetection/indexController';
import emitter from './core/shared/emitter';

const App = () => {
  // const socket = useContext(SocketContext);

  // const [connectedSocket, setConnectedSocket] = useState(socket.socket?.connected);
  // const [showPhoneInsideIframe, setShowPhoneInsideIframe] = useState(false);
  const [url_iframe] = useState(window.location.href?.includes('chat-bot-iframe'));
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [fadingOut, setFadingOut] = useState(false);
  const i18nLoadedStorage = localStorage.getItem('i18n-loaded');
  const [i18nLoaded, setI18nLoaded] = useState(false);
  const [isMultiTab, setIsMultiTab] = useState(false);
  
  const TAB_KEY = 'activeTab';

  // const values: TokenInterface = {
  //   user: {
  //     id: useSelector(getIdUser),
  //     name: useSelector(getNameUser),
  //     profile_id: useSelector(getProfileId)
  //   },
  //   token: {
  //     value: useSelector(getToken)
  //   },
  //   callcenter: {
  //     extension: useSelector(getExtension),
  //     password: useSelector(getPassword),
  //     owner_id: useSelector(getOwner_id),
  //   }
  // };

  useEffect(() => {
    if (i18nLoadedStorage === 'true') {
      setI18nLoaded(true);
    } else {
      setI18nLoaded(false);
    }
  }, [i18nLoadedStorage]);
    
  // useEffect(() => { 
  //   UserService.token = values.token.value;

  //   let token = values.token.value;
  //   if (token) {
  //     socket.createSocket(token);
  //   } else {
  //     setConnectedSocket(undefined)
  //   }
  // }, [values.token.value]);

  // useEffect(() => {
  //     const element = document.getElementById('verify-iframe-parent');
  //     if (element) {
  //         if (element?.ownerDocument?.URL?.includes('chat-bot-iframe')) {
  //             setShowPhoneInsideIframe(false);
  //         } else {
  //             setShowPhoneInsideIframe(true);
  //         }
  //     }
  // }, []);

  // useEffect(() => {
  //   if(url_iframe !== true && control_panel !== true){
  //     const onConnect = () =>{
  //       setConnectedSocket(true)
  //     }
  //     const onDisconnect = () =>{
  //       setConnectedSocket(false)
  //     }

  //     socket.socket?.on('connect', onConnect)
  //     socket.socket?.on('disconnect', onDisconnect)

  //     return () => {
  //       socket.socket?.off('connect', onConnect)
  //       socket.socket?.off('disconnect', onDisconnect)
  //     }
  //   }
  // }, []);

  useEffect(() => { // Mostrar load de carregamento da aplicação ao entrar pela 1ª vez (refresh aparece tb)
    emitter.on('i18n-status', data => {
      if (data.status === 'loaded') {
        setFadingOut(true);
        setTimeout(() => {
          setI18nLoaded(true);
          setIsAppLoaded(true);
        }, 300)
      }
    });

    emitter.emit('app-loaded', true);

    return () => {
      emitter.off('i18n-status');
    }
  }, []);

  useEffect(() => { // Effect para evitar que os tooltips fiquem à mostra durante o scroll
    const handleScroll = () => {
      const tooltip = document.querySelector('.fade.show.tooltip') as HTMLDivElement;
      const popover = document.querySelector('.fade.show.popover-custom-action-menu') as HTMLDivElement;

      if (tooltip) {
        tooltip.classList.remove('show');
        tooltip.style.visibility = 'hidden';
      }

      if (popover) {
        popover.classList.remove('show');
        popover.style.visibility = 'hidden';

        if (popover.classList.contains('popover-custom-action-menu-view-ticket')) {
          emitter.emit('close-item-action-popover-view-ticket');
        }

        if (popover.classList.contains('popover-file-model-file-consumers')) {
          emitter.emit('close-action-popover-file-model-file-consumers');
        }
      }
    }

    window.addEventListener('wheel', handleScroll);
    window.addEventListener('mousedown', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('mousedown', handleScroll);
    };
  }, []);

  // const alertFeedback = useSelector(getShowAlertFeedback);

  // return (
  //   <AppContextProviders>
  //       { 
  //           alertFeedback.visibility && 
  //           <AlertPositiveNegativeController
  //               timestamp={Date.now()}
  //               visibility={alertFeedback.visibility}
  //               message={alertFeedback.message}
  //               signalIcon={alertFeedback.signalIcon}
  //               timeOut={alertFeedback.timeOut}
  //           />
  //       }
  //       { 
  //           !connectedSocket && connectedSocket !== undefined && !url_iframe &&
  //           <AlertDisconnectSocket
  //               visibility={!connectedSocket}
  //               signalIcon={alertFeedback.signalIcon} />
  //       }
  //       <div id='verify-iframe-parent' style={{display: 'none'}}></div>
  //       { i18nLoaded && <Routers /> }
  //       { !isAppLoaded && !url_iframe &&
  //           <AppLoadingController fadingOut={fadingOut} />
  //       }
  //   </AppContextProviders>       
  // );

  useEffect(() => {
    const currentTabId = Date.now(); // Identificador único para a aba
    let isCurrentTabMain = false;

    const registerTab = () => {
      const activeTab = localStorage.getItem(TAB_KEY);
      if (!activeTab) {
        // Nenhuma aba registrada, registra a atual
        localStorage.setItem(TAB_KEY, currentTabId.toString());
        isCurrentTabMain = true;
      } else if (activeTab === currentTabId.toString()) {
        // Aba já registrada, atualiza estado
        isCurrentTabMain = true;
      } else {
        // Outra aba está ativa
        setIsMultiTab(true);
      }
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === TAB_KEY && event.newValue !== currentTabId.toString()) {
        setIsMultiTab(true);
      }
    };

    const cleanup = () => {
      if (isCurrentTabMain) {
        localStorage.removeItem(TAB_KEY);
      }
    };

    registerTab();

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  }, []);

  if (isMultiTab && !url_iframe) {
    return (
      i18nLoaded ? <SingleTabDetectionController /> : <AppLoadingController fadingOut={fadingOut} />
    );
  } else {
    return (
      <AppContextProviders>
        { i18nLoaded &&
          <SocketWrapper>
            <Routers />
          </SocketWrapper>
        }
        { (!i18nLoaded || !isAppLoaded) && !url_iframe &&
          <AppLoadingController fadingOut={fadingOut} />
        }
      </AppContextProviders>
    );
  }
}

export default App;
