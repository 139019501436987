import { FC, useEffect, useState } from "react";
import ModalVisualizeTemplateMessage from ".";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getShowCreateTemplateMessage, getShowTemplateTagModal, setShowAlertFeedback, setShowCreateTemplateMessage, setShowTemplateTagModal } from "../../../../../../store/internal";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../../../store/token";
import { FormValues } from "../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/formOfModalsTemplateMessage/indexModel";
import { IModalTemplateMessageController } from "./indexModel";
import { useTranslation } from "react-i18next";

const ModalVisualizeTemplateMessageController:FC<IModalTemplateMessageController> = (props) => {
    // const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [attachmentIds, setAttachmentIds] = useState([]);

    const [tags, setTags] = useState([]);
    
    const { t } = useTranslation();

    const [language, setLanguage] = useState([{label: t('home_departments.template_message_tab.pt_br'), value: 'pt_BR'}, {label: t('home_departments.template_message_tab.en'), value: 'en_GB'}, {label: t('home_departments.template_message_tab.pt'), value: 'pt_PT'}, {label: t('home_departments.template_message_tab.fr'), value: 'fr'}, {label: t('home_departments.template_message_tab.es'), value: 'es'}]);

    const values: TokenInterface = {
        // internal: {
        //     getShowCreateTemplateMessage: useSelector(getShowCreateTemplateMessage),
        //     getShowTemplateTagModal: useSelector(getShowTemplateTagModal),
        // },
        token: {
            value: useSelector(getToken)
        }
    }

    const attachmentIdsCallback = (ids: []) => {
      setAttachmentIds(ids);
    }


    const label_language = (value: string) => {
      if (value){
        const language_label = language.find(item => (item.value === value));
        return language_label.label || '';
      }
    }

    return <ModalVisualizeTemplateMessage 
        showModal={props.showModal} 
        hide={props.onHide} 
        dispatch={dispatch}
        tags={tags}
        setTags={setTags}
        isLoading={isLoading}
        t={t}
        template={props.template}
        attachmentIdsCallback={attachmentIdsCallback}
        previewFiles={(props.template?.image_id && props.template?.image_url) ? { filename: props.template.image_url.split('/').pop(), _id: props.template.image_id, url: props.template.image_url }: null}
        label_language={label_language}
    />
}

export default ModalVisualizeTemplateMessageController;