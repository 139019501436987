import React, { useEffect, useState } from 'react'
import BotCollectedData from '.'
import { addDays, isAfter, isBefore, isSameDay } from 'date-fns';
import UserService from '../../../../../../services/user-service';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterface } from '../../../../../../services/requestsInterfacesModel';
import { getIdCompany } from '../../../../../../store/company';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { t } from 'i18next';

const BotCollectedDataController = ({
    dataBot
}) => {

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        }
    };
    
    const [isLoading, setIsLoading] = useState(false)

    const controller = new AbortController();
    const appRequesterConst = new AppRequesterController();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [hasData, setHasData] = useState(false)

    const [fieldsOptions, setFieldsOptions] = useState([]);
    const [selectedFieldOptions, setSelectedFieldOptions] = useState([]);

    const botId = dataBot?.state?.data?.id; /* pegando o ID do bot */

    /* DATA */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        getCollectedDataBot();
    }, [])

    const getCollectedDataBot = async () => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await appRequesterConst.Get(
            `bot-cxpress/${botId}/collected-fields`, { headers: headers, signal },
            (response: Object) => { },
            (data) => {
                if(data.data.code_cxpress === 1652){
                    const a = data.data.collected_fields;
                    const listCollectedFields = a.map((field, index) => { /* criando os objetos dos campos */
                        return{
                            label: field,
                            value: index
                        }
                    })
                    
                    setFieldsOptions(listCollectedFields)
                    setHasData(true)
                }
                else if(data.data.code_cxpress === 1651) {
                    setHasData(false);
                }
            },
            (error: Object) => { 
                dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.tab_collected_data.error_collected_data"), visibility: true, signalIcon: false }))
            },
            navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const onChangeDate = (dates: any) => {
        if (dates) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
        }
    };

    const calculateMaxDate = (date) => {
        if (!date) return null;
        return addDays(date, 30);
    };

    const [selectedDate, setSelectedDate] = useState(null);

    const isDateSelectable = (date) => {
        const today = new Date();
        if (!selectedDate) {// permitir datas no passado até hoje
            return isBefore(date, today)
        } else {  // permitir datas até 30 dias após a data selecionada
            const maxSelectableDate = addDays(selectedDate, 30);
            return isBefore(date, today) && isAfter(date, selectedDate) && isBefore(date, maxSelectableDate);
        }
    };

    const [showModal, setShowModal] = useState(false);

    const handleOpenModalExport = () => {
        setShowModal(true);
    }

    const handleCloseModalExport = () => {
        setShowModal(false);
    }

    const disableButtonExport = () => {
        if(selectedFieldOptions.length !== 0 && (startDate && endDate)){
            return false
        }
        return true
    }

    const [validDate, setValidDate] = useState(validateDate());
    
    useEffect(() => {
        setValidDate(validateDate())
    }, [startDate, endDate]);
    
    function validateDate() {
        return (startDate && endDate);
    }

    return (
        <BotCollectedData 
        hasData={hasData}
        fieldsOptions={fieldsOptions}
        selectedFieldOptions={selectedFieldOptions}
        setSelectedFieldOptions={setSelectedFieldOptions}
        startDate={startDate}
        endDate={endDate}
        onChangeDate={onChangeDate}
        calculateMaxDate={calculateMaxDate}
        isDateSelectable={isDateSelectable}
        handleOpenModalExport={handleOpenModalExport}
        handleCloseModalExport={handleCloseModalExport}
        showModal={showModal}
        isLoading={isLoading}
        botId={botId}
        disableButtonExport={disableButtonExport}
        validDate={validDate}
        />
    )
}

export default BotCollectedDataController;