import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { getToken } from '../../../../../../store/token'
import { getIdCompany } from '../../../../../../store/company'
import { getIdEmployee } from '../../../../../../store/employee'
import { getIdUser, getNameUser, getProfileId } from '../../../../../../store/user'
import constsRouters from '../../../../../../routes/constsRouter';

import { Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { TokenInterface } from '../../../../../../services/requestsInterfacesModel';
import BotInfo from "./index";

import { setShowAlertFeedback, setShowConfirmationModal } from '../../../../../../store/internal'
import { Tiktoken } from '@dqbd/tiktoken/lite';
import cl100kBase from '@dqbd/tiktoken/encoders/cl100k_base.json';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

const chatGPTEncoder = new Tiktoken(
    cl100kBase.bpe_ranks,
    cl100kBase.special_tokens,
    cl100kBase.pat_str,
  )

const BotInfoController = (props) => {
  const dataBot = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const bot = new BroadcastChannel('botChannel');
  const botId = new BroadcastChannel('botIdChannel')

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
  };
  const filterFile = (file, bool) => {
    let array = []
    array = file?.filter((item) => {
      return item.url && (item.is_file === bool)
    });
    if (bool === true) {
      return array?.map((item) => {
        return { path: item.name, name: item.name, isLoading: true, file_id: item.id, url: item.url }
      })
    } else {
      return array?.map((item) => {
        return item.url
      })
    }
  }

  const [channel] = useState(dataBot.state?.channel);
  const [channelKeysId] = useState(dataBot.state?.channelKeysId);

  const [botName, setBotName] = useState(dataBot.state?.data ? dataBot.state?.data.bot_name : "");
  const [prompt, setPrompt] = useState(dataBot.state?.data && dataBot.state?.data.behavioral_rules !== null ? dataBot.state?.data.behavioral_rules : "");
  const [sourceText, setSourceText] = useState(dataBot.state?.data ? dataBot.state?.data.source_text : "");

  const [showBotNameErrorFeedback, setShowBotNameErrorFeedback] = useState(false);
  const [showPromptErrorFeedback, setShowPromptErrorFeedback] = useState(false);
  const [maxToken, setMaxToken] = useState(null)

  const [optionsBotVersion, setOptionsBotVersion] = useState([])
  const [selectedBotVersion, setSelectedBotVersion] = useState(dataBot.state?.data ?  optionsBotVersion.filter((item) => {if(item.label === dataBot.state?.data.bot_version){return [{id: dataBot.state?.data.gpt_version_id, label:  dataBot.state?.data.bot_version, value: item.value}]}}) : [{id: 'b7b39169-6a41-4416-8a80-73044276b8a9', label: 'gpt-3.5-turbo', value: 1046}])
  const [auxState, setAuxState] = useState([]);

  const [fileIds, setFileIds] = useState([]);
  const [state, setState] = useState("");
  const [files, setFiles] = useState(dataBot.state?.data ? filterFile(dataBot.state?.data.files, true) : []);
  const [fileLoading, setFileLoading] = useState([]);

  const getTokens = (text: string) => {
    const tokens = chatGPTEncoder.encode(text);
    setMaxToken(tokens.length)
    return tokens.length
  }

  const [isLoading, setIsLoading] = useState(false);

  const handleFileDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.target = '_blank';
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const showFiles = files?.map((file, index) => (
    <span key={index}
      style={{
        background: '#E8E8E8 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        opacity: '1',
        marginRight: '0.5vw',
        marginBottom: '1vh',
        whiteSpace: 'pre', display: 'inline-block'
      }}
    >
      <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
        <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </svg>
      <span
        onClick={(e) => { e.stopPropagation(); handleFileDownload(file) }}
        style={{
          textAlign: 'left',
          font: 'var(--px10_13-font-Roboto)',
          letterSpacing: '0px',
          color: '#1E1E1E',
          opacity: '1',
        }}
      >
        {file.name}
      </span>
      {file.isLoading ?
        <svg name={file.name} onClick={(e) => { e.stopPropagation(); deleteFile(file.name) }} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
          <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
          <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
        :
        <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
        </Spinner>
      }
    </span>
  ));

  const deleteFile = (fileName: any[]) => {
    let newFiles = files.filter((item) => item.path !== fileName);
    acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
    fileIds.forEach((item) => {
      if (item.name == fileName) {
        fileIds.splice(fileIds.indexOf(item), 1)
      }
    });
    // setIsClicked(false);
    setFiles(newFiles);
  }

  const uploadFile = (file, sameName) => {
    const formData = new FormData();

    formData.append("file", file[0]);
    // formData.append("chanel_id", selected_channel[0].value)

    const headers = {
      "Content-Type": "multipart/form-data; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    AppRequesterConst.Post(
      '/bot-cxpress/upload', formData, { headers },
      (response: Object) => {
        return response;
      },
      (data: { data: { gfsId: string; name: string }; }) => {
        if (sameName) {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_info.changed_file"), visibility: true, signalIcon: true }));
        } else {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_info.uploaded_file"), visibility: true, signalIcon: true }));
        }
        setFileId(data.data.gfsId, data.data.name);
      },
      (error: { response: { data: { message: any[]; }; }; }) => {
        if (error.response.data.message) {
          dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
        } else {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
        }
      }, navigate, dispatch, setIsLoading,
    );
  }

  const loadGpt = async () => {
    const headers = {
      "Content-Type": "multipart/form-data; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
      '/bot-cxpress/gpt/version', { headers: headers, params:{} },
      (response: Object) => {
        return response;
      },
      (data: { data: { GptVersions: any[]; }; status }) => {
        if (data.status === 200) {
          let varGpt = [];
          varGpt = data.data.GptVersions.map(item => { return {label: item.name, value: item.max_output_tokens} });
          setOptionsBotVersion(varGpt);
          setOptionsBotVersion((value) => {
            setSelectedBotVersion(dataBot.state?.data ? value.filter((item) => {
              if (item.label === dataBot.state?.data.gpt_version) {
                return [{label: dataBot.state?.data.gpt_version, value: item.value}]
              }
            }) : [{label: 'gpt-3.5-turbo', value: 1046}])
            return value
          })
        }
      },
      (error: { response: { data: { message: any[]; }; }; }) => {
        if (error.response.data.message) {
          dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
        } else {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
        }
      }, navigate, dispatch, setIsLoading, {values: values}
    );
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 15728640,
    multiple: true,
    onDrop: (file) => {
      let sameName = false;
      fileIds.map((item) => {
        if (item.name === file[0]?.name) {
          sameName = true;
        }
      });
      if (sameName) {
        dispatch(setShowConfirmationModal({
          visibility: true, text: { "title": t("configurations.chatbots.view.bot_info.change_name"), "body": t("configurations.chatbots.view.bot_info.change_body"), "buttonConfirmationText": t("configurations.chatbots.view.bot_info.yes"), "buttonReturnText": t("configurations.chatbots.view.bot_info.no") },
          functionConfirmation: () => {
            file.map((item) => {
              uploadFile([item], sameName);
            })
          }
        }))
      } else {
        file.map((item) => {
          uploadFile([item], sameName);
        })
      }

    },
    onDropRejected: (reject) => {
      /* if (reject[0].errors[0].code === "file-too-large") {
        dispatch(setShowAlertFeedback({ visibility: true, message: "O tamanho do arquivo excede 15MB.", signalIcon: false }));
      } */
    },
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  useEffect(() => {

    let allFiles = files?.concat(acceptedFiles);

    allFiles = [...new Map(allFiles?.map(file => [file.name, file])).values()];

    allFiles.map(file => {
      const returnedTarget = Object.assign(file, { isLoading: true });
      file = returnedTarget;
    });

    setFiles(allFiles);

  }, [acceptedFiles, state]);

  useEffect(() => {
    if (isLoading) {
      props.setIsLoading(true);
    } else {
      props.setIsLoading(false);
    }
  }, [isLoading])

  useEffect(() => {
    addFileId();
    loadGpt();
  }, [])

  useEffect(() => {
    if (selectedBotVersion.length === 0){
      setSelectedBotVersion(auxState)
    } else {
      setAuxState(selectedBotVersion)
    }
  }, [selectedBotVersion[0]])


  const setFileId = (id: string, name: string) => {
    let hasName = false
    fileIds.forEach((item) => {
      if (item.name === name) {
        item.id = id
        hasName = true
        fileLoading.splice(fileIds.indexOf(item), 1)
        setState("Editado")
        if (state === "Editado") {
          setState("")
        }
      }
    });
    if (hasName === false) {
      fileIds.push({ id: id, name: name });
    }
  }

  const addFileId = () => {
    let array = []
    array = files?.filter((item) => {
      return item && item.file_id
    })
    array?.map((item) => {
      fileIds.push({ file_id: item.file_id, name: item.name });
    })
  }
  const goToListOfBots = (promptUpdate?: boolean, idPost?: string, voice?: boolean) => {
    botId.postMessage(idPost)
    navigate(constsRouters.routers.configurationChatbot.path, { state: { channel: channel ? channel : '4a7b71bb-6432-4794-ad11-f978b067871d', promptUpdate: promptUpdate, idPost: idPost, voice: voice } });
  }

  return (
    <BotInfo
      botName={botName}
      prompt={prompt}
      sourceText={sourceText}
      setBotName={setBotName}
      setPrompt={setPrompt}
      setSourceText={setSourceText}
      setShowBotNameErrorFeedback={setShowBotNameErrorFeedback}
      setShowPromptErrorFeedback={setShowPromptErrorFeedback}
      values={values}
      channel={channel}
      fileIds={fileIds}
      dataBot={dataBot}
      dispatch={dispatch}
      goToListOfBots={goToListOfBots}
      navigate={navigate}
      setIsLoading={setIsLoading}
      showBotNameErrorFeedback={showBotNameErrorFeedback}
      showPromptErrorFeedback={showPromptErrorFeedback}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      showFiles={showFiles}
      optionsBotVersion={optionsBotVersion}
      setOptionsBotVersion={setOptionsBotVersion}
      selectedBotVersion={selectedBotVersion}
      setSelectedBotVersion={setSelectedBotVersion}
      maxToken={maxToken}
      getTokens={getTokens}
      t={t}
      setActiveTab={props?.setActiveTab}
      bot={bot}
    />
  );
}

export default BotInfoController;