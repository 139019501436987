import { FC, SetStateAction, useEffect, useState } from "react";
import DataDashboard from ".";
import { useTranslation } from "react-i18next";
import { DashboardButton, IDataDashboardController } from "./indexModel";
import { useNavigate } from "react-router";
import constsRouters from "../../../../../routes/constsRouter";
import { TicketStatus } from "../../../../../core/enums/ticket-status";

const DataDashboardController: FC<IDataDashboardController> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [totalTickets, setTotalTickets] = useState(0);
    const [inProgressTickets, setInProgressTickets] = useState(0);
    const [newTickets, setNewTickets] = useState(0);
    const [totalConsumers, setTotalConsumers] = useState(0);
    // const [totalExchangeMessages, setTotalExchangeMessages] = useState(0);
    // const [totalSentMessages, setTotalSentMessages] = useState(0);
    const [averageTicketsTime, setAverageTicketsTime] = useState(0);
    const [averageWaitingTime, setAverageWaitingTime] = useState(0);
    const [dropoutRate, setDropoutRate] = useState(0);
    const [averageTicketsSolved, setAverageTicketsSolved] = useState(0);

    const [showModalListConsumers, setShowListConsumers] = useState(false);
    const [showModalListTickets, setShowListTickets] = useState(false);

    useEffect(() => {
        setTotalTickets(props.totalTickets)
        setInProgressTickets(props.inProgressTickets)
        setNewTickets(props.newTickets)
        setTotalConsumers(props.totalConsumers)
        /* setTotalExchangeMessages(props.totalExchangeMessages)
        setTotalSentMessages(props.totalSentMessages) */
        setAverageTicketsTime(props.averageTicketsTime)
        setAverageWaitingTime(props.averageWaitingTime)
        setDropoutRate(props.dropoutRate)
        setAverageTicketsSolved(props.averageTicketsSolved)
    }, [props.totalTickets]);


    const convertNumToTime = (num: number) => {
        var hour = Math.floor(num);
        var dec = num - hour;
        var min = 1 / 60;
        dec = min * Math.round(dec / min);

        var minute = Math.floor(dec * 60) + '';
        if (minute.length < 2) {
            minute = '0' + minute;
        }

        return hour + 'h' + minute
    }

    // redireciona para tickets com os parametros do filtro e as flags para indicar o carregamento dos parametros
    const openModalListConsumers = () => {
        setShowListConsumers(true);
    }

    const openModalListTickets = (dashboardButton?: DashboardButton) => {
        props.setCurrentParams((params) => {
            // estão sendo considerados como tickets em andamento os abertos e pendentes
            if (dashboardButton === DashboardButton.InProgress) {
                params = Object.assign(params, { status: `${TicketStatus.Open.toString()},${TicketStatus.Pending.toString()},${TicketStatus.OnHold.toString()}` });
            } else if (dashboardButton === DashboardButton.New) {
                params = Object.assign(params, { status: `${TicketStatus.New.toString()}` });
            } else if (dashboardButton === DashboardButton.TotalOfTicket) {
                delete params['status'];
            }

            return params;
        });

        setShowListTickets(true);
    }

    return <DataDashboard
        printPDF={props.printPDF}
        t={t}
        totalTickets={totalTickets}
        inProgressTickets={inProgressTickets}
        newTickets={newTickets}
        averageTicketsSolved={averageTicketsSolved}
        totalConsumers={totalConsumers}
        averageTicketsTime={averageTicketsTime}
        convertNumToTime={convertNumToTime}
        averageWaitingTime={averageWaitingTime}
        dropoutRate={dropoutRate}
        openModalListConsumers={openModalListConsumers}
        showModalListConsumers={showModalListConsumers}
        setShowListConsumers={setShowListConsumers}
        currentParams={props.currentParams} 
        showModalListTickets={showModalListTickets} 
        openModalListTickets={openModalListTickets} 
        setShowListTickets={setShowListTickets}    
    />

}

export default DataDashboardController