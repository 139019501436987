import { FC, useEffect, useState, MouseEvent, useRef } from 'react';
import ListOfTasks from './index';
import { FetchListTasksInterface, ListOfTasksControllerInterface, ListTaskInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMainAppContext } from '../../../../core/context/main-app-context';
import UserService from '../../../../services/user-service';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../components-fixed/searchComponent/indexModel';
import { setShowAlertFeedback } from '../../../../store/internal';
import emitter from '../../../../core/shared/emitter';
import constsRouters from '../../../../routes/constsRouter';
import { Popover } from 'react-bootstrap';
import { IconEdit, IconTicket, IconTrashX } from '@tabler/icons-react';
import { getTaskListDateLimitOrderBy, getTaskListSortBy, setTaskListDateLimitOrderBy } from '../../../../store/table_sort_order';
import { OrderByType, SortByType } from '../../../../core/enums/order-sort-by';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const AGENT_REQUESTER_SIZE = '170px';
const AGENT_REQUESTER_MAX_SIZE = '200px';
const AGENT_ASSIGNED_SIZE = '170px';
const AGENT_ASSIGNED_MAX_SIZE = '200px';
const DATE_SIZE = '185px';
const DESCRIPTION_SIZE = '210px';
const TAGS_SIZE = '200px';
const STATUS_SIZE = '60px';

const ListOfTasksController: FC<ListOfTasksControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfTasks');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setMainHeader, 
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    filteredMode,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
    setIsSearchedDashCounter,
  } = useMainAppContext();

  const taskListSortBy = useSelector(getTaskListSortBy);
  const taskListSortByRef = useRef<SortByType>(taskListSortBy);
  const taskListDateLimitOrderBy = useSelector(getTaskListDateLimitOrderBy);
  const taskListDateLimitOrderByRef = useRef<OrderByType>(taskListDateLimitOrderBy);

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<ListTaskInterface[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalTask, setShowModalTask] = useState(false);
  const [showModalDeleteTask, setShowModalDeleteTask] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    setListHeaders([
      { 
        title: t("list_headers.agent_requester"),  field: 'requester_employee_name', 
        width: AGENT_REQUESTER_SIZE, 
        maxWidth: AGENT_REQUESTER_MAX_SIZE,
        hasTooltip: true,
      },
      { 
        title: t("list_headers.agent_assigned"), 
        field: 'assigned_employee_name', 
        width: AGENT_ASSIGNED_SIZE, 
        maxWidth: AGENT_ASSIGNED_MAX_SIZE, 
        hasTooltip: true 
      },
      { 
        title: t("list_headers.date"), 
        field: 'date_limit', 
        width: DATE_SIZE, 
        hasTooltip: true,
        hasOrderBy: true,
        orderByFn: getTasksOrdered, 
        orderBy: taskListDateLimitOrderBy || 'desc',
        orderType: 'default',
      },
      { 
        title: t("list_headers.description"), 
        field: 'description', 
        width: DESCRIPTION_SIZE, 
        hasTooltip: true 
      },
      { 
        title: t("list_headers.tags"), 
        field: 'tags', 
        width: TAGS_SIZE, 
        hasTooltip: true 
      },
      { 
        title: t("list_headers.status"), 
        field: 'status_task_id', 
        width: STATUS_SIZE, 
        hasTooltip: true,
        alignment: 'center'
      },
      { 
        title: t("list_headers.actions"), 
        field: 'action', 
        width: 'auto' 
      },
    ]);
  }, []);

  useEffect(() => {
    if (apiHeader) {
      setCurrentPage(1);
      getTasks({ limit: LIMIT_PAGINATION, page: currentPage || 1, sort_by: taskListSortBy, order_by: taskListDateLimitOrderBy });
    }
  }, [apiHeader]);

  useEffect(() => {
    configMainHeader();
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = () => {
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    setMainHeader({
      pageTitle: t('header.welcome_page'),
      pageSubtitle: t('header.sub_info'),
      hasSearchComponent: true,
      hasSearchInput: false,
      hasSearchFilter: true,
      searchPage: 'tasks',
      searchPlaceholder: t('header.search_placeholder'),
      searchFunction: searchTasks,
      clearSearchFunction: clearSearchTasks,
      hasCreateButton: false
    });
  }

  const getTasks = (params?: any, isScrollPagination?: boolean) => {
    setErrorLoading(false);

    const config = { headers: apiHeader, params };

    AppRequesterConst.Get(
      '/task', config,
      () => {},
      (response: FetchListTasksInterface) => {
        if (response.status === 200 && response.data.tasks.length > 0) {
          if (response.data.tasks.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }

          if (isScrollPagination) {
            const new_array = [...data, ...response.data.tasks];
            setData(new_array);
          } else {
            setData(response.data.tasks || []);
          }

          processPaginationDetails(response.data.pagination);
        } else {
          setData([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getTasksPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: taskListSortBy, order_by: taskListDateLimitOrderBy, ...currentFilter };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      getTasks(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getTasks(params, true);
      }
    }
  }

  const getTasksOrdered = async (field: string, order: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: taskListSortBy, order_by: order, ...currentFilter };
    setHeaderOrderBy(field, order);
    getTasks(params);
  }

  const setHeaderOrderBy = (field: string, order: 'asc' | 'desc'): void => {
    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === field) {
          item.orderBy = order;
        }
      });

      dispatch(setTaskListDateLimitOrderBy(order));
      taskListDateLimitOrderByRef.current = order;

      taskListSortByRef.current = field as SortByType;
      
      return prevState;
    });
  }

  const searchTasks = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: taskListSortByRef.current, order_by: taskListDateLimitOrderByRef.current, ...query };
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    setIsSearchedDashCounter(false);

    getTasks(params);
  }

  const clearSearchTasks = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: taskListSortByRef.current, order_by: taskListDateLimitOrderByRef.current };

    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    setIsSearchedDashCounter(false);

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }

    getTasks(params);
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };

    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (filterMode === 'main') {
        if (key === 'tags_id') {
          tempFilterApplied.selectedTag = [];
        } else if (key === 'requester_user_id') {
          tempFilterApplied.selectedAgent = [];
        } else if (key === 'assigned_user_id') {
          tempFilterApplied.selectedAgentTaskAssigned = [];
        } else if (key === 'status_id') {
          tempFilterApplied.selectedStatus = [];
        }
      } else if (filterMode === 'custom') {
        delete tempFilterApplied.selectedCustomFieldListValuesObject[key];

        const indexToDelete = tempFilterApplied.selectedCustomFieldListFilter.findIndex(item => item.id === key);

        if (indexToDelete >= 0) {
          tempFilterApplied.selectedCustomFieldListFilter.splice(indexToDelete, 1);
        }
      }

      setFilterApplied(tempFilterApplied);
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, 'tasks'), tempFilterApplied);
      setIsSearchedDashCounter(false);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters = [];

    Object.keys(queryFilterRef.current).forEach(key => {
      const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };

      if (key === 'requester_user_id') {
        item.value = filterApplied.selectedAgent?.map(item => item.value).join(', ');
      } else if (key === 'assigned_user_id') {
        item.value = filterApplied.selectedAgentTaskAssigned?.map(item => item.value).join(', ');
      } else if (key === 'status_id') {
        item.value = filterApplied.selectedStatus?.map(item => item.value).join(', ');
        item.tooltip_key = 'filter_applied.status';
      } else if (key === 'tags_id') {
        item.value = filterApplied.selectedTag?.map(item => item.value).join(', ');
        item.tooltip_key = 'filter_applied.tags';
      }

      filters.push(item);
    });

    setFilterMode('main');

    return filters;
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const reloadTasks = (preserveFilter?: boolean) => {
    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: taskListSortByRef.current, order_by: taskListDateLimitOrderByRef.current };

    if (preserveFilter) {
      const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
      params = { ...params, ...currentFilter };
    }

    getTasks(params, false);
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders;
  }

  const openModalEditTask = (item: ListTaskInterface, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowModalTask(true);
    setTimeout(() => {
      emitter.emit('open-modal-task', { taskId: item.id });
    }, 50);
  }

  const closeModalTask = (reload: boolean) => {
    if (reload) {
      reloadTasks(true);
    }
    setShowModalTask(false);
  }

  const openModalDeleteTask = (
    taskInfo: { taskId: string, date_limit: string, status: string, description: string, requesterName: string, assignedName: string
  }) => {
    setShowModalDeleteTask(true);
    setTimeout(() => {
      emitter.emit('open-modal-delete-task', taskInfo);
    }, 50);
  }

  const closeModalDeleteTask = (reload: boolean, userIds: string[]) => {
    if (reload) {
      reloadTasks(true);
    }
    setShowModalDeleteTask(false);
  }

  const handlePopoverTasksListItemAction = (event: any, data: ListTaskInterface, type:  'edit-task' | 'open-task-ticket' | 'delete-task') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-tasks');
    setTimeout(() => {
      if (type === 'edit-task') {
        openModalEditTask(data, event);
      } else if (type === 'open-task-ticket') {
        navigate(
          `${constsRouters.routers.taskTicket.path}/${data.ticket_id}`, 
          { 
            state: { 
              taskTicketId: data.ticket_id,
              taskTicketValue: data.id
            } 
          }
        );
      } else if (type === 'delete-task') {
        openModalDeleteTask({ 
          taskId: data.id, 
          date_limit: data.date_limit,
          status: data.status_task_id,
          description: data.description,
          requesterName: data.requester_employee_name, 
          assignedName: data.assigned_employee_name, 
        });
      } 
    }, 50);
  }

  const popoverItemAction = (data: ListTaskInterface) => {
    const showEditTask = true;
    const showOpenTaskTicket = true;
    const showDeleteTask = true;

    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenTaskTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTasksListItemAction(e, data, 'open-task-ticket')}>
              <IconTicket />{t('list_popover_actions.open_task_ticket')}
            </span>
          }
          { showEditTask &&
            <span className='popover-item' onClick={(e) => handlePopoverTasksListItemAction(e, data, 'edit-task')}>
              <IconEdit />{t('list_popover_actions.edit_task')}
            </span>
          }
          { showDeleteTask &&
            <span className='popover-item' onClick={(e) => handlePopoverTasksListItemAction(e, data, 'delete-task')}>
              <IconTrashX />{t('list_popover_actions.delete_task')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfTasks 
      t={t}
      listHeaders={getListHeaders}
      listData={data}
      paginationDetails={paginationDetails}
      getTasksPagination={getTasksPagination}
      isLoading={isLoading}
      errorLoading={errorLoading}
      reloadTasks={reloadTasks}
      showModalTask={showModalTask}
      closeModalTask={closeModalTask}
      showModalDeleteTask={showModalDeleteTask}
      closeModalDeleteTask={closeModalDeleteTask}
      filters={filters}
      clearSearchTasks={clearSearchTasks}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}
      openModalEditTask={openModalEditTask}
    />
  );
};

export default ListOfTasksController;
