import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken, setToken } from "../../../../store/token";
import { DataCompanyInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getAppOpenSidebar, getConfirmChangePage, setAppOpenSidebar, setAppSidebarPage, setConfirmChangePage } from "../../../../store/app_sidebar";
import { getForceLogout, getForceLogoutExpired, getReopenTicket, getShowConfirmationModal, setForceLogout, setForceLogoutExpired, setReopenTicket, setShowConfirmationModal, setShowEditTicketsModal } from "../../../../store/internal";
import constsRouters from "../../../../routes/constsRouter";
import { IconCheckupList, IconDashboard, IconError404, IconHistory, IconSend, IconSettings, IconTicket, IconUserBolt, IconUsers } from "@tabler/icons-react";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useTranslation } from "react-i18next";
import { getCompanyHasApp2FA, getEnableSummary, getHasSummary, getIdCompany, getSubdomainCompany, setCompanyHasApp2FA, setEnableSummary, setHasSummary, setIdCompany, setNameCompany, setSubdomainCompany } from "../../../../store/company";
// import { SocketContext } from "../../../../core/context/socket-context";
import { setExtension, setPassword, setOwner_id } from "../../../../store/callcenter";
import { getHasPhoneIntegrationEmployee, setHasPhoneIntegrationEmployee, setIdEmployee, setIdStatusEmployee } from "../../../../store/employee";
import { setIdUser, setNameUser, setUserLastName, setProfileId, getNameUser, getProfileId, getIdUser } from "../../../../store/user";
import { CallcenterContext } from "../../../../core/context/callcenter-context";
import SidebarApp from ".";
import LinksConstants from "../../../../core/constants/links-contants";
import constsApi from "../../../../services/constsApi";
import { getChatbot } from "../../../../store/chatbot";
import { TicketContext } from "../../../../core/context/ticket-context";
import useUpdateStatusEmployee from "../../../../hooks/useUpdateStatusEmployee";
import { StatusAgent } from "../../../../core/enums/status-agent";
import { BlockTicketContext } from "../../../../core/context/block-ticket-context";
import useLeaveTicketChat from "../../../../hooks/useLeaveTicketChatSocket";
import { useSocketV2Context } from "../../../../core/context/socket-context-v2";

const SidebarAppController = () => {
  const { t } = useTranslation('SidebarApp');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { 
    registerSocketAppEvent, 
    unregisterSocketAppEvent,
    disconnectSocket, 
    socketInitialized 
  } = useSocketV2Context();

  const callcenterContext = useContext(CallcenterContext);
  // const socket = useContext(SocketContext);
  const ticket = useContext(TicketContext);
  const block_ticket = useContext(BlockTicketContext);

  const appRequester = new AppRequesterController();

  const { requestAgentStatus } = useUpdateStatusEmployee()

  const values: TokenInterface = {
    token: {
      value: useSelector(getToken),
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId),
    },
    company: {
      id: useSelector(getIdCompany),
      subdomain: useSelector(getSubdomainCompany),
      configs: {
        has_summary: useSelector(getHasSummary),
        enable_summary: useSelector(getEnableSummary)
      }
    },
    internal: {
      getForceLogout: useSelector(getForceLogout),
      getForceLogoutExpired: useSelector(getForceLogoutExpired),
      getShowConfirmationModal: useSelector(getShowConfirmationModal),
    },
    chatbot: {
      hasBot: useSelector(getChatbot),
    }
  };

  const isAdmin = () => values.user.profile_id === constsApi.profile[0].id;
  const isAgent = () => values.user.profile_id === constsApi.profile[1].id;
  const isOwner = () => values.user.profile_id === constsApi.profile[2].id;
  const isMaster = () => values.user.profile_id === constsApi.profile[3].id;

  const employee_id = new BroadcastChannel('employee_id')

  const companyHasApp2fa = useSelector(getCompanyHasApp2FA);
  const hasPhoneIntegrationEmployee = useSelector(getHasPhoneIntegrationEmployee);
  const sidebarOpened = useSelector(getAppOpenSidebar);
  const confirmPageChanged = useSelector(getConfirmChangePage);
  // const sidebarPage = useSelector(getAppSidebarPage);

  const [currentPage, setCurrentPage] = useState('tickets');
  const [/*isLoading*/, setIsLoading] = useState(false);
  const { onSocketLeaveTicketChat } = useLeaveTicketChat()
  let idReopen = useSelector(getReopenTicket)

  // Novas páginas devem ser adicionadas aqui, na ordem em que devem aparecer na sidebar
  const [pages, setPages] = useState({
    tickets: {
      id: 'tickets',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.tickets.path,
      icon: <IconTicket className="menu-icon" stroke={1.5} />,
      show: true
    },
    ticketsOld: {
      id: 'ticketsOld',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.ticketsOld.path,
      icon: <IconTicket className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    },
    history: {
      id: 'history',
      i18n_key: 'menu.history',
      route: null,
      icon: <IconHistory className="menu-icon" stroke={1.5} />,
      show: true,
      subpage_opened: false,
      subpages: {
        history_tickets: {
          id: 'history_tickets',
          i18n_key: 'menu.history_general',
          route: constsRouters.routers.history.path,
          icon: null,
          show: true
        },
        history_chatbots: {
          id: 'history_chatbots',
          i18n_key: 'menu.history_chatbots',
          route: constsRouters.routers.historyChatbot.path,
          icon: null,
          show: values.chatbot.hasBot
        },
        history_telephony: {
          id: 'history_telephony',
          i18n_key: 'menu.history_telephony',
          route: constsRouters.routers.historyTelephony.path,
          icon: null,
          show: true
        },
      }
    },
    consumers: {
      id: 'consumers',
      i18n_key: 'menu.consumers',
      route: constsRouters.routers.consumers.path,
      icon: <IconUsers className="menu-icon" stroke={1.5} />,
      show: true
    },
    consumerTicket: {
      id: 'consumer-tickets',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.consumerTickets.path,
      icon: <IconTicket className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    },
    agents: {
      id: 'agents',
      i18n_key: 'menu.agents',
      route: constsRouters.routers.agents.path,
      icon: <IconUserBolt className="menu-icon" stroke={1.5} />,
      show: true
    },
    agentTicket: {
      id: 'agent-tickets',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.agentTickets.path,
      icon: <IconTicket className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    },
    tasks: {
      id: 'tasks',
      i18n_key: 'menu.tasks',
      route: constsRouters.routers.tasks.path,
      icon: <IconCheckupList className="menu-icon" stroke={1.5} />,
      show: true
    },
    taskTicket: {
      id: 'task-ticket',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.taskTicket.path,
      icon: <IconTicket className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    },
    trigger: {
      id: 'trigger',
      i18n_key: 'menu.schedule',
      route: constsRouters.routers.activeTrigger.path,
      icon: <IconSend className="menu-icon" stroke={1.5} />,
      show: isAdmin() || isOwner() || isMaster()
    },
    triggerTicket: {
      id: 'trigger-tickets',
      i18n_key: 'menu.tickets',
      route: constsRouters.routers.activeTriggerTickets.path,
      icon: <IconSend className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    },
    dashboard: {
      id: 'dashboard',
      i18n_key: 'menu.dashboard',
      route: null,
      icon: <IconDashboard className="menu-icon" stroke={1.5} />,
      show: isAdmin() || isOwner() || isMaster(),
      subpage_opened: false,
      subpages: {
        dashboard_tickets: {
          id: 'dashboard_tickets',
          i18n_key: 'menu.dashboard_general',
          route: constsRouters.routers.dashboard.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        dashboard_chatbots: {
          id: 'dashboard_chatbots',
          i18n_key: 'menu.dashboard_chatbots',
          route: constsRouters.routers.dashboardChatbot.path,
          icon: null,
          show: values.chatbot.hasBot
        },
        dashboard_summary: {
          id: 'dashboard_summary',
          i18n_key: 'menu.dashboard_summary',
          route: constsRouters.routers.dashboardSummary.path,
          icon: null,
          show: (isAdmin() || isOwner() || isMaster()) && (values.company.configs.enable_summary || values.company.configs.has_summary)/* apenas para admin e que ja tenham feito o sumário */
        },
      }
    },
    configuration: {
      id: 'configuration',
      i18n_key: 'menu.config',
      route: null,
      icon: <IconSettings className="menu-icon" stroke={1.5} />,
      show: isAdmin() || isOwner() || isMaster() || ((isAgent() && hasPhoneIntegrationEmployee) || (isAgent() && companyHasApp2fa)),
      subpage_opened: false,
      subpages: {
        configuration_company: {
          id: 'configuration_company',
          i18n_key: 'menu.config_company',
          route: constsRouters.routers.configurationDepartments.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_channels: {
          id: 'configuration_channels',
          i18n_key: 'menu.config_channels',
          route: constsRouters.routers.configurationChannels.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster() || (isAgent() && hasPhoneIntegrationEmployee)
        },
        configuration_sectors: {
          id: 'configuration_sectors',
          i18n_key: 'menu.config_sectors',
          route: constsRouters.routers.configurationSectors.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_sector_tickets: {
          id: 'configuration_sector_tickets',
          i18n_key: 'menu.tickets',
          route: constsRouters.routers.configurationSectorsTickets.path,
          icon: <IconTicket className="menu-icon" stroke={1.5} />,
          show: false // Não deve ser exibido na sidebar
        },
        configuration_tickets: {
          id: 'configuration_tickets',
          i18n_key: 'menu.config_tickets',
          route: constsRouters.routers.configurationTickets.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_chatbots: {
          id: 'configuration_chatbots',
          i18n_key: 'menu.config_chatbots',
          route: constsRouters.routers.configurationChatbot.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_tags: {
          id: 'configuration_tags',
          i18n_key: 'menu.config_tags',
          route: constsRouters.routers.configurationTags.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_tag_tickets: {
          id: 'configuration_tag_tickets',
          i18n_key: 'menu.tickets',
          route: constsRouters.routers.configurationTagsTickets.path,
          icon: <IconTicket className="menu-icon" stroke={1.5} />,
          show: false // Não deve ser exibido na sidebar
        },
        configuration_tag_consumers: {
          id: 'configuration_tag_consumers',
          i18n_key: 'menu.consumers',
          route: constsRouters.routers.configurationTagsConsumers.path,
          icon: <IconUsers className="menu-icon" stroke={1.5} />,
          show: false // Não deve ser exibido na sidebar
        },
        configuration_custom_fields: {
          id: 'configuration_custom_fields',
          i18n_key: 'menu.config_custom_fields', /* ADD TRADUÇÃO */
          route: constsRouters.routers.configurationCustomFields.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster()
        },
        configuration_security: {
          id: 'configuration_security',
          i18n_key: 'menu.config_security',
          route: constsRouters.routers.configurationSecurity.path,
          icon: null,
          show: isAdmin() || isOwner() || isMaster() || (isAgent() && companyHasApp2fa)
        },
      }
    },
    404: {
      id: '404',
      i18n_key: 'menu.tickets',
      route: '',
      icon: <IconError404 className="menu-icon" stroke={1.5} />,
      show: false // Não deve ser exibido na sidebar
    }
  });

  const getMenuTitle = (key: string) => {
    return t(key);
  }

  useEffect(() => {
    return () => {
      document.title = 'Cxpress';
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem('hasLogin') !== 'true') {
  //     logout();
  //     navigate("/");
  //   }
  //   dispatch(setShowEditTicketsModal(false));
  //   dispatch(setAppSidebarPage(currentPage));
  // }, [location]);

  useEffect(() => {
    if (values.internal.getForceLogout) {
      dispatch(setForceLogout(false));
      logoutConfirm(false);
    }
    if (values.internal.getForceLogoutExpired) {
      leaveTicketChat()
      dispatch(setForceLogoutExpired(false));
      logoutConfirm(true);
    }
  }, [values.internal.getForceLogout, values.internal.getForceLogoutExpired]);

  useEffect(() => {
    // socket.socket?.on("disconnect-old-logged-users", () => {
    //   leaveTicketChat()
    //   dispatch(setForceLogoutExpired(false));
    //   logout(true);
    //   logoutConfirm(true);
    // });

    // return () => {
    //   socket.socket?.off("disconnect-old-logged-users", () => {});
    // }

    if (socketInitialized) {
      registerSocketAppEvent('disconnect-old-logged-users', () => {
        dispatch(setForceLogoutExpired(false));
        logout(true);
        logoutConfirm(true);
      });
    }

    return () => {
      unregisterSocketAppEvent('disconnect-old-logged-users');
    }

  }, [socketInitialized]);
  
  useEffect(() => {
    if (values.internal.getShowConfirmationModal.visibility) {
      dispatch(setShowConfirmationModal({ visibility: false, text: { title: '', body: '', id: '' }, functionConfirmation: () => {} }));
    }
  }, []);

  useEffect(() => { // Definir a página atual na sidebar ao atualizar página
    dispatch(setShowEditTicketsModal(false));
    if (localStorage.getItem('hasLogin') !== 'true') {
      logout();
      navigate("/");
    } else {
      const paths = {};
  
      Object.keys(pages).forEach(key => {
        if (pages[key].subpages) {
          Object.keys(pages[key].subpages).forEach(sub_key => {
            paths[pages[key].subpages[sub_key].route] = pages[key].subpages[sub_key].id;
          });
        } else {
          paths[pages[key].route] = pages[key].id;
        }
      });
  
      const sortedKeys = Object.keys(paths).sort((a, b) => b.length - a.length);

      // Precisa de um tratamento melhor para pegar o current
      const current = paths[location.pathname] ? location.pathname : sortedKeys.filter(key => location.pathname.startsWith(key))[0];
      if (current && paths[current]) {
        setCurrentPage(paths[current]);
        dispatch(setAppSidebarPage(paths[current]));
      } else {
        setCurrentPage('404');
        dispatch(setAppSidebarPage('404'));
      }
    }
  }, [location]);

  useEffect(() => { // Alterar título da aba do navegador
    const [pageId, subpageId] = currentPage?.split('_');
    let title = 'Cxpress';
    if (pageId && subpageId) {
      title += ` | ${getMenuTitle(pages[pageId]?.i18n_key)} | ${getMenuTitle(pages[pageId]?.subpages[currentPage]?.i18n_key)}`;
    } else if (pageId) {
      let titleKey = pages[currentPage]?.i18n_key;

      if (pageId === 'consumer-tickets') {
        titleKey = pages['consumerTicket']?.i18n_key;
      } else if (pageId === 'agent-tickets') {
        titleKey = pages['agentTicket']?.i18n_key;
      } else if (pageId === 'ticket-task') {
        titleKey = pages['taskTicket']?.i18n_key;
      } else if (pageId === 'trigger-tickets') {
        titleKey = pages['triggerTicket']?.i18n_key;
      } else if (pageId === 'configuration_sector_tickets') {
        titleKey = pages['configuration_sector_tickets']?.i18n_key;
      } else if (pageId === 'configuration_tag_tickets') {
        titleKey = pages['configuration_tag_tickets']?.i18n_key;
      } else if (pageId === pages['configuration_tag_consumers']) {
        titleKey = pages['configuration_tag_consumers']?.i18n_key;
      }
      title += ` | ${getMenuTitle(titleKey)}`;
    }
    document.title = title;
  }, [currentPage]);

  const getPages = () => {
    return Object.keys(pages).map(key => ({
      id: pages[key].id,
      i18n_key: pages[key].i18n_key,
      icon: pages[key].icon,
      show: pages[key].show,
      subpage_opened: pages[key].subpage_opened,
      subpages: pages[key].subpages,
    }));
  }

  const getSubpages = (page: string) => {
    if (pages[page].subpages) {
      return Object.keys(pages[page].subpages).map(key => ({
        id: pages[page].subpages[key].id,
        i18n_key: pages[page].subpages[key].i18n_key,
        icon: pages[page].subpages[key].icon,
        show: pages[page].subpages[key].show
      }));
    } else {
      return [];
    }
  }

  const closeSubmenus = () => {
    const temp = Object.assign({}, pages);
    Object.keys(temp).forEach(key => {
      temp[key].subpage_opened = false;
    });
    setPages(temp);
  }

  const showHideSidebar = (): void => {
    closeSubmenus();
    if (sidebarOpened) {
      dispatch(setAppOpenSidebar(false));
    } else {
      dispatch(setAppOpenSidebar(true));
    }
  }

  const menuAction = (menu: string, is_subpage?: boolean, parent_page?: string): void => {
    if (is_subpage) {
      const temp = Object.assign({}, pages);
      temp[menu].subpage_opened = !temp[menu].subpage_opened;

      Object.keys(temp).forEach(key => {
        if (key !== menu) {
          temp[key].subpage_opened = false;
        }
      });

      setPages(temp);
    } else {
      if (menu?.split('_')[0] !== parent_page || !sidebarOpened) {
        closeSubmenus();
      }
      switch (menu) {
        case 'logout':
          leaveTicketChat()
          logout();
          break;
        case 'tutorials':
          window.open(LinksConstants.tutorialsBuild(), '_blank');
          // window.open(LinksConstants.homeBuild(values.user.name, values.company.subdomain), '_blank');
          break;
        default: {
          const [page, subpage] = menu?.split('_');
          if (idReopen !== "") {
            onSocketLeaveTicketChat(idReopen)
            dispatch(setReopenTicket(""))
          }
          const destiny = page && subpage ? pages[page].subpages[menu].route : pages[menu].route;

          if (location.pathname === constsRouters.routers.createTicket.path && confirmPageChanged) {
            dispatch(setShowConfirmationModal({ 
              visibility: true, 
              text: { 
                title: t("home_tickets.alert_feedback.without_save.title"), 
                body: t("home_tickets.alert_feedback.without_save.body"), 
                id: '', 
                buttonConfirmationText: t("home_tickets.alert_feedback.without_save.confirm"), 
                buttonReturnText: t("home_tickets.alert_feedback.without_save.cancel") }, 
                functionConfirmation: () => handleNavigate(menu, destiny)
              }));
          } else {
            handleNavigate(menu, destiny);
          }
        }
      }
    }
  }

  const handleNavigate = (menu: string, destiny: string) => {
    if (destiny === "/tickets") {
      setCurrentPage(menu);
      navigate(destiny);
      dispatch(setConfirmChangePage(false));
      if (ticket.getTicket() !== "") {
        leaveTicketChat()
        ticket.setTicket("")
        employee_id.postMessage("")
      }

    } else {
      setCurrentPage(menu);
      navigate(destiny);
      dispatch(setConfirmChangePage(false));

      if (ticket.getTicket() !== "") {
        leaveTicketChat()
        ticket.setTicket("")
        employee_id.postMessage("")
      }
    }
  }

  const logout = async (expired?: boolean) => {
    document.title = 'Cxpress';
    if (expired) {
      clearInfos();
    } else {
        const headers = UserService.getHeaders();
        clearInfos();
        await appRequester.Post(
            "authentication/logout", {},
            { headers },
            (response: Object) => { return response; },
            (data: any) => {},
            (error: Object) => {},
            navigate, dispatch, setIsLoading,
        )
    }
  }

  const logoutConfirm = (expired: boolean) => {

    if (expired) {
        dispatch(setShowConfirmationModal({
            visibility: true,
            text: { 
              title: t('modals.expired_session_title'),
              body: t('modals.expired_session_expired_body'), 
              buttonConfirmationText: 'OK', 
              closeButton: false 
            },
            functionConfirmation: () => { logout(true) }
        }));

    }
    else {
        dispatch(setShowConfirmationModal({
            visibility: true,
            text: { 
              title: t('modals.expired_session_title'),
              body: t('modals.expired_session_body'), 
              buttonConfirmationText: 'OK', 
              closeButton: false 
            },
            functionConfirmation: () => { logout(true) }
        }));
    }
  }

  const leaveTicketChat = () => {
    // if (ticket.getTicket() != null) {
    //   socket.getInstance()?.emit("leave-ticket-chat", ticket.getTicketId())
    //   block_ticket.setAdmBlocked(false);
    // }
  }

  const clearInfos = () => {
    try {
      // if (socket) {
      //   requestAgentStatus(values.user.id, StatusAgent.Offline, values.company.id)
      //   dispatch(setIdStatusEmployee(StatusAgent.Offline))
      //   leaveTicketChat();
      //   socket.disconnectSocket();
      // }

      if (socketInitialized) {
        requestAgentStatus(values.user.id, StatusAgent.Offline, values.company.id);
        dispatch(setIdStatusEmployee(StatusAgent.Offline));
        disconnectSocket();
      }
  
      localStorage.setItem('hasLogin', null);
      dispatch(setToken(null));
      dispatch(setIdCompany(null));
      dispatch(setNameCompany(null));
      dispatch(setSubdomainCompany(null));
      dispatch(setCompanyHasApp2FA(null));
      dispatch(setIdEmployee(null));
      dispatch(setIdUser(null));
      dispatch(setNameUser(null));
      dispatch(setUserLastName(null));
      dispatch(setProfileId(null));
      dispatch(setExtension(null));
      dispatch(setPassword(null));
      dispatch(setOwner_id(null));
      dispatch(setEnableSummary(null));
      dispatch(setHasSummary(null));
      dispatch(setHasPhoneIntegrationEmployee(false));
  
      if (callcenterContext?.instance) {
        callcenterContext.instance.unregister();
      }
  
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  const clickLogo = () => {
    if (window.location.href === `http://${window.location.host}/tickets`) {
      window.location.replace(`http://${window.location.host}/tickets`);
    } else if (window.location.href === `https://${window.location.host}/tickets`) {
      window.location.replace(`https://${window.location.host}/tickets`);
    } else {
      navigate('/tickets');
    }
  }
  
  return (
    <SidebarApp
      t={t}
      currentPage={currentPage}
      menuAction={menuAction}
      showHideSidebar={showHideSidebar}
      sidebarOpened={sidebarOpened}
      confirmationModal={values.internal.getShowConfirmationModal}
      getPages={getPages}
      getSubpages={getSubpages}
      logoutBySetLanguage={clearInfos}
      getMenuTitle={getMenuTitle}
      clickLogo={clickLogo}
    />
  );
}

export default SidebarAppController;