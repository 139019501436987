import { useState, useEffect } from "react"
import { Button, Popover } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getUserControlPanelPermissions } from "../../../../../store/user_controlpanel";
import { getToken } from "../../../../../store/token";
import { setShowAlertFeedback, setShowConfirmationModal, setShowModalActiveBase } from "../../../../../store/internal"
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController"
import constsApi from "../../../../../services/constsApi"
import ListConfigWhatsapp from "./index"

const ListConfigWhatsappController = ({ data, getWhatsAppById, isEditAllowed }) => {
    const [botId, setBotId] = useState(null);
    const [show, setShow] = useState(false);
    const [showBroker, setShowBroker] = useState(false);
    const [currentWhatsappId, setCurrentWhatsappId] = useState<any>("");
    const [currentWhatsappPhone, setCurrentWhatsappPhone] = useState("");
    const [currentWhatsappData, setCurrentWhatsappData] = useState([]);
    const [currentConfig, setCurrentConfig] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const values = {
        token: {
            value: useSelector(getToken),
        },
    };

    const userPermissions = useSelector(getUserControlPanelPermissions);
    const AppRequesterConst = new AppRequesterControlPanelController();

    useEffect(() => {
        if (currentWhatsappId) {
            getWhatsAppById(currentWhatsappId);
            // getBrokersById(currentWhatsappId)
        }
    }, [currentWhatsappId]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        getWhatsAppKeysById(currentWhatsappId)
        // getBrokersById(currentWhatsappId)
    };

    const handleCloseBroker = () => setShowBroker(false);
    const handleShowBroker = () => {
        setShowBroker(true)
        getWhatsAppKeysById(currentWhatsappId)
        getBrokersById(currentWhatsappId)
    };

    const handleShowActiveBase = (id: string) => {
        dispatch(setShowModalActiveBase(true));
        setBotId(id);
    };

    const onCloseActiveBase = () => {
        setBotId(null);
    };

    const formatDate = (date: string, timeZone: string) => {
        const result = new Date(date).toLocaleDateString(timeZone);
        return result;
    };

    const brokerTyper = (id: string) => {
        if (id === "ba3a545f-a77d-44bf-8344-ba05f54f5324") {
            return "Twilio";
        }
        if (id === "c8ade4e2-993f-42dd-a108-430e9655a10f") {
            return "Positus";
        }
        if (id === "e7fd7160-c9a3-435f-893d-a2d87ede6de5") {
            return "Meta";
        }
        if (id === "695e98ef-d32c-472f-aa66-8bc26510eac5") {
            return "Smarters";
        }
        return "N/A";
    };

    const currentWhatsAppInfo = (e: { currentTarget: { id: string } }) => {
        setCurrentWhatsappId(e.currentTarget.id.split("*ID*")[0])
        setCurrentWhatsappPhone(e.currentTarget.id.split("*ID*")[1])
    }

    const getWhatsappEditById = (whatsapp_keys_is) => {
        handleShow()
        getWhatsAppKeysById(whatsapp_keys_is)
        getBrokersById(currentWhatsappId)
    }

    const deleteWhastappNumber = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": "Excluir número", "body": "Deseja excluir o número", "id": ' ' + currentWhatsappPhone + '?', "end": " Essa operação não poderá ser desfeita.", "buttonReturnText": "Cancelar" }, functionConfirmation: () => deleteWhatsAppKeys(currentWhatsappId) }))
    }

    const getWhatsAppKeysById = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value,
        };

        await AppRequesterConst.Get(
            `/channel-whatsapp/whatsapp-key/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setCurrentWhatsappData(data.data);
                }
            },
            (error: { response: { statue: number } }) => { }, navigate, dispatch, setIsLoading
        );
    };

    const deleteWhatsAppKeys = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/channel-whatsapp/whatsapp-key/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                dispatch(setShowAlertFeedback({ message: "Whatsapp key excluída com sucesso.", visibility: true, signalIcon: true }))
                getWhatsAppById()
            },
            (error: { response: { status: number } }) => { }, navigate, dispatch, setIsLoading
        );
    };

    const getBrokersById = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `channel-whatsapp/broker-config/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status == 200) {
                    setCurrentConfig(data.data)
                }
            },
            (error: { response: { data: { message: string, code_cxpress: number }, status: number } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: 'Ocorreu um erro inesperado', visibility: true, signalIcon: false }))
                }
                setShowBroker(false)
            }, navigate, dispatch, setIsLoading
        );
    };

    const popover = (item) => {
        const permissions = userPermissions.map(item => item.id);
        return (
            <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShow()}>
                        Editar número
                    </Button>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => deleteWhastappNumber()}>
                        Excluir número
                    </Button>
                    {(item.broker_id === "695e98ef-d32c-472f-aa66-8bc26510eac5" && permissions.includes(constsApi.permissions_controlpanel.master)) &&
                        <>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                            <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShowBroker()}>Configurações de Broker</Button>
                        </>
                    }
                    {item.has_bot && item.bot_cxpress ?
                        <>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                            <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShowActiveBase(item.bot_cxpress.id)}>Base de ativos</Button>
                        </> :
                        <></>
                    }
                </Popover.Body>
            </Popover>
        );
    };

    return (
        <ListConfigWhatsapp
            data={data}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            showBroker={showBroker}
            handleCloseBroker={handleCloseBroker}
            currentWhatsappData={currentWhatsappData}
            getWhatsAppById={getWhatsAppById}
            isEditAllowed={isEditAllowed}
            userPermissions={userPermissions}
            brokerTyper={brokerTyper}
            formatDate={formatDate}
            popover={popover}
            botId={botId}
            onCloseActiveBase={onCloseActiveBase}
            currentConfig={currentConfig}
            currentWhatsappId={currentWhatsappId}
            currentWhatsAppInfo={currentWhatsAppInfo}
            isLoading={isLoading}
        />
    );
};

export default ListConfigWhatsappController;