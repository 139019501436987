import { FC, useState } from 'react';
import RegisterFormNew from './index';
import { BodyInterface, RegisterFormControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthenticationRequests } from '../../../services/authentication/authenticationControler';
import { RegisterControler } from '../registerDesktop/indexControler';
import Utils from '../../../core/shared/utils';
import { setShowAlertFeedback } from '../../../store/internal';
import { verifyCode } from '../../../services/codeCxpressInterface';

const RegisterFormController: FC<RegisterFormControllerInterface> = (props) => {

  const AuthenticationRequestsConst = new AuthenticationRequests();

  const { t } = useTranslation('RegisterForm');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [agent_phone, setAgent_phone] = useState<string>(null);
  
  const [stage, setStage] = useState(1);
  const [progress, setProgress] = useState(34);

  const [company_name, setCompany_name] = useState<string>(null);
  const [company_employee_number, Setcompany_employee_number] = useState<string>(null);
  const [user_email, setUser_email] = useState<string>(null);
  // const [user_country, Setuser_country] = useState(null as string || null);
  const [user_password, setUser_password] = useState<string>(null);
  const [user_name, setUser_name] = useState<string>(null);
  const [user_lastname, setUser_lastname] = useState<string>(null);
  const [user_whatsapp, Setuser_whatsapp] = useState<string>(null);
  const [subdomain, Setsubdomain] = useState<string>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showfeedbackEmail, setshowfeedbackEmail] = useState<boolean>(false);
  const [showfeedbackName, setShowfeedbackName] = useState<boolean>(false);
  const [showfeedbackLastname, setShowfeedbackLastname] = useState<boolean>(false);
  const [showfeedbackPhone, setShowfeedbackPhone] = useState<boolean>(true);
  // const [showfeedbackWhatsapp, setShowfeedbackWhatsapp] = useState(false);
  const [showfeedbackCompany_name, setShowfeedbackCompany_name] = useState<boolean>(false);
  const [showfeedbackPassword, setShowfeedbackPassword] = useState<boolean>(false);
  const [document_number, setDocument_number] = useState<string>(null);
  const [ShowfeedbackDocument_number, setShowfeedbackDocument_number] = useState<boolean>(false);
  const [validateNumber, setValidateNumber] = useState<boolean>(false);
  const [whatsappTag] = useState<string>("whatsapp");
  const [whatsappError, setWhatsappError] = useState<Object>(null);
  const [/*forceValidateWhats*/, setForceValidateWhats] = useState<boolean>(false);

  const [validated] = useState<boolean>(false);
  const [/*isLoading*/, setIsLoading] = useState<boolean>(false);

  const [selected_country, setSelected_country] = useState([]);

  // tem que refatorar dps tbm
  const validSelectDropdownCompanyEmployeeNumber = () => RegisterControlerConst.ValidSelectDropdownCompanyEmployeeNumber(props.isMobile);
  const RegisterControlerConst = new RegisterControler(setShowPassword,showPassword,setStage,stage,setProgress,progress,Setcompany_employee_number);

  const resendEmailConfirmation = () => {
    const body = {
      "email": user_email,
      "subdomain": subdomain,
    };

    const headers = {
      "Content-Type": "application/json; charset=utf-8"
    };

    AuthenticationRequestsConst.SendEmailWithConfirmation(
      body,
      { headers },
      (response: Object) => {
        return response
      },
      (data: Object) => {
        return data
        // setIsLoading(false);
      },
      (error: Object) => {
        // return error
      }, navigate, dispatch, setIsLoading,
      
    );
  }

  const [options_country, setOptions_country] = useState([
      { label: t("options_country.brazil"), value: 'PT-BR' },
      { label: t("options_country.portugal"), value: 'PT-PT' },
      { label: t("options_country.spain"), value: 'ES' },
      { label: t("options_country.france"), value: 'FR' },
      { label: t("options_country.uk"), value: 'UK' },
    ])
  
    const switchLanguage = () => {
      if (selected_country[0]?.value === 'PT-BR') {
        return ['br']
      }
      if (selected_country[0]?.value === 'PT-PT') {
        return ['pt']
      }
      else if (selected_country[0]?.value === 'ES') {
        return ['es']
      }
      else if (selected_country[0]?.value === 'FR') {
        return ['fr']
      }
      else if (selected_country[0]?.value === 'UK') {
        return ['gb']
      }
      else {
        return ['br']
      }
    }
  
    const validatePhone = () => {
      if(selected_country[0]?.value === 'PT-BR' && agent_phone?.length === 13){
        return false;
      }
      else if(selected_country[0]?.value === 'PT-PT' && agent_phone?.length === 12){
        return false;
      }
      else if(selected_country[0]?.value === 'ES' && agent_phone?.length === 11){
        return false;
      }
      else if(selected_country[0]?.value === 'FR' && agent_phone?.length === 11){
        return false;
      }
      else if(selected_country[0]?.value === 'UK' && agent_phone?.length === 12){
        return false;
      }
      return true
    }
  
    const validateCountry = () => {
      if(selected_country[0] !== undefined) { // verifica se o país foi selecionado, e libera o botão para seguir com o cadastro
        return false
      }
      else{
        return true
      }
    }
  
    const switchDocument = () => {
      if (selected_country[0]?.value === 'PT-BR') {
        return 'CNPJ'
      }
      else if (selected_country[0]?.value === 'PT-PT') {
        return 'NIPC'
      }
      else if (selected_country[0]?.value === 'ES') {
        return 'NIF'
      }
      else if (selected_country[0]?.value === 'FR') {
        return 'SIREN'
      }
      else if (selected_country[0]?.value === 'UK') {
        return 'CRN'
      }
      else {
        return 'CNPJ'
      }
    }
  
    const countryCodes = {
      'PT-PT': 'PT',
      'PT-BR': 'BR',
      'UK': 'GB',
      'ES': 'ES'
    };

    const ValidateRegisterForm = (values: BodyInterface) => {
      let errors = {};
      if (values.name && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.name)) {
        errors['name'] = t("errors.invalid_name");
        setShowfeedbackName(true);
      } else if (values.name.length > 25) {
        errors['name'] = t("errors.limit_chars_name");
        setShowfeedbackName(true);
      } else {
        setShowfeedbackName(false);
      }

      if (values.lastname && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.lastname)) {
        errors['lastname'] = t("errors.invalid_lastname");
        setShowfeedbackLastname(true);
      } else if (values.lastname.length > 25) {
        errors['lastname'] = t("errors.limit_chars_lastname");
        setShowfeedbackLastname(true);
      } else {
        setShowfeedbackLastname(false);
      }

      return errors;
  }

  const ValidateEmailForm = (values: BodyInterface) => {
      let errors = {};
      if (!values.email) {
        errors['email'] = t("errors.error_fill_field");
        setshowfeedbackEmail(true);
      } else if (values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
      ) {
        errors['email'] = t("errors.error_invalid_email");
        setshowfeedbackEmail(true);
      }
      else if (values.email.length >= 100) {
        errors['email'] = t("errors.limit_chars_email");
        setshowfeedbackEmail(true);
      } else {
        setshowfeedbackEmail(false);
      }
      return errors;
  }

  const ValidateCompanyForm = (values: BodyInterface) => {
      let errors = {};

      if (
          values.company_name && !/^[a-zA-Z0-9À-ÿÇç&-]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/g.test(values.company_name)
      ) {
          errors['company_name'] = t("errors.invalid_company_name");
          setShowfeedbackCompany_name(true);
      } else if (values.company_name.length > 25) {
          errors['company_name'] = t("errors.limit_chars_company_name");
          setShowfeedbackCompany_name(true);
      } else {
          setShowfeedbackCompany_name(false);
      }

      if (values.password) {    
          if (values.password.length > 0 && values.password.length <= 4) {
              errors['password'] = t("errors.limit_short_password");
              setShowfeedbackPassword(true);
          } else if (values.password.length > 128) {
              errors['password'] = t("errors.limit_password");
              setShowfeedbackPassword(true);
          } else if (user_email?.includes(values?.password)) {
              errors['password'] = t("errors.distinct_password_email");
              setShowfeedbackPassword(true);
          } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
              errors['password'] = t("errors.fill_special_character_password");
              setShowfeedbackPassword(true);
          } else if (!/[A-Z]/.test(values.password)) {
              errors['password'] = t("errors.fill_uppercase_character_password");
              setShowfeedbackPassword(true);
          } else if (!/[a-z]/.test(values.password)) {
              errors['password'] = t("errors.fill_lowercase_character_password");
              setShowfeedbackPassword(true);
          } else if (!/\d/.test(values.password)) {
              errors['password'] = t("errors.fill_number_password");
              setShowfeedbackPassword(true);
          } else if (/012|123|234|345|456|567|678|789/.test(values.password)) {
              errors['password'] = t("errors.no_sequential_numbers");
              setShowfeedbackPassword(true);
          } else {
              setShowfeedbackPassword(false);
          }
      } else {
          setShowfeedbackPassword(false);
      }

      validSelectDropdownCompanyEmployeeNumber();

      if (values.document_number && values.document_number.search("_") !== -1) {
          //errors['document_number'] = `${this.t('defaults.cnpj_nif')} inválido`;
          errors['document_number'] = t("errors.invalid_document");
          setShowfeedbackDocument_number(true);
      } else {
          setShowfeedbackDocument_number(false);
      }

      return errors;
  }

  const ClickToViewPassword = () => {
    setShowPassword(!showPassword);

    if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
      (document.getElementById("user_password") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("user_password") as HTMLInputElement).type = "text";
    }
  }


  const clickNextStage = () => {
    setStage(stage + 1);
    setProgress(progress + 34);

    RegisterControlerConst.ClickStage();

    setStage((state) => {
      Utils.changeStyleElementById('stage' + state, 'display', 'block');
      return state;
    });
  }

  const clickPreviousStage = () => {
    setStage(stage - 1);
    setProgress(progress - 34);

    RegisterControlerConst.ClickStage();

    setStage((state) => {
      Utils.changeStyleElementById('stage' + state, 'display', 'block');
      return state;
    });

  }

  const OnSelectDropdownCompanyEmployeeNumber = (value: string) => {
    Setcompany_employee_number(value);
    RegisterControlerConst.OnSelectDropdownCompanyEmployeeNumber(value, props.isMobile);
  }

  const submit = async() => {
    const country = countryCodes[selected_country[0]?.value] || selected_country[0]?.value?.toUpperCase();
    if (!validSelectDropdownCompanyEmployeeNumber()) {
      const body = {
        "company_name": company_name,
        "employee_amount": company_employee_number,
        "email": user_email,
        "password": user_password,
        "user_name": user_name,
        "user_lastname": user_lastname,
        "phone": agent_phone,
        "document_number": document_number.toLocaleUpperCase().replace(/[^a-zA-Z0-9]/g, ''),
        "country": country
      };

      const headers = {
        "Content-Type": "application/json; charset=utf-8"
      };

      // setIsLoading(true);
      AuthenticationRequestsConst.RegisterCompany(
        body,
        { headers },
        (response: Object) => {
          return response
        },
        (data: { data: { subdomain: string; }; }) => {
          Setsubdomain(data.data.subdomain);
          clickNextStage();
          // setIsLoading(false);
          return data
        },
        (error:{response:{status:number; data:{code_cxpress: number}}}) => {
            if (error.response.status === 500) {
              dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else {
              if(error?.response?.data?.code_cxpress === 1005){
                document.getElementById("feedbackCNPJ").innerHTML = t("errors.already_used_error_document");
                document.getElementById("feedbackCNPJ").style.display = "block";
              }
              else {
                // document.getElementById("feedbackCNPJ").innerHTML = verifyCode(error.response.data.code_cxpress, t);
                document.getElementById("feedbackCNPJ").innerHTML = t("errors.invalid_document");
                document.getElementById("feedbackCNPJ").style.display = "block";
                setShowfeedbackDocument_number(true);
              }
            }
          //}
        }, navigate, dispatch, setIsLoading,
      );
    } else {
      if (document.getElementById("feedbackCompany_employee_number")) {
        document.getElementById("feedbackCompany_employee_number").innerHTML = t("errors.select_number_employees");
        document.getElementById("feedbackCompany_employee_number").style.display = "block";
      }
    }
  }

  return (
    <RegisterFormNew 
      t={t}
      stage={stage}
      progress={progress}
      setUser_email={setUser_email}
      ValidateEmailForm={ValidateEmailForm}
      clickNextStage={clickNextStage}
      validated={validated}
      options_country={options_country}
      setOptions_country={setOptions_country}
      selected_country={selected_country}
      setSelected_country={setSelected_country}
      showfeedbackEmail={showfeedbackEmail}
      setshowfeedbackEmail={setshowfeedbackEmail}
      validateCountry={validateCountry}
      setUser_name={setUser_name}
      setUser_lastname={setUser_lastname}
      ValidateRegisterForm={ValidateRegisterForm}
      showfeedbackName={showfeedbackName}
      showfeedbackLastname={showfeedbackLastname}
      setAgent_phone={setAgent_phone}
      switchLanguage={switchLanguage}
      validatePhone={validatePhone}
      clickPreviousStage={clickPreviousStage}
      setDocument_number={setDocument_number}
      setCompany_name={setCompany_name}
      setUser_password={setUser_password}
      ValidateCompanyForm={ValidateCompanyForm}
      countryCodes={countryCodes}
      validSelectDropdownCompanyEmployeeNumber={validSelectDropdownCompanyEmployeeNumber}
      submit={submit}
      showfeedbackCompany_name={showfeedbackCompany_name}
      OnSelectDropdownCompanyEmployeeNumber={OnSelectDropdownCompanyEmployeeNumber}
      switchDocument={switchDocument}
      ShowfeedbackDocument_number={ShowfeedbackDocument_number}
      showfeedbackPassword={showfeedbackPassword}
      ClickToViewPassword={ClickToViewPassword}
      showPassword={showPassword}
      resendEmailConfirmation={resendEmailConfirmation}
      subdomain={subdomain}
    />
  );
};

export default RegisterFormController;
