import { createContext, useContext, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { BACKEND_URL } from '../shared/backend_url';

export const SocketV2Context = createContext<SocketV2ContextType>(null);

export const SocketV2Provider = ({ children }) => {

  const [socket, setSocket] = useState<Socket>(undefined);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const [socketConnectError, setSocketConnectError] = useState(false);
  const [connectingSocket, setConnectingSocket] = useState(false);

  const initSocket = (token: string, origin: 'context' | 'app') => {
    if (token && !socket && !connectingSocket) {
      const new_socket: Socket = io(
        BACKEND_URL,
        {
          path: '/api/socket',
          transports: ['websocket'],
          auth: { token: token }
        }
      );

      new_socket.on('connect', () => {
        console.info(':: socket connected ::', origin);
        setSocketConnectError(false);
        setConnectingSocket(false);
        setSocketInitialized(true);
      });

      new_socket.on('connect_error', () => {
        console.info(':: socket connection error ::', origin);
        setSocketConnectError(true);
        setSocketInitialized(false);
      });

      new_socket.on('disconnect', () => {
        console.info(':: socket disconnected ::', origin);
      });

      setSocket(new_socket);
    }
  }

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(undefined);
    } else {
      setSocket(undefined);
    }
  }

  const emitSocketAppEvent = (api_event: SocketSendAppEvents, data: any) => {
    if (socket) {
      socket.emit(api_event, data);
    }
  }

  const emitSocketChatbotEvent = (api_event: string, data: any) => {
    if (socket) {
      socket.emit(api_event, data);
    }
  }

  const registerSocketAppEvent = (event: SocketReceiveAppEvents, listener: (socket_data: any) => void) => {
    // console.info(':: trying register socket app event:', event);
    if (socket) {
      addEvent(event, listener);
    } else {
      const interval = setInterval(() => {
        if (socket) {
          // console.info(':: socket instantiated')
          clearInterval(interval);
          addEvent(event, listener);
        } /*else {
          console.info(':: socket not instantiated');
          if (!connectingSocket) {
            initSocket(token, 'context');
          }
        }*/
      }, 1000);
    }
  }

  const registerSocketChatbotEvent = (event: SocketReceiveChatbotEvents, listener: (socket_data: any) => void) => {
    // console.info(':: trying register socket chatbot event:', event);
    if (socket) {
      addEvent(event, listener);
    } else {
      const interval = setInterval(() => {
        if (socket) {
          // console.info(':: socket instantiated :: registerSocketChatbotEvent')
          clearInterval(interval);
          addEvent(event, listener);
        } /*else {
          console.info(':: socket not instantiated :: registerSocketChatbotEvent');
          if (!connectingSocket) {
            initSocket(token, 'context');
          }
        }*/
      }, 1000);
    }
  }

  const unregisterSocketAppEvent = (event: SocketReceiveAppEvents, listener?: (socket_data: any) => void) => {
    // console.info(':: trying unregister socket app event:', event);
    if (socket) {
      removeEvent(event, listener);
    } else {
      const interval = setInterval(() => {
        if (socket) {
          // console.info(':: socket instantiated')
          clearInterval(interval);
          removeEvent(event, listener);
        } /*else {
          console.info(':: socket not instantiated');
          if (!connectingSocket) {
            initSocket(token);
          }
        }*/
      }, 500);
    }
  }

  const unregisterSocketChatbotEvent = (event: SocketReceiveChatbotEvents, listener?: (socket_data: any) => void) => {
    // console.info(':: trying unregister socket chatbot event:', event);
    if (socket) {
      removeEvent(event, listener);
    } else {
      const interval = setInterval(() => {
        if (socket) {
          console.info(':: socket instantiated')
          clearInterval(interval);
          removeEvent(event, listener);
        } /*else {
          console.info(':: socket not instantiated');
          if (!connectingSocket) {
            initSocket(token);
          }
        }*/
      }, 500);
    }
  }

  const addEvent = (event: string, listener: (socket_data: any) => void) => {
    if (!socket.hasListeners(event)) {
      setSocket(current_socket => {
        if (current_socket) {
          current_socket.on(event, listener);
          // console.info(':: register socket event', event, 'registered successfully');
          return current_socket;
        }
      });
    } else {
      let sameSocketCallback = false;

      socket.listeners(event).forEach(callbackFn => {
        if (callbackFn.name === listener.name) {
          sameSocketCallback = true;
        }
      });

      if (sameSocketCallback) {
        // console.info(':: register socket event', event, 'not completed. Event already exists');
      } else {
        setSocket(current_socket => {
          if (current_socket) {
            current_socket.on(event, listener);
            // console.info(':: register socket event', event, 'registered successfully');
            return current_socket;
          }
        });
      }
    }
  }

  const removeEvent = (event: string, listener?: (socket_data: any) => void) => {
    if (socket.hasListeners(event)) {
      setSocket(current_socket => {
        if (current_socket) {
          if (listener) {
            current_socket.off(event, listener);
          } else {
            current_socket.off(event);
          }
          // console.info(':: unregister socket event', event, 'unregistered successfully');
          return current_socket;
        }
      });
    } else {
      // console.info(':: unregister socket event', event, 'not completed. Event not registered');
    }
  }
  
  const ProviderValue = {
    socket,
    initSocket,
    socketInitialized,
    disconnectSocket,
    emitSocketAppEvent,
    emitSocketChatbotEvent,
    registerSocketAppEvent,
    registerSocketChatbotEvent,
    unregisterSocketAppEvent,
    unregisterSocketChatbotEvent,
    socketConnectError,
  }
  
  return (
    <SocketV2Context.Provider value={ProviderValue}>
      {children}
    </SocketV2Context.Provider>
  );
}

export const useSocketV2Context = () => useContext(SocketV2Context);

type SocketV2ContextType = {
  socket: Socket;
  initSocket: (token: string, origin: 'app' | 'context') => void;
  socketInitialized: boolean;
  disconnectSocket: () => void;
  emitSocketAppEvent: (api_event: SocketSendAppEvents, data: any) => void;
  emitSocketChatbotEvent: (api_event: SocketSendChatbotEvents, data: any) => void;
  registerSocketAppEvent: (event: SocketReceiveAppEvents, listener: (socket_data?: any) => void) => void;
  registerSocketChatbotEvent: (event: SocketReceiveChatbotEvents, listener: (socket_data?: any) => void) => void;
  unregisterSocketAppEvent: (event: SocketReceiveAppEvents, listener?: (socket_data: any) => void) => void;
  unregisterSocketChatbotEvent: (event: SocketReceiveChatbotEvents, listener?: (socket_data: any) => void) => void;
  socketConnectError: boolean;
}

export type SocketSendAppEvents = (
  'start-ticket-chat' | 'leave-ticket-chat' | 'create-message' |
  'update-status-employee'
);
export type SocketSendChatbotEvents = '';
export type SocketReceiveAppEvents = (
  'who-is-viewing' | 'ticket-block' | 'new-ticket-from-consumer-telephony' | 'ticket-update' | 'ticket-deleted' | 'ticket-sector-changed' |
  'update-counters' |
  'disconnect-old-logged-users' | 'new-consumer-message' | 'new-agent-message' | 'new-ticket' | 'error-on-create-message' |
  'ticket-notification' | 'task-notification' |
  'status-employee'
);
export type SocketReceiveChatbotEvents = (
  'botcxpress-updated-files'
);