import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from 'react';
import TicketEdit from '.';
import { TicketEditControllerInterface } from './indexModel';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';
import { useMainAppContext } from "../../../../../../../../core/context/main-app-context";
import emitter from "../../../../../../../../core/shared/emitter";
import { PagesType } from "../../../../../../components-fixed/sidebarAppComponent/indexModel";
import { CustomSelectOptionType } from "../../../../../../../../components/customSelect/indexModel";
import { useSelector } from "react-redux";
import { getIdUser, getNameUser } from "../../../../../../../../store/user";
import { getEmployeeSectors } from "../../../../../../../../store/employee";
import { getAppSidebarPage } from "../../../../../../../../store/app_sidebar";

const TicketEditController: FC<TicketEditControllerInterface> = (props) => {
  const { t } = useTranslation('TicketEdit');

  const currentPage = useSelector(getAppSidebarPage);

  const { 
    isLoadingSelectedConsumer,
    ticketIsBlocked,
    setShowConsumerModal,
    currentConsumer,
    isLoadingRequest,
    currentTicket,
  } = useViewTicketContext();

  const {
    isSearched
  } = useMainAppContext();

  const loggedUserId = useSelector(getIdUser);
  const loggedUserName = useSelector(getNameUser);
  const loggedUserSectors = useSelector(getEmployeeSectors);

  const inputItemsRef = useRef<HTMLDivElement>(null);

  const [tab, setTab] = useState<'main' | 'custom'>('main');
  const [hasScroll, setHasScroll] = useState(false);
  const [showModalSector, setShowModalSector] = useState(false);
  const [showModalTag, setShowModalTag] = useState(false);

  useEffect(() => {
    const element = inputItemsRef.current;

    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      setHasScroll(scrollTop > 0 || scrollHeight > clientHeight);
    }
  }, [tab]);

  const handleSubmit = () => {
    if (isValidForm() && !isLoadingRequest()) {
      props.handleSubmit();
    }
  }

  const openEditConsumerModal = () => {
    setShowConsumerModal(true);
    setTimeout(() => {
      emitter.emit('open-modal-consumer', { userId: currentConsumer?.user_id });
    }, 50);
  }

  const isValidForm = () => {
    return props.getSubjectInput().isValidSubject() && props.getCustomFieldsData().isValidCustomFields();
  }

  const getComponentHeighDiff = () => {
    const customPagesFilter: PagesType[] = [
      'consumer-tickets', 
      'agent-tickets', 
      'task-ticket', 
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];
    
    if (isSearched || customPagesFilter.includes(props.pageType)) {
      return 255;
    } else {
      return 185;
    }
  }

  const openModalSector = (name?: string) => {
    setShowModalSector(true);
    setTimeout(() => {
      emitter.emit('open-modal-sector', { id: null, name, active: true });
    }, 50);
  }

  const closeModalSector = (reload: boolean, sector?: { id: string; name: string; active: boolean; }) => {
    if (reload && sector && sector.active) {
      props.getSectorSelect().fetchSectorsPagination(1);
      props.getSectorSelect().setSelectedSector({ id: sector.id, value: sector.name });
    }
    setShowModalSector(false);
  }

  const openModalTag = (name?: string) => {
    setShowModalTag(true);
    setTimeout(() => {
      emitter.emit('open-modal-tag', { id: null, name, active: true });
    }, 50);
  }

  const closeModalTag = (reload: boolean, tag?: { id: string; name: string; active: boolean; }) => {
    if (reload && tag && tag.active) {
      props.getTagsSelect().fetchTagsPagination(1);
      props.getTagsSelect().setSelectedTags(prevState => {
        const newOption: CustomSelectOptionType = { id: tag.id, value: tag.name };
        if (prevState) {
          return [newOption, ...prevState];
        } else {
          return [newOption];
        }
      });
    }
    setShowModalTag(false);
  }

  const currentUserAccept = (type: 'agent' | 'follower') => {
    if (type === 'agent') {
      props.getEmployeeSelect().setSelectedEmployee({ id: loggedUserId, value: loggedUserName });
    } else if (type === 'follower') {
      props.getFolowersSelect().setSelectedFollowers(prevState => {
        if (!prevState?.map(item => item.id).includes(loggedUserId)) {
          return [{ id: loggedUserId, value: loggedUserName }, ...prevState];
        } else {
          return [...prevState];
        }
      });
    }
  }

  const isEmployeeSector = () => {
    if (props.getSectorSelect().selectedSector?.id) {
      if (loggedUserSectors?.map(item => item.sector_id).includes(props.getSectorSelect().selectedSector.id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const isSelectedEmployee = (type: 'agent' | 'follower') => {
    if (type === 'agent') {
      return props.getEmployeeSelect().selectedEmployee?.id === loggedUserId;
    } else if (type === 'follower') {
      return props.getFolowersSelect().selectedFollowers?.map(item => item.id).includes(loggedUserId);
    }
  }

  const ticketWebchatBlocked = () => {
    if (currentPage === 'history_chatbots') {
      if (currentTicket?.who_is_viewing && currentTicket?.who_is_viewing_user_id !== loggedUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return ticketIsBlocked;
    }
  }

  return (
    <TicketEdit
      t={t}
      inputItemsRef={inputItemsRef}
      hasScroll={hasScroll}
      tab={tab}
      setTab={setTab}
      ticketIsBlocked={ticketIsBlocked}
      handleSubmit={handleSubmit}
      isValidForm={isValidForm}
      isLoadingEdit={props.isLoadingEdit}
      isLoadingRequest={isLoadingRequest()}
      getConsumerSelect={props.getConsumerSelect}
      isLoadingSelectedConsumer={isLoadingSelectedConsumer}
      openEditConsumerModal={openEditConsumerModal}
      getSectorSelect={props.getSectorSelect}
      getEmployeeSelect={props.getEmployeeSelect}
      getStatusSelect={props.getStatusSelect}
      getFolowersSelect={props.getFolowersSelect}
      getTagsSelect={props.getTagsSelect}
      getSubjectInput={props.getSubjectInput}
      getCustomFieldsData={props.getCustomFieldsData}
      getComponentHeighDiff={getComponentHeighDiff}
      showModalSector={showModalSector}
      openModalSector={openModalSector}
      closeModalSector={closeModalSector}
      showModalTag={showModalTag}
      openModalTag={openModalTag}
      closeModalTag={closeModalTag}
      currentUserAccept={currentUserAccept}
      isEmployeeSector={isEmployeeSector}
      isSelectedEmployee={isSelectedEmployee}
      currentTicketChannel={currentTicket?.channel_id}
      ticketWebchatBlocked={ticketWebchatBlocked}
    />
  );
}

export default TicketEditController;