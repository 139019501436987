import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/control_panel";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Brokers } from "../../../../../core/enums/brokers";
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import ModalRegisterWhastapp from ".";

const ModalRegisterWhastappController = ({ show, onHide, company_data, getWhatsAppById }) => {
    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [hasBot, setHasbot] = useState(false)
    const [selectedBroker, setSelectedBrokers] = useState([])

    const [onSelectBroker, setOnSelectBroker] = useState([])

    // const [companyCNPJ, setCompanyCnpj] = useState("")
    const [nameAccount, setNameAccount] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [tokenAuth, setTokenAuth] = useState("")
    const [sid, setSid] = useState("")
    const [botNlp, setBotNlp] = useState("")
    const [apiUrl, setApiUrl] = useState("")
    const [urlBot, setUrlBot] = useState("")
    const [postBackUrl, setPostBackUrl] = useState("")
    const [senderSid, setSenderSid] = useState("")

    const [showFeedbackNameAccount, setShowFeedbackNameAccount] = useState(false)
    const [showFeedbackPhoneNumber, setShowFeedbackPhoneNumber] = useState(false)
    const [showFeedbackTokenAuth, setShowFeedbackTokenAuth] = useState(false)
    const [showFeedbackSid, setShowFeedbackSid] = useState(false)
    const [showFeedbackBotNlp, setShowFeedbackBotNlp] = useState(false)
    const [showFeedbackUrlBot, setShowFeedbackUrlBot] = useState(false)
    const [showFeedbackApiUrl, setShowFeedbackApiUrl] = useState(false)
    const [showFeedbackPostBackUrl, setShowFeedbackPostBackUrl] = useState(false)
    const [showFeedbackSenderSid, setShowFeedbackSenderSid] = useState(false)

    const AppRequesterConst = new AppRequesterControlPanelController()

    const [optionsBroker, setOptionsBroker] = useState([
        { label: "Twilio", value: Brokers.Twilio },
        { label: "Positus", value: Brokers.Positus },
        { label: "Meta", value: Brokers.Meta },
        { label: "Smarters", value: Brokers.Smarters }
    ])

    // useEffect(() => {
    //     if (company_data) {
    //         setCompanyCnpj(company_data.company_cnpj)
    //     }
    // }, [company_data])

    const clearInput = () => {
        setShowFeedbackBotNlp(false)
        setShowFeedbackUrlBot(false)
        setShowFeedbackTokenAuth(false)
        setShowFeedbackNameAccount(false)
        setShowFeedbackPhoneNumber(false)
        setShowFeedbackSid(false)
        setShowFeedbackApiUrl(false)
        setShowFeedbackPostBackUrl(false)
        setShowFeedbackSenderSid(false)
        setNameAccount("")
        setPhoneNumber("")
        setTokenAuth("")
        setSid("")
        setBotNlp("")
        setApiUrl("")
        setUrlBot("")
        setPostBackUrl("")
        setSenderSid("")
        setHasbot(false)
        setOnSelectBroker([])
        setSelectedBrokers([])
        onHide()
    }

    const selectBrokerId = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === 'NA') {
                setOnSelectBroker([])
            } else {
                setOnSelectBroker([value[value.length - 1]])
            }
        } else {
            setOnSelectBroker([])
        }
    }

    return <ModalRegisterWhastapp
        show={show}
        clearInput={clearInput}
        setNameAccount={setNameAccount}
        setPhoneNumber={setPhoneNumber}
        setTokenAuth={setTokenAuth}
        setSid={setSid}
        setBotNlp={setBotNlp}
        setApiUrl={setApiUrl}
        setUrlBot={setUrlBot}
        setPostBackUrl={setPostBackUrl}
        setShowFeedbackNameAccount={setShowFeedbackNameAccount}
        setShowFeedbackPhoneNumber={setShowFeedbackPhoneNumber}
        setShowFeedbackTokenAuth={setShowFeedbackTokenAuth}
        setShowFeedbackSid={setShowFeedbackSid}
        hasBot={hasBot}
        setShowFeedbackBotNlp={setShowFeedbackBotNlp}
        setShowFeedbackUrlBot={setShowFeedbackUrlBot}
        setShowFeedbackPostBackUrl={setShowFeedbackPostBackUrl}
        company_data={company_data}
        nameAccount={nameAccount}
        phoneNumber={phoneNumber}
        tokenAuth={tokenAuth}
        sid={sid}
        apiUrl={apiUrl}
        onSelectBroker={onSelectBroker}
        botNlp={botNlp}
        urlBot={urlBot}
        postBackUrl={postBackUrl}
        values={values}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        getWhatsAppById={getWhatsAppById}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        showFeedbackNameAccount={showFeedbackNameAccount}
        showFeedbackPhoneNumber={showFeedbackPhoneNumber}
        showFeedbackTokenAuth={showFeedbackTokenAuth}
        showFeedbackSid={showFeedbackSid}
        showFeedbackApiUrl={showFeedbackApiUrl}
        optionsBroker={optionsBroker}
        setOptionsBroker={setOptionsBroker}
        selectedBroker={selectedBroker}
        setSelectedBrokers={setSelectedBrokers}
        selectBrokerId={selectBrokerId}
        setHasbot={setHasbot}
        showFeedbackBotNlp={showFeedbackBotNlp}
        showFeedbackUrlBot={showFeedbackUrlBot}
        showFeedbackPostBackUrl={showFeedbackPostBackUrl}
        senderSid={senderSid}
        setSenderSid={setSenderSid}
        setShowFeedbackSenderSid={setShowFeedbackSenderSid}
        showFeedbackSenderSid={showFeedbackSenderSid}
    />
}

export default ModalRegisterWhastappController;