import { Formik } from "formik"
import { Modal, Form, Row, Col, InputGroup, FormControl, FormLabel, Button, Spinner } from "react-bootstrap"
import styles from '../styles'
import { setShowAlertFeedback } from "../../../../../store/internal"
import "./style.scss"
import allStyles from "../../../../homeApp/components/chatEditor/styles"


const ModalEditBroker = ({
    show,
    onHide,
    currentWhatsappId,
    Webhook,
    description,
    addres,
    email,
    setWebhook,
    setAddres,
    setDescription,
    setEmail,
    setShowFeedbackEmail,
    validateUrl,
    setShowFeedbackWebhook,
    urls,
    file_id,
    AppRequesteConst,
    values,
    dispatch,
    setIsLoading,
    navigate,
    validated,
    showFeedbackWebhook,
    getRootProps,
    getInputProps,
    files,
    deleted,
    setErrorSelectRecipients,
    showFiles,
    errorSelectRecipients,
    showFeedbackAddres,
    showFeedbackDescription,
    showFeedbackEmail,
    handleChangeUrl,
    addUrl,
    removeUrl

}) => {

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Configurações de Broker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        Webhook: Webhook,
                        description: description,
                        addres: addres,
                        email: email
                    }}
                    validate={(values) => {
                        let errors = {};
                        setWebhook(values.Webhook)
                        setAddres(values.addres)
                        setDescription(values.description)
                        setEmail(values.email)

                        if (!values.email) {
                            setShowFeedbackEmail(false)
                        }
                        else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
                                values.email
                            )
                        ) {
                            errors["email"] = 'E-mail inválido'
                            setShowFeedbackEmail(true)
                        } else {
                            setShowFeedbackEmail(false)
                        }

                        if(validateUrl(values.Webhook)) {
                            errors["Webhook"] = ''
                            setShowFeedbackWebhook(true);
                        } else {
                            setShowFeedbackWebhook(false);
                        }
                        return errors
                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            profile: {
                            }

                        }

                        if (addres) {
                            JsonSend['profile']['address'] = addres
                        }
                        if (description) {
                            JsonSend['profile']['description'] = description
                        }
                        if (email) {
                            JsonSend['profile']['email'] = email
                        }
                        if (Webhook !== '') {
                            JsonSend["webhook"] = Webhook
                        }
                        if (urls[0].length > 0) {
                            JsonSend['profile']['websites'] = urls
                        }
                        if (file_id) {
                            JsonSend['profile_picture_id'] = file_id
                        }

                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + values.token.value
                        };
                        await AppRequesteConst.Post(
                            `/channel-whatsapp/broker-config/${currentWhatsappId}`, JsonSend, { headers },
                            (response: Object) => {

                            },
                            (data) => {
                                dispatch(setShowAlertFeedback({ message: "Alterações salvas com sucesso.", visibility: true, signalIcon: true }))
                                onHide()
                            },
                            (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
                                if (error.response.status === 400) {
                                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                                } 
                                else if (error?.response?.status === 422) {
                                    dispatch(setShowAlertFeedback({ message: `Campo com formato inválido`, visibility: true, singnalIcon: false }))
                                }
                            }, navigate, dispatch, setIsLoading
                        )

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="Webhook">
                                        Webhook
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="Webhook"
                                            isInvalid={showFeedbackWebhook}
                                            className="form-control-Default"
                                            placeholder="https://exemplo"
                                            aria-label="Informe o webhook"
                                            aria-describedby="Informe o webhook"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Webhook}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackWebhook">
                                            {errors.Webhook}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="mb-3 d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">Foto de perfil</Form.Label>
                                    <div style={{
                                        width: '102%',
                                        display: 'flex',
                                        overflowY: 'hidden',
                                        marginLeft: '-0.6vw',
                                        justifyContent: 'left'
                                    }}>
                                        <div {...getRootProps({ className: 'dropzone' })}
                                            style={allStyles.dragDropzone}>
                                            <input {...getInputProps({})} />
                                            {files.length > 0 && !deleted ?
                                                <div name="showFilesName" className="ps-2 py-1 pt-2">
                                                    {setErrorSelectRecipients(false)}
                                                    <div style={{ display: 'block' }}>
                                                        {showFiles}
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div>Faça upload da foto de perfil</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {errorSelectRecipients && <p style={{ padding: '0', color: '#dc3545', fontSize: ".875em" }}> Preencha o campo </p>}
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel htmlFor="addres">
                                    Endereço
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="addres"
                                        isInvalid={showFeedbackAddres}
                                        className="form-control-Default"
                                        placeholder="Informe o endereço"
                                        aria-label="Informe o endereço"
                                        aria-describedby="Informe o endereço"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addres}
                                        maxLength={256}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackAddres">
                                        {errors.addres}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Descrição
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="description"
                                        isInvalid={showFeedbackDescription}
                                        className="form-control-Default"
                                        placeholder="Informe a descrição"
                                        aria-describedby="Informe a descrição"
                                        aria-label="Informe a descrição"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        maxLength={512}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackDescription">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="email"
                                        isInvalid={showFeedbackEmail}
                                        className="form-control-Default"
                                        placeholder="Informe o email"
                                        aria-describedby="Informe o email"
                                        aria-label="Informe o email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        maxLength={128}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackEmail">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Websites
                                </FormLabel>
                                {urls.map((url, index) => (
                                    <InputGroup key={index} className="mb-2 d-flex align-items-center">
                                        <FormControl
                                            isInvalid={validateUrl(url)}
                                            className="form-control-Default"
                                            placeholder="https://exemplo"
                                            aria-describedby="Informe a(s) URL(s)"
                                            aria-label="Informe a(s) URL(s)"
                                            onChange={(e) => handleChangeUrl(index, e)}
                                            value={url}
                                            maxLength={256}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors[index]}
                                        </Form.Control.Feedback>
                                        {urls.length > 1 && (
                                            <Button size="lg" className="buttonWhiteBroker" style={{ width: '22%', marginLeft: '0.3rem' }} onClick={() => removeUrl(index)}>
                                                Remover
                                            </Button>
                                        )}
                                    </InputGroup>
                                ))}
                                {urls.length < 2 && (
                                    <>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            <Button size="lg" className="buttonBlue" style={{ width: '28%' }} onClick={addUrl}>
                                                Adicionar URL
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => onHide()}>Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit" disabled={!isValid}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal >
    )
}

export default ModalEditBroker