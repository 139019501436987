import React, { useState } from 'react';
import Styles from './styles.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import RegisterFormController from '../registerForm/indexController';

const RegisterHome = () => {
  const [isLoading, /*setIsLoading*/] = useState(false);
  const { t } = useTranslation();

  const getRegisterText = (): JSX.Element => {
    const language = localStorage.getItem('i18nextLng');

    if (language === 'en') {
      return (
        <div className='' style={{display: 'inline-block', textAlign: 'left', lineHeight: '6.5vh' }}>
          Start your <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15-day</span> <span className={Styles.registerDesktopOrangeLabel}>free trial</span><br/>
          on the most <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitive</span><br/> 
          <span className={Styles.registerDesktopOrangeLabel}>conversation management</span><br/> 
          platform on the market.
        </div>
      );
    } else if (language === 'es') {
      return (
        <div className='' style={{display: 'inline-block', textAlign: 'left', lineHeight: '6.5vh' }}>
          Comienza tu <span className={Styles.registerDesktopOrangeLabel}>prueba gratuita</span><br /> 
          de <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15 días en la plataforma</span><br /> 
          de <span className={Styles.registerDesktopOrangeLabel}>gestión de conversaciones</span><br /> 
          más <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitiva</span> del mercado.
        </div>
      );
    } else if (language === 'fr') {
      return (
        <div className='' style={{display: 'inline-block', textAlign: 'left', lineHeight: '6.5vh' }}>
          Démarrez votre <span className={Styles.registerDesktopOrangeLabel}>essai gratuit</span><br />
          de <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15 jours sur la plateforme</span><br /> 
          de <span className={Styles.registerDesktopOrangeLabel}>gestion de conversations</span><br /> 
          la plus <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitive</span> du marché.
        </div>
      );
    } else {
      return (
        <div className='' style={{display: 'inline-block', textAlign: 'left', lineHeight: '6.5vh' }}>
          Comece seu  <span className={Styles.registerDesktopOrangeLabel}>teste grátis</span> <br/>
          de <span className={Styles.boldTextHeader}>15 dias na plataforma</span> <br/>
          de <span className={Styles.registerDesktopOrangeLabel}>gestão de conversas</span> <br/>
          mais <span className={Styles.boldTextHeader}>intuitiva </span> do mercado.
        </div>
      );
    }
  }

  return (
    <Container fluid >
      <Loading visibility={isLoading}></Loading>
      <Row style={{ height: '100vh' }} className=''>
        <Col className={Styles.backgroundImage + " nopadding "} style={{}}>
          <Row className="nopadding d-flex align-items-end" style={{height: '100%'}}>
              <Row style={{}} className='nopadding d-flex'>
                <div className="ms-2" style={{ padding:'0' }}>
                  <img src="/Logo_Branca.svg" alt="" width={'29%'} />
                </div>
              </Row>
              <Row className="nopadding" style={{}}>
                <div className='ms-5'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="169.181" height="182.157" viewBox="0 0 169.181 182.157" style={{height: "18.9vh"}}>
                    <g id="Grupo_11238" data-name="Grupo 11238" transform="translate(-110 -178.643)">
                      <path id="Caminho_5464" data-name="Caminho 5464" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.955-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="translate(-335.143 -122.674)" fill="#ef863c" fillRule="evenodd"/>
                      <path id="Caminho_5467" data-name="Caminho 5467" d="M713.467,400.548l1.825-.034,1.823-.1,1.818-.168,1.81-.235,1.8-.3,1.788-.367,1.774-.433,1.756-.5,1.737-.562,1.715-.626,1.691-.688,1.664-.75,1.636-.811,1.6-.87,1.572-.929,1.536-.986,1.5-1.042,1.46-1.1,1.418-1.15,1.375-1.2,1.33-1.251,1.283-1.3,1.234-1.345,1.184-1.39,1.132-1.432,1.079-1.473L755.035,378l.967-1.548.91-1.583.851-1.615.791-1.646.73-1.673.667-1.7.6-1.723.541-1.744.476-1.762.411-1.779.345-1.793.279-1.8.212-1.813.146-1.82.078-1.824.015-1.217,0-.609L763,348.525l-.123-1.821-.19-1.816-.257-1.808-.323-1.8-.389-1.784-.454-1.768-.519-1.75-.583-1.73-.647-1.707-.709-1.682-.771-1.655-.831-1.626-.89-1.594-.948-1.56-1-1.524-1.06-1.486-1.114-1.446-1.167-1.4-1.218-1.36-1.267-1.314-1.314-1.267-1.36-1.218-1.4-1.167-1.446-1.114-1.486-1.06-1.524-1-1.56-.948-1.594-.89-1.626-.831-1.655-.77-1.682-.709-1.707-.647-1.73-.583-1.75-.519-1.768-.454-1.784-.389-1.8-.323-1.807-.257-1.816-.19-1.821-.123-1.825-.056-.609,0-.609,0-1.825.056-1.821.123-1.816.19-1.808.257-1.8.323-1.784.389-1.768.454-1.75.519-1.73.583-1.707.647-1.682.709-1.655.77-1.626.831-1.594.89-1.56.948-1.524,1-1.486,1.06-1.446,1.114-1.4,1.167-1.36,1.218-1.314,1.267-1.267,1.314-1.218,1.36-1.167,1.4-1.114,1.446-1.06,1.486-1,1.524-.948,1.56-.89,1.594-.831,1.626-.77,1.655-.709,1.682-.646,1.707-.583,1.73-.519,1.75-.454,1.768-.389,1.784-.323,1.8-.257,1.808-.19,1.816-.123,1.821-.056,1.825,0,.609,0,.609.056,1.825.123,1.821.19,1.816.257,1.808.323,1.8.389,1.784.454,1.768.519,1.75.583,1.73.646,1.707.709,1.682.77,1.655.831,1.626.89,1.594.948,1.56,1,1.524,1.06,1.486,1.114,1.446,1.167,1.4,1.218,1.36,1.267,1.314,1.314,1.267,1.36,1.218,1.4,1.167,1.446,1.114,1.486,1.06,1.524,1,1.56.948,1.594.89,1.626.831,1.655.77,1.682.709,1.707.646,1.73.583,1.75.519,1.768.454,1.784.389,1.8.323,1.808.257,1.816.19,1.821.123,1.825.056Z" transform="translate(-483.876 -39.748)" fill="#fff" fillRule="evenodd"/>
                    </g>
                  </svg>
                </div>
              </Row>
              <Row className='nopadding' /* style={{height: '33%'}} */>
                  <Col className={Styles.registerDesktopHeader + " "} style={{textAlign: 'center', }}>
                    {getRegisterText()}
                  </Col>
              </Row>
              <Row style={{ /* height: 'fit-content' *//* height: '33%' */ }} className="nopadding d-flex align-items-end">
                <div style={{ marginTop: 'auto', display: 'block' }} className="nopadding">
                  <svg xmlns="http://www.w3.org/2000/svg" /* width="981" height="359.998" */ viewBox="0 0 981 359.998" style={{ width: "100%"}}>
                    <g id="Grupo_11259" data-name="Grupo 11259" transform="translate(0 -720.002)">
                      <g id="Grupo_11246" data-name="Grupo 11246">
                        <path id="Caminho_5465" data-name="Caminho 5465" d="M654.38,400.674H555.074V301.368H654.38Z" transform="translate(326.62 679.326)" fill="#ef863c" fillRule="evenodd"/>
                        <path id="Caminho_5464" data-name="Caminho 5464" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.956-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="translate(337.371 679.503)" fill="#4a69bd" fillRule="evenodd"/>
                        <path id="Caminho_5466" data-name="Caminho 5466" d="M210.15,301.245l99.178,99.18H210.15Z" transform="translate(147.85 679.575)" fill="#ef863c" fillRule="evenodd"/>
                      </g>
                      <path id="Caminho_5462" data-name="Caminho 5462" d="M1919.98,719.036h-360l.035,5.09.106,5.073.175,5.055.244,5.037.313,5.019.382,5,.449,4.981.517,4.961.584,4.941.65,4.92.716,4.9.782,4.878.847,4.856.911,4.833.976,4.81,1.039,4.787,1.1,4.763,1.166,4.738,1.228,4.714,1.29,4.688,1.351,4.662,1.412,4.636,1.472,4.61,1.532,4.582,1.592,4.555,1.651,4.527,1.71,4.5,1.768,4.469,1.825,4.44,1.883,4.41,1.939,4.379,2,4.349,2.052,4.317,2.107,4.286,2.162,4.253,2.216,4.221,2.27,4.187,2.324,4.154,2.377,4.12,2.429,4.085,2.482,4.05,2.533,4.015,2.584,3.979,2.635,3.942,2.686,3.906,2.735,3.868,2.785,3.83,2.834,3.792,2.882,3.753,2.93,3.714,2.977,3.675,3.025,3.635,3.071,3.594,3.117,3.553,3.163,3.511,3.208,3.469,3.253,3.427,3.3,3.384,3.341,3.341,3.384,3.3,3.427,3.253,3.469,3.208,3.511,3.163,3.553,3.117,3.594,3.071,3.635,3.024,3.675,2.977,3.714,2.93,3.753,2.882,3.792,2.833,3.83,2.785,3.868,2.735,3.906,2.685,3.942,2.635,3.979,2.584,4.015,2.533,4.05,2.482,4.085,2.429,4.12,2.377,4.154,2.324,4.187,2.27,4.221,2.216,4.253,2.162,4.286,2.107,4.317,2.052,4.349,2,4.38,1.939,4.41,1.883,4.44,1.825,4.469,1.768,4.5,1.71,4.527,1.651,4.555,1.592,4.582,1.532,4.61,1.472,4.636,1.412,4.662,1.351,4.688,1.29,4.714,1.228,4.738,1.165,4.763,1.1,4.787,1.039,4.81.976,4.833.912,4.856.847,4.878.782,4.9.716,4.92.65,4.941.584,4.961.517,4.981.449,5,.381,5.019.313,5.037.244,5.055.175,5.073.105,5.09.035v-360Z" transform="translate(-1559.979 0.966)" fill="#4a69bd" fillRule="evenodd"/>
                    </g>
                  </svg>
                </div>
              </Row>
          </Row>
        </Col>
        <Col className="align-items-end">
          <Row className={Styles.backgroundLogin + " d-flex align-items-start"}>
            <RegisterFormController />
          </Row>
          <Row className={Styles.backgroundLogin2}>
            <Col className="d-flex justify-content-center">
              <p className="align-self-center nopadding">{t('register.copyright')}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container >
  );
}

export default RegisterHome;
