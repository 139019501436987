import React from 'react'
import { Button, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './styles.scss'
import { IconCheck, IconDownload } from '@tabler/icons-react'
import { t } from 'i18next'

const ModalExportData = ({
    modalShow,
    Hide,
    isLoading,
    fileName,
    handleDownload,
    hasLinks,
    isClicked
}) => {
    return (
        <Modal show={modalShow} onHide={Hide} centered>
            <Modal.Header closeButton >
                <Modal.Title className="textBlack">
                {t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.title_modal")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ?
                        <p>{t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.loading_text_export")}</p>
                    :
                        <div className='item-download-button'>
                            {
                                hasLinks ?
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{fileName}</Tooltip>}>
                                                <p className="text-links" style={{ flexGrow: 1, margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    {fileName}
                                                </p>
                                            </OverlayTrigger>

                                            <button onClick={handleDownload} style={{ flexShrink: 0 }} className={isClicked ? "clicked" : ""}>
                                                <IconDownload className="tabler-icon" />
                                                {t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.download_modal_export")}
                                                       
                                            </button>
                                        </div>

                                    </>
                                    :
                                    <p className='text-links'>{t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.empty_data")}</p>
                            }
                        </div>
                }
            </Modal.Body>
            {
                !isLoading ?
                <Modal.Footer style={{borderTop: "unset"}}>
                    <Button
                        type="submit"
                        size="lg"
                        className="buttonBlue"
                        style={{ width: "40%", display:'block', marginLeft: 'auto' }}
                        onClick={Hide}
                    >
                        {t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.close_modal_export")}
                    </Button>
                </Modal.Footer>
                :
                <></>
            }
        </Modal>
    )
}

export default ModalExportData