import { FC, useEffect, useState, MouseEvent, useRef } from 'react';
import ListOfMessageTriggers from './index';
import { FetchMessageTriggerSchedule, FetchMessageTriggerType, ListOfMessageTriggersControllerInterface, MessageTriggerSchedule, MessageTriggerType } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMainAppContext } from '../../../../core/context/main-app-context';
import UserService from '../../../../services/user-service';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../components-fixed/searchComponent/indexModel';
import {
  getMessageTriggerScheduleListDateOrderBy,
  getMessageTriggerScheduleListSortBy,
  getMessageTriggerScheduleListStatusOrderBy,
  getMessageTriggerScheduleListTypeOrderBy,
  getMessageTriggerTypeListSortBy,
  getMessageTriggerTypeListStatusOrderBy,
  getMessageTriggerTypeListNameOrderBy,
  setMessageTriggerScheduleListDateOrderBy,
  setMessageTriggerScheduleListSortBy,
  setMessageTriggerScheduleListStatusOrderBy,
  setMessageTriggerScheduleListTypeOrderBy,
  setMessageTriggerTypeListSortBy,
  setMessageTriggerTypeListStatusOrderBy,
  setMessageTriggerTypeListNameOrderBy,
} from '../../../../store/table_sort_order';
import { OrderByType, SortByType } from '../../../../core/enums/order-sort-by';
import { Popover } from 'react-bootstrap';
import { IconCalendarCancel, IconCancel, IconCircleCheck, IconEdit, IconFileDescription, IconFileInfo, IconTicket } from '@tabler/icons-react';
import emitter, { T_EmitterOpenModalCancelActiveTrigger, T_EmitterOpenModalReportActiveTrigger } from '../../../../core/shared/emitter';
import constsRouters from '../../../../routes/constsRouter';
import { MessageTriggerStatus } from '../../../../core/enums/message-trigger-status';
import { setShowAlertFeedback } from '../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const TITLE_SIZE = '250px';
const SCHEDULE_TYPE_SIZE = '150px';
const TYPE_SIZE = '250px';
const CHANNEL_SIZE = '120px';
const SCHEDULE_SIZE = '185px';
const REQUESTER_SIZE = '160px';
const QTY_RECIPIENTS_SIZE = '115px';
const QTY_CONVERSIONS_SIZE = '110px';
const STATUS_SIZE = '120px';

const ListOfMessageTriggersController: FC<ListOfMessageTriggersControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfMessageTriggers');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setMainHeader,
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
  } = useMainAppContext();

  const messagesTriggerScheduleListSortBy = useSelector(getMessageTriggerScheduleListSortBy);
  const messagesTriggerScheduleListSortByRef = useRef<SortByType>(messagesTriggerScheduleListSortBy);
  const messagesTriggerScheduleListTypeOrderBy = useSelector(getMessageTriggerScheduleListTypeOrderBy);
  const messagesTriggerScheduleListTypeOrderByRef = useRef<OrderByType>(messagesTriggerScheduleListTypeOrderBy);
  const messagesTriggerScheduleListDateOrderBy = useSelector(getMessageTriggerScheduleListDateOrderBy);
  const messagesTriggerScheduleListDateOrderByRef = useRef<OrderByType>(messagesTriggerScheduleListDateOrderBy);
  const messagesTriggerScheduleListStatusOrderBy = useSelector(getMessageTriggerScheduleListStatusOrderBy);
  const messagesTriggerScheduleListStatusOrderByRef = useRef<OrderByType>(messagesTriggerScheduleListStatusOrderBy);

  const messagesTriggerTypeListSortBy = useSelector(getMessageTriggerTypeListSortBy);
  const messagesTriggerTypeListSortByRef = useRef<SortByType>(messagesTriggerTypeListSortBy);
  const messagesTriggerTypeListNameOrderBy = useSelector(getMessageTriggerTypeListNameOrderBy);
  const messagesTriggerTypeListNameOrderByRef = useRef<OrderByType>(messagesTriggerTypeListNameOrderBy);
  const messagesTriggerTypeListStatusOrderBy = useSelector(getMessageTriggerTypeListStatusOrderBy);
  const messagesTriggerTypeListStatusOrderByRef = useRef<OrderByType>(messagesTriggerTypeListStatusOrderBy);

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [tab, setTab] = useState<'schedules' | 'types'>('schedules');
  const [listHeadersSchedules, setListHeadersSchedules] = useState<CustomTableListHeaders[]>([]);
  const [listHeadersTypes, setListHeadersTypes] = useState<CustomTableListHeaders[]>([]);
  const [dataSchedules, setDataSchedules] = useState<MessageTriggerSchedule[]>([]);
  const [dataTypes, setDataTypes] = useState<MessageTriggerType[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalActiveTrigger, setShowModalActiveTrigger] = useState(false);
  const [showModalReportActiveTrigger, setShowModalReportActiveTrigger] = useState(false);
  const [showModalCancelActiveTrigger, setShowModalCancelActiveTrigger] = useState(false);

  const [showModalTriggerType, setShowModalTriggerType] = useState(false);
  const [showModalChangeStatusTriggerType, setShowModalChangeStatusTriggerType] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    if (tab === 'schedules') {
      setListHeadersSchedules([
        { 
          title: t('list_headers.title'), 
          field: 'title', 
          width: TITLE_SIZE,
          hasTooltip: true
        },
        {
          title: t('list_headers.type'),
          field: 'type',
          width: SCHEDULE_TYPE_SIZE,
        },
        { 
          title: t('list_headers.channel'), 
          field: 'channel_id', 
          width: CHANNEL_SIZE 
        },
        {
          title: t('list_headers.schedule'),
          field: 'date_scheduled',
          width: SCHEDULE_SIZE,
          hasOrderBy: true,
          orderByFn: getMessagesTriggerSchedulesOrdered,
          orderBy: messagesTriggerScheduleListDateOrderBy || 'desc',
          orderType: 'default',
        },
        {
          title: t('list_headers.requester'),
          field: 'employee',
          width: REQUESTER_SIZE,
          hasTooltip: true
        },
        {
          title: t('list_headers.recipients'),
          field: 'number_of_recipients',
          width: QTY_RECIPIENTS_SIZE,
          alignment: 'center'
        },
        {
          title: t('list_headers.tickets'),
          field: 'number_of_ticket_conversions',
          width: QTY_CONVERSIONS_SIZE,
          alignment: 'center'
        },
        {
          title: t('list_headers.status'),
          field: 'status_id',
          width: STATUS_SIZE,
        },
        { title: t("list_headers.actions"), field: 'action', width: 'auto' },
      ]);
    } else {
      setListHeadersTypes([
        {
          title: t('list_headers.name'),
          field: 'name',
          width: TYPE_SIZE,
          hasOrderBy: true,
          orderByFn: getMessagesTriggerTypesOrdered,
          orderBy: messagesTriggerTypeListNameOrderBy || 'asc',
          orderType: 'text',
          hasTooltip: true,
        },
        {
          title: t('list_headers.status'),
          field: 'active',
          width: STATUS_SIZE,
          hasOrderBy: true,
          orderByFn: getMessagesTriggerTypesOrdered,
          orderBy: messagesTriggerTypeListStatusOrderBy || 'asc',
          orderType: 'default',
        },
        { title: t("list_headers.actions"), field: 'action', width: 'auto' },
      ]);
    }
  }, [tab]);

  useEffect(() => {
    configMainHeader(tab);

    if (apiHeader) {
      setCurrentPage(1);
      if (tab === 'schedules') {
        clearSearchActiveTriggersSchedules();
      } else {
        clearSearchActiveTriggersTypes();
      }
    }

  }, [apiHeader, tab]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = (tab: 'schedules' | 'types') => {
    if (tab === 'schedules') {
      setIsSearched(false);
      queryFilterRef.current = {};
      setFilterApplied({});
      setFilteredMode('simple');
      setMainHeader({
        pageTitle: t('header.welcome_page'),
        pageSubtitle: t('header.sub_info_schedule'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'messages-trigger-schedules',
        searchPlaceholder: t('header.search_schedule_placeholder'),
        searchFunction: searchActiveTriggersSchedules,
        clearSearchFunction: clearSearchActiveTriggersSchedules,
        hasCreateButton: true,
        createButtonText: t('header.button_create_schedule'),
        createButtonTooltip: t('header.button_create_schedule_tooltip'),
        createButtonAction: openModalNewActiveTrigger,
      });
    } else {
      setIsSearched(false);
      queryFilterRef.current = {};
      setFilterApplied({});
      setFilteredMode('simple');
      setMainHeader({
        pageTitle: t('header.welcome_page'),
        pageSubtitle: t('header.sub_info_type'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: false,
        searchPage: 'messages-trigger-types',
        searchPlaceholder: t('header.search_type_placeholder'),
        searchFunction: searchActiveTriggersTypes,
        clearSearchFunction: clearSearchActiveTriggersTypes,
        hasCreateButton: true,
        createButtonText: t('header.button_create_type'),
        createButtonTooltip: t('header.button_create_type_tooltip'),
        createButtonAction: openModalNewTriggerType,
      });
    }
  }

  const getListHeadersSchedules = (): CustomTableListHeaders[] => {
    return listHeadersSchedules.filter(item => !item.hidden);
  }

  const getListHeadersTypes = (): CustomTableListHeaders[] => {
    return listHeadersTypes.filter(item => !item.hidden);
  }

  const changeTab = (tab: 'schedules' | 'types') => {
    setTab(tab);
  }

  const getActiveTriggersSchedules = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);
    const config = { headers: apiHeader, params };
    AppRequesterConst.Get(
      '/message-sending', config,
      () => {},
      (response: FetchMessageTriggerSchedule) => {
        if (response.status === 200 && response.data.message_sending.length > 0) {
          if (response.data.message_sending.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          if (isScrollPagination) {
            const new_array = [...dataSchedules, ...response.data.message_sending];
            setDataSchedules(new_array);
          } else {
            setDataSchedules(response.data.message_sending || []);
          }
          processPaginationDetails(response.data.pagination);
        } else {
          setDataSchedules([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getActiveTriggersSchedulesPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const sortBy = messagesTriggerScheduleListSortBy || 'date_scheduled';
    let orderBy = 'desc';

    if (sortBy === 'message_sending_type') {
      orderBy = messagesTriggerScheduleListTypeOrderBy || 'asc';  
    } else if (sortBy === 'message_sending_status') {
      orderBy = messagesTriggerScheduleListStatusOrderBy || 'asc';  
    } else {
      orderBy = messagesTriggerScheduleListDateOrderBy || 'desc';
    }

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...currentFilter };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      getActiveTriggersSchedules(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getActiveTriggersSchedules(params, true);
      }
    }
  }

  const reloadActiveTriggersSchedules = (preserveFilter?: boolean) => {
    const sortBy = messagesTriggerScheduleListSortBy || 'date_scheduled';
    let orderBy = 'desc';

    if (sortBy === 'message_sending_type') {
      orderBy = messagesTriggerScheduleListTypeOrderBy || 'asc';  
    } else if (sortBy === 'message_sending_status') {
      orderBy = messagesTriggerScheduleListStatusOrderBy || 'asc';  
    } else {
      orderBy = messagesTriggerScheduleListDateOrderBy || 'desc';
    }

    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };

    if (preserveFilter && queryFilterRef.current) {
      params.page = currentPage;
      params = { ...params, ...queryFilterRef.current };
    }
    getActiveTriggersSchedules(params, false);
  }

  const searchActiveTriggersSchedules = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const sortBy = messagesTriggerScheduleListSortByRef.current || 'date_scheduled';
    let orderBy = 'desc';

    if (sortBy === 'message_sending_type') {
      orderBy = messagesTriggerScheduleListTypeOrderByRef.current || 'asc';  
    } else if (sortBy === 'message_sending_status') {
      orderBy = messagesTriggerScheduleListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = messagesTriggerScheduleListDateOrderByRef.current || 'desc';
    }

    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...query };
    
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);

    getActiveTriggersSchedules(params);
  }

  const clearSearchActiveTriggersSchedules = () => {
    const sortBy = messagesTriggerScheduleListSortByRef.current || 'date_scheduled';
    let orderBy = 'desc';

    if (sortBy === 'message_sending_type') {
      orderBy = messagesTriggerScheduleListTypeOrderByRef.current || 'asc';  
    } else if (sortBy === 'message_sending_status') {
      orderBy = messagesTriggerScheduleListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = messagesTriggerScheduleListDateOrderByRef.current || 'desc';
    }

    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };

    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }
    getActiveTriggersSchedules(params);
  }

  // ############################################################################################

  const getActiveTriggersTypes = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);
    const config = { headers: apiHeader, params };
    AppRequesterConst.Get(
      '/message-sending-type', config,
      () => {},
      (response: FetchMessageTriggerType) => {
        if (response.status === 200 && response.data.messageSendingTypes.length > 0) {
          if (response.data.messageSendingTypes.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          if (isScrollPagination) {
            const new_array = [...dataTypes, ...response.data.messageSendingTypes];
            setDataTypes(new_array);
          } else {
            setDataTypes(response.data.messageSendingTypes || []);
          }
          processPaginationDetails(response.data.pagination);
        } else {
          setDataTypes([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        console.log(error)
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getActiveTriggersTypesPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const sortBy = messagesTriggerTypeListSortBy || 'name';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = messagesTriggerTypeListNameOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = messagesTriggerTypeListStatusOrderBy || 'asc';  
    }

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...currentFilter };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      getActiveTriggersTypes(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getActiveTriggersTypes(params, true);
      }
    }
  }

  const reloadActiveTriggersTypes = (preserveFilter?: boolean) => {
    const sortBy = messagesTriggerTypeListSortBy || 'name';
    let orderBy = 'asc';

    if (sortBy === 'name') {
      orderBy = messagesTriggerTypeListNameOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = messagesTriggerTypeListStatusOrderBy || 'asc';  
    }

    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };

    if (preserveFilter && queryFilterRef.current) {
      params.page = currentPage;
      params = { ...params, ...queryFilterRef.current };
    }
    getActiveTriggersTypes(params, false);
  }

  const searchActiveTriggersTypes = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const sortBy = messagesTriggerTypeListSortByRef.current || 'name';
    let orderBy = 'asc';

    if (sortBy === 'name') {
      orderBy = messagesTriggerTypeListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = messagesTriggerTypeListStatusOrderByRef.current || 'asc';  
    }

    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy, ...query };
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    
    getActiveTriggersTypes(params);
  }

  const clearSearchActiveTriggersTypes = () => {
    const sortBy = messagesTriggerTypeListSortByRef.current || 'name';
    let orderBy = 'asc';

    if (sortBy === 'name') {
      orderBy = messagesTriggerTypeListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = messagesTriggerTypeListStatusOrderByRef.current || 'asc';  
    }

    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }
    getActiveTriggersTypes(params);
  }

  // ############################################################################################

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };
    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (key === 'channel') {
        tempFilterApplied.selectedChannel = [];
      } else if (key === 'type') {
        tempFilterApplied.selectedTriggerType = [];
      } else if (key === 'employee') {
        tempFilterApplied.selectedAgent = [];
      } else if (key === 'status') {
        tempFilterApplied.selectedStatus = [];
      } else if (key === 'date') {
        tempFilterApplied.selectedFilterDate = { id: null, value: null };
        tempFilterApplied.selectedDate = { start: null, end: null };
      } else if (key === 'search') {
        currentSearchTerm = '';
        clearFilterTermFunction();
      }
      setFilterApplied(tempFilterApplied);
      const pageTab = tab === 'types' ? 'messages-trigger-types' : 'messages-trigger-schedules';
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, pageTab), tempFilterApplied);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters: AppliedFiltersBarType[] = [];
    let hasDate = false;
    setFilterMode('main');

    Object.keys(queryFilterRef.current || {}).forEach(key => {
      const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
      
      if (key === 'search') {
        item.value = queryFilterRef.current[key];
      }
      if (key === 'channel') {
        item.value = filterApplied.selectedChannel?.map(item => item.value).join(', ');
      }
      if (key === 'type') {
        item.value = filterApplied.selectedTriggerType?.map(item => item.value).join(', ');
        item.tooltip_key = 'filter_applied.trigger_type';
      }
      if (key === 'employee') {
        item.value = filterApplied.selectedAgent?.map(item => item.value).join(', ');
      }
      if (key === 'status') {
        item.value = filterApplied.selectedStatus?.map(item => item.value).join(', ');
      }

      if (!hasDate && key.includes('date')) {
        hasDate = true;
        if (filterApplied.selectedFilterDate?.id === 'custom') {
          item.value = `${filterApplied.selectedDate.start.toDateString()} - ${filterApplied.selectedDate.end.toDateString()}`
        } else {
          item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
        }
      }

      if (!key.includes('date')) {
        filters.push(item);
      }
    });

    if (queryFilterRef.current?.start_date && queryFilterRef.current?.end_date) {
      const item = { key: 'date', tooltip_key: 'filter_applied.period', value: '' };

      if (filterApplied.selectedFilterDate.id === 'custom') {
        item.value = `${filterApplied.selectedDate.start.toLocaleDateString()} - ${filterApplied.selectedDate.end.toLocaleDateString()}`
      } else {
        item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
      }

      filters.push(item);
    }
    return filters;
  }

  const getMessagesTriggerSchedulesOrdered = async (field: string, order: OrderByType) => {
    let formattedField = field as SortByType;

    if (field === 'type') {
      formattedField = 'message_sending_type';
    } else if (field === 'status_id') {
      formattedField = 'message_sending_status';
    }

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: formattedField, order_by: order, ...currentFilter };
    setHeaderSchedulesOrderBy(formattedField, order);
    getActiveTriggersSchedules(params);
  }

  const getMessagesTriggerTypesOrdered = async (field: SortByType, order: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: field, order_by: order, ...currentFilter };
    setHeaderTypesOrderBy(field, order);
    getActiveTriggersTypes(params);
  }

  const setHeaderSchedulesOrderBy = (field: SortByType, order: 'asc' | 'desc'): void => {
    let clearedField: string = field;

    if (field === 'message_sending_type') {
      clearedField = 'type';
    } else if (field === 'message_sending_status') {
      clearedField = 'status_id';
    }

    setListHeadersSchedules(prevState => {
      prevState.forEach(item => {
        if (item.field === clearedField) {
          item.orderBy = order;
        } else {
          if (item.field === 'type') {
            item.orderBy = 'asc';
          } else if (item.field === 'date_scheduled') {
            item.orderBy = 'asc';
          } else if (item.field === 'status_id') {
            item.orderBy = 'asc';
          }
        }
      });

      if (field === 'message_sending_type') {
        dispatch(setMessageTriggerScheduleListTypeOrderBy(order));
        messagesTriggerScheduleListTypeOrderByRef.current = order;
        dispatch(setMessageTriggerScheduleListDateOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListDateOrderByRef.current = 'asc';
        dispatch(setMessageTriggerScheduleListStatusOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListStatusOrderByRef.current = 'asc';
      } else if (field === 'message_sending_status') {
        dispatch(setMessageTriggerScheduleListStatusOrderBy(order));
        messagesTriggerScheduleListStatusOrderByRef.current = order;
        dispatch(setMessageTriggerScheduleListTypeOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListTypeOrderByRef.current = 'asc';
        dispatch(setMessageTriggerScheduleListDateOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListDateOrderByRef.current = 'asc';
      } else {
        dispatch(setMessageTriggerScheduleListDateOrderBy(order));
        messagesTriggerScheduleListDateOrderByRef.current = order;
        dispatch(setMessageTriggerScheduleListStatusOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListStatusOrderByRef.current = 'asc';
        dispatch(setMessageTriggerScheduleListTypeOrderBy('asc')); // resetando valor default
        messagesTriggerScheduleListTypeOrderByRef.current = 'asc';
      }

      dispatch(setMessageTriggerScheduleListSortBy(field));
      messagesTriggerScheduleListSortByRef.current = field;

      return prevState;
    });
  }

  const setHeaderTypesOrderBy = (field: SortByType, order: 'asc' | 'desc'): void => {
    setListHeadersTypes(prevState => {
      prevState.forEach(item => {
        if (item.field === field) {
          item.orderBy = order;
        } else {
          if (item.field === 'type') {
            item.orderBy = 'asc';
          } else if (item.field === 'status_id') {
            item.orderBy = 'asc';
          }
        }
      });

      if (field === 'name') {
        dispatch(setMessageTriggerTypeListNameOrderBy(order));
        messagesTriggerTypeListNameOrderByRef.current = order;
        dispatch(setMessageTriggerTypeListStatusOrderBy('asc')); // resetando valor default
        messagesTriggerTypeListStatusOrderByRef.current = 'asc';
      } else if (field === 'active') {
        dispatch(setMessageTriggerTypeListStatusOrderBy(order));
        messagesTriggerTypeListStatusOrderByRef.current = order;
        dispatch(setMessageTriggerTypeListNameOrderBy('asc')); // resetando valor default
        messagesTriggerTypeListNameOrderByRef.current = 'asc';
      }

      dispatch(setMessageTriggerTypeListSortBy(field));

      return prevState;
    });
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const openModalActiveTrigger = (item: MessageTriggerSchedule, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowModalActiveTrigger(true);
    setTimeout(() => {
      emitter.emit('open-modal-active-trigger', { id: item.id, statusId: item.status_id });
    }, 50);
  }

  const openModalNewActiveTrigger = () => {
    setShowModalActiveTrigger(true);
    setTimeout(() => {
      emitter.emit('open-modal-active-trigger', { id: null, statusId: null });
    }, 50);
  }

  const closeModalActiveTrigger = (reload: boolean) => {
    if (reload) {
      reloadActiveTriggersSchedules(true);
    }
    setShowModalActiveTrigger(false);
  }

  const openModalReportActiveTrigger = (item: MessageTriggerSchedule) => {
    setShowModalReportActiveTrigger(true);
    setTimeout(() => {
      const dataEmitter: T_EmitterOpenModalReportActiveTrigger = {
        id: item.id,
        title: item.title,
        channel_id: item.channel_id,
        date_scheduled: item.date_scheduled,
        number_of_recipients: item.number_of_recipients, 
        type: item.type,
      }
      emitter.emit('open-modal-report-active-trigger', dataEmitter);
    }, 50);
  }

  const closeModalReportActiveTrigger = () => {
    setShowModalReportActiveTrigger(false);
  }
  
  const openModalCancelActiveTrigger = (item: MessageTriggerSchedule) => {
    setShowModalCancelActiveTrigger(true);
    setTimeout(() => {
      const dataEmitter: T_EmitterOpenModalCancelActiveTrigger = { 
        id: item.id,
        title: item.title,
        channel_id: item.channel_id,
        employee: item.employee, 
        date_scheduled: item.date_scheduled,
        number_of_recipients: item.number_of_recipients, 
        type: item.type, 
        status_id: item.status_id
      };
      emitter.emit('open-modal-cancel-active-trigger', dataEmitter);
    }, 50);
  }

  const closeModalCancelActiveTrigger = (reload: boolean) => {
    if (reload) {
      reloadActiveTriggersSchedules(true);
    }
    setShowModalCancelActiveTrigger(false);
  }

  const openModalTriggerType = (item: MessageTriggerType) => {
    setShowModalTriggerType(true);
    setTimeout(() => {
      emitter.emit('open-modal-trigger-type', { id: item.id, name: item.name, active: item.active });
    }, 50);
  }

  const openModalNewTriggerType = () => {
    setShowModalTriggerType(true);
    setTimeout(() => {
      emitter.emit('open-modal-trigger-type', null);
    }, 50);
  }

  const closeModalTriggerType = (reload: boolean) => {
    if (reload) {
      reloadActiveTriggersTypes(true);
    }
    setShowModalTriggerType(false);
  }

  const openModalChangeStatusTriggerType = (item: MessageTriggerType, newStatus: boolean) => {
    setShowModalChangeStatusTriggerType(true);
    setTimeout(() => {
      emitter.emit('open-modal-change-status-trigger-type', { triggerTypeId: item.id, triggerTypeName: item.name, newStatus });
    }, 50);
  }

  const closeModalChangeStatusTriggerType = (reload: boolean) => {
    if (reload) {
      reloadActiveTriggersTypes(true);
    }
    setShowModalChangeStatusTriggerType(false);
  }

  const handlePopoverActiveTriggerSchedulesListItemAction = (event: any, data: MessageTriggerSchedule, type: 'open-active-trigger-tickets' | 'view-active-trigger' | 'view-active-trigger-report' | 'edit-active-trigger' | 'cancel-active-trigger') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-active-trigger-schedules');
    setTimeout(() => {
      if (type === 'open-active-trigger-tickets') {
        navigate(
          `${constsRouters.routers.activeTriggerTickets.path}`, 
          { 
            state: { 
              activeTriggerId: data.id,
              activeTriggerValue: data.title || data.id,
              page: 'messages-trigger', 
              prevPath: constsRouters.routers.activeTrigger.path 
            } 
          }
        );
      } else if (type === 'view-active-trigger') {
        openModalActiveTrigger(data, event);
      } else if (type === 'view-active-trigger-report') {
        openModalReportActiveTrigger(data);
      } else if (type === 'edit-active-trigger') {
        openModalActiveTrigger(data, event);
      } else if (type === 'cancel-active-trigger') {
        openModalCancelActiveTrigger(data);
      } 
    }, 50);
  }

  const popoverItemActionSchedules = (data: MessageTriggerSchedule) => {
    const showOpenActiveTriggerTickets = data.status_id === MessageTriggerStatus.Triggered;
    const showViewActiveTrigger = data.status_id !== MessageTriggerStatus.Scheduled;
    const showViewTriggerReport = data.status_id === MessageTriggerStatus.Triggered;
    const showEditActiveTrigger = data.status_id === MessageTriggerStatus.Scheduled;
    const showCancelActiveTrigger = data.status_id === MessageTriggerStatus.Scheduled;
    
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenActiveTriggerTickets &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerSchedulesListItemAction(e, data, 'open-active-trigger-tickets')}>
              <IconTicket />{t('list_popover_actions.open_active_trigger_tickets')}
            </span>
          }
          { showViewActiveTrigger &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerSchedulesListItemAction(e, data, 'view-active-trigger')}>
              <IconFileInfo />{t('list_popover_actions.view_active_trigger')}
            </span>
          }
          { showViewTriggerReport &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerSchedulesListItemAction(e, data, 'view-active-trigger-report')}>
              <IconFileDescription />{t('list_popover_actions.view_active_trigger_report')}
            </span>
          }
          { showEditActiveTrigger &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerSchedulesListItemAction(e, data, 'edit-active-trigger')}>
              <IconEdit />{t('list_popover_actions.edit_active_trigger')}
            </span>
          }
          { showCancelActiveTrigger &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerSchedulesListItemAction(e, data, 'cancel-active-trigger')}>
              <IconCalendarCancel />{t('list_popover_actions.cancel_active_trigger')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  const handlePopoverActiveTriggerTypesListItemAction = (event: any, data: MessageTriggerType, type: 'edit-trigger-type' | 'activate-trigger-type' | 'inactivate-trigger-type') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-active-trigger-schedules');
    setTimeout(() => {
      if (type === 'edit-trigger-type') {
        openModalTriggerType(data);
      } else if (type === 'activate-trigger-type') {
        openModalChangeStatusTriggerType(data, true);
      } else if (type === 'inactivate-trigger-type') {
        openModalChangeStatusTriggerType(data, false);
      }
    }, 50);
  }

  const popoverItemActionTypes = (data: MessageTriggerType) => {
    const showEditTriggerType = true;
    const showActivateTriggerType = !data.active;
    const showInactivateTriggerType = data.active;
    
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showEditTriggerType &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerTypesListItemAction(e, data, 'edit-trigger-type')}>
              <IconEdit />{t('list_popover_actions.edit_trigger_type')}
            </span>
          }
          { showActivateTriggerType &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerTypesListItemAction(e, data, 'activate-trigger-type')}>
              <IconCircleCheck />{t('list_popover_actions.activate_trigger_type')}
            </span>
          }
          { showInactivateTriggerType &&
            <span className='popover-item' onClick={(e) => handlePopoverActiveTriggerTypesListItemAction(e, data, 'inactivate-trigger-type')}>
              <IconCancel />{t('list_popover_actions.inactivate_trigger_type')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfMessageTriggers 
      t={t}
      tab={tab}
      changeTab={changeTab}
      listHeadersSchedules={getListHeadersSchedules}
      listHeadersTypes={getListHeadersTypes}
      listDataSchedule={dataSchedules}
      listDataType={dataTypes}
      paginationDetails={paginationDetails}
      getActiveTriggersSchedulesPagination={getActiveTriggersSchedulesPagination}
      getActiveTriggersTypesPagination={getActiveTriggersTypesPagination}
      reloadActiveTriggersSchedules={reloadActiveTriggersSchedules}
      reloadActiveTriggersTypes={reloadActiveTriggersTypes}
      isLoading={isLoading}
      errorLoading={errorLoading}

      openModalActiveTrigger={openModalActiveTrigger}
      showModalActiveTrigger={showModalActiveTrigger}
      closeModalActiveTrigger={closeModalActiveTrigger}
      showModalReportActiveTrigger={showModalReportActiveTrigger}
      closeModalReportActiveTrigger={closeModalReportActiveTrigger}
      showModalCancelActiveTrigger={showModalCancelActiveTrigger}
      closeModalCancelActiveTrigger={closeModalCancelActiveTrigger}

      openModalTriggerType={openModalTriggerType}
      showModalTriggerType={showModalTriggerType}
      closeModalTriggerType={closeModalTriggerType}
      showModalChangeStatusTriggerType={showModalChangeStatusTriggerType}
      closeModalChangeStatusTriggerType={closeModalChangeStatusTriggerType}

      filters={filters}
      clearSearchActiveTriggersSchedules={clearSearchActiveTriggersSchedules}
      clearSearchActiveTriggersTypes={clearSearchActiveTriggersTypes}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemActionSchedules={popoverItemActionSchedules}
      popoverItemActionTypes={popoverItemActionTypes}
    />
  );
};

export default ListOfMessageTriggersController;
