import './styles.scss';
import { FC } from 'react';
import { ModalCreateTicketInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconEraser, IconEye, IconEyeOff, IconInfoCircle, IconMaximize, IconMaximizeOff, IconX } from '@tabler/icons-react';
import CustomInputController from '../../../../../components/customInput/indexController';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import ChatEditorComponentController from '../../viewTicket/components/chatEditorComponent/indexController';
import Loading from '../../../../../components/loading2';
import { CustomFieldsType } from '../../../../../core/enums/custom-field-type';
import { Channels } from '../../../../../core/enums/channels';

const ModalCreateTicket: FC<ModalCreateTicketInterface> = (props) => {

  const stepOpenedClass = {
    step1: props.step === 1 || props.showAllSteps ? 'modal-wizard-step-section-opened' : '',
    step2: props.step === 2 || props.showAllSteps ? 'modal-wizard-step-section-opened' : '',
    step3: props.step === 3 || props.showAllSteps ? 'modal-wizard-step-section-opened' : '',
    step4: props.step === 4 || props.showAllSteps ? 'modal-wizard-step-section-opened' : '',
    step5: props.step === 5 || props.showAllSteps ? 'modal-wizard-step-section-opened' : '',
  };

  const stepFilledClass = {
    step1: props.isValidField(1) ? 'modal-wizard-step-section-filled' : '',
    step2: props.isValidField(2) ? 'modal-wizard-step-section-filled' : '',
    step3: props.isValidField(3) ? 'modal-wizard-step-section-filled' : '',
    step4: props.isValidField(4) ? 'modal-wizard-step-section-filled' : '',
    step5: props.isValidField(5) ? 'modal-wizard-step-section-filled' : '',
  };

  const fullscreenClass = props.fullscreen ? 'modal-create-ticket-fullscreen' : '';
  const showAllClass = props.showAllSteps ? 'modal-create-ticket-show-all' : '';
  const noOverflowClass = props.showAllSteps && !props.loadingCreate ? 'modal-create-ticket-overflow' : '';
  const overflowHiddenClass = props.loadingCreate ? 'modal-create-ticket-overflow-hidden' : '';
  const loadingCreateDisabledClass = props.loadingCreate ? 'modal-create-ticket-header-disabled' : '';
  const loadingCreateModalClass = props.loadingCreate ? 'modal-create-ticket-component-loading' : ''

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      backdrop='static' // Evitar de fechar o modal com clique acidental fora dele
      aria-labelledby=""
      size="lg"
      centered={true}
      fullscreen={props.fullscreen || 'sm-down'}
      dialogClassName={`modal-create-ticket-component ${fullscreenClass} ${showAllClass} ${loadingCreateModalClass}`}
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('modal_title')}
          {props.filledForm() && <span className="modal-create-ticket-header-unsaved-changes">{props.t('modal_unsaved_changes')}</span>}
        </Modal.Title>
        <div className={`modal-create-ticket-header-actions ${loadingCreateDisabledClass}`}>
          { !props.showAllSteps &&
            <OverlayTrigger placement='bottom' show={props.loadingCreate ? false : undefined} overlay={<Tooltip>{props.t('btn_show_all_fields')}</Tooltip>}>
              <IconEye className='modal-create-ticket-header-actions-visibility' onClick={() => props.handleShowAllSteps(true)} />
            </OverlayTrigger>
          }
          { props.showAllSteps &&
            <OverlayTrigger placement='bottom' show={props.loadingCreate ? false : undefined} overlay={<Tooltip>{props.t('btn_show_one_field')}</Tooltip>}>
              <IconEyeOff className='modal-create-ticket-header-actions-visibility' onClick={() => props.handleShowAllSteps(false)} />
            </OverlayTrigger>
          }
          <OverlayTrigger placement='bottom' show={props.loadingCreate ? false : undefined} overlay={<Tooltip>{props.t('btn_clear_all')}</Tooltip>}>
            <IconEraser className='modal-create-ticket-header-actions-clear' onClick={props.resetWizard} />
          </OverlayTrigger>
          { !props.fullscreen &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('btn_fullscreen_on')}</Tooltip>}>
              <IconMaximize className='modal-create-ticket-header-actions-fullscreen' onClick={() => props.setFullscreen(true)} />
            </OverlayTrigger>
          }
          { props.fullscreen &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('btn_fullscreen_off')}</Tooltip>}>
              <IconMaximizeOff className='modal-create-ticket-header-actions-fullscreen' onClick={() => props.setFullscreen(false)} />
            </OverlayTrigger>
          }
          <IconX className='modal-create-ticket-header-actions-close' onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body className={`${noOverflowClass} ${overflowHiddenClass}`}>
        { (props.loading || props.loadingCreate) && <Loading blur={true} />}
        <div className="modal-create-ticket-wizard">
          <div className={`modal-wizard-step modal-wizard-step1 ${stepOpenedClass.step1} ${stepFilledClass.step1}`} onClick={() => props.changeStep(1)}>
            <div className="modal-wizard-step-header">
              <span className="modal-wizard-step-header-title">
                <span className="modal-wizard-step-header-title-value">
                  {props.t('step_1_header_title')}
                </span>
              </span>
              {props.getStepStatusIcon(1)}
            </div>
            <div className="modal-wizard-step-section">
            <CustomInputController 
              type='textarea'
              value={props.subject}
              setValue={props.setSubject}
              placeholder={props.t('subject_placeholder')}
              required={true}
              inputFocus={true}
            />
            </div>
          </div>
          <div className={`modal-wizard-step modal-wizard-step2 ${stepOpenedClass.step2} ${stepFilledClass.step2}`} onClick={() => props.changeStep(2)}>
            <div className="modal-wizard-step-header">
              <span className="modal-wizard-step-header-title">
                <span className="modal-wizard-step-header-title-value">
                  {props.t('step_2_header_title')}
                </span>
                { (props.step === 2 || props.showAllSteps) && props.showButtonSameTicketConsumer() &&
                  <span className="modal-wizard-step-header-title-action" onClick={props.useSameTicketConsumer}>
                    {props.t('consumer_action')}
                  </span>
                }
              </span>
              {props.getStepStatusIcon(2)}
            </div>
            <div className="modal-wizard-step-section">
              <div className="modal-wizard-step-section-items-row">
                <div className="modal-wizard-step-section-item">
                  <span className="modal-wizard-step-section-item-label">{props.t('consumer_label')}</span>
                  <CustomSelectController 
                    type='pagination'
                    dropdownPosition='body'
                    hasFilter={true}
                    showTooltipOptions={true}
                    tooltipOptionsPosition='right'
                    showIconSelected={true}
                    placeholder={props.t('consumer_placeholder')}
                    placeholderFilter={props.t('consumer_search')}
                    options={props.getConsumersSelect().consumersList || []}
                    selected={props.getConsumersSelect().selectedConsumer}
                    setSelected={props.getConsumersSelect().setSelectedConsumer}
                    fetchMore={props.getConsumersSelect().fetchConsumersPagination}
                    hasMore={props.getConsumersSelect().hasMoreConsumers}
                    loading={props.getConsumersSelect().isLoadingConsumersSelect}
                    search={props.getConsumersSelect().fetchSearchConsumers}
                    required={true}
                  />
                </div>
                <div className="modal-wizard-step-section-item">
                  <div className="modal-wizard-step-section-item-label">
                    <span className="modal-wizard-step-section-item-label-value">{props.t('channel_label')}</span>
                    <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('channel_tooltip')}</Tooltip>}>
                      <span className="modal-wizard-step-section-item-label-info">
                        <IconInfoCircle />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <CustomSelectController 
                    type='simple'
                    dropdownPosition='body'
                    placeholder={props.t('channel_placeholder')}
                    options={props.getChannelSelect().channelsList || []}
                    selected={props.getChannelSelect().selectedChannel}
                    setSelected={props.getChannelSelect().setSelectedChannel}
                    loading={props.getChannelSelect().isLoadingCompanyChannels}
                    required={true}
                    disabled={!props.getConsumersSelect().selectedConsumer?.id}
                    showIconSelected={true}
                    showTooltipSelected={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`modal-wizard-step modal-wizard-step3 ${stepOpenedClass.step3} ${stepFilledClass.step3}`} onClick={() => props.changeStep(3)}>
            <div className="modal-wizard-step-header">
              <span className="modal-wizard-step-header-title">
                {props.t('step_3_header_title')}
              </span>
              {props.getStepStatusIcon(3)}
            </div>
            <div className="modal-wizard-step-section">
              <div className="modal-wizard-step-section-items-row">
                <div className="modal-wizard-step-section-item">
                  <span className="modal-wizard-step-section-item-label">{props.t('sector_label')}</span>
                  <CustomSelectController 
                    type='pagination'
                    dropdownPosition='body'
                    hasFilter={true}
                    showTooltipOptions={true}
                    tooltipOptionsPosition='right'
                    showIconSelected={true}
                    placeholder={props.t('sector_placeholder')}
                    placeholderFilter={props.t('sector_search')}
                    options={props.getSectorsSelect().sectorsList || []}
                    selected={props.getSectorsSelect().selectedSector}
                    setSelected={props.getSectorsSelect().setSelectedSector}
                    fetchMore={props.getSectorsSelect().fetchSectorsPagination}
                    hasMore={props.getSectorsSelect().hasMoreSectors}
                    loading={props.getSectorsSelect().isLoadingSectorsSelect}
                    search={props.getSectorsSelect().fetchSearchSectors}
                  />
                </div>
                <div className="modal-wizard-step-section-item">
                  <div className="modal-wizard-step-section-item-label-header">
                    <span className="modal-wizard-step-section-item-label-header-title">{props.t('assigned_label')}</span>
                    { props.isEmployeeSector() && !props.isSelectedEmployee('agent') && !props.getAgentsSelect().selectedAgent?.id &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('assigned_accept_tooltip')}</Tooltip>}>
                        <span className="modal-wizard-step-section-item-label-header-action" onClick={() => props.currentUserAccept('agent')}>
                          {props.t('assigned_accept')}
                        </span>
                      </OverlayTrigger>
                    }
                  </div>
                  <CustomSelectController 
                    type='pagination'
                    dropdownPosition='body'
                    hasFilter={true}
                    showTooltipOptions={true}
                    tooltipOptionsPosition='right'
                    showIconSelected={true}
                    placeholder={props.t('assigned_placeholder')}
                    placeholderFilter={props.t('assigned_search')}
                    options={props.getAgentsSelect().agentsList || []}
                    selected={props.getAgentsSelect().selectedAgent}
                    setSelected={props.getAgentsSelect().setSelectedAgent}
                    fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                    hasMore={props.getAgentsSelect().hasMoreAgents}
                    loading={props.getAgentsSelect().isLoadingAgentsSelect}
                    search={props.getAgentsSelect().fetchSearchAgents}
                  />
                </div>
                <div className="modal-wizard-step-section-item">
                  <span className="modal-wizard-step-section-item-label">{props.t('status_label')}</span>
                  <CustomSelectController 
                    type='simple'
                    dropdownPosition='body'
                    placeholder={props.t('status_placeholder')}
                    options={props.getStatusSelect().statusList || []}
                    selected={props.getStatusSelect().selectedStatus}
                    setSelected={props.getStatusSelect().setSelectedStatus}
                  />
                </div>
              </div>
              <div className="modal-wizard-step-section-item">
                <div className="modal-wizard-step-section-item-label-header">
                  <span className="modal-wizard-step-section-item-label-header-title">{props.t('follower_label')}</span>
                  { !props.isSelectedEmployee('follower') &&
                    <OverlayTrigger overlay={<Tooltip>{props.t('follower_accept_tooltip')}</Tooltip>}>
                      <span className="modal-wizard-step-section-item-label-header-action" onClick={() => props.currentUserAccept('follower')}>
                        {props.t('follower_accept')}
                      </span>
                    </OverlayTrigger>
                  }
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  isMultiselect={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  showIconSelected={true}
                  placeholder={props.t('follower_placeholder')}
                  placeholderFilter={props.t('follower_search')}
                  options={props.getFollowersSelect().followersList || []}
                  selectedMulti={props.getFollowersSelect().selectedFollower}
                  setSelectedMulti={props.getFollowersSelect().setSelectedFollower}
                  fetchMore={props.getFollowersSelect().fetchFollowersPagination}
                  hasMore={props.getFollowersSelect().hasMoreFollowers}
                  loading={props.getFollowersSelect().isLoadingFollowersSelect}
                  search={props.getFollowersSelect().fetchSearchFollowers}
                />
              </div>
              <div className="modal-wizard-step-section-item">
                <span className="modal-wizard-step-section-item-label">{props.t('tag_label')}</span>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  isMultiselect={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  showIconSelected={true}
                  placeholder={props.t('tag_placeholder')}
                  placeholderFilter={props.t('tag_search')}
                  options={props.getTagsSelect().tagsList || []}
                  selectedMulti={props.getTagsSelect().selectedTag}
                  setSelectedMulti={props.getTagsSelect().setSelectedTag}
                  fetchMore={props.getTagsSelect().fetchTagsPagination}
                  hasMore={props.getTagsSelect().hasMoreTags}
                  loading={props.getTagsSelect().isLoadingTagsSelect}
                  search={props.getTagsSelect().fetchSearchTags}
                />
              </div>
            </div>
          </div>
          { props.getCustomFieldsData().formattedCustomFields?.length > 0 &&
            <div className={`modal-wizard-step modal-wizard-step4 ${stepOpenedClass.step4} ${stepFilledClass.step4}`} onClick={() => props.changeStep(4)}>
              <div className="modal-wizard-step-header">
                <span className="modal-wizard-step-header-title">
                  { props.getCustomFieldsData().hasMandatoryField ? props.t('step_4_header_title') : props.t('step_4_header_title_opt')}
                </span>
                {props.getStepStatusIcon(4)}
              </div>
              <div className="modal-wizard-step-section">
                {props.getCustomFieldsData().formattedCustomFields.map(item => {
                  return (
                    <div className="modal-wizard-step-section-item" key={item.id}>
                      { item.required && 
                        <div className="modal-wizard-step-section-item-label">
                          <span className="modal-wizard-step-section-item-label-value" title={item.name}>{item.name}</span>
                          <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('tooltip_mandatory_field')}</Tooltip>}>
                            <span className="modal-wizard-step-section-item-label-required">
                              <IconInfoCircle />
                            </span>
                          </OverlayTrigger>
                        </div>
                      }
                      { !item.required && 
                        <span className="modal-wizard-step-section-item-label" title={item.name}>{item.name}</span>
                      }
                      {
                        item.custom_field_type_id === CustomFieldsType.Text &&
                        <CustomInputController 
                          type='input'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomFieldsType.Textarea &&
                        <CustomInputController 
                          type='textarea'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomFieldsType.Dropdown &&
                        <CustomSelectController 
                          type='simple'
                          dropdownPosition='body'
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selected={props.getCustomFieldsData().customFieldsValuesObject[item.id]}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomFieldsType.DropdownMultiselect &&
                        <CustomSelectController 
                          type='simple'
                          dropdownPosition='body'
                          isMultiselect={true}
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selectedMulti={props.getCustomFieldsData().customFieldsValuesObject[item.id] || []}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                        />
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          }
          <div className={`modal-wizard-step modal-wizard-step5 ${stepOpenedClass.step5} ${stepFilledClass.step5}`} onClick={() => props.changeStep(5)}>
            <div className="modal-wizard-step-header">
              <span className="modal-wizard-step-header-title">
                {props.t('step_5_header_title')}
              </span>
              {props.getStepStatusIcon(5)}
            </div>
            <div className="modal-wizard-step-section">
              <ChatEditorComponentController 
                type='create-ticket'
                editorState={props.editorState} 
                setEditorState={props.setEditorState}
                files={props.files}
                setFiles={props.setFiles}
                hasFileUploader={props.hasFileUploader}
                setHasFileUploader={props.setHasFileUploader}
                audioRecordedFiles={props.audioRecordedFiles}
                setAudioRecordedFiles={props.setAudioRecordedFiles}
                currentAudioInfo={props.currentAudioInfo}
                setCurrentAudioInfo={props.setCurrentAudioInfo}
                createSelectedConsumer={props.selectedConsumerData}
                createSelectedChannel={props.getChannelSelect().selectedChannel}
                listCC={props.listCC}
                setListCC={props.setListCC}
                listCCO={props.listCCO}
                setListCCO={props.setListCCO}
                editorDisabled={props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp}
                setCreateSelectedTemplateMessage={props.setTemplateMessage}
                statusChatEditor={{ blocked: props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp, blocked_type: 'not_consumer_message' }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-create-ticket-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_close')}</button>
        <button className='modal-create-ticket-btn-save' disabled={!props.isValidForm() || props.loadingCreate} onClick={() => props.handleSubmit()}>{props.t('btn_create')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCreateTicket;
