import { FC, useEffect, useState } from 'react';
import { FormConfigClientInterface } from './indexModel';
import './styles.scss';
import { ContractStatus } from '../../../../core/enums/contract-status';
import InputMask from 'react-input-mask';
import MaskController from '../../../../core/shared/mask-controller';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { Row, Container, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import UserService from '../../../../services/user-service';
import { useDispatch, useSelector } from "react-redux";
import { setShowAlertFeedback } from '../../../../store/internal';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { getToken } from "../../../../store/token";
import { TokenInterfaceControlPanel } from "../../../../services/requestsInterfacesModel";

const appRequesterConst = new AppRequesterController();

const FormConfigClient: FC<FormConfigClientInterface> = (props) => {

  const [data, setData] = useState(props.data);
  const [errors, setErrors] = useState<{ company_name?: string }>({});
  const [nameCompany, setNameCompany] = useState('');
  const [showFeedbackCompanyName, setShowFeedbackCompanyName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  useEffect(() => {
    setData(props.data);
    setNameCompany(props.data?.company_name);
  }, [props.data]);

  const getCompanyStatus = (): string => {
    switch (data?.contract_status_id) {
      case ContractStatus.Active:
        return 'Ativa';
      case ContractStatus.Inactive:
        return 'Inativa';
      case ContractStatus.Trial:
        return 'Período grátis';
      case ContractStatus.EndTrial:
        return 'Fim da gratuidade';
      default:
        return 'N/A';
    }
  }

  const formatDate = (date: string): string => {
    return date ? new Date(date).toLocaleString('pt-BR') : 'N/A';
  }

  const getValue = (value: any) => {
    return value ? value : 'N/A';
  }

  return (
    <>
      <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
        <Col style={{
          textAlign: 'left',
          font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
          letterSpacing: '0px',
          color: '#0C2461',
          opacity: '1',
          display: 'flex',
          alignItems: 'center',
        }}
          md={5}
          className="mt-2"
        >
          <div style={{ fontSize: "18px", paddingRight: "20px", color: "black" }}>
            Dados da empresa
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
        
      <Formik
        enableReinitialize={true}

        initialValues={{ 
          company_name: nameCompany
        }}

        validate={values => {
          let errors = {};
          setNameCompany(values.company_name);
  
          if (!values.company_name) {
            errors['company_name'] = 'Preencha este campo';
            setShowFeedbackCompanyName(true);
          } else if (values.company_name && !/^[a-zA-Z0-9À-ÿ&\s'.,-]*$/i.test(values.company_name)) {
            errors['company_name'] = 'Nome da empresa inválido';
            setShowFeedbackCompanyName(true);
          } else if (values.company_name.length > 25) {
            errors['company_name'] = 'Nome da empresa muito longo (máximo de 25 caracteres)';
            setShowFeedbackCompanyName(true);
          } else if (/^-|-$/.test(values.company_name)) {
            errors['company_name'] = 'O nome da empresa não pode começar ou terminar com hífen';
            setShowFeedbackCompanyName(true);
          } else {
            setShowFeedbackCompanyName(false);
          }
        
          return errors;
        }}

        onSubmit={async () => {
          let JsonSend = {
            "id": data.company_id,
            "name": nameCompany,
          }

          const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
          };

          await appRequesterConst.Put(
            `/panel/company`, JsonSend, { headers },
            (response: Object) => {
              return response;
            },
            (data: { data: { user_id: string; }; }) => {
              dispatch(setShowAlertFeedback({ message: "Empresa atualizada com sucesso!", signalIcon: true, visibility: true }));
              props.getCompanyById(data.data.user_id);
              props.setClientName(nameCompany);
            },
            (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
              if (error.response !== undefined) {
                if (error.response.status === 422) {
                  if (data.company_name.trim() === '') {
                    dispatch(setShowAlertFeedback({ message: "O nome não pode ser composto apenas por espaços em branco.", visibility: true, signalIcon: false }));
                  } else if (!/^[a-zA-Z0-9À-ÿ& \s'.,-]+$/i.test(data.company_name)) {
                    dispatch(setShowAlertFeedback({ message: "O nome deve conter apenas caracteres alfanuméricos, acentos, cedilha e um único espaço entre palavras.", visibility: true, signalIcon: false }));
                  } else if (/^-|-$/.test(data.company_name)) {
                    dispatch(setShowAlertFeedback({ message: "O nome da empresa não pode começar ou terminar com hífen", visibility: true, signalIcon: false }));
                  } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  }
                } else
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
              }
            }, navigate, dispatch, setIsLoading
          );
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="company_name" style={{ marginTop: '0.5rem', color: '#707070' }}>Nome da empresa</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    maxLength={25}
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={showFeedbackCompanyName}
                    className='form-control'
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors['company_name']}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="subdomain" style={{ marginTop: '0.5rem', color: '#707070' }}>Subdomínio na Cxpress</Form.Label>
                  <Form.Control
                    type="text"
                    value={getValue(data?.company_subdomain)}
                    className='form-control'
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="document" style={{ marginTop: '0.5rem', color: '#707070' }}>Documento</Form.Label>
                  <InputMask
                    mask={MaskController.maskCpfCnpj(data?.company_cnpj || '', 'PT-BR')}
                    disabled={true}
                    readOnly={true}
                    maskChar={MaskController.maskCharCpfCnpj(data?.company_cnpj || '', 'PT-BR')}
                    value={data?.company_cnpj || 'N/A'}
                  >
                    {(inputProps) => <input {...inputProps} className="form-control" type="text" disabled />}
                  </InputMask>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="created_at" style={{ marginTop: '0.5rem', color: '#707070' }}>Data de cadastro</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatDate(data?.created_at)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="status" style={{ marginTop: '0.5rem', color: '#707070' }}>Status</Form.Label>
                  <Form.Control
                    type="text"
                    value={getCompanyStatus()}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="started_date" style={{ marginTop: '0.5rem', color: '#707070' }}>Data de ativação</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatDate(data?.started_date)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
              <Col style={{
                textAlign: 'left',
                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                letterSpacing: '0px',
                color: '#0C2461',
                opacity: '1',
                display: 'flex',
                alignItems: 'center',
              }}
                md={5}
                className="mt-2"
              >
                <div style={{ paddingRight: "20px", color: "black" }}>
                  Dados do proprietário
                </div>
              </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="owner_firstname" style={{ marginTop: '0.5rem', color: '#707070' }}>Nome do proprietário</Form.Label>
                  <Form.Control
                    className='mb-3'
                    type="text"
                    value={getValue(data?.owner_firstname)}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="owner_email" style={{ marginTop: '0.5rem', color: '#707070' }}>E-mail</Form.Label>
                  <Form.Control
                    className='mb-3'
                    type="text"
                    value={getValue(data?.owner_email)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="owner_phone" style={{ marginBottom: '0.5rem', color: '#707070' }}>Telefone</Form.Label>
                  <Form.Control
                    className='mb-3'
                    type="text"
                    value={getValue(data?.owner_phone)}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="owner_email_confimation_at" style={{ marginBottom: '0.5rem', color: '#707070' }}>Data de ativação da conta</Form.Label>
                  <Form.Control
                    className='mb-3'
                    type="text"
                    value={formatDate(data?.owner_email_confimation_at)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col className="d-flex justify-content-center" >
              <Button type='submit' className="buttonBlue" style={{ width: '14%', marginRight: 'auto' }}>Salvar</Button>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormConfigClient;