import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from 'react';
import ToolbarAudioRecorder from './index';
import { ToolbarAudioRecorderControllerInterface } from './indexModel';
import { useViewTicketContext } from "../../../../../../../../core/context/view-ticket-context";

const ToolbarAudioRecorderController: FC<ToolbarAudioRecorderControllerInterface> = (props) => {
  const { t } = useTranslation('ToolbarAudioRecorder');
  
  const { currentTicket } = useViewTicketContext();

  const [currentAudioPlayingUrl, setCurrentAudioPlayingUrl] = useState<string>();
  const [hasPermission, setHasPermission] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const intervalRef = useRef<number | null>(null);
  const audioRecorderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // const handleClickOutside = (event: any) => {
    //   if (audioRecorderRef?.current && !audioRecorderRef?.current?.contains(event.target) &&
    //     props.audioRecorderIconRef?.current && !props.audioRecorderIconRef?.current?.contains(event.target)
    //   ) {
    //     props.handleShowAudioRecorder(false);
    //   }
    // }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.handleShowAudioRecorder(false);
      }
    }

    if (props.showAudioRecorder) {
      if (audioRecorderRef?.current) {
        // document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
      }
    } else {
      // document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.showAudioRecorder]);

  useEffect(() => {
    discardRecording();
  }, [currentTicket?.id]);

  useEffect(() => {
    if (props.currentAudioInfo && props.currentAudioInfo?.preview) {
      setAudioURL(props.currentAudioInfo.preview);
    }
  }, []);
  
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(audioStream => {
        setHasPermission(true);
        setStream(audioStream);

        const mediaRecorder = new MediaRecorder(audioStream);
        setMediaRecorder(mediaRecorder);
      })
      .catch(error => {
        console.log(error);
        setHasPermission(false);
      });
  }, []);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        setStream(null);
        setMediaRecorder(null);
      }
    }
  }, [stream]);

  const startRecording = () => {
    if (mediaRecorder) {
      setIsRecording(true);
      mediaRecorder.start();
      intervalRef.current = window.setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      mediaRecorder.ondataavailable = (event) => {
        const format = {
          mimeType: 'audio/mpeg',
          extension: '.aac'
        }

        const audioBlob = new Blob([event.data], { type: format.mimeType });
        const preview = URL.createObjectURL(audioBlob);
        const timestamp = new Date().getTime();
        const newAudioBlobData = {
          isLoading: true,
          name: `audio-${timestamp}${format.extension}`,
          path: `audio-${timestamp}${format.extension}`,
          preview,
          audioBlob,
          recordingTime
        }

        props.setCurrentAudioInfo(newAudioBlobData);
        setAudioURL(preview);
      };
    }
  };

  const discardRecording = () => {
    setAudioURL(null);
    setRecordingTime(0);
    props.setCurrentAudioInfo(null);
    props.setAudioRecordedFiles([]);
  };

  const attachRecording = () => {
    if (props.currentAudioInfo) {

      const newAudioInfo = JSON.parse(JSON.stringify(props.currentAudioInfo));
      delete newAudioInfo.audioBlob;
      
      const formattedBlob = Object.assign(props.currentAudioInfo.audioBlob, newAudioInfo);
      const file = new File([formattedBlob], formattedBlob.name, { type: formattedBlob.type });

      const formattedFile = Object.assign(file, { path: props.currentAudioInfo.path });

      props.setAudioRecordedFiles([formattedFile]);
      setAudioURL(null);
      setRecordingTime(0);
      props.setCurrentAudioInfo(null);
    }
  };

  return (
    <ToolbarAudioRecorder 
      t={t}
      showAudioRecorder={props.showAudioRecorder}
      audioRecorderRef={audioRecorderRef}
      hasPermission={hasPermission}
      isRecording={isRecording}
      audioURL={audioURL}
      recordingTime={recordingTime}
      startRecording={startRecording}
      stopRecording={stopRecording}
      discardRecording={discardRecording}
      attachRecording={attachRecording}
      currentAudioPlayingUrl={currentAudioPlayingUrl}
      setCurrentAudioPlayingUrl={setCurrentAudioPlayingUrl}
      currentAudioInfo={props.currentAudioInfo}
    />
  );
};

export default ToolbarAudioRecorderController;
