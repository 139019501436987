import { FC } from "react";
import { IFormOfModalsTemplateMessage } from "./indexModel";
import { Button, Col, Container, Form, FormControl, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Formik } from "formik";
import allStyles from './styles';
import AttachmentsInMessageController from "../attachmentsInMessage/indexController";
import ListTagsComponentController from "./listTagsComponent/indexController";
import SelectComponentController from "../../../../../homeApp/components/selectComponent/indexController";
import { Channels } from "../../../../../../core/enums/channels";

const FormOfModalsTemplateMessage: FC<IFormOfModalsTemplateMessage> = (props) => {
    return (
        <Container>
            <Formik
                initialValues={props.initialValues}
                validate={values => props.validate(values)}
                onSubmit={(values) => props.onSubmit(values)}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="title">{props.t('home_departments.template_message_tab.title')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="form-control-Default"
                                        isInvalid={props.showFeedbackTitle}
                                        placeholder={props.t('home_departments.template_message_tab.title_description')}
                                        aria-label={props.t('home_departments.template_message_tab.title_description')}
                                        aria-describedby={props.t('home_departments.template_message_tab.title_description')}
                                        size='lg'
                                        type='title'
                                        id="title"
                                        name="title"
                                        autoComplete='given-name'
                                        maxLength={512}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbacktitle">
                                        {errors.title}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            {/* SelectComponent da cx */}
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.whatsapp')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                {props?.createInCompany && 
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {props.t('home_departments.template_message_tab.broken_alert_allowed')}
                                            </Tooltip>
                                        }
                                        >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="ms-2">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                }
                                <SelectComponentController
                                    className={"multiselectForm mb-3"}
                                    options={props.whatsappKeys}
                                    setOptions={props.setWhatsappKeys}
                                    selecteds={props.selectedWhatsappKey}
                                    setSelecteds={props.setSelectedWhatsappKey}
                                    singleSelect={true}
                                    isFormNoFilter={true}
                                    title={props.t('home_departments.template_message_tab.whatsapp_description')}
                                    overrideStrings={
                                        {
                                        "clearSearch": "Limpar pesquisa",
                                        "noOptions": props.t('home_departments.template_message_tab.no_numbers'),
                                        "search": props.t('home_departments.template_message_tab.search_number'),
                                        "selectSomeItems": props.t('home_departments.template_message_tab.select_number'),
                                        "create": "Criar",
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={true}
                                    hasAllOption={false}
                                />
                            </Col>
                            {
                                props.selectedWhatsappKey.length > 0 && props.selectedWhatsappKey[0]?.broker_id === "ba3a545f-a77d-44bf-8344-ba05f54f5324" &&
                                <Col sm={true}>
                                    <Form.Label htmlFor="title">Content Sid<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackContentSid}
                                            placeholder={"Content Sid"}
                                            aria-label={"Content Sid"}
                                            size='lg'
                                            type='content_sid'
                                            id="content_sid"
                                            name="content_sid"
                                            autoComplete='given-name'
                                            maxLength={512}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.content_sid}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackseed">
                                            {errors.content_sid}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            }
                        </Row>
                        { props?.createInCompany && 
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.category')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <SelectComponentController
                                    className={"multiselectForm mb-3"}
                                    options={props.categories}
                                    setOptions={props.setCategories}
                                    selecteds={props.selectedCategories}
                                    setSelecteds={props.setSelectedCategories}
                                    singleSelect={true}
                                    isFormNoFilter={true}
                                    title={props.t('home_departments.template_message_tab.category_description')}
                                    overrideStrings={
                                        {
                                        "clearSearch": "Limpar pesquisa",
                                        "noOptions": props.t('home_departments.template_message_tab.no_categories'),
                                        "search": props.t('home_departments.template_message_tab.search_category'),
                                        "selectSomeItems": props.t('home_departments.template_message_tab.select_category'),
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={true}
                                    hasAllOption={false}
                                />
                            </Col>
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.language')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <SelectComponentController
                                    className={"multiselectForm mb-3"}
                                    options={props.language}
                                    setOptions={props.setLanguage}
                                    selecteds={props.selectedLanguage}
                                    setSelecteds={props.setSelectedLanguage}
                                    singleSelect={true}
                                    isFormNoFilter={true}
                                    title={props.t('home_departments.template_message_tab.language_description')}
                                    overrideStrings={
                                        {
                                        "clearSearch": "Limpar pesquisa",
                                        "noOptions": props.t('home_departments.template_message_tab.no_language'),
                                        "search": props.t('home_departments.template_message_tab.search_language'),
                                        "selectSomeItems": props.t('home_departments.template_message_tab.select_language'),
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={true}
                                    hasAllOption={false}
                                />
                            </Col>
                        </Row>
                        }
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="message">{props.t('home_departments.template_message_tab.message')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl 
                                        style={{ height: '10vh' }}
                                        className="form-control-Default-text-area"
                                        isInvalid={props.showFeedbackMessage}
                                        placeholder={props.t('home_departments.template_message_tab.message_description')}
                                        aria-label={props.t('home_departments.template_message_tab.message_description')}
                                        aria-describedby={props.t('home_departments.template_message_tab.message_description')}
                                        size='lg'
                                        type='text'
                                        as="textarea"
                                        id="message"
                                        name="message"
                                        maxLength={1024}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackmessage">
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Form.Label htmlFor="name">
                            {props.t('home_departments.template_message_tab.variables')}
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip'>
                                            {props.t('home_departments.template_message_tab.variable_info')} {/* &#x7B;&#x7B;nome&#x7D;&#x7D; ou  */}&#x7B;&#x7B;1&#x7D;&#x7D;
                                            {/* Insira o nome ou o número da variável entre '&#x7B;&#x7B;&#x7D;&#x7D;'. Ou insira somente o nome sem '&#x7B;&#x7B;&#x7D;&#x7D;'. */}
                                        </Tooltip>
                                    }
                                    >
                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="ms-2">
                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </OverlayTrigger>
                              <Button className="mx-3 nopadding buttonTicketsSec" onClick={props.showCreateTagModal}>
                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Button>
                            </Form.Label>
                            <InputGroup className="mb-3" style={{  }}>
                                <ListTagsComponentController initTags={props.tags} setInitTags={props.setTags} setExamples={props?.setExamples}/>
                            </InputGroup>
                        </Row>
                        <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                            <AttachmentsInMessageController setAttachmentsFilesIds={props.attachmentIdsCallback} previewFiles={props.previewImage? [props.previewImage]: []} createInCompany={props?.createInCompany} selectedChannelId={Channels.WhatsApp}/>
                        </Row>
                        
                        {/* <Row className="d-flex justify-content-center">
                            <Col sm={true} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">Agente Administrador? &nbsp; &nbsp;</Form.Label>
                                <Form.Check name="radioIsAdministrator" label={'Não'} type={'radio'} checked={!agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(false) }} inline></Form.Check>
                                <Form.Check name="radioIsAdministrator" label={'Sim'} type={'radio'} checked={agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(true) }} inline></Form.Check>
                            </Col>
                        </Row> */}
                        <Row className="d-flex justify-content-center mt-4">
                            <Col xs={6} className="d-flex justify-content-start">
                                <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => { props?.setExamples([]); props.hide() }} >{props.t('home_departments.template_message_tab.cancel')}</Button>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                <Button disabled={props.disabledSubmit} size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{props.t('home_departments.template_message_tab.save')}</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default FormOfModalsTemplateMessage;