import { Button, Popover } from "react-bootstrap"
import ListRanking from "."
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Utils from "../../../../../core/shared/utils"

const ListRankingController = (props) => {


    const [show, setShow] = useState(false)
    const [currentAgentId, setCurrentAgentId] = useState(null)

    const [currentFilterDatesInitial, setCurrentFilterDatesInitial] = useState()
    const [currentFilterDateFinal, setCurrentFilterDateFinal] = useState()
    const [currentTag, setCurrentTag] = useState([])
    const [currentChannel, setCurrentChannel] = useState([])
    const [currentDepartmentId, setCurrentDepartmentId] = useState([])
    const [currentEmployee, setCurrentEmployee] = useState([])
    const [currentConsumer, setCurrentConsumer] = useState([])

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const { t } = useTranslation();

    const JsonSend = {
        start_date: currentFilterDatesInitial != null ? currentFilterDatesInitial : null,
        end_date: currentFilterDateFinal != null ? currentFilterDateFinal : null,
        tag: currentTag != null ? currentTag?.join(',') : null,
        channel: currentChannel != null ? currentChannel?.join(',') : null,
        sector_id: currentDepartmentId != null ? currentDepartmentId?.join(',') : null,
        employee: currentEmployee != null ? currentEmployee?.join(',') : null,
        consumer: currentConsumer != null ? currentConsumer?.join(',') : null
    }

    useEffect(() => { 
        Utils.changeActiveScrollBar("ListOfAgents");
    }, [props?.dataList]);

    const setCurrentAgentInfo = (e: { currentTarget: { id: string } }) => {
        setCurrentAgentId(e.currentTarget.id.split("*ID*")[1])
    }

    const popover = (
        <Popover id='list-ranking' style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className='nopadding'>
                <Button onClick={props.onClick} className='m-1' style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('dashboard.list_export_ranking')}</Button>
            </Popover.Body>
        </Popover>
    )

    useEffect(() => {

        if (props != null) {
            setCurrentFilterDatesInitial(props?.currentFilterDatesInitial)
            setCurrentFilterDateFinal(props?.currentFilterDateFinal)
            setCurrentTag(props?.currentTag)
            setCurrentChannel(props?.currentChannel)
            setCurrentDepartmentId(props?.currentDepartmentId)
            setCurrentEmployee(props?.currentEmployee)
            setCurrentConsumer(props?.currentConsumer)
        }

    }, [props])


    return <ListRanking
        t={t}
        printPDF={props.printPDF}
        JsonSend={JsonSend}
        show={show}
        handleClose={handleClose}
        currentAgentId={currentAgentId}
        data={props?.data}
        popover={popover}
        dataList={props?.dataList}
        updateListfunction={props?.updateListfunction}
        HasMoreInformation={props?.HasMoreInformation}
        setCurrentAgentInfo={setCurrentAgentInfo}
        handleShow={handleShow}
    />

}

export default ListRankingController
