import './styles.scss';
import { FC } from 'react';
import { ModalExportConsumerInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import { Trans } from 'react-i18next';
import Loading from '../../../../../components/loading2';

const ModalExportConsumer: FC<ModalExportConsumerInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-export-consumer-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.mode === 'single' ? props.t('title_single') : props.t('title_multi')}
        </Modal.Title>
        <div className="modal-export-consumer-header-actions">
          <IconX onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingExport) && <Loading blur={true}/>}
        { props.data?.length === 1 && !props.data[0]?.consumerName && 
          <span className="modal-export-consumer-message">{props.t('message_selected_single')}</span> 
        }
        { props.data?.length === 1 && props.data[0]?.consumerName &&
          <span className="modal-export-consumer-message">
            <Trans 
              i18nKey='ModalExportConsumer:message_menu_single'
              values={{ consumerName: props.data[0].consumerName }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data?.length > 1 &&
          <span className="modal-export-consumer-message">
            <Trans 
              i18nKey='ModalExportConsumer:message_selected_multiple'
              values={{ selectedCount: props.data.length }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data?.length === 0 &&
          <span className="modal-export-consumer-message">
            <Trans 
              i18nKey={props.hasFilter ? 'ModalExportConsumer:message_all_filtered' : 'ModalExportConsumer:message_all'}
              values={{ textAll: props.t(props.hasFilter ? 'text_all_filtered' : 'text_all') }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-export-consumer-btn-cancel' onClick={() => props.hide()}>{props.t('btn_cancel')}</button>
        <button className='modal-export-consumer-btn-save' disabled={props.loadingExport} onClick={() => props.handleSubmit()}>{props.t('btn_export')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExportConsumer;
