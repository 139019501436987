import { BodyInterface } from './indexModel'
import { useState } from 'react';
import HomeSubdomain from '.';
import { CompanyRequests } from '../../services/company/companyControler';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const CompanyRequestsConst = new CompanyRequests();

const HomeControler = () => {
    const { t } = useTranslation();
    const [subdomain, setSubDomain] = useState("");
    const [showfeedback, setShowfeedback] = useState(false);
    const [validated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmitValidationSubdomain = () => {
        const body = {
            "subdomain": subdomain,
        };

        const headers = {
            "Content-Type": "application/json; charset=utf-8"
        };

        CompanyRequestsConst.CompanySubdomainValidation(
            body,
            { headers },
            (response: Object) => {
                return response
            },
            (data: Object) => {
                return data
            },
            (error: Object) => {
                setShowfeedback(true)
                document.getElementById("feedbackText").innerHTML = "Subdomínio não encontrado";
            }, navigate, dispatch, setIsLoading,
        );
    }

    const ValidationInputSubdomain = (values: BodyInterface, FeedbackShow: Function, FeedbackHide: Function) => {
        let errors = {};
        if (!values.subdomain) {
            errors['subdomain'] = t("login.error_fill_field");
            FeedbackShow();
        } else if (
            !/^[A-Z0-9]{2,40}$/i.test(values.subdomain)
        ) {
            errors['subdomain'] = t("login.invalid_subdomain");
            FeedbackShow();
        } else {
            FeedbackHide();
        }
        return errors;
    }

    return <HomeSubdomain 
        isLoading={isLoading}
        t={t}
        setSubDomain={setSubDomain}
        showfeedback={showfeedback}
        setShowfeedback={setShowfeedback}
        ValidationInputSubdomain={ValidationInputSubdomain}
        onSubmitValidationSubdomain={onSubmitValidationSubdomain}
        validated={validated}
    />
}

export default HomeControler;