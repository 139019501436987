import { FC, useEffect, useRef, useState, MouseEvent, useCallback } from 'react';
import ListOfTags from './index';
import { I_FetchTags, I_Tags, ListOfTagsControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMainAppContext } from '../../../../../core/context/main-app-context';
import UserService from '../../../../../services/user-service';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../../components-fixed/searchComponent/indexModel';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { getTagsConsumerListDateOrderBy, getTagsConsumerListIdOrderBy, getTagsConsumerListNameOrderBy, getTagsConsumerListSortBy, getTagsConsumerListStatusOrderBy, getTagsTicketListDateOrderBy, getTagsTicketListIdOrderBy, getTagsTicketListNameOrderBy, getTagsTicketListSortBy, getTagsTicketListStatusOrderBy, setTagsConsumerListDateOrderBy, setTagsConsumerListIdOrderBy, setTagsConsumerListNameOrderBy, setTagsConsumerListSortBy, setTagsConsumerListStatusOrderBy, setTagsTicketListDateOrderBy, setTagsTicketListIdOrderBy, setTagsTicketListNameOrderBy, setTagsTicketListSortBy, setTagsTicketListStatusOrderBy } from '../../../../../store/table_sort_order';
import emitter from '../../../../../core/shared/emitter';
import constsRouters from '../../../../../routes/constsRouter';
import { Popover } from 'react-bootstrap';
import { IconCancel, IconCircleCheck, IconEdit, IconTicket, IconTrashX, IconUsers } from '@tabler/icons-react';
import { OrderByType, SortByType } from '../../../../../core/enums/order-sort-by';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const ID_SIZE = '100px';
const NAME_SIZE = '300px';
const DATE_SIZE = '200px';
const STATUS_SIZE = '120px';

const ListOfTagsController: FC<ListOfTagsControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfTags');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setMainHeader,
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
  } = useMainAppContext();

  const tagsTicketListSortBy = useSelector(getTagsTicketListSortBy);
  const tagsTicketListSortByRef = useRef<SortByType>(tagsTicketListSortBy);
  const tagsTicketListIdOrderBy = useSelector(getTagsTicketListIdOrderBy);
  const tagsTicketListIdOrderByRef = useRef<OrderByType>(tagsTicketListIdOrderBy);
  const tagsTicketListNameOrderBy = useSelector(getTagsTicketListNameOrderBy);
  const tagsTicketListNameOrderByRef = useRef<OrderByType>(tagsTicketListNameOrderBy);
  const tagsTicketListDateOrderBy = useSelector(getTagsTicketListDateOrderBy);
  const tagsTicketListDateOrderByRef = useRef<OrderByType>(tagsTicketListDateOrderBy);
  const tagsTicketListStatusOrderBy = useSelector(getTagsTicketListStatusOrderBy);
  const tagsTicketListStatusOrderByRef = useRef<OrderByType>(tagsTicketListStatusOrderBy);

  const tagsConsumerListSortBy = useSelector(getTagsConsumerListSortBy);
  const tagsConsumerListSortByRef = useRef<SortByType>(tagsConsumerListSortBy);
  const tagsConsumerListIdOrderBy = useSelector(getTagsConsumerListIdOrderBy);
  const tagsConsumerListIdOrderByRef = useRef<OrderByType>(tagsConsumerListIdOrderBy);
  const tagsConsumerListNameOrderBy = useSelector(getTagsConsumerListNameOrderBy);
  const tagsConsumerListNameOrderByRef = useRef<OrderByType>(tagsConsumerListNameOrderBy);
  const tagsConsumerListDateOrderBy = useSelector(getTagsConsumerListDateOrderBy);
  const tagsConsumerListDateOrderByRef = useRef<OrderByType>(tagsConsumerListDateOrderBy);
  const tagsConsumerListStatusOrderBy = useSelector(getTagsConsumerListStatusOrderBy);
  const tagsConsumerListStatusOrderByRef = useRef<OrderByType>(tagsConsumerListStatusOrderBy);

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [tab, setTab] = useState<'ticket' | 'consumer'>('ticket');
  const [listHeadersTagsTicket, setListHeadersTagsTicket] = useState<CustomTableListHeaders[]>([]);
  const [listHeadersTagsConsumer, setListHeadersTagsConsumer] = useState<CustomTableListHeaders[]>([]);
  const [dataTicket, setDataTicket] = useState<I_Tags[]>([]);
  const [dataConsumer, setDataConsumer] = useState<I_Tags[]>([]);
  const [dataObj, setDataObj] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalTag, setShowModalTag] = useState(false);
  const [showModalChangeStatusTag, setShowModalChangeStatusTag] = useState(false);
  const [showModalDeleteTag, setShowModalDeleteTag] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    if (tab === 'ticket') {
      setListHeadersTagsTicket([
        {
          title: t('list_headers.id'),
          field: 'reference_id',
          width: ID_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsTicketOrdered,
          orderBy: tagsTicketListIdOrderBy || 'asc',
          orderType: 'number',
          hasTooltip: true
        },
        {
          title: t('list_headers.name'),
          field: 'name',
          width: NAME_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsTicketOrdered,
          orderBy: tagsTicketListNameOrderBy || 'asc',
          orderType: 'text',
          hasTooltip: true
        },
        {
          title: t('list_headers.created_at'),
          field: 'created_at',
          width: DATE_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsTicketOrdered,
          orderBy: tagsTicketListDateOrderBy || 'desc',
          orderType: 'default',
          hasTooltip: true
        },
        {
          title: t('list_headers.status'),
          field: 'active',
          width: STATUS_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsTicketOrdered,
          orderBy: tagsTicketListStatusOrderBy || 'asc',
          orderType: 'default',
          hasTooltip: true
        },
        { title: t("list_headers.actions"), field: 'action', width: 'auto' },
      ]);
    } else {
      setListHeadersTagsConsumer([
        {
          title: t('list_headers.id'),
          field: 'reference_id',
          width: ID_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsConsumerOrdered,
          orderBy: tagsConsumerListIdOrderBy || 'asc',
          orderType: 'number',
          hasTooltip: true
        },
        {
          title: t('list_headers.name'),
          field: 'name',
          width: NAME_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsConsumerOrdered,
          orderBy: tagsConsumerListNameOrderBy || 'asc',
          orderType: 'text',
          hasTooltip: true
        },
        {
          title: t('list_headers.created_at'),
          field: 'created_at',
          width: DATE_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsConsumerOrdered,
          orderBy: tagsConsumerListDateOrderBy || 'desc',
          orderType: 'default',
          hasTooltip: true
        },
        {
          title: t('list_headers.status'),
          field: 'active',
          width: STATUS_SIZE,
          hasOrderBy: true,
          orderByFn: getTagsConsumerOrdered,
          orderBy: tagsConsumerListStatusOrderBy || 'asc',
          orderType: 'default',
          hasTooltip: true
        },
        { title: t("list_headers.actions"), field: 'action', width: 'auto' },
      ]);
    }
  }, [tab]);

  useEffect(() => {
    configMainHeader(tab);
    
    if (apiHeader) {
      setCurrentPage(1);
      if (tab === 'ticket') {
        clearSearchTagsTicket();
      } else {
        clearSearchTagsConsumer();
      }
    }
  }, [apiHeader, tab]);

  useEffect(() => {
    if (dataTicket) {
      setDataObj(getDataObject(dataTicket));
    }
  }, [dataTicket]);

  useEffect(() => {
    if (dataConsumer) {
      setDataObj(getDataObject(dataConsumer));
    }
  }, [dataConsumer]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = (tab: 'ticket' | 'consumer') => {
    if (tab === 'ticket') {
      setIsSearched(false);
      queryFilterRef.current = {};
      setFilterApplied({});
      setFilteredMode('simple');
      setSelectedItems([]);
      setMainHeader({
        pageTitle: t('header.welcome_page'),
        pageSubtitle: t('header.sub_info_ticket'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: false,
        searchPage: 'tags-ticket',
        searchPlaceholder: t('header.search_ticket_placeholder'),
        searchFunction: searchTagsTicket,
        clearSearchFunction: clearSearchTagsTicket,
        hasCreateButton: true,
        createButtonText: t('header.button_create'),
        createButtonTooltip: t('header.button_create_ticket_tooltip'),
        createButtonAction: openModalNewTag,
      });
    } else {
      setIsSearched(false);
      queryFilterRef.current = {};
      setFilterApplied({});
      setFilteredMode('simple');
      setSelectedItems([]);
      setMainHeader({
        pageTitle: t('header.welcome_page'),
        pageSubtitle: t('header.sub_info_consumer'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: false,
        searchPage: 'tags-consumer',
        searchPlaceholder: t('header.search_consumer_placeholder'),
        searchFunction: searchTagsConsumer,
        clearSearchFunction: clearSearchTagsConsumer,
        hasCreateButton: true,
        createButtonText: t('header.button_create'),
        createButtonTooltip: t('header.button_create_consumer_tooltip'),
        createButtonAction: openModalNewTag,
      });
    }
  }

  const getListHeadersTagsTicket = (): CustomTableListHeaders[] => {
    return listHeadersTagsTicket.filter(item => !item.hidden);
  }

  const getListHeadersTagsConsumer = (): CustomTableListHeaders[] => {
    return listHeadersTagsConsumer.filter(item => !item.hidden);
  }

  const changeTab = (tab: 'ticket' | 'consumer') => {
    setTab(tab);
  }

  // =======================================================================================

  const getTagsTicket = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);
    const config = { headers: apiHeader, params };

    AppRequesterConst.Get(
      '/tag', config,
      () => {},
      (response: I_FetchTags) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          if (isScrollPagination) {
            const new_array = [...dataTicket, ...response.data.tags];
            setDataTicket(new_array);
          } else {
            setDataTicket(response.data.tags || []);
          }
          processPaginationDetails(response.data.pagination);
        } else {
          setDataTicket([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getTagsTicketPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const sortBy = tagsTicketListSortBy || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsTicketListNameOrderBy || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsTicketListDateOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsTicketListStatusOrderBy || 'asc';  
    } else {
      orderBy = tagsTicketListIdOrderBy || 'desc';
    }
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...currentFilter };
    if (page) {
      params.page = page;
      setCurrentPage(page);
      getTagsTicket(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getTagsTicket(params, true);
      }
    }
  }

  const reloadTagsTicket = (preserveFilter?: boolean) => {
    const sortBy = tagsTicketListSortBy || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsTicketListNameOrderBy || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsTicketListDateOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsTicketListStatusOrderBy || 'asc';  
    } else {
      orderBy = tagsTicketListIdOrderBy || 'desc';
    }
    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    if (preserveFilter && queryFilterRef.current) {
      params = { ...params, ...queryFilterRef.current };
    }
    getTagsTicket(params, false);
  }

  const searchTagsTicket = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const sortBy = tagsTicketListSortByRef.current || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsTicketListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsTicketListDateOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsTicketListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = tagsTicketListIdOrderByRef.current || 'desc';
    }
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...query };
    
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    getTagsTicket(params);
  }

  const clearSearchTagsTicket = () => {
    const sortBy = tagsTicketListSortByRef.current || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsTicketListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsTicketListDateOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsTicketListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = tagsTicketListIdOrderByRef.current || 'desc';
    }
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }
    getTagsTicket(params);
  }

  const getTagsTicketOrdered = async (field: string, order: OrderByType) => {
    let formattedField = field as SortByType;

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: formattedField, order_by: order, ...currentFilter };
    setHeaderTagsTicketOrderBy(formattedField, order);
    getTagsTicket(params);
  }

  const getTagsConsumerOrdered = async (field: string, order: OrderByType) => {
    let formattedField = field as SortByType;

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: formattedField, order_by: order, ...currentFilter };
    setHeaderTagsConsumerOrderBy(formattedField, order);
    getTagsConsumer(params);
  }

  const setHeaderTagsTicketOrderBy = (field: SortByType, order: 'asc' | 'desc'): void => {
    let clearedField: string = field;

    setListHeadersTagsTicket(prevState => {
      prevState.forEach(item => {
        if (item.field === clearedField) {
          item.orderBy = order;
        } else {
          if (item.field === 'reference_id') {
            item.orderBy = 'asc';
          } else if (item.field === 'name') {
            item.orderBy = 'asc';
          } else if (item.field === 'created_at') {
            item.orderBy = 'desc';
          } else if (item.field === 'active') {
            item.orderBy = 'asc';
          }
        }
      });

      if (field === 'reference_id') {
        dispatch(setTagsTicketListIdOrderBy(order));
        tagsTicketListIdOrderByRef.current = order;
        dispatch(setTagsTicketListNameOrderBy('asc')); // resetando valor default
        tagsTicketListNameOrderByRef.current = 'asc';
        dispatch(setTagsTicketListDateOrderBy('desc')); // resetando valor default
        tagsTicketListDateOrderByRef.current = 'desc';
        dispatch(setTagsTicketListStatusOrderBy('asc')); // resetando valor default
        tagsTicketListStatusOrderByRef.current = 'asc';
      } else if (field === 'name') {
        dispatch(setTagsTicketListNameOrderBy(order));
        tagsTicketListNameOrderByRef.current = order;
        dispatch(setTagsTicketListIdOrderBy('asc')); // resetando valor default
        tagsTicketListIdOrderByRef.current = 'asc';
        dispatch(setTagsTicketListDateOrderBy('desc')); // resetando valor default
        tagsTicketListDateOrderByRef.current = 'desc';
        dispatch(setTagsTicketListStatusOrderBy('asc')); // resetando valor default
        tagsTicketListStatusOrderByRef.current = 'asc';
      } else if (field === 'created_at') {
        dispatch(setTagsTicketListDateOrderBy(order));
        tagsTicketListDateOrderByRef.current = order;
        dispatch(setTagsTicketListIdOrderBy('asc')); // resetando valor default
        tagsTicketListIdOrderByRef.current = 'asc';
        dispatch(setTagsTicketListNameOrderBy('desc')); // resetando valor default
        tagsTicketListNameOrderByRef.current = 'desc';
        dispatch(setTagsTicketListStatusOrderBy('asc')); // resetando valor default
        tagsTicketListStatusOrderByRef.current = 'asc';
      } else if (field === 'active') {
        dispatch(setTagsTicketListStatusOrderBy(order));
        tagsTicketListStatusOrderByRef.current = order;
        dispatch(setTagsTicketListIdOrderBy('asc')); // resetando valor default
        tagsTicketListIdOrderByRef.current = 'asc';
        dispatch(setTagsTicketListNameOrderBy('asc')); // resetando valor default
        tagsTicketListNameOrderByRef.current = 'asc';
        dispatch(setTagsTicketListDateOrderBy('desc')); // resetando valor default
        tagsTicketListDateOrderByRef.current = 'desc';
      }

      dispatch(setTagsTicketListSortBy(field));
      tagsTicketListSortByRef.current = field;

      return prevState;
    });
  }
  
  const setHeaderTagsConsumerOrderBy = (field: SortByType, order: 'asc' | 'desc'): void => {
    let clearedField: string = field;

    setListHeadersTagsConsumer(prevState => {
      prevState.forEach(item => {
        if (item.field === clearedField) {
          item.orderBy = order;
        } else {
          if (item.field === 'reference_id') {
            item.orderBy = 'asc';
          } else if (item.field === 'name') {
            item.orderBy = 'asc';
          } else if (item.field === 'created_at') {
            item.orderBy = 'desc';
          } else if (item.field === 'active') {
            item.orderBy = 'asc';
          }
        }
      });

      if (field === 'reference_id') {
        dispatch(setTagsConsumerListIdOrderBy(order));
        tagsConsumerListIdOrderByRef.current = order;
        dispatch(setTagsConsumerListNameOrderBy('asc')); // resetando valor default
        tagsConsumerListNameOrderByRef.current = 'asc';
        dispatch(setTagsConsumerListDateOrderBy('desc')); // resetando valor default
        tagsConsumerListDateOrderByRef.current = 'desc';
        dispatch(setTagsConsumerListStatusOrderBy('asc')); // resetando valor default
        tagsConsumerListStatusOrderByRef.current = 'asc';
      } else if (field === 'name') {
        dispatch(setTagsConsumerListNameOrderBy(order));
        tagsConsumerListNameOrderByRef.current = order;
        dispatch(setTagsConsumerListIdOrderBy('asc')); // resetando valor default
        tagsConsumerListIdOrderByRef.current = 'asc';
        dispatch(setTagsConsumerListDateOrderBy('desc')); // resetando valor default
        tagsConsumerListDateOrderByRef.current = 'desc';
        dispatch(setTagsConsumerListStatusOrderBy('asc')); // resetando valor default
        tagsConsumerListStatusOrderByRef.current = 'asc';
      } else if (field === 'created_at') {
        dispatch(setTagsConsumerListDateOrderBy(order));
        tagsConsumerListDateOrderByRef.current = order;
        dispatch(setTagsConsumerListIdOrderBy('asc')); // resetando valor default
        tagsConsumerListIdOrderByRef.current = 'asc';
        dispatch(setTagsConsumerListNameOrderBy('desc')); // resetando valor default
        tagsConsumerListNameOrderByRef.current = 'desc';
        dispatch(setTagsConsumerListStatusOrderBy('asc')); // resetando valor default
        tagsConsumerListStatusOrderByRef.current = 'asc';
      } else if (field === 'active') {
        dispatch(setTagsConsumerListStatusOrderBy(order));
        tagsConsumerListStatusOrderByRef.current = order;
        dispatch(setTagsConsumerListIdOrderBy('asc')); // resetando valor default
        tagsConsumerListIdOrderByRef.current = 'asc';
        dispatch(setTagsConsumerListNameOrderBy('asc')); // resetando valor default
        tagsConsumerListNameOrderByRef.current = 'asc';
        dispatch(setTagsConsumerListDateOrderBy('desc')); // resetando valor default
        tagsConsumerListDateOrderByRef.current = 'desc';
      }

      dispatch(setTagsConsumerListSortBy(field));
      tagsConsumerListSortByRef.current = field;

      return prevState;
    });
  }

  // ############################################################################################
  // ############################################################################################

  const getTagsConsumer = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);
    const config = { headers: apiHeader, params };

    AppRequesterConst.Get(
      '/consumer-tag', config,
      () => {},
      (response: I_FetchTags) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          if (isScrollPagination) {
            const new_array = [...dataConsumer, ...response.data.tags];
            setDataConsumer(new_array);
          } else {
            setDataConsumer(response.data.tags || []);
          }
          processPaginationDetails(response.data.pagination);
        } else {
          setDataConsumer([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getTagsConsumerPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const sortBy = tagsConsumerListSortBy || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsConsumerListNameOrderBy || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsConsumerListDateOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsConsumerListStatusOrderBy || 'asc';  
    } else {
      orderBy = tagsConsumerListIdOrderBy || 'desc';
    }
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...currentFilter };
    if (page) {
      params.page = page;
      setCurrentPage(page);
      getTagsConsumer(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getTagsConsumer(params, true);
      }
    }
  }

  const reloadTagsConsumer = (preserveFilter?: boolean) => {
    const sortBy = tagsConsumerListSortBy || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsConsumerListNameOrderBy || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsConsumerListDateOrderBy || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsConsumerListStatusOrderBy || 'asc';  
    } else {
      orderBy = tagsConsumerListIdOrderBy || 'desc';
    }
    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    if (preserveFilter && queryFilterRef.current) {
      params = { ...params, ...queryFilterRef.current };
    }
    getTagsConsumer(params, false);
  }

  const searchTagsConsumer = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const sortBy = tagsConsumerListSortByRef.current || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsConsumerListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsConsumerListDateOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsConsumerListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = tagsConsumerListIdOrderByRef.current || 'desc';
    }
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...query };
    
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    getTagsConsumer(params);
  }

  const clearSearchTagsConsumer = () => {
    const sortBy = tagsConsumerListSortByRef.current || 'reference_id';
    let orderBy = 'asc';
    if (sortBy === 'name') {
      orderBy = tagsConsumerListNameOrderByRef.current || 'asc';  
    } else if (sortBy === 'created_at') {
      orderBy = tagsConsumerListDateOrderByRef.current || 'asc';  
    } else if (sortBy === 'active') {
      orderBy = tagsConsumerListStatusOrderByRef.current || 'asc';  
    } else {
      orderBy = tagsConsumerListIdOrderByRef.current || 'desc';
    }
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }
    getTagsConsumer(params);
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };
    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (key === 'search') {
        currentSearchTerm = '';
        clearFilterTermFunction();
      }

      setFilterApplied(tempFilterApplied);
      const pageTab = tab === 'ticket' ? 'tags-ticket' : 'tags-consumer';
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, pageTab), tempFilterApplied);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters: AppliedFiltersBarType[] = [];
    // let hasDate = false;
    setFilterMode('main');

    Object.keys(queryFilterRef.current || {}).forEach(key => {
      const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
      
      if (key === 'search') {
        item.value = queryFilterRef.current[key];
      }

      // if (!hasDate && key.includes('date')) {
      //   hasDate = true;
      //   if (filterApplied.selectedFilterDate?.id === 'custom') {
      //     item.value = `${filterApplied.selectedDate.start.toDateString()} - ${filterApplied.selectedDate.end.toDateString()}`
      //   } else {
      //     item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
      //   }
      // }

      if (!key.includes('date')) {
        filters.push(item);
      }
    });

    // if (queryFilterRef.current?.start_date && queryFilterRef.current?.end_date) {
    //   const item = { key: 'date', tooltip_key: 'filter_applied.period', value: '' };

    //   if (filterApplied.selectedFilterDate.id === 'custom') {
    //     item.value = `${filterApplied.selectedDate.start.toLocaleDateString()} - ${filterApplied.selectedDate.end.toLocaleDateString()}`
    //   } else {
    //     item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
    //   }

    //   filters.push(item);
    // }
    return filters;
  }

  // ===== Seleção de itens
  const getDataObject = (data: I_Tags[]) => {
    const obj = {};
    data.forEach(item => {
      obj[item.id] = item.id;
    });
    return obj;
  }

  const selectItem = (item: I_Tags, event: MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    event.stopPropagation();

    if (selectedItems.includes(item.id)) {
      const new_selected = [...selectedItems];
      const index = new_selected.indexOf(item.id);
      if (index > -1) {
        new_selected.splice(index, 1);
        setSelectedItems(new_selected);
      }
    } else {
      const new_selected = [...selectedItems, item.id];
      setSelectedItems(new_selected);
    }
  }

  const removeSelectedItem = useCallback((ids: string[]): void => {
    setSelectedItems(prevSelected => prevSelected.filter(id => !ids.includes(id)));
  }, []);

  const selectAllItems = (): void => {
    const data = tab === 'ticket' ? dataTicket : dataConsumer;

    if (data.length > 0) {
      const localSelect = (id: string, array: string[]) => {
        if (array.includes(id)) {
          const index = array.indexOf(id);
          array.splice(index, 1);
        } else {
          array = [...array, id];
        }
        return array;
      }
  
      let count = 0;
  
      let selected = Object.assign([], selectedItems);
  
      selected.forEach(id => {
        if (dataObj[id]) {
          count += 1;
        }
      });
  
      if (count === data.length) { // Se todos na página tiver selecionados, remove apenas o da página
        Object.keys(dataObj).forEach(id => {
          if (dataObj[id]) {
            selected = localSelect(id, selected);
          }
        });
      } else { // Senão, marca apenas os que estão desmarcados
        Object.keys(dataObj).forEach(id => {
          if (!selectedItems.includes(id)) {
            selected = localSelect(id, selected);
          }
        });
      }
  
      setSelectedItems(selected);
    }
  }

  const isSelectedItem = (id: string): boolean => {
    return selectedItems.includes(id);
  }

  const isAllSelectedItems = (): boolean => {
    if (selectedItems.length > 0) {
      let count = 0;

      Object.keys(dataObj).forEach(id => {
        if (selectedItems.includes(id)) {
          count += 1;
        }
      });
      const data = tab === 'ticket' ? dataTicket : dataConsumer;

      return data.length === count;
    } else {
      return false;
    }
  }

  const hasSomeSelected = (): boolean => {
    return selectedItems.length > 0;
  }

  const hasSomeSelectedCheck = (): boolean => {
    let hasSome = false;

    const pageIds = Object.keys(dataObj);

    for (let i = 0; i < pageIds.length; i++) {
      if (selectedItems.includes(pageIds[i])) {
        hasSome = true;
        break;
      }
    }

    return hasSome;
  }

  const clearSelected = () => {
    setSelectedItems([]);
  }

  const clearPageSelected = () => {
    let selected = Object.assign([], selectedItems);

    Object.keys(dataObj).forEach(id => {
      if (selected.includes(id)) {
        const index = selected.indexOf(id);
        selected.splice(index, 1);
      }
    });
    setSelectedItems(selected);
  }

  const deleteSelected = () => {
    if (selectedItems.length === 1) { // Se for só um, se comportar como o menu de ações
      const data = tab === 'ticket' ? dataTicket : dataConsumer;
      const tag = data.find(item => item.id === selectedItems[0]);
      openModalDeleteTag('menu-action', [{ id: selectedItems[0], name: tag?.name || 'N/A' }]);
    } else {
      openModalDeleteTag('table-action', selectedItems.map(id => ({ id })));
    }
  }

  const activateSelected = () => {
    if (selectedItems.length === 1) { // Se for só um, se comportar como o menu de ações
      const data = tab === 'ticket' ? dataTicket : dataConsumer;
      const tag = data.find(item => item.id === selectedItems[0]);
      openModalChangeStatusTag('menu-action', { ids: [{ id: selectedItems[0], name: tag?.name || 'N/A' }], newStatus: true });
    } else {
      openModalChangeStatusTag('table-action', { ids: selectedItems.map(id => ({ id })), newStatus: true });
    }
  }

  const inactivateSelected = () => {
    if (selectedItems.length === 1) { // Se for só um, se comportar como o menu de ações
      const data = tab === 'ticket' ? dataTicket : dataConsumer;
      const tag = data.find(item => item.id === selectedItems[0]);
      openModalChangeStatusTag('menu-action', { ids: [{ id: selectedItems[0], name: tag?.name || 'N/A' }], newStatus: false });
    } else {
      openModalChangeStatusTag('table-action', { ids: selectedItems.map(id => ({ id })), newStatus: false });
    }
  }

  // =====

  const openModalTag = (item: I_Tags, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowModalTag(true);
    setTimeout(() => {
      emitter.emit('open-modal-tag', { id: item.id, name: item.name, active: item.active });
    }, 50);
  }

  const openModalNewTag = () => {
    setShowModalTag(true);
    setTimeout(() => {
      emitter.emit('open-modal-tag', null);
    }, 50);
  }

  const closeModalTag = (reload: boolean) => {
    if (reload) {
      setSelectedItems([]);
      if (tab === 'ticket') {
        reloadTagsTicket(true);
      } else {
        reloadTagsConsumer(true);
      }
    }
    setShowModalTag(false);
  }

  // =====

  const openModalDeleteTag = (type: 'menu-action' | 'table-action', tagInfo: { id: string; name?: string }[]) => {
    setShowModalDeleteTag(true);
    setTimeout(() => {
      if (type === 'menu-action') {
        emitter.emit('open-modal-delete-tag', tagInfo);
      } else {
        const formatted = selectedItems.map(id => ({ id }));
        emitter.emit('open-modal-delete-tag', formatted);
      }
    }, 50);
  }

  const closeModalDeleteTag = (reload: boolean, tagsIds: string[]) => {
    if (reload) {
      setSelectedItems([]);
      if (tab === 'ticket') {
        reloadTagsTicket(true);
      } else {
        reloadTagsConsumer(true);
      }

      if (tagsIds.length > 0) {
        removeSelectedItem(tagsIds);
      }
    }
    setShowModalDeleteTag(false);
  }

  // =====

  const openModalChangeStatusTag = (type: 'menu-action' | 'table-action', data: { ids: { id: string; name?: string }[], newStatus: boolean }) => {
    setShowModalChangeStatusTag(true);
    setTimeout(() => {
      if (type === 'menu-action') {
        emitter.emit('open-modal-change-status-tag', { ids: data.ids, newStatus: data.newStatus });
      } else {
        const formatted = selectedItems.map(id => ({ id }));
        emitter.emit('open-modal-change-status-tag', { ids: formatted, newStatus: data.newStatus });
      }
    }, 50);
  }

  const closeModalChangeStatusTag = (reload: boolean) => {
    if (reload) {
      setSelectedItems([]);
      if (tab === 'ticket') {
        reloadTagsTicket(true);
      } else {
        reloadTagsConsumer(true);
      }
    }
    setShowModalChangeStatusTag(false);
  }

  // =====

  const handlePopoverListItemAction = (event: any, data: I_Tags, type: 'open-tag-tickets' | 'open-tag-consumers' | 'edit-tag' | 'delete-tag' | 'activate-tag' | 'inactivate-tag' ) => {
    event.stopPropagation();
    emitter.emit(tab === 'ticket' ? 'close-item-action-popover-list-tags-ticket' : 'close-item-action-popover-list-tags-consumer');
    
    setTimeout(() => {
      if (type === 'open-tag-tickets') {
        navigate(
          `${constsRouters.routers.configurationTagsTickets.path}`, 
          { 
            state: { 
              tagId: data.id,
              tagValue: data.name,
              page: 'tags', 
              prevPath: constsRouters.routers.configurationTags.path 
            } 
          }
        );
      } else if (type === 'open-tag-consumers') {
        navigate(
          `${constsRouters.routers.configurationTagsConsumers.path}`, 
          { 
            state: { 
              tagId: data.id,
              tagValue: data.name,
              page: 'tags', 
              prevPath: constsRouters.routers.configurationTags.path 
            } 
          }
        );
      } else if (type === 'edit-tag') {
        openModalTag(data, event);
      } else if (type === 'delete-tag') {
        openModalDeleteTag('menu-action', [{ id: data.id, name: data.name }]);
      } else if (type === 'activate-tag') {
        openModalChangeStatusTag('menu-action', { ids: [{ id: data.id, name: data.name }], newStatus: true });
      } else if (type === 'inactivate-tag') {
        openModalChangeStatusTag('menu-action', { ids: [{ id: data.id, name: data.name }], newStatus: false });
      } 
    }, 50);
  }

  const popoverItemAction = (data: I_Tags) => {
    const showOpenTagTickets = true;
    const showOpenTagConsumers = true;
    const showEditTag = true;
    const showDeleteTag = true;
    const showActivateTag = !data.active;
    const showInactivateTag = data.active;
    
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenTagTickets && tab === 'ticket' &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'open-tag-tickets')}>
              <IconTicket />{t('list_popover_actions.open_tag_tickets')}
            </span>
          }
          { showOpenTagConsumers && tab === 'consumer' &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'open-tag-consumers')}>
              <IconUsers />{t('list_popover_actions.open_tag_consumers')}
            </span>
          }
          { showEditTag &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'edit-tag')}>
              <IconEdit />{t('list_popover_actions.edit_tag')}
            </span>
          }
          { showActivateTag &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'activate-tag')}>
              <IconCircleCheck />{t('list_popover_actions.activate_tag')}
            </span>
          }
          { showInactivateTag &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'inactivate-tag')}>
              <IconCancel />{t('list_popover_actions.inactivate_tag')}
            </span>
          }
          { showDeleteTag &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'delete-tag')}>
              <IconTrashX />{t('list_popover_actions.delete_tag')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfTags 
      t={t}
      tab={tab}
      changeTab={changeTab}
      listHeadersTagsTicket={getListHeadersTagsTicket}
      listHeadersTagsConsumer={getListHeadersTagsConsumer}
      listDataTicket={dataTicket}
      listDataConsumer={dataConsumer}
      paginationDetails={paginationDetails}
      getTagsTicketPagination={getTagsTicketPagination}
      getTagsConsumerPagination={getTagsConsumerPagination}
      reloadTagsTicket={reloadTagsTicket}
      reloadTagsConsumer={reloadTagsConsumer}
      isLoading={isLoading}
      errorLoading={errorLoading}

      openModalTag={openModalTag}
      showModalTag={showModalTag}
      closeModalTag={closeModalTag}
      showModalDeleteTag={showModalDeleteTag}
      closeModalDeleteTag={closeModalDeleteTag}
      showModalChangeStatusTag={showModalChangeStatusTag}
      closeModalChangeStatusTag={closeModalChangeStatusTag}

      filters={filters}
      clearSearchTagsTicket={clearSearchTagsTicket}
      clearSearchTagsConsumer={clearSearchTagsConsumer}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}

      selectItem={selectItem}
      selectAllItems={selectAllItems}
      isSelectedItem={isSelectedItem}
      selectedItemsLength={selectedItems.length}
      hasSomeSelected={hasSomeSelected}
      hasSomeSelectedCheck={hasSomeSelectedCheck}
      isAllSelectedItems={isAllSelectedItems}
      deleteSelected={deleteSelected}
      activateSelected={activateSelected}
      inactivateSelected={inactivateSelected}
      clearSelected={clearSelected}
      clearPageSelected={clearPageSelected}
    />
  );
};

export default ListOfTagsController;
