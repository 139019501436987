import { useDispatch, useSelector } from "react-redux";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataCompanyInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowConfirmationModal, getShowMeetButton } from "../../../../store/internal";
import { getOwner_id } from "../../../../store/callcenter";
import UserService from "../../../../services/user-service";
import HomeChannels from "./index";
import { useTranslation } from "react-i18next";
import { useMainAppContext } from "../../../../core/context/main-app-context";

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeConfigurationsTicketsController = (props: any) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { setMainHeader } = useMainAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(null)

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        setMainHeader({
            pageTitle: t('configurations_ticket.general.welcome_main'),
            pageSubtitle: t('configurations_ticket.general.welcome_sub'),
            hasSearchComponent: false,
            hasSearchInput: false,
            hasSearchFilter: false,
            searchPage: null,
            searchPlaceholder: null,
            searchFunction: null,
            clearSearchFunction: null,
            hasCreateButton: false,
            createButtonText: null,
            createButtonTooltip: null,
            createButtonAction: null,
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        getLifeTime(controller);
        //getTicketAlert();

        return () => {
            controller.abort();
        }
    }, []);

    const convertMinToHour = (num: number) => {
        return num / 60;
    }

    const getLifeTime = async (controller: AbortController) => {

        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            '/company', { headers },
            (response: Object) => { },
            (data: DataCompanyInterface) => {
                setIsLoading(true)
                if (data.status === 200) {
                    setCompany(data.data.companies[0])
                }
                setIsLoading(false)
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    /*const getTicketAlert = async () => {
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/ticket-alert', { headers },
            (response: Object) => { },
            (data: DataTicketAlertInterface) => {
                if(data.status === 200){
                    setTicketAlertInternal(data.data.ticket_alerts);
                    setTicketAlertView(data.data.ticket_alerts);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading
        );
        /* await Api.get('/ticket-alert', { headers }).then((response) => {
            return response;
        }).then(data => {
            setTicketAlertInternal(data.data.ticket_alerts);
            setTicketAlertView(data.data.ticket_alerts);
        }).catch((error) => {
            console.log(error.response);
            if (error.response !== undefined) {
                console.log(error.response);
                if (error.response.status === 400) {
                    //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedback(true);
                }
                if (error.response.status === 401) {
                    //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedbackPassword(true);
                }
            }
        }); 
    }*/

    // const deleteAlert = (e: any) => {
    //     setTicketAlertInternal(state => {
    //         if (e.target != null) {
    //             state[e.target.id.split("*ID*")[1]] = { ...state[e.target.id.split("*ID*")[1]], isDelete: true };
    //             return state;
    //         }
    //         else {
    //             return state;
    //         }
    //     });

    //     setTicketAlertView(state => {
    //         if (e.target != null) {
    //             state.splice(e.target.id.split("*ID*")[1], 1);
    //             return state;
    //         }
    //         else {
    //             return state;
    //         }
    //     });
    //     if (reload === false) {
    //         setReload(true)
    //     } else {
    //         setReload(false)
    //     }
    // }

    return <HomeChannels
        t={t}
        isLoading={isLoading}
        values={values}
        setIsLoading={setIsLoading}
        convertMinToHour={convertMinToHour}
        company={company}
    />
}

export default HomeConfigurationsTicketsController