import './styles.scss';
import { FC } from "react";
import { IconChevronUp, IconLogout, IconHistory, IconDialpad, IconUsers, IconInfoCircle, IconUserBolt } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CallHistory from "./scenes/CallHistory";
import { MainScreenInterface } from './indexModel';
import KeyboardController from "./scenes/Keyboard/indexController";
import AgentsExtensionController from './scenes/AgentsExtension/indexController';
import CallInstructionsController from './scenes/CallInstructions/indexController';
import ConsumersController from './scenes/Consumers/indexController';


const MainScreen: FC<MainScreenInterface> = ({
  callcenterContext,
  scene,
  setScene,
  disconnect,
  minimize,
  getMainActionsClass,
  t,
}) => {

  return (
    <div className="phoneCallcenterDialpad">
      <div className="header">
        <div className="info">
          <span className="text">{t('phone_callcenter.main_screen.general.dialer')}</span>
          <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.close_dialer')}</Tooltip>}>
            <span className="minimize" onClick={minimize}><IconChevronUp /></span>
          </OverlayTrigger>
        </div>
        <div className="header-actions">
          <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.disconnect_terminal')}</Tooltip>}>
            <span className="logout" onClick={disconnect}><IconLogout color="#fff" size={22}/></span>
          </OverlayTrigger>
        </div>
      </div>
      <div className="main-actions">
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.show_dialer')}</Tooltip>}>
          <span onClick={() => setScene('keyboard')} className={getMainActionsClass('keyboard')}><IconDialpad color="#fff" size={22}/></span>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.show_history')}</Tooltip>}>
          <span onClick={() => setScene('history')} className={getMainActionsClass('history')}><IconHistory color="#fff" size={22}/></span>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.show_consumers')}</Tooltip>}>
          <span onClick={() => setScene('consumers')} className={getMainActionsClass('consumers')}><IconUsers color="#fff" size={22}/></span>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.show_agent_terminals')}</Tooltip>}>
          <span onClick={() => setScene('extensions')} className={getMainActionsClass('extensions')}><IconUserBolt color="#fff" size={22}/></span>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.main_screen.general.show_usage_instructions')}</Tooltip>}>
          <span onClick={() => setScene('instructions')} className={getMainActionsClass('instructions')}><IconInfoCircle color="#fff" size={22}/></span>
        </OverlayTrigger>
      </div>
      <div className="selected-action">
      { scene === 'consumers' && <ConsumersController callcenterContext={callcenterContext} setScene={setScene} /> }
        { scene === 'history' && <CallHistory /> }
        { scene === 'keyboard' && <KeyboardController callcenterContext={callcenterContext} /> }
        { scene === 'extensions' && <AgentsExtensionController callcenterContext={callcenterContext} setScene={setScene} /> }
        { scene === 'instructions' && <CallInstructionsController />}
      </div>
      <div className="dialpad-actions">
        { !['trying', 'calling', 'ringing', 'in-call'].includes(callcenterContext.callStatusEvent) && 
          <span className="btn-action call" onClick={() => callcenterContext.instance.startCall()}>{t('phone_callcenter.main_screen.general.call')}</span> 
        }
        { callcenterContext.callStatusEvent === 'ringing' && 
          <span className="btn-action call" onClick={() => callcenterContext.instance.acceptCall()}>{t('phone_callcenter.main_screen.general.answer')}</span>
        }
        { ['trying', 'calling', 'ringing', 'in-call'].includes(callcenterContext.callStatusEvent) && 
          <span className="btn-action hangup" onClick={() => callcenterContext.instance.hangup()}>{t('phone_callcenter.main_screen.general.hang_up')}</span>
        }
      </div>
    </div>
  );
}

export default MainScreen;