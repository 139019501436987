import { FC } from "react"
import { Container, Row, Col, Nav, TabContent, TabPane } from "react-bootstrap"
import Loading from "../../../components/loading"
import FormConfigWhatsapp from "../components/formConfigWhatsapp"
import FormConfigClient from "../components/formConfigClient"
import styles from "./styles"
import FormConfigInstagramController from "../components/formConfigInstagram/indexController"
import FormConfigContractController from "../components/formConfigContract/indexController"
import FormConfigFacebookController from "../components/formConfigFacebook/indexController"
import FormConfigCompanyController from "../components/formConfigCompany/indexController"
import FormConfigDomainController from "../components/formConfigDomain/indexController"
import FormConfigAgents from "../components/formConfigAgents/indexController"
import FormConfigHistoricController from "../components/formConfigHistoric/indexController"
import { IHomeViewClient } from "./indexModel"
import FormConfigVozxpressController from "../components/formConfigVozxpress/indexController"

const ViewClient: FC<IHomeViewClient> = (props) => {
    return (
        <Container fluid className="nopadding">
            <Loading visibility={props.isLoading}></Loading>
            <div className="wrapper" style={{ paddingLeft: '1vw' }}>
                <div className="content pt-0 ps-0" >
                    <Row className="nopadding mt-3" style={{ marginBottom: '1vh' }}>
                        <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0vw', marginBottom: '2vh', paddingLeft: '0.5vh' }} >
                            <div className="nopadding" style={{ fontSize: '18px', fontFamily: 'Montserrat', color: '#1E1E1E', cursor: 'pointer' }} onClick={props.listOfHomeClients}>
                                <span style={{ paddingRight: '0.5vw' }}>
                                    <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                        <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg>
                                </span>
                                Voltar para a listagem
                            </div>
                        </Col>
                    </Row>
                    <div style={{ backgroundColor: '#F9F9F9', marginRight: '1vw', borderRadius: 3, marginBottom: '1vh' }}>
                        <Row className="nopadding">
                            <Col md={7} style={{ padding: '1.2vh 2vh' }}>
                                <div className="d-flex align-items-center">
                                    { props.getContractStatusIcon(props.statusIcon ? props.statusIcon : props.state.contract_status_id) }
                                    <div className="ps-3" style={{ display: 'flex', flexDirection: 'column'}}>
                                        <div style={{ font: 'var(--px17_24_Bold-font)' }}>{props.clientName}</div>
                                        <div style={{ font: 'var(--px15_19-font)', color: '#707070', marginTop: '-0.3vh' }}>{props.state.email}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ backgroundColor: '#F9F9F9', padding: '2vh', marginRight: '1vw', borderRadius: 3 }}>
                        <div style={{ marginRight: '-1vw' }}>
                            <Row className="nopadding ps-0">
                                <Col md={2} style={{ marginTop: '0vh', marginLeft: '-0.5vw', minWidth: '200px' }}>
                                    <Nav variant="pills" className="flex-column" style={{ backgroundColor: 'white', padding: '0.8vh', borderRadius: 3 }}>
                                        {props.opcoes.filter(item => item.show).map((opcao, index) => {
                                            const divStyle = props.activeTab === opcao.tabkey ? { ...styles.navItemActive } : { ...styles.navItem };
                                            divStyle['marginBottom'] = index === props.opcoes.length - 1 ? '0' : '0.5vh';

                                            return (
                                                <div onClick={() => props.handleTabChange(opcao.tabkey)} style={divStyle}>
                                                    {props.activeTab === opcao.tabkey ? opcao.iconActive : opcao.icon}
                                                    <span style={props.activeTab === opcao.tabkey ? styles.spanActive : styles.span}>{opcao.title}</span>
                                                </div>
                                            );
                                        })}
                                    </Nav>
                                </Col>
                                <Col style={{ paddingLeft: 0 }}>
                                    <TabContent>
                                        <TabPane eventKey={props.activeTab} className={"show active"}>
                                            <div style={{ backgroundColor: 'white', padding: '0 2vh 2vh 2vh', borderRadius: 3 }}>
                                                {
                                                    props.activeTab === 'cliente' &&
                                                    <FormConfigClient data={props.currentCompany} getCompanyById={props.getCompanyById} setClientName={props.setClientName}/>
                                                }
                                                {
                                                    props.activeTab === 'whatsapp' &&
                                                    <FormConfigWhatsapp data={props.currentWhatsAppById} company_data={props.currentCompany} token={props.values.token.value} company_id={props.state.id} getWhatsAppById={props.getWhatsAppById} isEditAllowed={props.isEditAllowed(props.activeTab)} />
                                                }
                                                {
                                                    props.activeTab === 'vozxpress' &&
                                                    <FormConfigVozxpressController currentCompany={props.currentCompany} data={props.aux} new_data={props.employeesAccount} accounts={props.accountsVx} currentPage={props.updateListFunction} updateListAfterDelete={props.updateListAfterDelete} callback={props.callback} isEditAllowed={props.isEditAllowed(props.activeTab)} />
                                                }
                                                {
                                                    props.activeTab === 'facebook' &&
                                                    <FormConfigFacebookController data={props.currentFacebookPages} company_data={props.currentCompany} token={props.values.token.value} company_id={props.state.id} cnpj={props.state.cnpj} updateListAfterDelete={props.updateListAfterDelete} getFacebookById={props.getFacebookById} isEditAllowed={props.isEditAllowed(props.activeTab)} />
                                                }
                                                {
                                                    props.activeTab === 'instagram' &&
                                                    <FormConfigInstagramController data={props.currentInstagramPages} company_data={props.currentCompany} token={props.values.token.value} company_id={props.state.id} cnpj={props.state.cnpj} updateListAfterDelete={props.updateListAfterDelete} getInstagramById={props.getInstagramById} isEditAllowed={props.isEditAllowed(props.activeTab)} />
                                                }
                                                {
                                                    props.activeTab === 'contrato' &&
                                                    <FormConfigContractController data={props.currentCompany} company_data={props.currentCompany} token={props.values.token.value} company_id={props.state.id} cnpj={props.state.cnpj} getCompanyById={props.getCompanyById} isEditAllowed={props.isEditAllowed(props.activeTab)} setStatusIcon={props.setStatusIcon} />
                                                }
                                                {
                                                    props.activeTab === 'agentes' && 
                                                    <FormConfigAgents data={props.currentAgents} company_data={props.currentCompany} token={props.values.token.value} callback={props.callbackAgents} isEditAllowed={props.isEditAllowed(props.activeTab)} company_id={props.state.id} setIsLoading={props.setIsLoading}/>
                                                }
                                                {
                                                    props.activeTab === 'domain' &&
                                                    <FormConfigDomainController data={props.currentFacebookPages} cnpj={props.state.cnpj} updateListAfterDelete={props.updateListAfterDelete} companyId={props.currentCompany?.company_id} isEditAllowed={props.isEditAllowed(props.activeTab)} />
                                                }
                                                {
                                                    props.activeTab === 'empresa' &&
                                                    <FormConfigCompanyController data={props.dataCompany} isActiveCompany={props.isActiveCompany} data_subdomain={props.state} company_data={props.currentCompanyPages} token={props.values.token.value} company_id={props.state.id} cnpj={props.state.cnpj} getCompany={props.getCompany} />
                                                }
                                                {
                                                    props.activeTab === 'historico' &&
                                                    <FormConfigHistoricController setIsLoading={props.setIsLoading} company_data={props.currentCompany} token={props.values.token.value} company_id={props.state.id} cnpj={props.state.cnpj} updateListFunction={props.updateListFunction} />
                                                }
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ViewClient