import React, { FC } from "react";

import { Modal, Row, Container, Col, Button, ListGroup, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import InfiniteScroll from "react-infinite-scroll-component";
import { IModalListTickets } from "./indexModel";
import allStyles from "./styles";
import { TicketStatus } from "../../../../../core/enums/ticket-status";
import ChannelLabel from "../../../components/channelLabel";
import "./styles.scss"

const ModalListTickets: FC<IModalListTickets> = (props) => {
    return (
        <Modal
            show={props.modalShow}
            onHide={props.hide}
            aria-labelledby=""
            size="xl"
            centered={true}
            dialogClassName="modal-unlimited modal-list-tickets"
        >
            <Modal.Header
                closeButton
                style={{ marginTop: "1%", marginBottom: "-1%" }}
            >
                <Modal.Title style={allStyles.textBlack}>
                    { 
                        props.params?.status === undefined || props.params?.status === null?
                            props.t('dashboard.modal_list_tickets.all_tickets'):
                        props.params?.status === TicketStatus.New?
                            props.t('dashboard.modal_list_tickets.new_tickets'):
                            props.t('dashboard.modal_list_tickets.tickets_progress')
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ marginBottom: "2%" }}
            >
                <Container style={{ overflow: 'hidden' }}>
                    <Row>
                        <ListGroup variant="flush" className="px-0">
                            <ListGroup.Item className="TicketsRowCollums" style={{ marginRight: '30px' }}>
                                <Row name="ListOfConsumersHead">
                                    <Col md={1} className="d-flex nopadding justify-content-start">
                                        {props.t("home_tickets.list_tickets.headers.id")}
                                    </Col>
                                    <Col md={3} className="d-flex nopadding justify-content-start ps-3">
                                        <p>{props.t('dashboard.modal_list_tickets.transhipment_date')}</p>
                                    </Col>
                                    <Col md={3} className="d-flex nopadding justify-content-start">
                                        {props.t("home_tickets.list_tickets.headers.requester")}
                                    </Col>
                                    <Col md={2} className="d-flex nopadding justify-content-start">
                                        {props.t("home_tickets.list_tickets.headers.subject")}
                                    </Col>
                                    <Col md={2} className="d-flex nopadding justify-content-center">
                                        {props.t("home_tickets.list_tickets.headers.channel")}
                                    </Col>
                                    <Col md={1} className="d-flex nopadding justify-content-end">
                                        {props.t("home_tickets.list_tickets.headers.status")}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        {
                            props.tickets.length > 0 ?
                                <div id="ListOfTickets" className="px-0" style={{ overflowY: 'auto', height: '56vh' }}>
                                    <InfiniteScroll
                                        dataLength={props.tickets.length}
                                        next={props.updateListFunction}
                                        hasMore={true}
                                        loader={<h4> </h4>}
                                        scrollableTarget={"ListOfTickets"}
                                    // onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfConsumers) }}
                                    >
                                        {
                                            <ListGroup variant="flush" className="TicketsRow" >
                                                {props.tickets.map((item, index) => {
                                                    let date: Date | null = null;
                                                    let hour: string = null;
                                                    let newDate: Date | null = null;

                                                    if (item.updated_level2_at) {
                                                        newDate = new Date(item.updated_level2_at); // data de criação
                                                    }
                                                    date = newDate;
                                                    hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;

                                                    let channels = "N/A";
                                                    let channelId = item.channel_id;
                                                    let status = "N/A";

                                                    switch (item.status_id) {
                                                        case TicketStatus.Open:
                                                            status = 'A';
                                                            break;
                                                        case TicketStatus.Resolved:
                                                            status = 'R';
                                                            break;
                                                        case TicketStatus.Pending:
                                                            status = 'P';
                                                            break;
                                                        case TicketStatus.OnHold:
                                                            status = 'E';
                                                            break;
                                                        case TicketStatus.New:
                                                            status = 'N';
                                                            break;
                                                        case TicketStatus.Abandoned:
                                                            status = 'B';
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    switch (item.channel_id) {
                                                        case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a':
                                                            // channels = 'E-mail';
                                                            channels = props.t("channels_names.email");
                                                            break;
                                                        case '6977996d-11d1-44cc-a863-2e1d9c24e046':
                                                            // channels = 'Telefonia';
                                                            channels = props.t("channels_names.telephony");
                                                            break;
                                                        case '33be81d8-88ac-4e08-bb4d-cf69fd23267f':
                                                            // channels = 'WhatsApp';
                                                            channels = props.t("channels_names.whatsapp");
                                                            break;
                                                        case 'aa1cea93-23de-46aa-af14-d6f766acf5c8':
                                                            // channels = 'Facebook';
                                                            channels = props.t("channels_names.facebook");
                                                            break;
                                                        case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2':
                                                            // channels = 'Instagram';
                                                            channels = props.t("channels_names.instagram");
                                                            break;
                                                        case '4a7b71bb-6432-4794-ad11-f978b067871d':
                                                            // channels = 'Webchat';
                                                            channels = props.t("channels_names.webchat");
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070', marginRight: '20px' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} key={"keyAll" + index.toString()} >
                                                            <Row className='TicketsRowHeight'>
                                                                <Col md={1} className="align-self-center nopadding" style={{ marginRight: '3rem' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    #{item.ticket_reference_id}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                #{item.ticket_reference_id}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={3} className={'align-self-start nopadding p-3'}>
                                                                    {date ?
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {props.formatedDate(date)} - {hour}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {props.formatedDate(date)} - {hour}
                                                                            </span>
                                                                        </OverlayTrigger> :
                                                                        <>{props.t('dashboard.modal_list_tickets.na')}</>
                                                                    }
                                                                </Col>
                                                                <Col md={3} className={'align-self-center nopadding'}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.requester_user != null ?
                                                                                        <>{item.requester_user.name} {item.requester_user.lastname ? item.requester_user.lastname : ""}</>
                                                                                        :
                                                                                        <>N/A</>
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item.requester_user && item.requester_user.name ? item.requester_user.name + (item.requester_user.lastname ? " " + item.requester_user.lastname : "") : <>N/A</>}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={2} className={'align-self-center nopadding'}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {(item.subject != null) ?
                                                                                        <>{item.subject}</>
                                                                                        : null
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item.subject ? item.subject : 'N/A'}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={2} className={'d-flex align-self-center justify-content-center nopadding'}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {channels != null ?
                                                                                    <>{channels}</>
                                                                                    // ChannelLabel(channels, channelId, { color: '#fff' })
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {channels != null ?
                                                                                // <>{channels}</>
                                                                                ChannelLabel("", channelId)
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col md={1} className='d-flex align-self-center justify-content-end'>
                                                                    {status === "R" ?
                                                                        <Button style={{ backgroundColor: '#707070', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {props.t("ticket_status.resolved")}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.resolved")}</span>
                                                                            </OverlayTrigger>
                                                                        </Button>
                                                                        :
                                                                        status === "P" ?
                                                                            <Button style={{ backgroundColor: '#DE2450', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            {props.t("ticket_status.pending")}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.pending")}</span>
                                                                                </OverlayTrigger>
                                                                            </Button>
                                                                            :
                                                                            status === "E" ?
                                                                                <Button style={{ backgroundColor: '#FBBD4E', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id='tooltip'>
                                                                                                {props.t("ticket_status.on_hold")}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.on_hold")}</span>
                                                                                    </OverlayTrigger>
                                                                                </Button>
                                                                                :
                                                                                status === "A" ?
                                                                                    <Button style={{ backgroundColor: '#4A69BD', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id='tooltip'>
                                                                                                    {props.t("ticket_status.open")}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.open")}</span>
                                                                                        </OverlayTrigger>
                                                                                    </Button>
                                                                                    :
                                                                                    status === "N" ?
                                                                                        <Button style={{ backgroundColor: '#17a589', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                            <OverlayTrigger
                                                                                                key='top'
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip id='tooltip'>
                                                                                                        {props.t("ticket_status.new")}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.new")}</span>
                                                                                            </OverlayTrigger>
                                                                                        </Button>
                                                                                        :
                                                                                        status === "B" ?
                                                                                            <Button style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                                <OverlayTrigger
                                                                                                    key='top'
                                                                                                    placement='top'
                                                                                                    overlay={
                                                                                                        <Tooltip id='tooltip'>
                                                                                                            {props.t("ticket_status.abandoned")}
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.abandoned")}</span>
                                                                                                </OverlayTrigger>
                                                                                            </Button> :
                                                                                            null
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );

                                                })}
                                            </ListGroup>
                                        }
                                    </InfiniteScroll>
                                </div>
                                :
                                <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                        <Col md={1} className="d-flex justify-content-center">
                                            <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </Col>
                                    </Row>
                                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '5%', backgroundColor: '#F9F9F9' }}>
                                        <Col md={4}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{props.t('home_consumers.list_of_consumers.no_results').slice(0, -1)}</p>
                                        </Col>
                                    </Row>
                                </div>
                        }
                    </Row>
                    <Row
                        style={allStyles.textFontGlay}
                        className="d-flex justify-content-center mt-4"
                    >
                        <Col md={6} className="d-flex justify-content-start">
                            <Button
                                // type="submit"
                                size="lg"
                                className="buttonBlue"
                                style={{ width: "50%" }}
                                onClick={props.exportTicketsCsvConfirmation}
                                disabled={props.isLoading}
                            >
                                {props.t('dashboard.modal_list_tickets.export')}
                            </Button>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                            <Button
                                size="lg"
                                className="buttonWhite"
                                style={{ width: "50%" }}
                                onClick={props.hide}
                            >
                                {props.t('dashboard.modal_list_tickets.close')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ModalListTickets;
