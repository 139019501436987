import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IconFingerprint, IconShieldCheckFilled } from '@tabler/icons-react'
import Loading from '../../../../../components/loading'
import styles from '../styles';
// import ModalEditarAccountController from '../../modalEditVozxpress/indexController';


const ListConfigVozxpress = ({
    isLoading,
    isEditAllowed,
    datas,
    popoverOptions
}) => {


    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div  className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={styles.textRowCollums}>
                        <Row name="ListOfAgentsHead">

                            <Col md={3} className="nopadding">
                                <p>Nome</p>
                            </Col>
                            <Col md={4} className="nopadding">
                                <p>E-mail</p>
                            </Col>
                            <Col md={3} className="nopadding">
                                <p>Conta no VX</p>
                            </Col>

                            <Col md={1} className='nopadding'>
                                {isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    datas !== undefined ?
                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {datas
                                    .slice()
                                    .sort((a, b) => {
                                        const nameA = a.name ? a.name.toLowerCase() : 'n/a';
                                        const nameB = b.name ? b.name.toLowerCase() : 'n/a';
                                        return nameA.localeCompare(nameB);
                                    })
                                    .map((item, index) => {
                                        return (
                                            <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                <Row className='TicketsRowHeight'>
                                                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.name ?
                                                                            <>{item?.name}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.name? item.name: <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.email != null ?
                                                                            <>{item.email}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.email? item.email: <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>Sim</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.has_vx_account? <>Sim</> : <>Não</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>É o user no VX</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                               <span>
                                                                    {item.is_vx_user ? <> <IconShieldCheckFilled /></> : null}
                                                                </span> 
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>Possui integração sem senha</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.has_token_integration ? <> <IconFingerprint /></> : null}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={1} className='d-flex justify-content-end' style={{marginLeft:'2.3rem'}} >
                                                        {
                                                            isEditAllowed &&
                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverOptions(item)}>
                                                                <Button className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <circle cx="12" cy="12" r="1" />
                                                                        <circle cx="12" cy="19" r="1" />
                                                                        <circle cx="12" cy="5" r="1" />
                                                                    </svg>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        }
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            }
                        </div>
                    :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '2%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, adicione uma atendente</p>
                                </Col>
                            </Row>
                        </div>
                }
                {/* <ModalEditarAccountController
                show={showModalEdit}
                handleClose={handleCloseModalEdit}
                data={currentAgentData}
            /> */}
            </div>
        </>
    );

}

export default ListConfigVozxpress;