import './styles.scss';
import { FC } from 'react';
import { ListOfConsumersInterface } from './indexModel';
import FilteredViewBarController from '../../components-fixed/searchComponent/filteredViewBar/indexController';
import CustomTableListController from '../../../../components/customTableList/indexController';
// import ModalConsumersSimilarDataController from '../../homeConsumers/unificationConsumers/modalConsumersSimilarData/indexController';
import ModalConsumerController from '../components/modalConsumer/indexController';
import ModalDeleteConsumerController from '../components/modalDeleteConsumer/indexController';
import ModalExportConsumerController from '../components/modalExportConsumer/indexController';
import { IconUsers } from '@tabler/icons-react';

const ListOfConsumers: FC<ListOfConsumersInterface> = (props) => {
  return (
    <div className="list-consumers-component">
      {/* { props.showConsumerSimilarData && <ModalConsumersSimilarDataController originRedirectPage='consumer_page' visibility={props.showConsumerSimilarData} similarConsumers={props.similarConsumers} currentConsumer={props.currentConsumerForSimilarData} /> } */}
      { props.showModalConsumer && <ModalConsumerController show={props.showModalConsumer} hide={props.closeModalConsumer} /> }
      { props.showModalDeleteConsumer && <ModalDeleteConsumerController show={props.showModalDeleteConsumer} hide={props.closeModalDeleteConsumer} /> }
      { props.showModalExportConsumer && <ModalExportConsumerController show={props.showModalExportConsumer} hide={props.closeModalExportConsumer} /> }

      <div className="list-consumers-page-info">
        { props.filters?.length > 0 && 
          <FilteredViewBarController pageType={props.pageType} filters={props.filters} filterMode="simple" clearSearch={props.clearSearchConsumers} clearSpecificFilter={props.clearSpecificFilter} />
        }
      </div>
      <div className="list-consumers-page-content">
        <CustomTableListController 
          component='consumers'
          text_empty_list={props.t('no_consumers')}
          text_loading_list={props.t('loading_consumers')}
          text_error_list={props.t('error_loading_consumers')}
          icon_entity_list={<IconUsers className='tabler-icon-entity' />}
          headers={props.listHeaders}
          data={props.listData}
          paginationDetails={props.paginationDetails}
          fetchMore={props.getConsumersPagination}
          isLoading={props.isLoading}
          errorLoading={props.errorLoading}
          popoverItemAction={props.popoverItemAction}
          reloadData={props.reloadConsumers}
          handleItemClick={props.openModalEditConsumer}
          hasItemCheckSelection={true}
          selectItem={props.selectItem}
          selectAllItems={props.selectAllItems}
          isSelectedItem={props.isSelectedItem} 
          selectedItemsLength={props.selectedItemsLength}
          hasSomeSelected={props.hasSomeSelected}
          hasSomeSelectedCheck={props.hasSomeSelectedCheck}
          isAllSelectedItems={props.isAllSelectedItems}
          hasItemIconInfo={true}
          itemIconInfoTooltip={props.t('icon_agent_tooltip')}
          hasExportAll={props.pageType === 'consumers'}
          hasExportSelected={true}
          hasDeleteSelected={true}
          exportAll={props.exportAll}
          exportSelected={props.exportSelected}
          deleteSelected={props.deleteSelected}
          clearSelected={props.clearSelected}
          clearPageSelected={props.clearPageSelected}
        />
      </div>
    </div>
  );
};

export default ListOfConsumers;
