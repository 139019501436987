import { Button } from 'react-bootstrap';
import './styles.scss'
import InputMask from "react-input-mask";
import { useEffect, useState } from 'react';

const InputTimeNew = ({
    index, 
    start_time,
    end_time,
    removeItemDataBase,
    idNewItem,
    handleStartTime,
    handleEndTime,
    t
}: { 
    index: number, 
    removeItemDataBase: any, 
    start_time?: string, 
    end_time?: string, 
    idNewItem?: any, 
    handleStartTime?: any, 
    handleEndTime?: any, 
    t?: any }) => {
    return (
        <div className='container-input' id={idNewItem}>
            <div className='container-col-inputs'>
                <div>
                    <div className='container-title'>{t("home_departments.schedule_service_tab.start")}</div>
                    <InputMask
                        mask="99:99"
                        value={start_time}
                        disabled={start_time ? true : false}
                        onChange={handleStartTime}
                        placeholder="08:00"
                    >
                        {(inputProps) => (
                            <input {...inputProps} type="text" disabled={start_time ? true : false} name='start_time' id={`start_time_${index}`} className='container-input'/>
                        )}
                    </InputMask>
                </div>
            </div>
            <div className='container-col-inputs'>
                <div>
                    <div className='container-title'>{t("home_departments.schedule_service_tab.end")}</div>
                    <InputMask
                        mask="99:99"
                        value={end_time}
                        disabled={end_time ? true : false}
                        onChange={handleEndTime}
                        placeholder="12:00"
                    >
                        {(inputProps) => (
                            <input {...inputProps} type="text" disabled={end_time ? true : false} name='end_time' id={`end_time_${index}`} className='container-input'/>
                        )}
                    </InputMask>
                </div>
            </div>
            <div className='container-col-button'>
                <Button id={"*ID*" + `${start_time + "_" + end_time + "_" + idNewItem}`} className='container-button' onClick={(e) => removeItemDataBase(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                </Button>
            </div>
        </div>
    )
}

export default InputTimeNew