import { useEffect, useState } from "react"
import ServiceModalNew from "."
import UserService from "../../../../../../services/user-service"
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import InputTimeControllerNew from "../inputTimeNew/indexController"

const ServiceModalNewController = ({
    show,
    onHide,
    setItems,
    items,
    setIndex,
    index,
    setStartTime,
    setEndTime,
    dayOfWeek,
    removeItemDataBse,
    handleCloseSave,
    warning,
    setInputId,
    t,
    blockButtom
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const AppRequesterConst = new AppRequesterController()

    /**
     * exibe os horários já cadastrados
     * no modal de cadastro de horários
     */
    useEffect(() => {
        const controller = new AbortController()
        if (show) {
            getSchedulesDay(controller, dayOfWeek)
        }
    }, [dayOfWeek, show])

    /**
     * Retorna os horário de acordo com o dia da 
     * semana que é passado
     * @param controller 
     */
    const getSchedulesDay = async (controller: AbortController, dayOfWeek: string) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal

        const schedules = []

        await AppRequesterConst.Get(
            `/company/day-opening-hour/${dayOfWeek}`, { headers, signal },
            (response: Object) => {},
            (data: any) => {
                if (data.status == 200) {
                    if (data.data.data.schedules !== null) {
                        data.data.data.schedules?.map((item, idx) => {
                            const parsedItem = JSON.parse(item)
                            schedules.push(<InputTimeControllerNew t={t} index={idx} setStartTime={setStartTime} setEndTime={setEndTime} start_time={parsedItem.start_time} end_time={parsedItem.end_time} removeItemDataBase={removeItemDataBse} idNewItem={uuidv4()} key={uuidv4()} setInputId={setInputId}/>)
                        })
                    } else {
                        setIndex(index + 1)
                        schedules.push(<InputTimeControllerNew t={t} index={index} setStartTime={setStartTime} setEndTime={setEndTime} removeItemDataBase={removeItemDataBse} idNewItem={uuidv4()} key={uuidv4()} setInputId={setInputId}/>)
                    }
                    setItems(schedules)
                }
            },
            (error: any) => {

            }, navigate, dispatch, setIsLoading, { }
        )
    }

    const addItems = () => {
        setIndex(index + 1);
        setItems([...items, <InputTimeControllerNew t={t} index={index} setStartTime={setStartTime} setEndTime={setEndTime} removeItemDataBase={removeItemDataBse} idNewItem={uuidv4()} key={uuidv4()} setInputId={setInputId}/>]);
    }

    /**
     * Exibe o dia da semana 
     * no modal
     * @param dayOfWeek dayOfWeek
     * @returns 
     */
    const daysOfWeek = (dayOfWeek) => {
        let day = 'N/A'

        switch(dayOfWeek) {
            case 'sunday':
                day = `${t("home_departments.schedule_service_tab.days.sun")}`;
                break;
            case 'monday':
                day = `${t("home_departments.schedule_service_tab.days.mon")}`;
                break;
            case 'tuesday':
                day = `${t("home_departments.schedule_service_tab.days.tue")}`;
                break;
            case 'wednesday':
                day = `${t("home_departments.schedule_service_tab.days.wed")}`;
                break;
            case 'thursday':
                day = `${t("home_departments.schedule_service_tab.days.thu")}`;
                break;
            case 'friday':
                day = `${t("home_departments.schedule_service_tab.days.fri")}`;
                break;
            case 'saturday':
                day = `${t("home_departments.schedule_service_tab.days.sat")}`;
                break;
            default:
                break;
        }

        return day
    }

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16)
            })
    }
    
    return (
        <ServiceModalNew
            show={show}
            items={items}
            addItems={addItems}
            dayOfWeek={dayOfWeek}
            daysOfWeek={daysOfWeek}
            handleCloseSave={handleCloseSave}
            warning={warning}
            onHide={onHide}
            t={t}
            blockButtom={blockButtom}
        />
    )
}

export default ServiceModalNewController