import allStyles from './styles';
import { Row, Col, InputGroup, Button, Form, FormControl, Tab, Tabs } from 'react-bootstrap';
import { Formik } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowCallNotificationBar,
    setShowAlertFeedback,
    getShowAcquirePlatformComponent,
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import CallNotificationBar from '../../components/callNotificationBar';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import HomeQuickMessageController from './quickMessage/indexController';
import SurveyTabController from './surveyTab/indexController';
import { useSelector } from 'react-redux';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import AutomaticDistributionController from './automaticDistribution/indexController';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import ChatbotSurveyTabController from './chatbotSurveyTab/indexController';
import HumanSurveyTabController from './humanSurveyTab/indexController';
import NotificationTicketTabController from './notificationTicket/indexController';
import TicketFinalizationController from './ticketFinalization/indexController';

const HomeConfigurationsTickets = ({
    t,
    isLoading,
    values,
    setIsLoading,
    convertMinToHour,
    company,
}) => {
    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div className="wrapper">
                {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubTickets} className="nopadding"></SidebarController> */}
                <div className="content ps-0 pt-0"  style={{ overflow: 'auto', overflowY: 'hidden'}}>
                    <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                    <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                    {/* <Row className="nopadding mt-3 ps-3">
                        <Col md={7} style={{ marginBottom: '2vh' }}>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('configurations_ticket.general.welcome_main')}</div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('configurations_ticket.general.welcome_sub')}</div>
                            </Row>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ButtonListNotificationController />
                            <ButtonStatusAgent />
                        </Col>
                    </Row> */}
                    <Row className="mx-4">
                        <Tabs defaultActiveKey={"automatic_distribution"} id="channelsTabs" style={{ padding: 0 }}>

                            <Tab eventKey={"automatic_distribution"} title={t('configurations_ticket.general.automatic_distribution')}>
                                <AutomaticDistributionController
                                    company={company}
                                />
                            </Tab>
                            <Tab eventKey={"notification_ticket"} title={t('configurations_ticket.general.ticket_notification')}>
                                <NotificationTicketTabController company={company} />
                            </Tab>
                            <Tab eventKey={"quick_messages"} title={t('configurations_ticket.general.quick_messages')}>
                                <HomeQuickMessageController values={values} />
                            </Tab>
                            {company && company?.ticket_life_time !== null ?
                                <Tab eventKey={"chatbot-survey"} title={t('configurations_ticket.general.csat_chatbot')}>
                                    <ChatbotSurveyTabController company={company}/>
                                    {/* <ChatbotSurveyTabController lifeTime={lifeTime} lifeTimeLevelOne={company?.ticket_life_time_level_one} enableNpsLevelOne={company?.enable_nps_level_one} enableBotCSAT={company?.configs?.enable_check_nps_bot_answer} npsWaitTimeLevelOne={company?.nps_wait_time_level_one} npsBotInitialMessage={company?.nps_bot_initial_message} npsBotFinalMessage={company?.nps_bot_final_message} npsBotInvalidResponse={company?.configs?.nps_bot_invalid_answer_message} consumerDowntimeHour={company?.consumer_downtime} hourWaitSurvey={company?.configs?.nps_n1_time_to_await_response} enableInactivityMessage={company?.configs?.send_inactivity_message} inactivityMessage={company?.configs?.inactivity_message}/> */}
                                </Tab>
                                :
                                <></>
                            }
                            {company && company?.ticket_life_time !== null ?
                                <Tab eventKey={"human-survey"} title={t('configurations_ticket.general.csat_human')}>
                                    <HumanSurveyTabController company={company} />
                                    {/* <HumanSurveyTabController lifeTime={lifeTime} enableNps={company?.enable_nps} enableNpsAbandoned={company?.configs?.enable_nps_abandoned} enableAgentCSAT={company?.configs?.enable_check_nps_agent_answer} npsWaitTime={company?.nps_wait_time} npsAgentInitialMessage={company?.nps_agent_initial_message} npsAgentFinalMessage={company?.nps_agent_final_message} npsAgentInvalidResponse={company?.configs?.nps_agent_invalid_answer_message} /> */}
                                </Tab>
                                :
                                <></>                         
                            }
                            {/* {lifeTime !== null ?
                                <Tab eventKey={"survey_service"} title={t('configurations_ticket.general.survey_service')}>
                                    <SurveyTabController lifeTime={lifeTime} lifeTimeLevelOne={lifeTimeLevelOne} enableNps={enableNps} enableNpsLevelOne={enableNpsLevelOne} npsWaitTime={npsWaitTime} npsWaitTimeLevelOne={npsWaitTimeLevelOne} consumerDowntime={consumerDowntime} npsBotInitialMessage={npsBotInitialMessage} npsBotFinalMessage={npsBotFinalMessage} npsAgentInitialMessage={npsAgentInitialMessage} npsAgentFinalMessage={npsAgentFinalMessage} />
                                </Tab>
                                :
                                <></>
                            } */}
                            <Tab eventKey={"ticketTime"} title={t('configurations_ticket.ticket_finalization_tab.title')} >
                                <TicketFinalizationController setIsLoading={setIsLoading} company={company} convertMinToHour={convertMinToHour} values={values} />
                            </Tab>
                        </Tabs>
                    </Row>

                </div >
            </div >
        </>
    );
}

export default HomeConfigurationsTickets;
