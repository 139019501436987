import mitt from 'mitt';
import { SearchComponentFilterType } from '../../pages/homeApp/components-fixed/searchComponent/indexModel';
import { TicketInterface } from '../../pages/homeApp/homeTickets2/viewTicket/indexModel';

type Events = {
  // ######################################################
  // ################# EVENTOS DE POPUPS ##################
  'close-item-action-popover-list-tickets': void;
  'close-item-action-popover-view-ticket': void;
  'close-item-action-popover-list-consumers': void;
  'close-item-action-popover-list-agents': void;
  'close-item-action-popover-modal-list-agent-consumers': void;
  'close-item-action-popover-modal-list-tasks': void;
  'close-item-action-popover-list-tasks': void;
  'close-item-action-popover-list-active-trigger-schedules': void;
  'close-item-action-popover-list-active-trigger-types': void;
  'close-item-action-popover-list-sectors': void;
  'close-item-action-popover-list-tags-ticket': void;
  'close-item-action-popover-list-tags-consumer': void;
  'close-action-popover-file-model-file-consumers': void;

  // ######################################################
  // ################# EVENTOS DE MODAIS ##################
  
  'open-modal-delete-ticket': { ticketId: string, ticketReferenceId: string };
  'open-modal-reopen-ticket': { ticketId: string, ticketReferenceId: string };
  'open-modal-recovery-deleted-ticket': { ticketId: string, ticketReferenceId: string };
  'open-modal-send-ticket-copy': { ticketId: string, ticketReferenceId: string, subject: string };
  'open-modal-list-tasks': { ticketId: string, ticketReferenceId: string };
  'open-modal-export-all-tickets': { filter?: SearchComponentFilterType };
  'open-modal-manager-tickets': { ticket_ids: string[] };

  'open-modal-consumer': { userId: string };
  'open-modal-consumer-similar-data': { current: any, duplicated: any[] };
  'open-modal-delete-consumer': { userId: string, consumerName?: string }[];
  'open-modal-export-consumer': { list: { userId: string, consumerName?: string }[], filter?: { [key: string]: string } };

  'open-modal-agent': { userId: string };
  'open-modal-delete-agent': { userId: string, agentName?: string }[];
  'open-modal-list-agent-consumers': { userId: string, agentName: string };

  'open-modal-task': { taskId: string };
  'open-modal-delete-task': { taskId: string, date_limit: string, status: string, description: string, requesterName: string, assignedName: string };

  'open-modal-active-trigger': { id: string, statusId: string };
  'open-modal-report-active-trigger': T_EmitterOpenModalReportActiveTrigger;
  'open-modal-cancel-active-trigger': T_EmitterOpenModalCancelActiveTrigger;
  'open-modal-trigger-type': { id: string, name: string, active: boolean };
  'open-modal-change-status-trigger-type': { triggerTypeId: string, triggerTypeName: string, newStatus: boolean };

  'open-modal-sector': { id: string, name: string, active: boolean };
  'open-modal-change-status-sector': { sectorId: string, sectorName?: string, newStatus: boolean };

  'open-modal-tag': { id: string, name: string, active: boolean };
  'open-modal-delete-tag': { id: string, name?: string }[];
  'open-modal-change-status-tag': { ids: { id: string, name?: string }[], newStatus: boolean };

  // ######################################################
  // ################# EVENTOS DE SOCKET ##################
  'socket-event::list-tickets:ticket-update': { ticket: TicketInterface };
  'socket-event::list-tickets:ticket-deleted': string[];
  'socket-event::list-tickets:ticket-sector-changed': { ticket: TicketInterface };
  'socket-event::list-tickets:who-is-viewing': { ticket_id: string; who_is_viewing: string; who_is_viewing_user_id: string;};
  'socket-event::list-tickets:new-ticket': { ticket: TicketInterface };

  'socket-event::view-ticket:ticket-update': TicketInterface;
  'socket-event::view-ticket:ticket-messages-update': { ticket_id: string };

  'socket-event::update-counters': T_EmitterSocketUpdateCounters;
  // ######################################################

  // ######################################################
  // ################## EVENTOS DIVERSOS ##################
  'app-loaded': boolean;
  'i18n-status': { status: string };
  'clear-all-search-custom-select': void;
  // ######################################################
}

const emitter = mitt<Events>();

export default emitter;

export type T_EmitterOpenModalCancelActiveTrigger = {
  id: string;
  title: string;
  channel_id: string;
  employee: string;
  date_scheduled: string;
  number_of_recipients: number;
  type: string;
  status_id: string;
};

export type T_EmitterOpenModalReportActiveTrigger = {
  id: string;
  title: string;
  channel_id: string;
  date_scheduled: string;
  number_of_recipients: number;
  type: string;
};

export type T_EmitterSocketUpdateCounters = { 
  action: 'increment' | 'decrement';
  last_assigned_employee_id: string;
  total: number;
  total_new_tickets: number;
  total_not_read_tickets: number;
};
