import { FC, useEffect, useState } from 'react';
import ModalCreateTicket from './index';
import { CreateTicketResponse, FetchCompanyChannels, ModalCreateTicketControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useViewTicketContext } from '../../../../../core/context/view-ticket-context';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandWhatsapp,
  IconMail,
  IconMessages,
  IconPhone,
  IconProgressAlert,
  IconProgressCheck,
  IconProgressHelp,
  IconUserBolt
} from '@tabler/icons-react';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { FetchUserConsumerInterface } from '../../viewTicket/indexModel';
import { UserConsumerInterface } from '../../../homeConsumers2/listOfConsumers/indexModel';
import { Channels } from '../../../../../core/enums/channels';
import { getIdUser, getNameUser } from '../../../../../store/user';
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import {
  CustomFieldInterface,
  EmployeeInterface,
  FetchCustomFieldsInterface,
  FetchEmployeesInterface,
  FetchSectorsInterface,
  FetchTagsInterface,
  FormattedCustomFieldInterface,
  SectorInterface,
  TagInterface
} from '../../viewTicket/components/detailsComponent/indexModel';
import { convertToRaw, EditorState } from 'draft-js';
import { FileWithPath } from 'react-dropzone';
import { CurrentAudioInfo } from '../../viewTicket/components/chatEditorComponent/components/toolbarAudioRecorder/indexModel';
import draftToHtml from 'draftjs-to-html';
import { CustomFile, CustomFileAttributes } from '../../viewTicket/components/chatEditorComponent/components/toolbarFileUploader/indexModel';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { verifyCode } from '../../../../../services/codeCxpressInterface';
import { CustomFieldEntity, CustomFieldsType } from '../../../../../core/enums/custom-field-type';
import { QuickMessageInterface, WhatsappMessageInterface } from '../../viewTicket/components/chatEditorComponent/components/popupTemplateMessages/indexModel';
import emitter from '../../../../../core/shared/emitter';
import { getEmployeeSectors } from '../../../../../store/employee';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 20;
const channelIconSize = 20;
const channelIconColor = '#707070';

const ModalCreateTicketController: FC<ModalCreateTicketControllerInterface> = (props) => {
  const { t } = useTranslation('ModalCreateTicket');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentTicket
  } = useViewTicketContext();

  const loggedUserId = useSelector(getIdUser);
  const loggedUserName = useSelector(getNameUser);
  const loggedUserSectors = useSelector(getEmployeeSectors);

  const [showAllSteps, setShowAllSteps] = useState(false);
  const [step, setStep] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  // Assunto do ticket
  const [subject, setSubject] = useState('');

  // Consumidor do ticket
  const [selectedConsumer, setSelectedConsumer] = useState<CustomSelectOptionType>(null);
  const [selectedConsumerData, setSelectedConsumerData] = useState<UserConsumerInterface>(null);
  const [consumersList, setConsumersList] = useState<UserConsumerInterface[]>([]);
  const [consumersListPage, setConsumersListPage] = useState(1);
  const [hasMoreConsumers, setHasMoreConsumers] = useState(false);
  const [isLoadingConsumersSelect, setIsLoadingConsumersSelect] = useState(false);

  // Canal do ticket
  const [selectedChannel, setSelectedChannel] = useState<CustomSelectOptionType>(null);
  const [allowedChannels, setAllowedChannels] = useState<{ id: string; name: string; }[]>([]);
  const [companyChannels, setCompanyChannels] = useState<{ id: string; name: string; }[]>([]);
  const [isLoadingCompanyChannels, setIsLoadingCompanyChannels]= useState(false);

  // Agente do ticket
  const [selectedAgent, setSelectedAgent] = useState<CustomSelectOptionType>(null);
  const [agentsList, setAgentsList] = useState<EmployeeInterface[]>([]);
  const [agentsListPage, setAgentsListPage] = useState(1);
  const [hasMoreAgents, setHasMoreAgents] = useState(false);
  const [isLoadingAgentsSelect, setIsLoadingAgentsSelect] = useState(false);

  // Setor do ticket
  const [selectedSector, setSelectedSector] = useState<CustomSelectOptionType>(null);
  const [sectorsList, setSectorsList] = useState<SectorInterface[]>([]);
  const [sectorsListPage, setSectorsListPage] = useState(1);
  const [hasMoreSectors, setHasMoreSectors] = useState(false);
  const [isLoadingSectorsSelect, setIsLoadingSectorsSelect] = useState(false);

  // Status do ticket
  const [selectedStatus, setSelectedStatus] = useState<CustomSelectOptionType>(null);

  // Seguidor do ticket
  const [selectedFollower, setSelectedFollower] = useState<CustomSelectOptionType[]>(null);
  const [followersList, setFollowersList] = useState<EmployeeInterface[]>([]);
  const [followersListPage, setFollowersListPage] = useState(1);
  const [hasMoreFollowers, setHasMoreFollowers] = useState(false);
  const [isLoadingFollowersSelect, setIsLoadingFollowersSelect] = useState(false);

  // Tags do ticket
  const [selectedTag, setSelectedTag] = useState<CustomSelectOptionType[]>(null);
  const [tagsList, setTagsList] = useState<TagInterface[]>([]);
  const [tagsListPage, setTagsListPage] = useState(1);
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);

  // CustomFields
  const [formattedCustomFields, setFormattedCustomFields] = useState<FormattedCustomFieldInterface[]>([]);
  const [customFieldsList, setCustomFieldsList] = useState<CustomFieldInterface[]>([]);
  // const [currentCustomFieldsListPage, setCurrentCustomFieldsListPage] = useState(1);
  const [customFieldsValuesObject, setCustomFieldsValuesObject] = useState<any>({});
  const [/*isLoadingCustomFieldsSelect*/, setIsLoadingCustomFieldsSelect] = useState(false);
  const [hasMandatoryField, setHasMandatoryField] = useState(false);

  // CC/CCO
  const [listCC, setListCC] = useState<string[]>([]);
  const [listCCO, setListCCO] = useState<string[]>([]);

  // Template message
  const [templateMessage, setTemplateMessage] = useState<{ quickMessage: QuickMessageInterface; whatsappMessage: WhatsappMessageInterface }>(null);

  // Channels
  const channelsList = [
    { id: Channels.Email, value: t("channels.email"), icon: <IconMail stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
    { id: Channels.Facebook, value: t("channels.facebook"), icon: <IconBrandFacebook stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
    { id: Channels.Instagram, value: t("channels.instagram"), icon: <IconBrandInstagram stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
    { id: Channels.Telefonia, value: t("channels.telephony"), icon: <IconPhone stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
    { id: Channels.Webchat, value: t("channels.webchat"), icon: <IconMessages stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
    { id: Channels.WhatsApp, value: t("channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={channelIconSize} color={channelIconColor} /> },
  ];

  // Status
  const statusList = [
    { id: TicketStatus.New, value: t('status.new') },
    { id: TicketStatus.Open, value: t('status.open') },
    { id: TicketStatus.Pending, value: t('status.pending') },
    { id: TicketStatus.OnHold, value: t('status.on_hold') }
  ];

  // Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState<CustomFile[]>([]);
  const [hasFileUploader, setHasFileUploader] = useState(false);
  const [audioRecordedFiles, setAudioRecordedFiles] = useState<File[]>([]);
  const [currentAudioInfo, setCurrentAudioInfo] = useState<CurrentAudioInfo>();

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      await Promise.all([
        fetchConsumers(false, 1),
        fetchSectors(false, 1),
        fetchFollowers(false, 1),
        fetchTags(false, 1),
        fetchCustomFields(false, 1),
        fetchCompanyChannels(),
      ]).then(() => {
        setLoading(false);
      });
    }

    fetchAll();
  }, []);

  useEffect(() => {
    setSelectedAgent(null);
    if (selectedSector?.id) {
      fetchAgents(false, 1, null, selectedSector.id);
    } else {
      fetchAgents(false, 1);
    }
  }, [selectedSector?.id]);

  useEffect(() => {
    if (selectedConsumer) {
      const consumerData = consumersList.find(consumer => consumer.user_id === selectedConsumer.id);
      if (consumerData) {
        setSelectedConsumerData(consumerData);
        const consumerChannelsID = consumerData.channels?.filter(consumerChannel => companyChannels?.map(item => item?.id).includes(consumerChannel?.id)) || [];
        setAllowedChannels(consumerChannelsID);
        
        if (consumerChannelsID?.length === 1) {
          const channel = channelsList.find(item => item.id === consumerChannelsID[0].id);
          setSelectedChannel({ id: channel?.id, value: channel?.value, icon: channel?.icon });
        } else {
          setSelectedChannel(null);
        }
      } else {
        setAllowedChannels([]);
        setSelectedChannel(null);
        setSelectedConsumerData(null);
      }
    } else {
      setAllowedChannels([]);
      setSelectedChannel(null);
      setSelectedConsumerData(null);
    }
  }, [selectedConsumer]);

  useEffect(() => {
    if (customFieldsList?.length > 0) {
      const newArray: FormattedCustomFieldInterface[] = [];
      const newValues = {};
      
      let tempHasMandatoryField = false;

      customFieldsList.forEach(item => {
        const customField = {
          id: item.id,
          name: item.name,
          options: formatCustomFieldOptions(item.custom_field_type_id, item.options),
          placeholder: item.placeholder,
          required: item.required,
          custom_field_type_id: item.custom_field_type_id,
          value: [],
          setValue: setCustomFieldValue
        }

        if (customField.required) {
          tempHasMandatoryField = true;
        }

        newArray.push(customField);
      });

      setHasMandatoryField(tempHasMandatoryField);
      setFormattedCustomFields(newArray);
      setCustomFieldsValuesObject(newValues);
    }
  }, [customFieldsList]);

  const handleSubmit = () => {
    if (isValidForm()) {
      const ticketForm = {
        subject,
        channel_id: selectedChannel.id,
        cc: listCC || [], // pegar cc de dentro do componente modal
        cco: listCCO || [], // pegar cco de dentro do componente modal
        requester_user_id: selectedConsumer.id,
        status_id: selectedStatus?.id || TicketStatus.New
      }

      const isEmptyEditor = !editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled';

      if (!isEmptyEditor) {
        const contentState = editorState.getCurrentContent();
        const html = draftToHtml(convertToRaw(contentState));
        
        // Regex para alterar o target de links para abrir em nova aba
        const regexLinkTarget = /target="_self"/g;
        const formattedHtml = html.replace(regexLinkTarget, 'target="_blank"').replace(/\n$/, '');
  
        ticketForm['initial_message'] = formattedHtml;
      }

      if (templateMessage?.quickMessage) {
        ticketForm['quick_message_id'] = templateMessage.quickMessage.id;
        ticketForm['initial_message'] = templateMessage.quickMessage.message;
      } else if (templateMessage?.whatsappMessage) {
        ticketForm['template_message_id'] = templateMessage.whatsappMessage.id;
        ticketForm['initial_message'] = templateMessage.whatsappMessage.message;
      }

      if (selectedAgent?.id) {
        ticketForm['assigned_employee_user_id'] = selectedAgent.id;
      }

      if (selectedSector?.id) {
        ticketForm['sector_id'] = selectedSector.id;
      }

      if (selectedSector?.id) {
        ticketForm['sector_id'] = selectedSector.id;
      }

      if (selectedFollower?.length > 0) {
        ticketForm['followers_id'] = selectedFollower.map(item => item.id);
      }

      if (selectedTag?.length > 0) {
        ticketForm['tags'] = selectedTag.map(item => item.id);
      }

      if (files.length > 0) {
        ticketForm['attachment_ids'] = files.map((item: FileWithPath & CustomFileAttributes) => item.bucketId) // Passar um state pra dentro do chatEditor para salvar IDs dos uploads
      }

      const customFields = Object.keys(customFieldsValuesObject || {});

      if (customFields.length > 0) {
        const fieldsValues = [];
        customFields.forEach(key => {
          const item = { custom_field_id: key, value: [] };
          const value = customFieldsValuesObject[key];
  
          if (value) {
            item.value = Array.isArray(value) ? value.map(item => item.value) : [value];
          }
          fieldsValues.push(item);
        });
  
        ticketForm['custom_fields'] = fieldsValues;
      }

      // if (data.id_quick_message) {
      //   socketData['id_quick_message'] = data.id_quick_message;
      // }

      // Adicionar Campos customizados?

      const headers = UserService.getHeaders();
      setLoadingCreate(true);
      AppRequesterConst.Post('/ticket', ticketForm, { headers },
        (response: any) => {},
        (response: CreateTicketResponse) => {
          dispatch(setShowAlertFeedback({ 
            message: t("success_create_ticket", { ticket_reference_id: response.data?.ticket_reference_id }), 
            signalIcon: true, 
            visibility: true 
          }));
          setTimeout(() => {
            setLoadingCreate(false);
            props.hide(true);
          }, 50);
        },
        (error: any) => {
          setLoadingCreate(false);
          if (error?.response?.data?.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
          } else {
            console.log(error);
          }
        },
        navigate, dispatch, setLoading, () => { },
      );
    }
  }

  const changeStep = (new_step: number) => { // Adicionar step de campo customizado
    if (new_step !== step) {
      setStep(new_step);
    }
  }

  const handleShowAllSteps = (value: boolean) => {
    if (!loadingCreate) {
      setShowAllSteps(value);
    }
  }

  const isLoadingFileUpload = () => {
    return files?.map(item => !!item?.isLoading).includes(true) || false;
  }

  const isValidForm = () => {
    let valid = true;

    if (!subject || subject?.length === 0) {
      valid = false;
    }

    if (!selectedConsumer?.id) {
      valid = false;
    }

    if (!selectedChannel?.id) {
      valid = false;
    }

    if (!isValidCustomFields()) {
      valid = false;
    }

    return valid && !isLoadingFileUpload();
  }

  const isValidField = (step: number) => {
    if (step === 1) {
      return subject?.length > 0;
    } else if (step === 2) {
      return selectedConsumer?.id?.length > 0 && selectedChannel?.id?.length > 0;
    } else if (step === 3) {
      return selectedAgent?.id?.length > 0 && selectedSector?.id?.length > 0 && selectedStatus?.id?.length > 0 &&
        selectedFollower?.length > 0 && selectedTag?.length > 0;
    } else if (step === 4) {
      return isValidCustomFields(true);
    } else if (step === 5) {
      return editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled' || files.length > 0;
    } else {
      return true;
    }
  }

  const filledForm = () => {
    let filled = false;

    if (subject?.length > 0) {
      filled = true;
    }

    if (selectedConsumer?.id) {
      filled = true;
    }

    if (selectedChannel?.id) {
      filled = true;
    }

    if (selectedAgent?.id) {
      filled = true;
    }

    if (selectedSector?.id) {
      filled = true;
    }

    if (selectedStatus?.id) {
      filled = true;
    }

    if (selectedFollower?.length > 0) {
      filled = true;
    }

    if (selectedTag?.length > 0) {
      filled = true;
    }

    if (editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled') {
      filled = true;
    }

    const customFieldsKeys = Object.keys(customFieldsValuesObject || {});

    if (customFieldsKeys.length > 0) {
      for (let i = 0; i < customFieldsKeys.length; i++) {
        const key = customFieldsKeys[i];

        if (customFieldsValuesObject[key]) {
          filled = true;
          break;
        }
      }
    }

    return filled;
  }

  const getStepStatusIcon = (step: number) => {
    const FieldOk = (
      <OverlayTrigger overlay={<Tooltip>{t('tooltip_ok')}</Tooltip>}>
        <IconProgressCheck className='icon-wizard-step-ok' />
      </OverlayTrigger>
    );
    const FieldEmpty = (
      <OverlayTrigger overlay={<Tooltip>{step === 4 ? t('tooltip_required_custom_field') : t('tooltip_required')}</Tooltip>}>
        <IconProgressAlert className='icon-wizard-step-required' />
      </OverlayTrigger>
    );

    if ([1, 2].includes(step)) {
      return isValidField(step) ? FieldOk : FieldEmpty;
    } else if (step === 4 && hasMandatoryField) {
      return isValidCustomFields() ? FieldOk : FieldEmpty;
    } else {
      if (isValidField(step)) {
        return FieldOk;
      } else {
        return (
          <OverlayTrigger overlay={<Tooltip>{t('tooltip_optional')}</Tooltip>}>
            <IconProgressHelp className='icon-wizard-step-ok' />
          </OverlayTrigger>
        );
      }
    }
  }

  // Request company channels
  const fetchCompanyChannels = async () => {
    const headers = UserService.getHeaders();
    const config = { headers };

    await AppRequesterConst.Get(
      '/company/channels', config,
      () => {},
      (response: FetchCompanyChannels) => {
        if (response.status === 200) {
          setCompanyChannels(response.data.channels);
        } else {
          setCompanyChannels([]);
        }
      },
      () => { // catch
        setCompanyChannels([]);
      },
      navigate, dispatch, setIsLoadingCompanyChannels, {}
    );
  }

  // Request consumer

  const fetchConsumers = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || consumersListPage, isEmployee: false };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setConsumersListPage(newPage);

    await AppRequesterConst.Get('/user', config,
      (_response: any) => {},
      (response: FetchUserConsumerInterface) => {
        if (response.status === 200 && response.data.users.length > 0) {
          if (response.data.users.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreConsumers(true);
          } else {
            setHasMoreConsumers(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...consumersList, ...response.data.users];
            setConsumersList(new_array);
          } else {
            setConsumersList(response.data.users || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setConsumersList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingConsumersSelect, {}
    );
  }

  const fetchConsumersPagination = async () => {
    if (hasMoreConsumers) {
      const newPage = consumersListPage + 1;
      setConsumersListPage(newPage);

      await fetchConsumers(true, newPage);
    }
  }

  const fetchSearchConsumers = (term: string) => {
    if (term) {
      fetchConsumers(false, 1, term);
    } else {
      fetchConsumers(false, 1, '');
    }
  }

  // ===================================
  // Request Agents

  const fetchAgents = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string, sectorId?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || agentsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    if (sectorId) {
      params['sector'] = sectorId;
    }

    const config = { headers, params };

    setAgentsListPage(newPage);

    await AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreAgents(true);
          } else {
            setHasMoreAgents(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsList, ...response.data.employees];
            setAgentsList(new_array);
          } else {
            setAgentsList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setAgentsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingAgentsSelect, {}
    );
  }

  const fetchAgentsPagination = async () => {
    if (hasMoreAgents) {
      const newPage = agentsListPage + 1;
      setAgentsListPage(newPage);

      await fetchAgents(true, newPage);
    }
  }

  const fetchSearchAgents = (term: string) => {
    if (term) {
      fetchAgents(false, 1, term);
    } else {
      fetchAgents(false, 1, '');
    }
  }
  
  // ===================================
  // Request Sectors

  const fetchSectors = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || sectorsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setSectorsListPage(newPage);

    await AppRequesterConst.Get('/sector/actives', config, 
      (_response: any) => {},
      (response: FetchSectorsInterface) => {
        if (response.status === 200 && response.data.sectors.length > 0) {
          if (response.data.sectors.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreSectors(true);
          } else {
            setHasMoreSectors(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...sectorsList, ...response.data.sectors];
            setSectorsList(new_array);
          } else {
            setSectorsList(response.data.sectors || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setSectorsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingSectorsSelect, {}
    );
  }

  const fetchSectorsPagination = async () => {
    if (hasMoreSectors) {
      const newPage = sectorsListPage + 1;
      setSectorsListPage(newPage);

      await fetchSectors(true, newPage);
    }
  }

  const fetchSearchSectors = (term: string) => {
    if (term) {
      fetchSectors(false, 1, term);
    } else {
      fetchSectors(false, 1, '');
    }
  }

  // ===================================
  // Request Followers

  const fetchFollowers = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || followersListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setFollowersListPage(newPage);

    await AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreFollowers(true);
          } else {
            setHasMoreFollowers(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...followersList, ...response.data.employees];
            setFollowersList(new_array);
          } else {
            setFollowersList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setFollowersList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingFollowersSelect, {}
    );
  }

  const fetchFollowersPagination = async () => {
    if (hasMoreFollowers) {
      const newPage = followersListPage + 1;
      setFollowersListPage(newPage);

      await fetchFollowers(true, newPage);
    }
  }

  const fetchSearchFollowers = (term: string) => {
    if (term) {
      fetchFollowers(false, 1, term);
    } else {
      fetchFollowers(false, 1, '');
    }
  }

  // ===================================
  // Request Tags

  const fetchTags = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || tagsListPage, is_active: true };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setTagsListPage(newPage);

    await AppRequesterConst.Get('/tag', config, 
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTags(true);
          } else {
            setHasMoreTags(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...tagsList, ...response.data.tags];
            setTagsList(new_array);
          } else {
            setTagsList(response.data.tags || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTagsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingTagsSelect, {}
    );
  }

  const fetchTagsPagination = async () => {
    if (hasMoreTags) {
      const newPage = tagsListPage + 1;
      setTagsListPage(newPage);

      await fetchTags(true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  // ===================================
  // Request CustomFields

  const fetchCustomFields = async (isInfiniteScroll: boolean, newPage: number) => {
    // const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage };
    const headers = UserService.getHeaders();
    const config = { headers };

    // setCurrentCustomFieldsListPage(newPage);
    await AppRequesterConst.Get(
      `/custom-fields/entity-type/${CustomFieldEntity.Ticket}`,
      config,
      (_response: any) => {},
      (response: FetchCustomFieldsInterface) => {
        if (response.status === 200 && response.data?.custom_fields.length > 0) {
          setCustomFieldsList(response.data.custom_fields);
        } else {
          setCustomFieldsList([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingCustomFieldsSelect, { }
    );
  }

  // ===================================

  const resetWizard = () => {
    if (!loadingCreate) {
      emitter.emit('clear-all-search-custom-select');
      setSubject('');
      setSelectedChannel(null);
      setSelectedConsumer(null);
      setSelectedAgent(null);
      setSelectedSector(null);
      setSelectedStatus(null);
      setSelectedFollower([]);
      setSelectedTag([]);
      setEditorState(EditorState.createEmpty());
    }
  }

  const useSameTicketConsumer = () => {
    if (currentTicket && currentTicket.requester_user) {
      setSelectedConsumer({ id: currentTicket.requester_user.user_id, value: currentTicket.requester_user.name });
    }
  }

  const showButtonSameTicketConsumer = () => {
    if (currentTicket && currentTicket?.requester_user) {
      if (currentTicket?.requester_user?.user_id === selectedConsumer?.id) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const currentUserAccept = (type: 'agent' | 'follower') => {
    if (type === 'agent') {
      setSelectedAgent({ id: loggedUserId, value: loggedUserName });
    } else if (type === 'follower') {
      setSelectedFollower(prevState => {
        if (!prevState?.map(item => item.id).includes(loggedUserId)) {
          return [{ id: loggedUserId, value: loggedUserName }, ...prevState];
        } else {
          return [...prevState];
        }
      });
    }
  }

  const isEmployeeSector = () => {
    if (selectedSector?.id) {
      if (loggedUserSectors?.map(item => item.sector_id).includes(selectedSector.id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const isSelectedEmployee = (type: 'agent' | 'follower') => {
    if (type === 'agent') {
      return selectedAgent?.id === loggedUserId;
    } else if (type === 'follower') {
      return selectedFollower?.map(item => item.id).includes(loggedUserId);
    }
  }

  const setCustomFieldValue = (custom_field_id: string, value: any) => {
    setCustomFieldsValuesObject((customFieldsValuesObject: any) => ({
      ...customFieldsValuesObject,
      [custom_field_id]: value,
    }));
  }

  const isValidCustomFields = (onlyValues?: boolean) => {
    let valid = true;
    let validValues = false;

    formattedCustomFields.forEach(customField => {
      if (customField.required && !customFieldsValuesObject[customField.id]) {
        valid = false;
      }

      if (onlyValues) {
        validValues = !!customFieldsValuesObject[customField.id];
      }
    });

    if (onlyValues) {
      return !loading && validValues;
    } else {
      return !loading && valid;
    }
  }

  const formatCustomFieldOptions = (custom_field_type_id: string, options: any[]) => {
    const selectType = [CustomFieldsType.Dropdown.toString(), CustomFieldsType.DropdownMultiselect.toString()];
    if (selectType.includes(custom_field_type_id) && options) {
      return formatDataSelect(options, 'custom-field');
    } else {
      return null;
    }
  }

  const getConsumersSelect = () => {
    return {
      consumersList: formatDataSelect(consumersList, 'consumer'),
      selectedConsumer,
      setSelectedConsumer,
      isLoadingConsumersSelect,
      fetchConsumersPagination,
      fetchSearchConsumers,
      hasMoreConsumers,
    };
  }

  const getChannelSelect = () => {

    const filteredChannels = channelsList.filter(channel => allowedChannels?.map(item => item.id).includes(channel.id));

    return {
      channelsList: filteredChannels,
      selectedChannel,
      setSelectedChannel,
      isLoadingCompanyChannels,
    }
  }

  const getStatusSelect = () => {
    return {
      statusList,
      selectedStatus,
      setSelectedStatus,
    }
  }

  const getAgentsSelect = () => {
    return {
      agentsList: formatDataSelect(agentsList, 'agent'),
      selectedAgent,
      setSelectedAgent,
      isLoadingAgentsSelect,
      fetchAgentsPagination,
      fetchSearchAgents,
      hasMoreAgents,
    };
  }

  const getSectorsSelect = () => {
    return {
      sectorsList: formatDataSelect(sectorsList, 'sector'),
      selectedSector,
      setSelectedSector,
      isLoadingSectorsSelect,
      fetchSectorsPagination,
      fetchSearchSectors,
      hasMoreSectors,
    };
  }

  const getFollowersSelect = () => {
    return {
      followersList: formatDataSelect(followersList, 'follower'),
      selectedFollower,
      setSelectedFollower,
      isLoadingFollowersSelect,
      fetchFollowersPagination,
      fetchSearchFollowers,
      hasMoreFollowers,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList: formatDataSelect(tagsList, 'tag'),
      selectedTag,
      setSelectedTag,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTags,
    };
  }

  const getCustomFieldsData = () => {
    return {
      customFieldsList,
      formattedCustomFields,
      setCustomFieldValue,
      customFieldsValuesObject,
      isValidCustomFields,
      hasMandatoryField
    }
  }

  const formatDataSelect = (data: any[], type: 'consumer' | 'agent' | 'sector' | 'follower' | 'tag' | 'custom-field'): { id: string, value: string}[] => {
    const formatted = [];

    data.forEach(item => {
      if (type === 'consumer' || type === 'agent' || type === 'follower') {
        const dataOption = { id: item.user_id, value: item.name };

        if (item.user_id === loggedUserId) {
          dataOption['icon'] = (
            <OverlayTrigger placement='right' overlay={<Tooltip>{t('icon_you')}</Tooltip>}>
              <IconUserBolt stroke={1.5} size={channelIconSize} color={channelIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
          dataOption['isOptionSystem'] = true;
        } else if (item.created_by_employee === 'true') {
          dataOption['icon'] = (
            <OverlayTrigger placement='right' overlay={<Tooltip>{t('assigned_label')}</Tooltip>}>
              <IconUserBolt stroke={1.5} size={channelIconSize} color={channelIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      } else if (type === 'sector' || type === 'tag') {
        formatted.push({ id: item.id, value: item.name });
      } else if (type === 'custom-field') {
        formatted.push({ id: item, value: item });
      }
    });

    return formatted;
  }

  return (
    <ModalCreateTicket 
      t={t}
      loading={loading}
      loadingCreate={loadingCreate}
      show={props.show}
      hide={props.hide}
      resetWizard={resetWizard}
      handleSubmit={handleSubmit}
      showAllSteps={showAllSteps}
      handleShowAllSteps={handleShowAllSteps}
      step={step}
      changeStep={changeStep}
      subject={subject}
      setSubject={setSubject}
      isValidForm={isValidForm}
      isValidField={isValidField}
      filledForm={filledForm}
      getStepStatusIcon={getStepStatusIcon}
      getConsumersSelect={getConsumersSelect}
      getChannelSelect={getChannelSelect}
      getStatusSelect={getStatusSelect}
      getAgentsSelect={getAgentsSelect}
      getSectorsSelect={getSectorsSelect}
      getFollowersSelect={getFollowersSelect}
      getTagsSelect={getTagsSelect}
      getCustomFieldsData={getCustomFieldsData}
      useSameTicketConsumer={useSameTicketConsumer}
      showButtonSameTicketConsumer={showButtonSameTicketConsumer}
      fullscreen={fullscreen}
      setFullscreen={setFullscreen}

      editorState={editorState}
      setEditorState={setEditorState}
      files={files}
      setFiles={setFiles}
      hasFileUploader={hasFileUploader}
      setHasFileUploader={setHasFileUploader}
      audioRecordedFiles={audioRecordedFiles}
      setAudioRecordedFiles={setAudioRecordedFiles}
      currentAudioInfo={currentAudioInfo}
      setCurrentAudioInfo={setCurrentAudioInfo}
      selectedConsumerData={selectedConsumerData}
      listCC={listCC}
      setListCC={setListCC}
      listCCO={listCCO}
      setListCCO={setListCCO}
      setTemplateMessage={setTemplateMessage}
      currentUserAccept={currentUserAccept}
      isEmployeeSector={isEmployeeSector}
      isSelectedEmployee={isSelectedEmployee}
    />
  );
};

export default ModalCreateTicketController;
