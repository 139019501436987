import { useEffect, useState } from "react";
import ControlPanelSecurity from ".";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../store/internal";
import UserService from "../../../services/user-service";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import constsApi from "../../../services/constsApi";
import { GenerateCode2faInterface } from "./indexModel";
import { getUserControlPanelHasAppAuth, getUserControlPanelId, setUserControlPanelAuthMethodDefault, setUserControlPanelHasAppAuth } from "../../../store/user_controlpanel";
import { ErrorLoginInterface } from "../../login/indexModel";
import { getToken } from "../../../store/control_panel";
import { TokenInterfaceControlPanel } from "../../../services/requestsInterfacesModel";

const AppRequesterConst = new AppRequesterController();

const ControlPanelSecurityController = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imageQR, setImageQR] = useState(null);
  const [secret, setSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingQR, setIsLoadingQR] = useState(true);
  const [showFeedback2FA, setShowFeedback2FA] = useState(false);

  const headers = Object.assign({}, UserService.getHeaders());
  const controller = new AbortController();

  const hasAppAuth = Boolean(useSelector(getUserControlPanelHasAppAuth));
  const credentialId = useSelector(getUserControlPanelId);
  const tokenPanel = useSelector(getToken);

  const values_global: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const basic_headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer ' + tokenPanel,
  };

  useEffect(() => {
    if (!hasAppAuth) {
      generateAppCode();
    }
  }, []);

  const submitVerify2FA = async (values: { code_2fa: string }) => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    const request_body = {
      code: String(values.code_2fa),
      token: values_global.token.value,
      autenticationType: constsApi.authentication_methods.app
    };

    await AppRequesterConst.Post(
      'panel-two-factor-authentication/verify-code', request_body, { headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: 'Configurações de autenticação multifator atualizadas com sucesso!', visibility: true, signalIcon: true }));
        dispatch(setUserControlPanelHasAppAuth(true));
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ 
          message: 'Erro na verificação do código', 
          visibility: true, signalIcon: false })
        );
      }, navigate, dispatch, setIsLoading
    );
  }

  const validate2FA = (values: { code_2fa: string }) => {
    const errors = {};
    if (isNaN(Number(values.code_2fa))) {
      errors['code_2fa'] = 'O código deve conter apenas números';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'O código deve conter apenas números');
    } else if (!values.code_2fa || String(values.code_2fa).length === 0) {
      errors['code_2fa'] = 'Preencha este campo';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'Preencha este campo');
    } else if (String(values.code_2fa).length !== 6) {
      errors['code_2fa'] = 'O código deve ter 6 caracteres';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'O código deve ter 6 caracteres');
    } else {
      setShowFeedback2FA(false);
      changeDocument('feedback_code_2fa', '');
    }
    return errors;
  }

  const generateAppCode = async () => {
    try {
      setImageQR(null);
      setSecret(null);
      await AppRequesterConst.Get(
        'panel-two-factor-authentication/generate-code-aplication', { headers: basic_headers, signal: controller.signal },
        (response: Object) => {},
        (response: GenerateCode2faInterface) => {
          setImageQR(response.data.token.qr);
          setSecret(response.data.token.secret);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ 
            message: 'Erro ao tentar gerar QR Code de configuração', 
            visibility: true, signalIcon: false })
          );
          setImageQR(null);
          setSecret(null);
        }, navigate, dispatch, setIsLoading, {}
      );
    } catch (error) {
      dispatch(setShowAlertFeedback({ 
        message: 'Erro ao tentar gerar QR Code de configuração', 
        visibility: true, signalIcon: false })
      );
      setImageQR(null);
      setSecret(null);
    }
  }

  const revokeApp2FA = async () => {
    await AppRequesterConst.Put(
      `panel/invalid-app-authentication-2fa/${credentialId}`, {}, { headers: basic_headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: 'Configuração de autenticação removida com sucesso!', visibility: true, signalIcon: true }));
        dispatch(setUserControlPanelHasAppAuth(false));
        generateAppCode();
        changeDefault2FA();
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ 
          message: 'Erro ao tentar remover configuração', 
          visibility: true, signalIcon: false })
        );
      }, navigate, dispatch, setIsLoading
    );
  }

  const changeDefault2FA = async () => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    const body = {
      credencial_id: credentialId,
      authentication_method_default: constsApi.authentication_methods.email,
    };

    await AppRequesterConst.Put(
      '/panel-two-factor-authentication/set-authentication_method_default', body, { headers },
      (_response: Object) => { },
      async (_response: any) => {
        dispatch(setUserControlPanelAuthMethodDefault(body.authentication_method_default));
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        dispatch(setShowAlertFeedback({ message: 'Algo de errado aconteceu!', visibility: true, signalIcon: true }));
      },
      navigate,
      dispatch,
      setIsLoading,
    );
  }

  const openRemoveConfirmation = async () => {
    dispatch(setShowConfirmationModal({
      visibility: true,
      text: { 
        title: 'Remover configuração', 
        body:  'Tem certeza que deseja remover a autenticação multifator via aplicativo de sua conta?', 
        buttonConfirmationText: 'Sim', 
        buttonReturnText: 'Cancelar', 
        closeButton: true,
        warning: 'Você terá que configurar novamente!'
      },
      funtionConfirmation: revokeApp2FA
    }));
    return;
  }

  const onLoadQR = () => {
    setIsLoadingQR(false);
  }

  const changeDocument = (element_id: string, text: string) => {
    if (document.getElementById(element_id)) {
      if (text) {
        document.getElementById(element_id).innerHTML = text;
      } else {
        document.getElementById(element_id).innerHTML = '';
      }
    }
  }

  return (
    <ControlPanelSecurity
    showFeedback2FA={showFeedback2FA}
      submitVerify2FA={submitVerify2FA}
      validate2FA={validate2FA}
      imageQR={imageQR}
      isLoading={isLoading}
      isLoadingQR={isLoadingQR}
      onLoadQR={onLoadQR}
      openRemoveConfirmation={openRemoveConfirmation}
      secret={secret}
      hasAppAuth={hasAppAuth}
    />
  );
}

export default ControlPanelSecurityController;
