import i18n from "../../i18next";

export default class MaskController {
    // validar funcoes de cada pais
    public static maskCpfCnpj = (document_number, language?) => {
        if (language === 'PT-BR') {
            let mask = "999.999.999-999";
            if (document_number.replace(/\D/g, "").length > 11) {
                mask = "99.999.999/9999-99";
            }
            return mask;
        } else if (language === 'PT-PT') {
            let mask = "999999999";
            return mask;
        } else if (language === 'FR') {
            let mask = "999.999.999";
            return mask;
        } else if (language === 'ES') {
            let mask = "*9999999*";
            return mask;
        } else if (language === 'UK') {
            let mask = "aa**9999*9";
            if (document_number?.replace(/[^a-zA-Z0-9]/g, "").length > 11) {
                mask = "aa**9 999* 99 999";
            } else if (document_number?.replace(/[^a-zA-Z0-9]/g, "").length > 9 && document_number?.replace(/[^a-zA-Z0-9]/g, "").length <= 11) {
                mask = "aa**9 999* 999";
            } else if (document_number?.replace(/[^a-zA-Z0-9]/g, "").length === 7) {
                mask = "aa**99999";
            }
            return mask;
        }

    };

    public static maskHour = (input) => {
        // Remove tudo que não for número
        const numericInput = input.replace(/\D/g, '');

        if(numericInput.length === 0) {
            return ''
        }

        // Limita o número de caracteres a no máximo 4
        const trimmedInput = numericInput.slice(0, 4);

        // Aplica a máscara HH:mm
        let maskedTime = trimmedInput;
        if (trimmedInput.length >= 3) {
            maskedTime = `${trimmedInput.slice(0, 2)}:${trimmedInput.slice(2)}`
        }

        return maskedTime
    }

    public static maskCpfNif = (document_number: string, language?: string) => {
        if (!language) {
            language = i18n.language;
        }
        if (language.toLowerCase() === 'pt-br') {
            let mask = "999.999.999-99";
            if (document_number.replace(/\D/g, "").length === 11) {
                mask = "999.999.999-99";
            }
            return mask;
        } else if (language.toLowerCase() === 'pt-pt') {
            let mask = "999999999";
            if (document_number.replace(/\D/g, "").length === 9) {
                mask = "999999999";
            }
            return mask;
        } else if (language.toLowerCase() === 'fr') {
            let mask = "9 99 99 99 999 999 99";
            if (document_number.replace(/\D/g, "").length === 15) {
                mask = "9 99 99 99 999 999 99";
            }
            return mask;
        } else if (language.toLowerCase() === 'es') {
            let mask = "99999999a";
            if (document_number.replace(/\D/g, "").length === 9) {
                mask = "99999999a";
            }
            return mask;
        } else if (language.toLowerCase() === 'en') {
            let mask = "aa 99 99 99 a";
            if (document_number.replace(/\D/g, "").length === 9) {
                mask = "aa 99 99 99 a";
            }
            return mask;
        }
    };

    public static maskNumberPhone = (phone) => {
        let defaultMask = "+99 (99) 9999-9999";
        let mask = defaultMask;

        if (phone.replace(/\D/g, "").length === 12) {
            mask = defaultMask + "9";
        } else if (phone.replace(/\D/g, "").length > 12) {
            mask = "+99 (99) 99999-9999";
        }

        return mask;
    };


    /**
     * change mask char
     * @param value 
     * @returns 
     */
    public static maskCharPhone = (value) => {
        return this.maskCharChange(value, 12);
    }

    public static maskCharCpfCnpj = (value, language?) => {
        let length;
        switch (language) {
            case 'PT-BR':
                length = value.replace(/\D/g, "").length > 11 ? 14 : 11;
                break;
            case 'PT-PT':
                length = 9;
                break;
            case 'FR':
                length = value.replace(/\D/g, "").length === 9 ? 9 : 14;
                break;
            case 'ES':
                length = 9; 
                break;
            case 'UK':
                if (value.replace(/[^a-zA-Z0-9]/g, "").length > 11) {
                    length = 14;
                } else if (value.replace(/[^a-zA-Z0-9]/g, "").length > 9 && value.replace(/[^a-zA-Z0-9]/g, "").length <= 11) {
                    length = 11;
                } else if (value.replace(/[^a-zA-Z0-9]/g, "").length === 7) {
                    length = 7;
                } else {
                    length = 9;
                }
                break;
            default:
                length = 11; 
                break;
        }
        return this.maskCharChange(value, length, language);
    }

    private static maskCharChange = (value, length, language?) => {
        if(language === 'PT-BR') {
            if (value.replace(/\D/g, "").length === length)
                return "";
            else
                return "_";
        } else if(language === 'PT-PT'){
            if (value.replace(/\D/g, "").length === length)
                return "";
            else
                return "_";
        } else if(language === 'FR'){
            if (value.replace(/\D/g, "").length === length)
                return "";
            else
                return "_";
        } else if(language === 'ES'){
            if (value.replace(/\D/g, "").length === length)
                return "";
            else
                return "_";
        } else if(language === 'UK'){
            if (value.replace(/[^a-zA-Z0-9]/g, "").length === length)
                return "";
            else
                return "_";
        }else {
            if (value.replace(/\D/g, "").length === length)
                return "";
            else
                return "_";
        }
    }
}