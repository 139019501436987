import './styles.scss';
import { FC } from 'react';
import { PopupTemplateMessagesGeneralVariableInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../../../../../../components/loading2';
import CustomInputController from '../../../../../../../../../../components/customInput/indexController';

const PopupTemplateMessagesGeneralVariable: FC<PopupTemplateMessagesGeneralVariableInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-general-variable-component"
    >
      <Modal.Header>
        <Modal.Title>
          { props.t('title_modal') }
        </Modal.Title>
        <div className="modal-general-variable-header-actions">
          <IconX onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {(!props?.data) && <Loading blur={true} />}
        <div className="modal-general-variable-section">
          {props?.data &&
            props?.data.map((variable) => {
              return (
                <div className="modal-general-variable-item">
                  <div className="modal-general-variable-item-header">
                    <span className="modal-general-variable-item-label">{variable.alias}</span>
                  </div>
                  <CustomInputController
                    type='input'
                    value={variable.tag_value}
                    setCustomValue={props.setDataValue}
                    placeholder={variable.alias}
                    required={true}
                    id={variable.id}
                  />
                </div>
              )
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-general-variable-btn-cancel' onClick={() => props.hide()}>{props.t('btn_cancel')}</button>
        <button className='modal-general-variable-btn-save' disabled={!props.isValid()} onClick={() => props.handleSubmit()}>
          {props.t('btn_save')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupTemplateMessagesGeneralVariable;
