import './styles.scss';
import { FC } from 'react';
import { AudioPlayerInterface, formatTime } from './indexModel';
import { IconDownload, IconInfinity, IconPlayerPause, IconPlayerPlay, IconVolume, IconVolume2, IconVolume3 } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AudioPlayer: FC<AudioPlayerInterface> = (props) => {
  const showVolumeClass = props.showVolume ? 'audio-player-volume-control' : '';
  const errorLoadClass = props.errorLoad ? 'audio-player-error' : '';

  return (
    <div className="audio-player-component2">
      <div className="audio-player-info" title={props.filename}>
        <span className="audio-player-info-filename">{props.getFileInfo(props.filename).name}</span>
        <span className="audio-player-info-extension">{props.getFileInfo(props.filename).extension}</span>
      </div>
      <div className={`audio-player-content-controller ${errorLoadClass}`}>
        <span className="audio-player-duration-current">{formatTime(props.currentTime)}</span>
        <input 
          className='audio-player-input-range'
          type="range" 
          value={props.currentTime} 
          onChange={props.handleProgressChange}
          style={{ width: '100%' }}
          min={0}
          step={0.0001}
          max={Math.round(props.duration)}
        />
        { props.duration !== 0 && <span className="audio-player-duration-total">{formatTime(props.duration)}</span> }
        { props.duration === 0 && <span className="audio-player-duration-total"><IconInfinity/></span> }
      </div>
      <div className={`audio-player-actions ${showVolumeClass}`}>
        <OverlayTrigger overlay={<Tooltip>{parseInt(`${props.volume * 100}`, 10)}%</Tooltip>} show={props.showVolume || props.errorLoad ? false : undefined}>
          <span className={`audio-player-button-volume ${errorLoadClass}`} onClick={() => !props.errorLoad ? props.setShowVolume(!props.showVolume) : undefined}>
            { props.volume >= 0.5 && <IconVolume /> }
            { props.volume < 0.5 && props.volume > 0 && <IconVolume2 /> }
            { props.volume === 0 && <IconVolume3 /> }
          </span>
        </OverlayTrigger>
        {
          props.showVolume &&
          <div className="audio-player-volume-control-range">
            <input 
              className='audio-player-input-range'
              type="range" 
              value={props.volume} 
              onChange={props.handleVolumeChange}
              style={{ width: '100%' }}
              min={0}
              step={0.01}
              max={1}
              onMouseUp={props.handleCloseVolume}
            />
            <span className="audio-player-volume-current">{parseInt(`${props.volume * 100}`, 10)}%</span>
          </div>
        }
        { !props.showVolume && !props.isPlaying &&
          <span className={`audio-player-button-main ${errorLoadClass}`} onClick={props.handlePlayPause}>
            <OverlayTrigger overlay={<Tooltip>{props.t('play')}</Tooltip>} show={props.errorLoad ? false : undefined}>
              <IconPlayerPlay />
            </OverlayTrigger>
          </span>
        }
        { !props.showVolume && props.isPlaying &&
          <span className={`audio-player-button-main ${errorLoadClass}`} onClick={props.handlePlayPause}>
            <OverlayTrigger overlay={<Tooltip>{props.t('pause')}</Tooltip>} show={props.errorLoad ? false : undefined}>
              <IconPlayerPause />
            </OverlayTrigger>
          </span>
        }
        { !props.showVolume &&
          <OverlayTrigger overlay={<Tooltip>{props.t('download_file')}</Tooltip>}>
            <span className={`audio-player-button-download`} onClick={props.handleDownloadFile}>
              <IconDownload />
            </span>
          </OverlayTrigger>
        }
      </div>
      { props.errorLoad &&
        <span className="audio-player-error-load-message">{props.t('error.not_loaded')}</span>
      }
    </div>
  );
};

export default AudioPlayer;
