import { useTranslation } from "react-i18next";
import TicketFinalization from ".";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";

const TicketFinalizationController = ({
    setIsLoading,
    company,
    convertMinToHour,
    values
}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [showFeedbackTime, setShowFeedbackTime] = useState(false);
    const [showFeedbackTimeLevelOne, setShowFeedbackTimeLevelOne] = useState(false);
    const [messageFinish, setMessageFinish] = useState(company?.finish_ticket_message ? company.finish_ticket_message : '');
    const [lifeTime, setLifeTime] = useState(company?.ticket_life_time ? company.ticket_life_time : null);
    const [lifeTimeLevelOne, setLifeTimeLevelOne] = useState(company?.ticket_life_time_level_one ? company.ticket_life_time_level_one : null);

    const AppRequesterConst = new AppRequesterController();

    useEffect(() => {
        if(company) {
            setMessageFinish(company?.finish_ticket_message)
            setLifeTime(company?.ticket_life_time)
            setLifeTimeLevelOne(company?.ticket_life_time_level_one)
        }
    }, [company])

    return <TicketFinalization
        t={t}
        lifeTime={lifeTime}
        setLifeTime={setLifeTime}
        showFeedbackTime={showFeedbackTime}
        setShowFeedbackTime={setShowFeedbackTime}
        messageFinish={messageFinish}
        setMessageFinish={setMessageFinish}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        company={company}
        showFeedbackMessage={showFeedbackMessage}
        setShowFeedbackMessage={setShowFeedbackMessage}
        convertMinToHour={convertMinToHour}
        values={values}
        showFeedbackTimeLevelOne={showFeedbackTimeLevelOne}
        setShowFeedbackTimeLevelOne={setShowFeedbackTimeLevelOne}
        lifeTimeLevelOne={lifeTimeLevelOne}
        setLifeTimeLevelOne={setLifeTimeLevelOne} />

}

export default TicketFinalizationController;