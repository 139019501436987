import React, { useEffect, useState } from 'react'
import ModalEditWhatsapp from '.'
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../store/token';
import { useNavigate } from 'react-router-dom';
import { Brokers } from '../../../../../core/enums/brokers';

const ModalEditWhatsappController = ({
    show,
    onHide,
    currentWhatsappData,
    getWhatsAppById,
    isLoading
}) => {
    
    interface BrokerInterface {
        label: string,
        value: string
    }

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false)

    const [hasBot, setHasbot] = useState(false)
    const [selectedBroker, setSelectedBrokers] = useState([])

    const [onSelectBroker, setOnSelectBroker] = useState([])

    const [id, setId] = useState("")
    const [nameAccount, setNameAccount] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [tokenAuth, setTokenAuth] = useState("")
    const [sid, setSid] = useState("")
    const [botNlp, setBotNlp] = useState("")
    const [apiUrl, setApiUrl] = useState("")
    const [urlBot, setUrlBot] = useState("")
    const [postBackUrl, setPostBackUrl] = useState("")
    const [senderSid, setSenderSid] = useState("")

    const [showFeedbackNameAccount, setShowFeedbackNameAccount] = useState(false)
    const [showFeedbackPhoneNumber, setShowFeedbackPhoneNumber] = useState(false)
    const [showFeedbackTokenAuth, setShowFeedbackTokenAuth] = useState(false)
    const [showFeedbackSid, setShowFeedbackSid] = useState(false)
    const [showFeedbackBotNlp, setShowFeedbackBotNlp] = useState(false)
    const [showFeedbackUrlBot, setShowFeedbackUrlBot] = useState(false)
    const [showFeedbackApiUrl, setShowFeedbackApiUrl] = useState(false)
    const [showFeedbackPostBackUrl, setShowFeedbackPostBackUrl] = useState(false)
    const [showFeedbackSenderSid, setShowFeedbackSenderSid] = useState(false)

    const [/* isLoading */, setIsLoading] = useState(false)

    const [optionsBroker, setOptionsBroker] = useState([
        { label: "Twilio", value: Brokers.Twilio},
        { label: "Positus", value: Brokers.Positus },
        { label: "Meta", value: Brokers.Meta},
        { label: "Smarters", value: Brokers.Smarters }
    ])

    useEffect(() => {
        setShowFeedbackBotNlp(false)
        setShowFeedbackUrlBot(false)
        setShowFeedbackTokenAuth(false)
        setShowFeedbackNameAccount(false)
        setShowFeedbackPhoneNumber(false)
        setShowFeedbackSid(false)
        setShowFeedbackUrlBot(false)
        setShowFeedbackPostBackUrl(false)
        setShowFeedbackSenderSid(false)
    }, [onHide])

    useEffect(() => {
        if (currentWhatsappData) {
            setId(currentWhatsappData?.whatsAppKey?.id)
            setHasbot(currentWhatsappData?.whatsAppKey?.has_bot)
            setNameAccount(currentWhatsappData?.whatsAppKey?.subaccount_name)
            setPhoneNumber(currentWhatsappData?.whatsAppKey?.phone_number)
            setTokenAuth(currentWhatsappData?.whatsAppKey?.auth_token)
            setSid(currentWhatsappData?.whatsAppKey?.sid)
            setApiUrl(currentWhatsappData?.whatsAppKey?.api_url)
            setUrlBot(currentWhatsappData?.whatsAppKey?.bot_url)
            setPostBackUrl(currentWhatsappData?.whatsAppKey?.post_back_url)
            setBotNlp(currentWhatsappData?.whatsAppKey?.bot_cxpress?.id)
            setSenderSid(currentWhatsappData?.whatsAppKey?.sender_sid)
            // setSelectBrokerId(currentWhatsappData?.whatsAppKey?.broker_id)

            setSelectedBrokers(optionsBroker.filter(item => item?.value == currentWhatsappData?.whatsAppKey?.broker_id).map((item) => {
                return { label: item?.label, value: item?.value }
            }))
        }  
    }, [currentWhatsappData])

    const selectBrokerId = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === 'NA') {
                setOnSelectBroker([])
            } else {
                setOnSelectBroker([value[value.length - 1]])
            }
        } else {
            setOnSelectBroker([])
        }
    }

    return (
        <ModalEditWhatsapp
            show={show}
            onHide={onHide}
            nameAccount={nameAccount}
            phoneNumber={phoneNumber}
            tokenAuth={tokenAuth}
            sid={sid}
            botNlp={botNlp}
            apiUrl={apiUrl}
            urlBot={urlBot}
            postBackUrl={postBackUrl}
            senderSid={senderSid}
            setNameAccount={setNameAccount}
            setPhoneNumber={setPhoneNumber}
            setTokenAuth={setTokenAuth}
            setSid={setSid}
            setBotNlp={setBotNlp}
            setApiUrl={setApiUrl}
            setUrlBot={setUrlBot}
            setPostBackUrl={setPostBackUrl}
            setSenderSid={setSenderSid}
            hasBot={hasBot}
            setShowFeedbackNameAccount={setShowFeedbackNameAccount}
            setShowFeedbackPhoneNumber={setShowFeedbackPhoneNumber}
            setShowFeedbackTokenAuth={setShowFeedbackTokenAuth}
            setShowFeedbackSid={setShowFeedbackSid}
            setShowFeedbackBotNlp={setShowFeedbackBotNlp}
            setShowFeedbackUrlBot={setShowFeedbackUrlBot}
            setShowFeedbackPostBackUrl={setShowFeedbackPostBackUrl}
            setShowFeedbackSenderSid={setShowFeedbackSenderSid}
            values={values}
            id={id}
            dispatch={dispatch}
            navigate={navigate}
            getWhatsAppById={getWhatsAppById}
            setIsLoading={setIsLoading}
            showFeedbackNameAccount={showFeedbackNameAccount}
            showFeedbackPhoneNumber={showFeedbackPhoneNumber}
            showFeedbackTokenAuth={showFeedbackTokenAuth}
            showFeedbackSid={showFeedbackSid}
            showFeedbackApiUrl={showFeedbackApiUrl}
            showFeedbackBotNlp={showFeedbackBotNlp}
            showFeedbackUrlBot={showFeedbackUrlBot}
            showFeedbackPostBackUrl={showFeedbackPostBackUrl}
            showFeedbackSenderSid={showFeedbackSenderSid}
            optionsBroker={optionsBroker}
            setOptionsBroker={setOptionsBroker}
            selectedBroker={selectedBroker}
            setSelectedBrokers={setSelectedBrokers}
            selectBrokerId={selectBrokerId}
            onSelectBroker={onSelectBroker}
            validated={validated}
            setHasbot={setHasbot}
            isLoading={isLoading}   
        />
    )
}

export default ModalEditWhatsappController