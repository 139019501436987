import './styles.scss';
import { 
  IconBell, 
  IconBellCog, 
  IconBellRingingFilled, 
  IconBellZ, 
  IconCircle, 
  IconCircleCheckFilled, 
  IconEyeCheck, 
  IconInfoCircle, 
  IconListDetails, 
  IconSearch, 
  IconSettings, 
  IconToggleLeftFilled, 
  IconToggleRightFilled, 
  IconTrash, 
  IconX 
} from "@tabler/icons-react";
import { FC } from "react";
import { NotificationsComponentInterface } from "./indexModel";
import FadeInOut from '../../../../components/fadeInOut';
import Loading from '../../../../components/loading2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalListTasksController from '../../homeTasks2/components/modalListTasks/indexController';

const NotificationsComponent: FC<NotificationsComponentInterface> = (props) => {
  const showPopupClass = props.showPopup ? 'notifications-component-popup-opened' : '';
  const activeActionSettingsClass = props.showSettings ? 'notifications-component-header-action-active' : '';
  const emptyClass = props.notificationsList.length === 0 ? 'notifications-component-list-items-empty' : '';
  const markAllClass = props.showMarkAllConfirm ? 'notifications-component-list-mark-all-active' : '';
  const inactiveGeneralSoundNotificationClass = !props.soundGeneralNotifyTemp ? 'notifications-component-configurations-section-inactive' : '';
  const inactiveTicketSoundNotificationClass = !props.soundTicketNotificationTemp ? 'notifications-component-configurations-section-inactive' : '';
  const inactiveVisualNotificationClass = !props.visualTicketNotificationTemp ? 'notifications-component-configurations-section-inactive' : '';

  return (
    <div className="notifications-component" ref={props.popupRef}>
      { props.showModalTask && <ModalListTasksController show={props.showModalTask} hide={props.closeModalTask} /> }
      
      <OverlayTrigger placement='bottom' show={(props.hasNewNotification || props.showPopup) ? false : undefined} overlay={<Tooltip>{props.t('btn_open_default')}</Tooltip>}>
        <span className={`notifications-component-trigger ${showPopupClass}`} onClick={() => props.show()}>
          { !props.hasNewNotification && (props.hasSoundTicketNotification || props.hasSoundGeneralNotify || props.hasVisualTicketNotification) && 
            <IconBell className='notifications-component-bell' />
          }
          { !props.hasNewNotification && !props.hasSoundTicketNotification && !props.hasSoundGeneralNotify && !props.hasVisualTicketNotification &&
            <IconBellZ className='notifications-component-bell' />
          }
          { props.hasNewNotification && 
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('btn_open_new')}</Tooltip>}>
              <IconBellRingingFilled className='notifications-component-has-new' />
            </OverlayTrigger>
          }
        </span>
      </OverlayTrigger>
      <FadeInOut duration={200} show={props.showPopup} style={{ zIndex: 20 }}>
        <div className="notifications-component-popup">
          <div className="notifications-component-header">
            { !props.showSettings && <span className="notifications-component-header-title">{props.t('popup_title')}</span> }
            { props.showSettings && <span className="notifications-component-header-title">{props.t('configuration.title')}</span> }
            <div className="notifications-component-header-actions">
              <OverlayTrigger placement='left' overlay={<Tooltip>{props.t(props.showSettings ? 'configuration.btn_tooltip_close' : 'configuration.btn_tooltip_open')}</Tooltip>}>
                <IconSettings className={activeActionSettingsClass} onClick={() => props.handleShowConfig()} />
              </OverlayTrigger>
            </div>
          </div>
          {
            !props.showSettings &&
            <div className="notifications-component-container notifications-component-list">
              <div className="notifications-component-container-actions">
                <div className="notifications-component-search">
                  <IconSearch />
                  <input disabled={props.showMarkAllConfirm} value={props.searchTerm} type="text" placeholder={props.t('search_placeholder')} onChange={props.handleInputChange} onKeyDown={(e) => props.handleEnterSearch(e)} />
                  { props.isFiltered && <IconX className='notifications-component-search-clear' onClick={() => props.handleClickClearSearch()} /> }
                </div>
                <span className={`notifications-component-container-action ${markAllClass}`} onClick={() => props.setShowMarkAllConfirm(!props.showMarkAllConfirm)}>{props.t('mark_all_as_read')}</span>
              </div>
              <div className="notifications-component-container-list">
                { props.isLoading && <Loading blur={true} /> }
                <div className={`notifications-component-container-list-items ${emptyClass}`} id="notifications-component-container-list-items">
                  { props.notificationsList.length > 0 &&
                    <InfiniteScroll
                      dataLength={props.notificationsList.length}
                      next={props.fetchNotificationsPagination}
                      hasMore={props.hasMoreNotifications}
                      loader={null}
                      scrollableTarget="notifications-component-container-list-items"
                      scrollThreshold="5px"
                    >
                      {props.notificationsList.map(item => {
                        const messageNotification = props.messageNotification({
                          notification_id: item.id,
                          field_ticket_id: item?.field_ticket_id,
                          ticket_reference: item?.ticket_reference,
                          trigger_type: item?.trigger_type,
                          ticket_id: item?.ticket_id,
                          task_id: item?.task_id,
                          read_at: item?.read_at,
                          has_expired: item.has_expired
                        });

                        const notReadClass = !item.read_at ? 'notifications-component-list-item-not-read' : '';
                        const showDeleteClass = item.id === props.itemDeleteConfirmation ? 'notifications-component-item-show-delete' : '';

                        return (
                          <div className={`notifications-component-list-item ${notReadClass} ${showDeleteClass}`} key={item.id}>
                            { (!props.itemDeleteConfirmation || item.id !== props.itemDeleteConfirmation) &&
                              <>
                              <div className="notifications-component-list-item-info">
                                <span className="notifications-component-list-item-author">{item.sender_name ? item.sender_name : props.t('system_sender')}</span>
                                {messageNotification?.element}
                                <span className="notifications-component-list-item-date">
                                  {props.getFormattedDate(item.created_at)}
                                </span>
                              </div>
                              <div className="notifications-component-list-item-actions">
                                { !item.read_at && 
                                  <OverlayTrigger placement='left' overlay={<Tooltip>{props.t('btn_mark_as_read')}</Tooltip>}>
                                    <IconEyeCheck onClick={() => props.handleClickNotificationRead(item.id)} />
                                  </OverlayTrigger>
                                }
                                <OverlayTrigger placement='left' overlay={<Tooltip>{props.t('btn_delete_notify')}</Tooltip>}>
                                  <IconTrash onClick={() => props.showDeleteConfirmation(item.id, true)} />
                                </OverlayTrigger>
                              </div>
                              </>
                            }
                            { props.itemDeleteConfirmation && item.id === props.itemDeleteConfirmation &&
                              <>
                              <div className="notifications-component-list-item-info">
                                <span className="notifications-component-list-item-author">{props.t('confirm_delete_title')}</span>
                                <div className="notifications-component-list-item-message">
                                {props.t('confirm_delete_message')}
                                </div>
                                <div className="notifications-component-list-item-delete-btn">
                                  <span className="notifications-component-list-item-delete-cancel" onClick={() => props.showDeleteConfirmation(item.id, false)}>{props.t('btn_cancel')}</span>
                                  <span className="notifications-component-list-item-delete-confirm" onClick={() => props.handleConfirmDelete()}>{props.t('btn_confirm')}</span>
                                </div>
                              </div>
                              </>
                            }
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  }
                  {
                    !props.isLoading && props.notificationsList.length === 0 &&
                    <div className="notifications-component-list-empty-alert">
                      <IconListDetails />
                      <span className="notifications-component-list-empty-info">
                        {props.t('empty_list')}
                      </span>
                    </div>
                  }
                </div>
                { props.showMarkAllConfirm &&
                  <div className="notifications-component-mark-all">
                    <div className="notifications-component-mark-all-info">
                      <span className="notifications-component-mark-all-title">
                        {props.t('section_mark_all_title')}
                      </span>
                      <span className="notifications-component-mark-all-message">
                        {props.t('section_mark_all_message')}
                      </span>
                    </div>
                    <div className="notifications-component-mark-all-actions">
                      <span className="notifications-component-mark-all-btn-cancel" onClick={() => props.setShowMarkAllConfirm(false)}>{props.t('btn_cancel')}</span>
                      <span className="notifications-component-mark-all-btn-confirm" onClick={() => props.handleMarkAllAsRead()}>{props.t('section_mark_all_confirm')}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {
            props.showSettings &&
            <div className="notifications-component-container notifications-component-configurations">
              <div className="notifications-component-configurations-sections">
                <div className="notifications-component-configurations-section">
                  <div className="notifications-component-configurations-section-header">
                    <span className="notifications-component-configurations-section-header-title">
                      {props.t('configuration.expire_notification_title')}
                      <OverlayTrigger overlay={<Tooltip>{props.t('configuration.expire_notification_tooltip')}</Tooltip>}>
                        <IconInfoCircle />
                      </OverlayTrigger>
                    </span>
                  </div>
                  <span className="notifications-component-configurations-section-subtitle">
                    {props.t('configuration.expire_notification_subtitle')}
                  </span>
                  <div className="notifications-component-configurations-section-range-form">
                    <span className="notifications-component-configurations-range-limit">{props.t('configuration.expire_notification_limit_min')}</span>
                    <div className="notifications-component-configurations-range-input">
                      <OverlayTrigger overlay={<Tooltip>{props.t(props.expireDays > 1 ? 'configuration.expire_notification_days' : 'configuration.expire_notification_day', { expireDays: props.expireDays })}</Tooltip>}>
                        <span className="notifications-component-range-input-value-display" style={props.getDaysExpirePositionStyle()}>
                          {props.expireDays}
                        </span>
                      </OverlayTrigger>
                      <input 
                        className='expire-days-input-range'
                        type="range" 
                        value={props.expireDays} 
                        onChange={props.handleExpireDaysChange}
                        style={{ width: '100%' }}
                        min={1}
                        step={1}
                        max={60}
                      />
                    </div>
                    <span className="notifications-component-configurations-range-limit">{props.t('configuration.expire_notification_limit_max')}</span>
                  </div>
                </div>
                <div className={`notifications-component-configurations-section ${inactiveGeneralSoundNotificationClass}`}>
                  <div className="notifications-component-configurations-section-header">
                    <span className="notifications-component-configurations-section-header-title">
                      {props.t('configuration.general_sound_notification_title')}
                      <OverlayTrigger overlay={<Tooltip>{props.t('configuration.general_sound_notification_tooltip')}</Tooltip>}>
                        <IconInfoCircle />
                      </OverlayTrigger>
                    </span>
                    <span className="notifications-component-configurations-section-header-action">
                      { !props.soundGeneralNotifyTemp && 
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_active_sound_notification')}</Tooltip>}>
                          <IconToggleLeftFilled className="configuration-header-action-inactive" onClick={() => props.setSoundGeneralNotifyTemp(true)} /> 
                        </OverlayTrigger>
                      }
                      { props.soundGeneralNotifyTemp && 
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_disable_sound_notification')}</Tooltip>}>
                          <IconToggleRightFilled className="configuration-header-action-active" onClick={() => props.setSoundGeneralNotifyTemp(false)} /> 
                        </OverlayTrigger>
                      }
                    </span>
                  </div>
                  <span className="notifications-component-configurations-section-subtitle section-subtitle-no-margin">
                    { props.soundGeneralNotifyTemp && props.t('configuration.general_sound_notification_subtitle_active') }
                    { !props.soundGeneralNotifyTemp && props.t('configuration.general_sound_notification_subtitle_inactive') }
                  </span>
                </div>
                <div className={`notifications-component-configurations-section ${inactiveTicketSoundNotificationClass}`}>
                  <div className="notifications-component-configurations-section-header">
                    <span className="notifications-component-configurations-section-header-title">
                      {props.t('configuration.ticket_sound_notification_title')}
                      <OverlayTrigger overlay={<Tooltip>{props.t('configuration.ticket_sound_notification_tooltip')}</Tooltip>}>
                        <IconInfoCircle />
                      </OverlayTrigger>
                    </span>
                    <span className="notifications-component-configurations-section-header-action">
                      { !props.soundTicketNotificationTemp && 
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_active_sound_notification')}</Tooltip>}>
                          <IconToggleLeftFilled className="configuration-header-action-inactive" onClick={() => props.changeCheckConfig('sound-notification', true)} /> 
                        </OverlayTrigger>
                      }
                      { props.soundTicketNotificationTemp && 
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_disable_sound_notification')}</Tooltip>}>
                          <IconToggleRightFilled className="configuration-header-action-active" onClick={() => props.changeCheckConfig('sound-notification', false)} /> 
                        </OverlayTrigger>
                      }
                    </span>
                  </div>
                  <span className="notifications-component-configurations-section-subtitle">
                    { props.soundTicketNotificationTemp && props.t('configuration.ticket_sound_notification_subtitle_active') }
                    { !props.soundTicketNotificationTemp && props.t('configuration.ticket_sound_notification_subtitle_inactive') }
                  </span>
                  <div className="notifications-component-configurations-section-form">
                    <span className="notifications-component-section-form-item" onClick={() => props.changeCheckConfigType('sound-notification', 'new-ticket')}>
                      { props.soundTicketNotificationType === 'new-ticket' && <IconCircleCheckFilled /> }
                      { props.soundTicketNotificationType === 'new-message' && <IconCircle /> }
                      <span className="notifications-component-section-form-item-label">
                        {props.t('configuration.form_option_every_ticket')}
                      </span>
                    </span>
                    <span className="notifications-component-section-form-item" onClick={() => props.changeCheckConfigType('sound-notification', 'new-message')}>
                      { props.soundTicketNotificationType === 'new-message' && <IconCircleCheckFilled /> }
                      { props.soundTicketNotificationType === 'new-ticket' && <IconCircle /> }
                      <span className="notifications-component-section-form-item-label">
                        {props.t('configuration.form_option_every_message')}
                      </span>
                    </span>
                  </div>
                </div>
                { props.hasNotificationPermission && props.notificationPermission === 'granted' &&
                  <div className={`notifications-component-configurations-section ${inactiveVisualNotificationClass}`}>
                    <div className="notifications-component-configurations-section-header">
                      <span className="notifications-component-configurations-section-header-title">
                        {props.t('configuration.ticket_visual_notification_title')}
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.ticket_visual_notification_tooltip')}</Tooltip>}>
                          <IconInfoCircle />
                        </OverlayTrigger>
                      </span>
                      <span className="notifications-component-configurations-section-header-action">
                        { !props.visualTicketNotificationTemp && 
                          <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_active_visual_notification')}</Tooltip>}>
                            <IconToggleLeftFilled className="configuration-header-action-inactive" onClick={() => props.changeCheckConfig('visual-notification', true)} /> 
                          </OverlayTrigger>
                        }
                        { props.visualTicketNotificationTemp && 
                          <OverlayTrigger overlay={<Tooltip>{props.t('configuration.check_disable_visual_notification')}</Tooltip>}>
                            <IconToggleRightFilled className="configuration-header-action-active" onClick={() => props.changeCheckConfig('visual-notification', false)} /> 
                          </OverlayTrigger>
                        }
                      </span>
                    </div>
                    <span className="notifications-component-configurations-section-subtitle">
                      { props.visualTicketNotificationTemp && props.t('configuration.ticket_visual_notification_subtitle_active') }
                      { !props.visualTicketNotificationTemp && props.t('configuration.ticket_visual_notification_subtitle_inactive') }
                    </span>
                    <div className="notifications-component-configurations-section-form">
                      <span className="notifications-component-section-form-item" onClick={() => props.changeCheckConfigType('visual-notification', 'new-ticket')}>
                        { props.visualTicketNotificationType === 'new-ticket' && <IconCircleCheckFilled /> }
                        { props.visualTicketNotificationType === 'new-message' && <IconCircle /> }
                        <span className="notifications-component-section-form-item-label">
                          {props.t('configuration.form_option_every_ticket')}
                        </span>
                      </span>
                      <span className="notifications-component-section-form-item" onClick={() => props.changeCheckConfigType('visual-notification', 'new-message')}>
                        { props.visualTicketNotificationType === 'new-message' && <IconCircleCheckFilled /> }
                        { props.visualTicketNotificationType === 'new-ticket' && <IconCircle /> }
                        <span className="notifications-component-section-form-item-label">
                          {props.t('configuration.form_option_every_message')}
                        </span>
                      </span>
                    </div>
                  </div>
                }
                { props.notificationPermission === 'default' &&
                  <div className="notifications-component-configurations-section">
                    { props.checkPermission && <Loading blur={true} /> }
                    <div className="notifications-component-configurations-section-header">
                      <span className="notifications-component-configurations-section-header-title">
                        {props.t('configuration.permission_notification_title')}
                        <OverlayTrigger overlay={<Tooltip>{props.t('configuration.permission_notification_tooltip')}</Tooltip>}>
                          <IconInfoCircle />
                        </OverlayTrigger>
                      </span>
                    </div>
                    <span className="notifications-component-configurations-section-subtitle">
                      {props.t('configuration.permission_notification_subtitle')}
                    </span>
                    <div className="notifications-component-configurations-section-form">
                      <span className="notifications-component-configurations-section-button" onClick={() => props.requestNotificationPermission()}>
                        <IconBellCog />
                        {props.t('configuration.permission_notification_button')}
                      </span>
                    </div>
                  </div>
                }
              </div>
              { !props.hasNotificationPermission &&
                <div className="notifications-component-configurations-alert">
                  <span className="notifications-component-configurations-alert-message">
                    {props.t('configuration.permission_notification_unsupported')}
                  </span>
                </div>
              }
              { props.hasNotificationPermission && props.notificationPermission === 'denied' &&
                <div className="notifications-component-configurations-alert">
                  <span className="notifications-component-configurations-alert-message-title">
                  {props.t('configuration.permission_notification_denied')}
                  </span>
                  <span className="notifications-component-configurations-alert-message-subtitle">
                    {props.t('configuration.permission_notification_denied_message')}
                  </span>
                </div>
              }
              <div className="notifications-component-configurations-actions">
                <span className="notifications-component-configurations-action-save" onClick={props.handleSubmitConfigurations}>
                  {props.t('configuration.btn_save')}
                </span>
              </div>
            </div>
          }
        </div>
      </FadeInOut>
    </div>
  );
}

export default NotificationsComponent;