import { FC, useEffect, useRef, useState } from 'react';
import ModalAgent from './index';
import { EmployeeFormInterface, ModalAgentControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EmployeeInterface, FetchEmployeesInterface } from '../../listOfAgents/indexModel';
import emitter from '../../../../../core/shared/emitter';
import { UserProfile } from '../../../../../core/enums/user-profile';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalAgentController: FC<ModalAgentControllerInterface> = (props) => {
  const { t } = useTranslation('ModalAgent');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [agent, setAgent] = useState<EmployeeInterface>();
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [errorLoadingAgent, setErrorLoadingAgent] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [agentValuesObject, setAgentValuesObject] = useState<EmployeeFormInterface>();
  const [selectedCountry, setSelectedCountry] = useState<CustomSelectOptionType>();
  const [isAdmin, setIsAdmin] = useState(false);

  const isValidEmailRef = useRef(true);
  const isValidPhoneRef = useRef(true);
  const isValidDocumentRef = useRef(true);

  // Status
  const countryList: CustomSelectOptionType[] = [
    { id: 'br', value: t('form.country.br') },
    { id: 'es', value: t('form.country.es') },
    { id: 'fr', value: t('form.country.fr') },
    { id: 'gb', value: t('form.country.gb') },
    { id: 'pt', value: t('form.country.pt') },
  ];

  const allowedCountries = ['br', 'es', 'fr', 'gb', 'pt'];

  useEffect(() => {
    emitter.on('open-modal-agent', data => {
      if (data?.userId) {
        setMode('edit');

        fetchAgent(data.userId).then(agent => {
          setAgent(agent);
          setAgentValuesObject(agent);

          if (agent.country) {
            const country = agent.country.toLowerCase();
            setSelectedCountry({ id: country, value: t(`form.country.${country}`)});
          }

          if (agent.profile_id === UserProfile.Admin) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }).catch(error => {
          dispatch(setShowAlertFeedback({ message: t('alert.error_loading'), visibility: true, signalIcon: false }));
        });
      } else {
        setMode('create');
        setAgent(null);
        setAgentValuesObject({
          name: '',
          email: '',
          country: '',
          document_number: '',
          phone: '',
          role: '',
          observations: '',
          profile_id: UserProfile.Employee,
        });
        setIsAdmin(false);
      }
    });

    return () => {
      emitter.off('open-modal-agent');
    }
  }, []);

  const fetchAgent = async (id: string): Promise<EmployeeInterface> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingAgent(true);
    setErrorLoadingAgent(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/agent/${id}`, config,
        () => {},
        (response: FetchEmployeesInterface) => {
          setErrorLoadingAgent(false);
          if (response.status === 200 && response.data?.employees.length > 0) {
            resolve(response.data.employees[0]);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingAgent(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingAgent, { }
      );
    });
  }

  const isValid = () => {
    let isValid = true;

    const NAME_MAX_LENGTH = 100;
    const EMAIL_MAX_LENGTH = 100;
    const ROLE_MAX_LENGTH = 50;
    const OBSERVATIONS_MAX_LENGTH = 100;

    if (agentValuesObject?.name?.length === 0 || agentValuesObject?.name?.length > NAME_MAX_LENGTH) {
      isValid = false;
    }
    if (agentValuesObject?.email?.length === 0 || !isValidEmailRef?.current || agentValuesObject?.email?.length > EMAIL_MAX_LENGTH) {
      isValid = false;
    }
    if (!selectedCountry?.id) {
      isValid = false;
    }
    if (agentValuesObject?.role?.length > ROLE_MAX_LENGTH) {
      isValid = false;
    }
    if (agentValuesObject?.observations?.length > OBSERVATIONS_MAX_LENGTH) {
      isValid = false;
    }
    if (!isValidDocumentRef?.current && agentValuesObject?.document_number?.length > 0) {
      isValid = false;
    }
    if (!isValidPhoneRef?.current && agentValuesObject?.phone?.length > 0) {
      isValid = false;
    }

    return isValid;
  }

  const handleSubmit = () => {
    if (isValid()) {
      const form = {
        name: agentValuesObject?.name,
        email: agentValuesObject?.email,
        profile_id: agent && agent.profile_id === UserProfile.Owner ? agent.profile_id : (isAdmin ? UserProfile.Admin : UserProfile.Employee),
        country: selectedCountry?.id?.toUpperCase()
      };

      if (agentValuesObject.document_number) {
        form['document_number'] = agentValuesObject.document_number.replace(/[^a-zA-Z0-9]/g, '');
      }

      // if (isValidPhoneRef.current && agentValuesObject?.phone?.length > 2) {
      if (isValidPhoneRef.current && agentValuesObject?.phone?.length > 2) {
        form['phone'] = agentValuesObject.phone;
      }

      if (agentValuesObject.role) {
        form['role'] = agentValuesObject.role;
      }
      
      if (agentValuesObject.observations) {
        form['observations'] = agentValuesObject.observations;
      }

      const headers = UserService.getHeaders();
      const config = { headers };
      
      if (mode === 'create') {
        AppRequesterConst.Post(
          '/agent', form, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_create'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
            if (error.response?.data?.code_cxpress) {
              if (error.response.data.code_cxpress === 814) {
                dispatch(setShowAlertFeedback({ message: t('alert.error_create_existent_email', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
              } else {
                dispatch(setShowAlertFeedback({ message: t('alert.error_create', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
              }
            } else {
              if (error.response.data?.message[0]) {
                let error_field = error.response.data?.message[0]?.context?.key;

                if (error_field === 'document_number') {
                  if (error.response.data.message[0]?.type === 'doc.character-invalid') {
                    error_field = 'document_number_invalid_char';
                  }
                }

                dispatch(setShowAlertFeedback({ message: t(error_field ? `alert.error_${error_field}` : 'alert.error'), visibility: true, signalIcon: false }));
              } else {
                dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
              }
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      } else {
        form['id'] = agent?.user_id;
        AppRequesterConst.Put(
          '/agent', form, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_edit'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
            if (error.response?.data?.code_cxpress) {
              if (error.response.data.code_cxpress === 808) {
                dispatch(setShowAlertFeedback({ message: t('alert.error_edit_existent_email', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
              } else {
                dispatch(setShowAlertFeedback({ message: t('alert.error_edit', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
              }
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      }
    }
  }

  const setAgentValue = (property: string, value: any) => {
    setAgentValuesObject((agentValuesObject: any) => ({
      ...agentValuesObject,
      [property]: value,
    }));
  }

  const getCountrySelect = () => {
    return {
      countryList,
      selectedCountry,
      setSelectedCountry,
      allowedCountries
    }
  }

  return (
    <ModalAgent 
      t={t}
      show={props.show}
      hide={props.hide}
      mode={mode}
      agent={agent}
      loadingAgent={loadingAgent}
      errorLoadingAgent={errorLoadingAgent}
      loadingRequest={loadingRequest}
      isValid={isValid}
      handleSubmit={handleSubmit}
      agentValuesObject={agentValuesObject}
      setAgentValue={setAgentValue}
      getCountrySelect={getCountrySelect}
      isAdmin={isAdmin}
      setIsAdmin={setIsAdmin}
      isValidEmailRef={isValidEmailRef}
      isValidPhoneRef={isValidPhoneRef}
      isValidDocumentRef={isValidDocumentRef}
    />
  );
};

export default ModalAgentController;
