import { FC, useEffect, useState } from 'react';
import ModalRecoveryDeletedTicket from './index';
import { ModalRecoveryDeletedTicketControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalRecoveryDeletedTicketController: FC<ModalRecoveryDeletedTicketControllerInterface> = (props) => {
  const { t } = useTranslation('ModalRecoveryDeletedTicket');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ ticketId: string, ticketReferenceId: string }>(null);
  const [loadingRecoveryDeletedTicket, setLoadingRecoveryDeletedTicket] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-recovery-deleted-ticket', data => {
      setData(data);
    });

    return () => {
      emitter.off('open-modal-recovery-deleted-ticket');
    }
  }, []);

  const handleSubmit = () => {
    reopenTicket();
  }

  const reopenTicket = () => {
    if (data) {
      const headers  = UserService.getHeaders();
      const ticket_id = `${data.ticketId}`;
      const ticket_reference_id = `${data.ticketReferenceId}`;

      const formData = {
        ticket_id
      };

      AppRequesterConst.Put(
        `/ticket/recovery`, formData, { headers },
        () => {},
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.recovered_successfully', { ticket_reference_id }), visibility: true, signalIcon: true }));
          props.hide(true, ticket_id);
        },
        (error) => {
          if(error.response.status === 400 && error.response.data.code_cxpress === 734) {
            dispatch(setShowAlertFeedback({ message: t('alert.consumer_deleted'), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          }
        },
        navigate, dispatch, setLoadingRecoveryDeletedTicket
      );
    }
  }

  return (
    <ModalRecoveryDeletedTicket 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingRecoveryDeletedTicket={loadingRecoveryDeletedTicket}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalRecoveryDeletedTicketController;
