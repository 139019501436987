import React, { useEffect, useState } from 'react'
import ModalExportData from '.'
import UserService from '../../../../../../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppRequesterController } from '../../../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../../../store/internal';
import { t } from 'i18next';

const ModalExportDataController = ({
    visibility,
    handleCloseModalExport,
    botId, 
    endDate,
    startDate,
    fields
}) => {

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const controller = new AbortController();
    const appRequesterConst = new AppRequesterController();
    
    const [modalShow, setModalShow] = useState(visibility);

    useEffect(() => {
        setModalShow(visibility);
        if(visibility) {
            handleExportData();
        }
    }, [visibility]);

    const Hide = () => {
        setModalShow(false);
        handleCloseModalExport();
        setHasLinks(false);
        setIsClicked(false);
    }

    const [fileName, setFileName] = useState("");
    const [bufferData, setBufferData] =useState([]);
    const [hasLinks, setHasLinks] = useState(false);
    const [isClicked, setIsClicked] = useState(true);

    const handleExportData = async () => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        const JsonSend = {
            fields: fields.map(field =>
                field.label /* transformando em array de string novamente */
            ),
            start_date: startDate,
            end_date: endDate
        }

        await appRequesterConst.Post(
            `bot-cxpress/${botId}/collected-fields/export`, JsonSend, { headers: headers, signal },
            (response: Object) => { },
            (data) => {
                if(data.data.code_cxpress === 1657){
                    // salvando os dados que serão necessarios para o download
                    setBufferData(data.data.content.data);
                    setFileName(data.data.fileName);
                    setHasLinks(true);
                }
                else if(data.data.code_cxpress === 1655){
                    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.error_export"), visibility: true, signalIcon: false }))
                    Hide();
                }
                else if(data.data.code_cxpress === 1656){ /* caso onde não existe dados para exportar */
                    setHasLinks(false);
                    //dispatch(setShowAlertFeedback({ message: "O chatbot não possui dados para exportar", visibility: true, signalIcon: false }))
                    //Hide();
                }
            },
            (error: Object) => { 
                dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.tab_collected_data.modal_export_collected_data.error_export"), visibility: true, signalIcon: false }))
                Hide();
            },
            navigate, dispatch, setIsLoading, 
        )
    }

    const handleDownload = () => {
        var decoder = new TextDecoder();
        var uintData = Uint8Array.from(bufferData);
        var arrayBufferData = uintData.buffer;
        var decoded = decoder.decode(arrayBufferData);

        const blob = new Blob([decoded], { type: "text/csv;charset=utf-8;" });

        var hiddenElement = document.createElement('a');

        if (hiddenElement.download !== undefined) {
            const url = URL.createObjectURL(blob);
            hiddenElement.setAttribute("href", url);
            hiddenElement.setAttribute("download", fileName);
            hiddenElement.style.visibility = "hidden";
            document.body.appendChild(hiddenElement);
            hiddenElement.click();
            document.body.removeChild(hiddenElement);
            setIsClicked(true);
        }
    };
      
    return (
        <ModalExportData
            modalShow={modalShow}
            Hide={Hide}
            isLoading={isLoading}
            fileName={fileName}
            handleDownload={handleDownload}
            hasLinks={hasLinks}
            isClicked={isClicked}
        />
    )
}

export default ModalExportDataController