import { TFunction } from "i18next";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

export interface AudioPlayerInterface {
  t: TFunction<"translation", undefined, "translation">;
  filename: string;
  duration: number;
  currentTime: number;
  isPlaying: boolean;
  volume: number;
  showVolume: boolean;
  setShowVolume: Dispatch<SetStateAction<boolean>>;
  handlePlayPause: () => void;
  handleProgressChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleVolumeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getFileInfo: (filename: string) => { name: string; extension: string; };
  handleDownloadFile: () => void;
  handleCloseVolume: (event: React.MouseEvent<HTMLInputElement>) => void;
  errorLoad: boolean;
}

export interface AudioPlayerControllerInterface {
  audioUrl: string;
  filename: string;
  currentUrlPlaying?: string;
  setCurrentAudioPlayingUrl?: Dispatch<SetStateAction<string>>;
  duration?: number;
}


export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = secs.toString().padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else if (minutes > 0) {
    return `${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `00:${formattedSeconds}`;
  }
}