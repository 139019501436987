import { useLocation, useNavigate } from "react-router-dom";
import constsRouters from "../../../../../routes/constsRouter";
import { setBlockLoadTicketBackup, setShowConfirmationModal, setShowModalUnifyConsumers } from "../../../../../store/internal";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import ViewAnalysisSimilarConsumers from ".";
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { ConsumerIdData } from "../../viewConsumers/indexModel";
import { Button, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ViewAnalysisSimilarConsumersController = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();

    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, /*setPhoneNumber*/] = useState<string>("");
    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);

    const [customFields, setCustomFields] = useState([]);

    const [currentConsumerId, setCurrentConsumerId] = useState(null);
    const [currentConsumer, setCurrentConsumer] = useState(null);
    const [similarConsumers, setSimilarConsumers] = useState([]);

    const [data, setData] = useState([]);
    const [isLargerList, setIsLargerList] = useState(false);
    // indica o consumidor selecionado do campo customizado
    const [/*selectedFieldsConsumerId*/, setSelectedFieldsConsumerId] = useState(null);
    // contem o valor do campo customizado do consumidor selecionado
    const [/*selectedFieldsDataConsumer*/, setSelectedFieldsDataConsumer] = useState(null);

    const [colConsumerPositions, /*setColConsumerPositions*/] = useState({});
    const { t } = useTranslation()

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken),
        }
    }

    useEffect(() => {
        setIsLargerList(consumerIsLarger("list_consumers_custom_field"));

        // dados selecionados iniciais, o primeiro consumidor da listagem é o default
        if (data && data.length > 1 && data[0] && data[1]) {
            let firstConsumer = data[0];
            let secondConsumer = data[1]
            let fieldsNames = Object.keys(secondConsumer);

            let selecteds = {};
            let selectedsData = {};
            fieldsNames.map((field, index) => {
                selecteds[field] = firstConsumer.name; // id
                selectedsData[field] = firstConsumer[field];
            });
            setSelectedFieldsConsumerId(selecteds);
            setSelectedFieldsDataConsumer(selectedsData);
        }
    }, [data]);

    useEffect(() => {
        const controller = new AbortController();
        const controllerCustomFields = new AbortController();

        if (location?.state /* && data === undefined */) {
            let similarConsumers: Array<any>; 
            similarConsumers = location.state.similarConsumers;

            let currentConsumer = location.state.currentConsumer;

            setCurrentConsumerId(currentConsumer.id);

            // getCurrentConsumer(currentConsumer);
            setData((state) => [currentConsumer, ...state]);
            setCurrentConsumer(currentConsumer);
            getSimilarConsumers(similarConsumers, controller);

            getAllCustomFields(controllerCustomFields);
        } else {
            navigate(constsRouters.routers.consumers.path);
        }

        return () => {
            controller.abort();
            controllerCustomFields.abort();
        }
    }, [location]);

    /* useEffect(() => {
        let consumers_list = Array.from(document.getElementsByName("consumers_custom_list"));
        let consumers_names;
        consumers_names = Array.from(document.getElementsByName("consumer_name_action"));

        let positions = {};

        consumers_names && consumers_names.map((name, index) => {
            let consumers_list_id = "consumer_list_" + name.id.split("consumer_name_")[1];

            consumers_list.map((list) => {
                if (list.id === consumers_list_id && positions[name.id] !== list.getBoundingClientRect().left) {
                    positions[name.id] = list.getBoundingClientRect().left;
                }
            }); 
        });

        console.log(positions);
        setColConsumerPositions(positions);
    }, [data, similarConsumers, currentConsumer]); */

    // obtem os consumidores similares
    const getSimilarConsumers = (similars: any [], controller: AbortController) => {
        if (similars && similars.length > 0) {
            let aux = []
            similars.map((similarConsumer) => {
                getConsumer(similarConsumer.user_id, 
                    // adiciona a data e consumidores similares
                    (dataConsumer) => {
                        aux.push(dataConsumer)
                        setData((state) => [...state, dataConsumer]);
                        // setSimilarConsumers([...similarConsumers, dataConsumer]);

                    },
                    controller);
            });
            setSimilarConsumers(aux);

        }
    }

    const getConsumer = async (consumerId: string, updateFunction, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/consumer/' + consumerId, { headers, signal },
            (response: Object) => {

            },
            (data: ConsumerIdData) => {
                if(data.status === 200 || data.status === 204){
                    updateFunction(data.data.consumers[0]);
                }
            },
            (error: Object) => {
                console.log(error);
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllCustomFields = async (controller: AbortController) => {

        const headers = UserService.getHeaders();
        const signal = controller.signal;
    
        await AppRequesterConst.Get(
          `/custom-fields`, { headers: headers, signal },
          (response: Object) => { },
          (data: any) => {
            if ((data?.status === 200 || data?.status === 204) && data.data?.custom_fields) {
                let custom = [];
                data.data?.custom_fields.map(custom_field => { /* fazendo um map para pegar apenas os campos de consumidor */
                    if(custom_field.entity_type_id === "b93b0740-a77d-4474-aad9-7aa0044d5654"){ /* id de campos customizados para consumidor */
                        custom.push(custom_field)
                    }
                })
                setCustomFields(state => {
                    return state.concat(custom);
                });
            }
          },
          (error: { response: { status: number } }) => {
            if (error.response?.status === 400) {
    
            }
            if (error.response?.status === 401) {
    
            }
          }, navigate, dispatch, setIsLoading, { values: values }
        )
      }

    const setCurrentConsumerInfo = (e) => {
        // setCurrentConsumerId(e.currentTarget.name);
    }

    const filteringConsumers = (e) => {
        navigate(constsRouters.routers.tickets.path, { state: { search: currentFilterSearch } });
    }

    const gotoConsumersPage = (e) => {
        navigate(constsRouters.routers.consumers.path);
    }

    const gotoConsumersTickets = (e) => {
        // navigate(constsRouters.routers.consumersView.path, { state: Object.assign({ originRedirectPage: "unify_consumers" }, data.filter(consumer => consumer.id === currentConsumerId)[0]) });

        const consumer = data.filter(consumer => consumer.id === currentConsumerId)[0];

        navigate(
            `${constsRouters.routers.consumerTickets.path}`, 
            { 
                state: { 
                consumerUserId: consumer.user_id, 
                consumerName: consumer.name || 'N/A',
                page: 'consumers', 
                prevPath: constsRouters.routers.consumers.path 
                } 
            }
        );
    }

    const confirmationRemoveFromAnalysis = (e) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": `${t('home_consumers.unification.view_analysis.confirmation_remove_from_analysis.title')}`, "body": `${t('home_consumers.unification.view_analysis.confirmation_remove_from_analysis.body')}`, buttonConfirmationText: `${t('home_consumers.unification.view_analysis.confirmation_remove_from_analysis.remove')}`, "buttonReturnText": `${t('home_consumers.unification.view_analysis.confirmation_remove_from_analysis.cancel')}` }, functionConfirmation: removeFromAnalysis }));
    }

    const removeFromAnalysis = () => {
        let checkedField; 
        setSimilarConsumers((similarConsumers) => {
            // pelo menos dois para remover da seleção
            if (similarConsumers?.length > 1) {
                let newSimilarConsumers = similarConsumers.filter((consumer) => consumer.id !== currentConsumerId);
                let newData = data.filter(consumer => consumer.id !== currentConsumerId);

                setData(newData);

                /// selecionando o consumidor default, os campos selecionados do consumidor removido irão sumir
                let fields = Object.keys(data[1]); // campos, maioria dos campos presente nos demais consumidores
                let firstConsumer = data[0]; // dados do formulário
                fields.map((field) => {
                    checkedField = document.getElementById("form-" + field + "-" + currentConsumerId + "-" + similarConsumers.find(item => item.id === currentConsumerId).name);
                    // se o campo do consumidor removido estiver selecionado então o primeiro é selecionado
                    if (checkedField?.checked) {
                        let firstConsumerRadio = document.getElementById("form-" + field + "-" + firstConsumer.id + "-" + firstConsumer.name);
                        firstConsumerRadio.click();
                    }
                });

                return newSimilarConsumers;
            } else {
                return similarConsumers;
            }
        });
    }

    const unifyConsumerConfirm = async (e) => {
        let formCheck;
        let selectedsFieldsConsumersId = {}, selectedsFieldsConsumersData = { 'custom_fields': [] };

        // Verificando forms para encontrar ids de consumidores nos campos selecionados
        if (data && data.length > 1) {
            Object.keys(data[1]).map((field, i_field) => {
                if (field === "custom_fields") {
                    data.map((consumer, i_consumer) => {
                        if (customFields && customFields.length > 0) {
                            
                            customFields.map((custom, c) => {
                                formCheck = document.getElementById("form-" + custom.id + "-" + consumer.id + "-" + consumer.name);
                                let formData = (formCheck && formCheck.value !== "")? formCheck.value: null;
            
                                if (formCheck && formCheck.checked && formData !== null){

                                    selectedsFieldsConsumersId['custom_fields'] = consumer.id + consumer.name;
                                    selectedsFieldsConsumersData['custom_fields'].push({
                                        "custom_field_id": custom.id,
                                        "value": [
                                            formData,
                                        ],
                                        "name": custom.name,
                                    });
                                }
                            });
                        }
                    });
                } else {
                    data.map((consumer, i_consumer) => {
                        formCheck = document.getElementById("form-" + field + "-" + consumer.id + "-" + consumer.name);
                        let formData = (formCheck && formCheck.value !== "")? formCheck.value: null;
    
                        if (formCheck && formCheck.checked && formData !== null){
                            selectedsFieldsConsumersId[field] = consumer.id + consumer.name;
                            selectedsFieldsConsumersData[field] = formData;
                        }
                    });
                }
            });
        }

        // atualizando dados que foram selecionados
        setCurrentConsumer(selectedsFieldsConsumersData);
        dispatch(setShowModalUnifyConsumers(true));
        dispatch(setBlockLoadTicketBackup(false));
    }

    // retorna true quando a listagem tem scroll
    const consumerIsLarger = (listId: string) => {
        let listDiv = document.getElementById(listId);

        if (listDiv && (listDiv.scrollWidth > listDiv.clientWidth)) {
            return true;
        } 
        return false;
    }

    const scrollAll = (e) => {
        let scrollTop = e.target.scrollTop;

        let consumer_list = Array.from(document.getElementsByName('consumerListClass'));
        consumer_list.map((list) => {
            list.scrollTo = scrollTop;
            list.scroll({ top: scrollTop });
        });
    }

    const consumerFieldName = (field: string) => {
        let name;
        switch (field) {
            case 'name':
                name = "Nome";
                break;
            case 'document_number':
                name = "CPF";
                break;
            case 'email':
                name = "Email";
                break;
            case 'phone':
                name = "Telefone";
                break;
            case 'whatsapp':
                name = "Whatsapp";
                break;
            case 'instagram':
                name = "Instagram";
                break;
            case 'twitter':
                name = "Twitter";
                break;
            case 'facebook':
                name = "Facebook"
                break;
            case 'youtube':
                name = "Youtube";
                break;
            case 'address':
                name = "Endereço"
                break;
            case 'observations':
                name = 'Observações'
                break;
            case 'webchat':
                name = "Webchat"
                break;
            case 'custom_fields':
                name = "Campos customizáveis";
                break;
            default:
                name = field;
                break;
        }

        return name;
    }

    const popoverConsumer = (
        <Popover id={currentConsumerId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }} key={'popover-' + currentConsumerId}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => gotoConsumersTickets(e)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_consumers.unification.view_analysis.go_to_tickets")}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={confirmationRemoveFromAnalysis} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_consumers.unification.view_analysis.remove_from_analysis")} <br /> ({t("home_consumers.unification.view_analysis.keep_registry")})</Button>
            </Popover.Body>
        </Popover>
    );

    return <ViewAnalysisSimilarConsumers 
        currentConsumerId={currentConsumerId} 
        gotoConsumersTickets={gotoConsumersTickets} 
        confirmationRemoveFromAnalysis={confirmationRemoveFromAnalysis} 
        similarConsumers={similarConsumers} 
        currentConsumer={currentConsumer} 
        isLoading={isLoading} 
        filteringConsumers={filteringConsumers} 
        currentFilterSearch={currentFilterSearch} 
        setCurrentFilterSearch={setCurrentFilterSearch} 
        phoneNumber={phoneNumber} 
        openModalPhone={openModalPhone} 
        setOpenModalPhone={setOpenModalPhone} 
        gotoConsumersPage={gotoConsumersPage} 
        data={data} 
        consumerFieldName={consumerFieldName} 
        isLargerList={isLargerList} 
        setCurrentConsumerInfo={setCurrentConsumerInfo} 
        unifyConsumerConfirm={unifyConsumerConfirm} 
        customFields={customFields}
        colPositions={colConsumerPositions}
        scrollAll={scrollAll}
        popoverConsumer={popoverConsumer}
        t={t}
    />
}

export default ViewAnalysisSimilarConsumersController;