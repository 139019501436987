import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { DataContractInterface, TokenInterface } from "../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getNameUser } from "../../store/user";
import { getContractIdCompany, getIdCompany, getSubdomainCompany } from "../../store/company";
import { getToken } from "../../store/token";
import { useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../services/appRequester/appRequesterController";
import { ContractStatus } from "../../core/enums/contract-status";
import { setShowAcquirePlatformComponent, setShowAlertFeedback } from "../../store/internal";
import { verifyCode } from "../../services/codeCxpressInterface";
import EndTrial from ".";

const EndTrialController = () => {
    const { t } = useTranslation();
    const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
    const values: TokenInterface = {
        user: {
            name: useSelector(getNameUser),
        },
        company: {
            id: useSelector(getIdCompany),
            subdomain: useSelector(getSubdomainCompany),
            contractId: useSelector(getContractIdCompany)
        },
        token: {
            value: useSelector(getToken)
        },
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const appRequester = new AppRequesterController();

    useEffect(() => {
        updateContractStatus();
    }, [])

    const updateContractStatus = async () => {

        // const headers = UserService.getHeaders();
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Basic " + process.env.REACT_APP_TOKEN_BASIC_AUTH,
        }; 

        if (values.company.contractId) {
            await appRequester.Get(
                "/contract/" + values.company.contractId,
                { headers },
                (response:Object) => { return response; },
                (data: DataContractInterface) => {
                    if (data.status === 200 && data && data.data && data.data.contract) {
                        let is_trial = false;
                        if (data.data.contract.contract_status_id === ContractStatus.Trial) {
                            is_trial = true;
                        }
                        dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': data.data.contract.contract_status_id, 'limitDays': data.data.contract.remaining_days, 'visibility': is_trial, 'quantityAgents': data.data.contract.quantity_agents }));
                    }
                },
                (error: {response: {data: {code_cxpress: number}}}) => {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
                },
                navigate, dispatch, setIsLoading, { values: values }
            );
        }
    }

    return <EndTrial isLoading={isLoading} values={values} t={t} />
}

export default EndTrialController;