import './styles.scss';
import { FC } from 'react';
import { CustomTableListInterface } from './indexModel';
import Loading from '../loading2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { 
  IconArrowNarrowDown, 
  IconArrowNarrowUp, 
  IconCancel, 
  IconChevronLeft, 
  IconChevronRight, 
  IconCircleCheck, 
  IconDeselect, 
  IconDotsVertical, 
  IconFileDownload, 
  IconListDetails, 
  IconSortAscendingLetters, 
  IconSortAscendingNumbers, 
  IconSortDescendingLetters, 
  IconSortDescendingNumbers, 
  IconSquare, 
  IconSquareCheckFilled, 
  IconSquareMinusFilled, 
  IconSquareOff, 
  IconTrashX,
} from '@tabler/icons-react';

const CustomTableList: FC<CustomTableListInterface> = (props) => {
  const customClass = `custom-table-style-${props.component}`;
  const headerWithCheckClass = props.hasItemCheckSelection ? 'table-header-with-check' : '';
  const itemWithCheckClass = props.hasItemCheckSelection ? 'table-list-item-with-check' : '';
  const scrollHeaderClass = props.hasVerticalScrollbar ? 'table-scroll' : '';
  const scrollbarThin = props.scrollbarThin ? 'table-scrollbar-thin' : '';

  return (
    <div className={`custom-table-list-component ${customClass} ${scrollbarThin}`} id="table-list-items" ref={props.tableListRef}>
      <div id='table-list-component-content' className={`table-list-component-content`}>
        { props.data?.length > 0 &&
          <div className={`table-header ${headerWithCheckClass} ${scrollHeaderClass}`}>
            { props.hasItemCheckSelection && (!props.hasSomeSelectedCheck() || props.isAllSelectedItems()) &&
              <span className="table-header-item check-btn" onClick={() => props.selectAllItems()}>
                {
                  !props.isAllSelectedItems() &&
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('select_all')}</Tooltip>}>
                    <IconSquare className='icon-check-btn' color='#bcbcbc' strokeWidth={1}/>
                  </OverlayTrigger>
                }
                {
                  props.isAllSelectedItems() && 
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('unselect_all')}</Tooltip>}>
                    <IconSquareCheckFilled className='icon-check-btn icon-check-filled' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                  </OverlayTrigger>
                }
              </span>
            }
            {
              props.hasItemCheckSelection && !props.isAllSelectedItems() && props.hasSomeSelectedCheck() &&
              <span className="table-header-item check-btn" onClick={() => props.clearPageSelected()}>
                <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('unselect_all')}</Tooltip>}>
                  <IconSquareMinusFilled className='icon-check-btn icon-check-filled' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                </OverlayTrigger>
              </span>
            }
            { props.hasItemIconInfo &&
              <span className="table-header-item table-header-item-info"></span>
            }
            {props.headers()?.map((header, index) => {
              let headerItemCss = 'table-header-item';
              const customStyle = {
                width: `calc(100% / ${(props.headers()?.length + 1) || 1})`,
              }

              if (header.width !== 'auto') {
                customStyle['width'] = header.width;
                customStyle['minWidth'] = header.width;
                customStyle['maxWidth'] = header.width;
              }

              if (header.maxWidth) {
                customStyle['maxWidth'] = header.maxWidth;
              }

              if (header.alignment) {
                if (header.alignment === 'center') {
                  customStyle['justifyContent'] = 'center';
                } else if (header.alignment === 'right') {
                  customStyle['justifyContent'] = 'flex-end';
                }
              }

              if (header.field === 'action') {
                customStyle['width'] = '100%';
                headerItemCss += ' header-item-action';
              }

              if (header.hasOrderBy) {
                return (
                  <span key={index} className={`${headerItemCss}`} style={customStyle}>
                    <span className="column-title">
                      <span>{header.title}</span>
                    </span>
                    {
                      header.orderBy === 'asc' && 
                      <>
                      { header.orderType === 'default' &&
                        <OverlayTrigger key={`header-${header.field}-asc`} placement='top' overlay={<Tooltip>{props.t('order_by_asc')}</Tooltip>}>
                          <IconArrowNarrowUp className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'desc')} />
                        </OverlayTrigger>
                      }
                      { header.orderType === 'text' &&
                        <OverlayTrigger key={`header-${header.field}-asc`} placement='top' overlay={<Tooltip>{props.t('order_by_asc')}</Tooltip>}>
                          <IconSortAscendingLetters className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'desc')} />
                        </OverlayTrigger>
                      }
                      { header.orderType === 'number' &&
                        <OverlayTrigger key={`header-${header.field}-asc`} placement='top' overlay={<Tooltip>{props.t('order_by_asc')}</Tooltip>}>
                          <IconSortAscendingNumbers className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'desc')} />
                        </OverlayTrigger>
                      }
                      </>
                    }
                    {
                      header.orderBy === 'desc' && 
                      <>
                      { header.orderType === 'default' &&
                        <OverlayTrigger key={`header-${header.field}-desc`} placement='top' overlay={<Tooltip>{props.t('order_by_desc')}</Tooltip>}>
                          <IconArrowNarrowDown className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'asc') } />
                        </OverlayTrigger>
                      }
                      { header.orderType === 'text' &&
                        <OverlayTrigger key={`header-${header.field}-desc`} placement='top' overlay={<Tooltip>{props.t('order_by_desc')}</Tooltip>}>
                          <IconSortDescendingLetters className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'asc') } />
                        </OverlayTrigger>
                      }
                      { header.orderType === 'number' &&
                        <OverlayTrigger key={`header-${header.field}-desc`} placement='top' overlay={<Tooltip>{props.t('order_by_desc')}</Tooltip>}>
                          <IconSortDescendingNumbers className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'asc') } />
                        </OverlayTrigger>
                      }
                      </>
                    }
                  </span>
                );
              } else {
                if (header.field === 'action') {
                  return (
                    <span key={index} className={headerItemCss} style={customStyle}><span>{header.title}</span></span>
                  );
                } else {
                  return (
                    <span key={index} className={headerItemCss} style={customStyle}>{header.title}</span>
                  );
                }
              }
            })}
          </div>
        }
        { props.isLoading && <Loading blur={true} /> }
        { props.data && props.data?.length > 0 &&
          <div id="table-list-items" className="table-list-items">
            {props.data.map(item => {
                let tableListItemCss = `table-list-item ${props.getItemCustomClass(item)} ${itemWithCheckClass}`;

                if (props.isSelectedItem(props.getItemId(item))) {
                  tableListItemCss += ' table-list-item-selected';
                }

                if (props.itemClickDisabled) {
                  tableListItemCss += ' table-list-item-click-disabled';
                }

                if (props.itemWrap) {
                  tableListItemCss += ' table-list-item-wrap';
                }

                return (
                  <div className={tableListItemCss} key={props.getItemId(item, true)} onClick={(e) => {props.handleItemClick(item, e)}}>
                    <div className="table-list-item-align">
                      { props.hasItemCheckSelection &&
                        <span className={`check-btn ${props.checkOffItem(item) ? 'check-off' : ''}`} onClick={(e) => props.selectItem(item, e)}>
                          { props.showAvailableCheck(item) && !props.isSelectedItem(props.getItemId(item)) && 
                            <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{props.t('select_one')}</Tooltip>}>
                              <IconSquare className='icon-check-btn' color='#bcbcbc' strokeWidth={1} />
                            </OverlayTrigger>
                          }
                          { props.showAvailableCheck(item) && props.isSelectedItem(props.getItemId(item)) && 
                            <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{props.t('unselect_one')}</Tooltip>}>
                              <IconSquareCheckFilled className='icon-check-btn icon-check-filled' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                            </OverlayTrigger>
                          }
                          { !props.showAvailableCheck(item) && 
                            <IconSquareOff className='icon-check-btn' color='#979797' strokeWidth={1} style={{ color: '#979797'}} />
                          }
                        </span>
                      }
                      { props.hasItemIconInfo &&
                        <span className="table-list-item-column table-list-item-info">
                          { props.showIconInfo(item) && props.getIconInfo()}
                        </span>
                      }
                      {props.headers()?.map(header => {
                        let bodyItemCss = 'table-list-item-column';
                        const customStyle = {
                          width: `calc(100% / ${(props.headers()?.length + 1) || 1})`,
                          marginRight: '10px'
                        }
              
                        if (header.width !== 'auto') {
                          customStyle['width'] = header.width;
                          customStyle['minWidth'] = header.width;
                          customStyle['maxWidth'] = header.width;
                        }
  
                        if (header.maxWidth) {
                          customStyle['maxWidth'] = header.width;
                        }

                        if (header.alignment) {
                          if (header.alignment === 'center') {
                            customStyle['justifyContent'] = 'center';
                          } else if (header.alignment === 'right') {
                            customStyle['justifyContent'] = 'flex-end';
                          }
                        }
  
                        if (header.field === 'action') {
                          customStyle['width'] = '100%';
                          customStyle['marginRight'] = '0';
                          customStyle['marginLeft'] = '10px';
                          bodyItemCss += ' table-list-item-action';
                        }

                        if (header.field === 'status_employee_id') {
                          bodyItemCss += ' table-list-item-agent-status';
                        }
  
                        const cell_item = props.util_formatValue(item[header.field], header.field);
  
                        if (header.field === 'status_task_id') {
                          return (
                            <div key={header.field} className={bodyItemCss} style={customStyle}> 
                              <OverlayTrigger container={props.tableListRef} placement='left' overlay={<Tooltip>{props.util_getStatusIcon(cell_item.value).tooltip}</Tooltip>}>
                                <span className='badge-status' style={{ backgroundColor: props.util_getStatusIcon(cell_item.value).color }}>
                                  <span>{props.util_getStatusIcon(cell_item.value).symbol}</span>
                                </span>
                              </OverlayTrigger>
                            </div>
                          );
                        } else if (header.field === 'status_employee_id') {
                          return (
                            <div key={header.field} className={bodyItemCss} style={customStyle}> 
                              <OverlayTrigger container={props.tableListRef} placement='bottom' overlay={<Tooltip>{props.util_getStatusIcon(cell_item.value).tooltip}</Tooltip>}>
                                {props.util_getStatusIcon(cell_item.value).icon}
                              </OverlayTrigger>
                            </div>
                          );
                        } else if ((props.component.includes('messages-trigger-') || props.component.includes('tags') || props.component === 'sectors') && ['status_id', 'active', 'status'].includes(header.field)) {
                          if (props.component === 'modal-messages-trigger-report') {
                            let reportItemClass = 'table-list-item-value';

                            if (item.message_erro) {
                              reportItemClass += ' table-list-item-value-invalid';
                              cell_item.value = `${props.t('message_trigger_report_status.failure')} (${item.message_erro})`;
                            } else {
                              cell_item.value = props.t('message_trigger_report_status.success');
                            }

                            return (
                              <div key={header.field} className={bodyItemCss} style={customStyle}>
                                <p className={reportItemClass}>
                                  {cell_item.value}
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div key={header.field} className={bodyItemCss} style={customStyle}>
                                <span className={`custom-status ${cell_item.class}`}>
                                  {cell_item.value}
                                </span>
                              </div>
                            );
                          }
                        } else if (props.component === 'sectors' && ['keyword', 'agents'].includes(header.field)) {
                          return (
                            <div key={header.field} className={bodyItemCss} style={customStyle}>
                              { header.hasTooltip && 
                                <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{cell_item.value}</Tooltip>}>
                                  <p className='table-list-item-value-badges'>
                                    {cell_item.icon}
                                    {cell_item.element || 'N/A'}
                                  </p>
                                </OverlayTrigger>
                              }
                              { !header.hasTooltip &&
                                <p className='table-list-item-value-badges'>
                                  {cell_item.icon}
                                  {cell_item.element || 'N/A'}
                                </p>
                              }
                            </div>
                          );
                        } else if (header.field === 'action') {
                          return (
                            <div key={header.field} className={bodyItemCss} style={customStyle}>
                              <span className="action">
                                <OverlayTrigger 
                                  trigger={['click']} 
                                  placement='left' 
                                  overlay={props.popoverItemAction(item)} 
                                  rootClose={true}
                                  show={props.openedPopoverId === props.getItemId(item, true)}
                                  onToggle={() => props.setOpenedPopoverId(null)}
                                >
                                  <IconDotsVertical color='#707070' strokeWidth={2} onClick={(e) => props.handlePopoverMenuAction(e, props.getItemId(item, true))} />
                                </OverlayTrigger>
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div key={header.field} className={bodyItemCss} style={customStyle}>
                              { header.hasTooltip && 
                                <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{cell_item.value}</Tooltip>}>
                                  <p className='table-list-item-value'>
                                    {cell_item.icon}
                                    {cell_item.value}
                                  </p>
                                </OverlayTrigger>
                              }
                              { !header.hasTooltip &&
                                <p className='table-list-item-value'>
                                  {cell_item.icon}
                                  {cell_item.value}
                                </p>
                              }
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        }
        {
          (!props.data || props.data?.length === 0) &&
          <div className={`table-list-items table-list-empty ${props.errorLoading ? 'table-list-error-loading' : ''}`}>
            { props.icon_entity_list }
            { !props.icon_entity_list && <IconListDetails className='tabler-icon-entity' /> }
            { !props.isLoading && !props.errorLoading && <span className="table-list-info">{props.text_empty_list}</span>}
            { !props.isLoading && props.errorLoading && 
              <span className="table-list-info">{props.text_error_list}</span>
            }
            { props.errorLoading &&
              <span className="btn-reload-data" onClick={() => props.reloadData()}>{props.t('refresh')}</span>
            }
            { props.isLoading && <span className="table-list-info">{props.text_loading_list}</span>}
          </div>
        }
      </div>
      { props.data?.length > 0 &&
        <div className="table-list-footer">
          <div className="table-list-footer-left">
            <div className="table-list-actions">
              { props.hasExportAll && !props.hasSomeSelected() &&
                <button className='table-list-action' onClick={props.exportAll}>
                  {props.t('footer_actions.export_all')}
                  <IconFileDownload />
                </button>
              }
              { props.hasExportSelected && props.hasSomeSelected() &&
                <button className='table-list-action' onClick={props.exportSelected}>
                  {props.t('footer_actions.export_selected')} ({props.selectedItemsLength})
                  <IconFileDownload />
                </button>
              }
              { props.hasActivateSelected && props.hasSomeSelected() &&
                <button className='table-list-action' onClick={props.activateSelected}>
                  {props.t('footer_actions.activate_selected')} ({props.selectedItemsLength})
                  <IconCircleCheck />
                </button>
              }
              { props.hasInactivateSelected && props.hasSomeSelected() &&
                <button className='table-list-action table-list-action-inactivate' onClick={props.inactivateSelected}>
                  {props.t('footer_actions.inactivate_selected')} ({props.selectedItemsLength})
                  <IconCancel />
                </button>
              }
              { props.hasDeleteSelected && props.hasSomeSelected() &&
                <button className='table-list-action table-list-action-delete' onClick={props.deleteSelected}>
                  {props.t('footer_actions.delete_selected')} ({props.selectedItemsLength})
                  <IconTrashX />
                </button>
              }
              { props.hasSomeSelected() &&
                <OverlayTrigger overlay={<Tooltip>{props.t('footer_actions.clear_selection')}</Tooltip>}>
                  <button className='table-list-action table-list-action-clear' onClick={props.clearSelected}>
                    <IconDeselect />
                  </button>
                </OverlayTrigger>
              }
            </div>
          </div>
          <div className="table-list-footer-right">
            { props.data && props.data?.length > 0 &&
              <div className="table-list-footer-pagination">
                { !props.paginationDetails && <span className='table-pagination-info'>...</span> }
                { props.paginationDetails && 
                  <>
                  <span className='table-pagination-info'>
                    <span className="table-pagination-interval">{props.util_formatNumberValue(props.paginationDetails.from)} - {props.util_formatNumberValue(props.paginationDetails.to)}</span>
                    <span className="table-pagination-separator">{props.t('pagination_of')}</span>
                    <span className="table-pagination-total-docs">{props.util_formatNumberValue(props.paginationDetails.total)}</span>
                  </span>
                  <span className="table-pagination-action" ref={props.paginationRef}>
                    { props.paginationDetails?.hasPrev &&  
                      <IconChevronLeft className="table-pagination-prev" onClick={() => props.handleClickPage(props.paginationDetails.prevPage)} /> 
                    }
                    { !props.paginationDetails?.hasPrev &&
                      <IconChevronLeft className="table-pagination-disabled" /> 
                    }
                    <div className="table-pagination-selected">
                      <OverlayTrigger key='table-pagination-selected' placement={props.showPopupPages ? 'left' : 'top'} overlay={<Tooltip>{props.t('pagination_select')}</Tooltip>}>
                        <span className="table-pagination-value" onClick={() => props.setShowPopupPages(!props.showPopupPages)}>
                          {props.paginationDetails.currentPage}
                        </span>
                      </OverlayTrigger>
                      {
                        props.showPopupPages &&
                        <div className="table-pagination-popup-pages">
                          {props.paginationDetails.pages.map(page => {
                            return (
                              <span key={page} className='table-pagination-popup-item' onClick={() => {props.handleClickPage(page); props.setShowPopupPages(false)}}>
                                {page}
                              </span>
                            );
                          })}
                        </div>
                      }
                    </div>
                    { props.paginationDetails?.hasNext &&
                      <IconChevronRight className="table-pagination-next" onClick={() => props.handleClickPage(props.paginationDetails.nextPage)} />
                    }
                    { !props.paginationDetails?.hasNext &&
                      <IconChevronRight className="table-pagination-disabled" />
                    }
                  </span>
                  </>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default CustomTableList;
