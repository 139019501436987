import { FC, useEffect, useState } from "react";
import DashCountersComponent from ".";
import { DashCounterComponentControllerInterface, FetchTotals } from "./indexModel";
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DashCounterClickType } from "../../listOfTickets/indexModel";
import { setShowAlertFeedback } from "../../../../../store/internal";
import { verifyCode } from "../../../../../services/codeCxpressInterface";
import { useMainAppContext } from "../../../../../core/context/main-app-context";
import i18n from "../../../../../i18next";
import { Channels } from "../../../../../core/enums/channels";
import { useSocketV2Context } from "../../../../../core/context/socket-context-v2";
import emitter, { T_EmitterSocketUpdateCounters } from "../../../../../core/shared/emitter";
import { getIdEmployee } from "../../../../../store/employee";

const AppRequesterConst = new AppRequesterController();

const DashCountersComponentController: FC<DashCounterComponentControllerInterface> = (props) => {
  const { t } = useTranslation('DashCountersComponent');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const {
    isSearchedDashCounter,
    actionEvent,
  } = useMainAppContext();

  const {
    socketInitialized,
    registerSocketAppEvent,
    unregisterSocketAppEvent,
  } = useSocketV2Context();

  const currentEmployeeId = useSelector(getIdEmployee);

  // Tickets: "Todos os tickets", "Seus tickets", "Tickets não atendidos", "TIckets não lidos"
  // Histórico geral: "Tickets finalizados", "Tickets excluídos"
  // Histórico chatbot: "Atendimentos finalizados"

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [allTickets, setAllTickets] = useState(0);
  const [yourTickets, setYourTickets] = useState(0);
  const [newTickets, setNewTickets] = useState(0);
  const [unreadTickets, setUnreadTickets] = useState(0);
  const [finishedTickets, setFinishedTickets] = useState(0);
  const [deletedTickets, setDeletedTickets] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClick, setIsLoadingClick] = useState(false);
  const [selected, setSelected] = useState<DashCounterClickType>('counter-all');
  
  useEffect(() => {
    function dashCountersUpdate(data: T_EmitterSocketUpdateCounters) {
      emitter.emit('socket-event::update-counters', data);
    }

    if (socketInitialized) {
      registerSocketAppEvent('update-counters', dashCountersUpdate);
    }
    
    return () => {
      unregisterSocketAppEvent('update-counters', dashCountersUpdate);
    }
  }, [socketInitialized]);

  useEffect(() => {
    emitter.on('socket-event::update-counters', data => {
      socketEventUpdateCounters(data);
    });

    return () => {
      emitter.off('socket-event::update-counters');
    }
  }, []);

  useEffect(() => {
    if (apiHeader && !id) {
      fetchTotals(false);
    }
    const currentDashCounter = sessionStorage.getItem('current_dash_counter');
    const dashCounterList: DashCounterClickType[] = ['counter-all', 'counter-user-assigned', 'counter-unassigned', 'counter-unread'];
    const dashCounterHistoryGeneral: DashCounterClickType[] = ['counter-finished', 'counter-deleted'];
    const dashCounterHistoryChatbot: DashCounterClickType[] = ['counter-finished'];
    const dashCounterHistoryTelephony: DashCounterClickType[] = ['counter-finished'];

    if (currentDashCounter) {
      const dashCounter = currentDashCounter as DashCounterClickType;

      let isIncluded = true;
      
      if (props.pageType === 'ticket-list' && !dashCounterList.includes(dashCounter)) {
        setSelected('counter-all');
        isIncluded = false;
        sessionStorage.setItem('current_dash_counter', 'counter-all');
      }
      if (props.pageType === 'history-general' && !dashCounterHistoryGeneral.includes(dashCounter)) {
        setSelected('counter-finished');
        isIncluded = false;
        sessionStorage.setItem('current_dash_counter', 'counter-finished');
      }
      if (props.pageType === 'history-chatbot' && !dashCounterHistoryChatbot.includes(dashCounter)) {
        setSelected('counter-finished');
        isIncluded = false;
        sessionStorage.setItem('current_dash_counter', 'counter-finished');
      }
      if (props.pageType === 'history-telephony' && !dashCounterHistoryTelephony.includes(dashCounter)) {
        setSelected('counter-finished');
        isIncluded = false;
        sessionStorage.setItem('current_dash_counter', 'counter-finished');
      }

      if (isIncluded) {
        setSelected(dashCounter);
      }
    } else {
      if (props.pageType === 'ticket-list') {
        setSelected('counter-all');
        sessionStorage.setItem('current_dash_counter', 'counter-all');
      } else {
        setSelected('counter-finished');
        sessionStorage.setItem('current_dash_counter', 'counter-finished');
      }
    }
  }, [apiHeader, props.pageType]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (!isSearchedDashCounter) {
      if (props.pageType === 'ticket-list') {
        setSelected('counter-all');
      } else {
        const isDeletedCounter = sessionStorage.getItem('is_deleted_counter');

        if (isDeletedCounter !== 'true') {
          setSelected('counter-finished');
        }
      }
    }
  }, [isSearchedDashCounter, props.pageType]);

  useEffect(() => {
    if (actionEvent === 'create-ticket') {
      fetchTotals(false);
    }
  }, [actionEvent]);

  const socketEventUpdateCounters = (data: T_EmitterSocketUpdateCounters) => {
    if (data) {
      if (data.total.toString()?.length > 0) {
        setAllTickets(prevState => prevState + data.total);
      }
      if (data.total_new_tickets.toString()?.length > 0) {
        setNewTickets(prevState => prevState === 0 ? 0 : prevState + data.total_new_tickets);
      }
      if (data.last_assigned_employee_id === currentEmployeeId && data.action === 'increment') {
        setYourTickets(prevState => prevState + 1);
      }
      if (data.last_assigned_employee_id === currentEmployeeId && data.action === 'decrement') {
        setYourTickets(prevState => prevState === 0 ? 0 : prevState - 1);
      }
      if (data.total_not_read_tickets.toString()?.length > 0) {
        setUnreadTickets(prevState => prevState + data.total_not_read_tickets);
      }
    }
  }

  const fetchTotals = async (isClick: boolean) => {
    const headers = UserService.getHeaders();
    const params = {};
    const path = props.pageType?.includes('history') ? '/ticket/historic/totals' : '/ticket/totals';

    if (props.pageType === 'history-chatbot') {
      const channels = [Channels.Facebook, Channels.Instagram, Channels.WhatsApp, Channels.Webchat];

      params['level'] = 1;
      params['channels'] = channels.toString();
    } else if (props.pageType === 'history-telephony') {
      params['level'] = 1;
      params['channels'] = Channels.Telefonia;
    }

    const config = { headers, params };
    
    await AppRequesterConst.Get(
      path,
      config,
      (response: any) => {},
      (response: FetchTotals) => {
        if (response.status === 200) {
          if (props.pageType === 'ticket-list') {
            setAllTickets(response.data.total);
            setYourTickets(response.data.total_my_tickets);
            setNewTickets(response.data.total_new_tickets);
            setUnreadTickets(response.data.total_not_read_tickets);
          } else {
            setFinishedTickets(response.data.total_resolved_tickets);
            setDeletedTickets(response.data.total_excluded_tickets);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, isClick ? setIsLoadingClick : setIsLoading, { }
    );
  }

  const handleClick = (type: 'counter-all' | 'counter-user-assigned' | 'counter-unassigned' | 'counter-unread' | 'counter-finished' | 'counter-deleted') => {
    setSelected(type);
    props.handleClick(type);
    fetchTotals(true);
    sessionStorage.setItem('current_dash_counter', type);
  }

  const formatValue = (value: number) => {
    return new Intl.NumberFormat(i18n.language?.toLocaleLowerCase() || 'pt').format(value);
  }
  
  return (
    <DashCountersComponent 
      t={t}
      pageType={props.pageType}
      isLoading={isLoading}
      isLoadingClick={isLoadingClick}
      allTickets={allTickets}
      yourTickets={yourTickets}
      newTickets={newTickets}
      unreadTickets={unreadTickets}
      finishedTickets={finishedTickets}
      deletedTickets={deletedTickets}
      handleClick={handleClick}
      selected={selected}
      formatValue={formatValue}
    />
  );
}

export default DashCountersComponentController;