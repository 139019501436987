import { FC, useEffect, useState } from 'react';
import ModalTag from './index';
import { ModalTagControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../../core/shared/emitter';
import UserService from '../../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalTagController: FC<ModalTagControllerInterface> = (props) => {
  const { t } = useTranslation('ModalTag');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ id: string, name: string, active: boolean }>(null);
  const [loadingEdit, setLoadingEdit] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-tag', data => {
      if (data) {
        setData(data);
      } else {
        setData({ id: null, name: '', active: true });
      }
    });

    return () => {
      emitter.off('open-modal-tag');
    }
  }, []);

  const isValid = () => {
    return data?.name?.length > 0 && data?.name?.length <= 50;
  }

  const handleSubmit = () => {
    if (isValid()) {
      const headers  = UserService.getHeaders();
  
      const config = { headers };
      
      const payload = {
        name: data.name,
        active: data.active
      };

      const requestPath = props.type === 'ticket' ? '/tag' : '/consumer-tag';
  
      if (data?.id) {
        payload['id'] = data.id;
  
        AppRequesterConst.Put(
          requestPath, payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_edit'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: { response?: { status: number; data: { statusCode: number; code_cxpress: number; message: string; }} }) => {
            if (error.response?.data?.code_cxpress === 2307) {
              dispatch(setShowAlertFeedback({ message: t('alert.existent_tag'), visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
            }
          },
          navigate, dispatch, setLoadingEdit
        );
      } else {
        AppRequesterConst.Post(
          requestPath, payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_create'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: { response?: { status: number; data: { statusCode: number; code_cxpress: number; message: string; }} }) => {
            if (error.response?.data?.code_cxpress === 2307) {
              dispatch(setShowAlertFeedback({ message: t('alert.existent_tag'), visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
            }
          },
          navigate, dispatch, setLoadingEdit
        );
      }
    }
  }

  const setDataValue = (property: string, value: any) => {
    setData((dataObject: any) => ({
      ...dataObject,
      [property]: value,
    }));
  }

  return (
    <ModalTag 
      t={t}
      show={props.show}
      hide={props.hide}
      type={props.type}
      data={data}
      loadingEdit={loadingEdit}
      isValid={isValid}
      handleSubmit={handleSubmit}
      setDataValue={setDataValue}
    />
  );
};

export default ModalTagController;
