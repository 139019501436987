import React, { useEffect, useState } from 'react'
import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button, Popover } from "react-bootstrap"
import StarComponent from "../../../components/starComponent"
import InfiniteScroll from "react-infinite-scroll-component"
import Utils from '../../../../../core/shared/utils'
import { ListName } from '../../../../../core/enums/order-sort-by'
import ModalRankingController from '../../../components/modalRanking/indexController'

const ListRanking = ({
    t,
    printPDF,
    JsonSend,
    show,
    handleClose,
    currentAgentId,
    data,
    popover,
    dataList,
    updateListfunction,
    HasMoreInformation,
    setCurrentAgentInfo,
    handleShow,
}) => {

    return (
        <>
            <ModalRankingController information={JsonSend} show={show} onHide={handleClose} currentAgentId={currentAgentId} />
            <ListGroup variant="flush" style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px" }}>
                <ListGroup.Item className="TicketsRowCollums">
                    <Row className="mb-3 mt-3" style={{ padding: "0", }}  name="ListOfAgentsHead">
                        <Col style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontFamily: "Montserrat", color: "#1E1E1E" }}>
                            {t('dashboard.list_ranking')}
                            {printPDF ? <></> :
                                <div style={{ paddingLeft: "5px", paddingBottom: "3px", cursor: 'pointer' }}>
                                    <svg id="Grupo_13507" data-name="Grupo 13507" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_10339" data-name="Caminho 10339" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Caminho_10340" data-name="Caminho 10340" d="M12,6H6A2,2,0,0,0,4,8V18a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V12" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_10341" data-name="Caminho 10341" d="M11,13l9-9" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_10342" data-name="Caminho 10342" d="M15,4h5V9" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </div>
                            }
                        </Col>
                        <Col md={4} style={{ padding: "0px", display: "flex", alignItems: "center", fontWeight: "bold", fontFamily: "Montserrat", color: "#4A69BD" }}>
                            {t('dashboard.list_average_general')}
                            <div style={{ display: "flex", paddingLeft: "5px", paddingBottom: "3px" }}>
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip'>
                                            {data ?
                                                <>{data?.toFixed(1)}</>
                                                :
                                                <>N/A</>

                                            }
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <StarComponent points={data} />
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </Col>
                        {printPDF ? <></> :
                            <Col className='d-flex justify-content-end'>
                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                    <div style={{ cursor: 'pointer' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                            <path d="M12 17v-6" />
                                            <path d="M9.5 14.5l2.5 2.5l2.5 -2.5" />
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        }
                    </Row>
                    {printPDF ?
                        <Row name="ListOfAgentsHead" className='justify-content-center'>
                            <Col style={{ width: '33vw', marginLeft: "55px" }}>
                                {t('dashboard.list_agents')}
                            </Col>
                            <Col style={{ width: '40vw', marginLeft: "50px" }}>
                                {t('dashboard.list_amount')}
                            </Col>
                            <Col style={{ width: '10vw', marginLeft: "50px" }}>
                                {t('dashboard.list_average')}
                            </Col>
                        </Row> :
                        <Row name="ListOfAgentsHead">
                            <Col md={4}>
                                {t('dashboard.list_agents')}
                            </Col>
                            <Col md={6}>
                                {t('dashboard.list_amount')}
                            </Col>
                            <Col md={2}>
                                {t('dashboard.list_average')}
                            </Col>
                        </Row>
                    }

                </ListGroup.Item>
            </ListGroup>
            {
                dataList !== undefined ?
                    <div id='ListOfAgents'>
                        <InfiniteScroll
                            dataLength={dataList?.length}
                            next={updateListfunction}
                            hasMore={HasMoreInformation as boolean}
                            loader={<h4> </h4>}
                            scrollableTarget={"ListOfAgents"}
                            onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfAgents) }}
                        >
                            {
                                <ListGroup variant='flush' className='TicketsRow'>
                                    {dataList?.map((item, index) => {
                                        return (
                                            <ListGroup.Item style={{ color: '#707070' }} id={item?.name + " " + (item?.name && item?.lastname) + "*ID*" + item?.id} key={"keyAll" + index.toString()}>
                                                <Row className={printPDF ? 'TicketsRowHeight justify-content-center' : 'TicketsRowHeight'}>
                                                    <Col md={4} className='align-self-center' style={{ cursor: 'pointer', width: printPDF ? '33vw' : '' }} id={item?.name + " " + (item?.name) + "*ID*" + item?.id} onClick={() => { }}>
                                                        <div className='oneLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.name != null ?
                                                                            <>{item?.name}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item?.name ? item?.name : <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className='align-self-center nopadding' style={{ cursor: 'pointer', width: printPDF ? '13vw' : '' }} id={item?.name + " " + (item?.name) + "*ID*" + item?.id}>
                                                        <div style={{ marginLeft: "10px" }} className='oneLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.qnt_ratings != null ?
                                                                            <span>{item?.qnt_ratings}</span>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div style={{ backgroundColor: "#E5ECFF", color: "#4A69BD", width: "10%", height: "3vh", justifyContent: "center", alignItems: "center", display: "flex", borderRadius: "15px" }}>
                                                                    {item?.qnt_ratings ? item?.qnt_ratings : <>N/A</>}
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer', width: printPDF ? '12vw' : '', marginRight: printPDF ? '12vw': '' }} id={item?.name + " " + (item?.name) + "*ID*" + item?.id} onClick={handleShow}>
                                                        <div style={{ marginLeft: "10px" }} className='onLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tootip'>
                                                                        {item?.overall_service_average != null ?
                                                                            <>{item.overall_service_average.toFixed(1)}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                                trigger={['hover', 'click']}
                                                            >
                                                                <Button id={item?.name + "*ID*" + item?.id} onClick={(e) => setCurrentAgentInfo(e)} className='nopadding d-flex justify-content-star align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none' }}>
                                                                    <span style={{ display: "flex" }}>
                                                                        <StarComponent points={item?.overall_service_average} />
                                                                    </span>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            }
                        </InfiniteScroll>
                    </div>
                    :
                    <></>
            }
        </>
    )
}

export default ListRanking