import { Formik } from "formik";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { setShowAlertFeedback } from "../../../../../store/internal";
import allStyles from './../styles';
import { verifyCode } from "../../../../../services/codeCxpressInterface";

const TicketFinalization = ({
    t,
    lifeTime,
    setLifeTime,
    showFeedbackTime,
    setShowFeedbackTime,
    messageFinish,
    setMessageFinish,
    AppRequesterConst,
    dispatch,
    navigate,
    setIsLoading,
    company,
    showFeedbackMessage,
    setShowFeedbackMessage,
    convertMinToHour,
    values,
    showFeedbackTimeLevelOne,
    setShowFeedbackTimeLevelOne,
    lifeTimeLevelOne,
    setLifeTimeLevelOne
}) => {
    return (

        <Row className="" id="buttonCreateConsumer" style={{ marginTop: '1vh', padding: 0 }}>
            {(company && company?.ticket_life_time !== null && company?.finish_ticket_message !== null) &&
                <Formik
                    initialValues={{ life_time: convertMinToHour(company.ticket_life_time), life_time_level_one: convertMinToHour(company.ticket_life_time_level_one), message: company.finish_ticket_message }} /* convertendo o lifeTime para horas novamente */
                    validate={values => {
                        let errors = {};
                        setLifeTime(values.life_time);
                        setLifeTimeLevelOne(values.life_time_level_one);
                        setMessageFinish(values.message)

                        if (!values.life_time) {
                            errors['life_time'] = t('configurations_ticket.general.validate.life_time');
                            setShowFeedbackTime(true);
                        } else if (values.life_time % 1 !== 0) {
                            errors['life_time'] = t('configurations_ticket.general.validate.life_time_error_integer');
                            setShowFeedbackTime(true);
                        } else {
                            setShowFeedbackTime(false);
                        }
                        if (!values.life_time_level_one) {
                            errors['life_time_level_one'] = t('configurations_ticket.general.validate.life_time');
                            setShowFeedbackTimeLevelOne(true);
                        } else if (values.life_time_level_one < 1 || values.life_time_level_one > 24) {
                            errors['life_time_level_one'] = t('configurations_ticket.general.validate.life_time_error_between_hours');
                            setShowFeedbackTimeLevelOne(true);
                        } else if (!Number.isInteger(values.life_time_level_one)) {
                            errors['life_time_level_one'] = t('configurations_ticket.general.validate.life_time_error_integer');
                            setShowFeedbackTimeLevelOne(true);
                        } else {
                            setShowFeedbackTimeLevelOne(false);
                        }
                        setMessageFinish((message) => {
                            if (!values.message) {
                                errors['message'] = "Preencha esse campo."
                                setShowFeedbackMessage(true);
                            } else {
                                setShowFeedbackMessage(false);
                            }
                            return message;
                        })

                        return errors;
                    }}

                    onSubmit={async () => {

                        let JsonSend = {
                            id: values.company.id,
                            life_time: lifeTime,
                            life_time_level_one: lifeTimeLevelOne,
                            finish_ticket_message: messageFinish
                        }

                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + values.token.value
                        };

                        await AppRequesterConst.Put(
                            '/company', JsonSend, { headers: headers },
                            (response: Object) => { },
                            (data: Object) => {
                                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.general.submit.message'), visibility: true, signalIcon: true }));
                            },
                            (error: { response: { data: { code_cxpress: number } } }) => {
                                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                            }, navigate, dispatch, setIsLoading, () => { },
                            {
                                error422: () => {
                                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.general.submit.life_time_error'), visibility: true, signalIcon: false }));
                                }
                            }
                        );
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', flexDirection: 'column', display: 'flex' }}>
                                <Row className=" d-flex justify-content-left my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.ticket_finalization_tab.section_finish_message_title')}</p>
                                            <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('configurations_ticket.ticket_finalization_tab.section_finish_message_sub')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputGroup>
                                                    <FormControl
                                                        className="form-control-Default-text-area "
                                                        style={{ height: '10vh', marginLeft: '0.1rem' }}
                                                        isInvalid={showFeedbackMessage}
                                                        as="textarea"
                                                        rows={10}
                                                        placeholder={t('configurations_ticket.ticket_finalization_tab.finish_message_placeholder')}
                                                        aria-label={t('configurations_ticket.ticket_finalization_tab.finish_message_placeholder')}
                                                        aria-describedby={t('configurations_ticket.ticket_finalization_tab.finish_message_placeholder')}
                                                        size="lg"
                                                        type="text"
                                                        id="message"
                                                        name="message"
                                                        maxLength={250}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.message}
                                                    ></FormControl>
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackmessage"
                                                    >
                                                        {errors.message}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', flexDirection: 'column', display: 'flex', marginTop: '-2vw' }}>
                                <Row className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.general.life_time')}</p>
                                            <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('configurations_ticket.general.life_time_description')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} >
                                                <InputGroup className="mb-3" style={{ width: '6.5vw' }}>
                                                    <FormControl className="form-control-Default  text-center"
                                                        placeholder={""}
                                                        aria-label={""}
                                                        aria-describedby={""}
                                                        type='number'
                                                        id="life_time"
                                                        name="life_time"
                                                        maxLength={2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.life_time}
                                                        isInvalid={showFeedbackTime}
                                                    >
                                                    </FormControl>
                                                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '1.4vh', marginLeft: '5px' }}>{t('configurations_ticket.general.hours')}</p>
                                                    <Form.Control.Feedback type="invalid" id="feedbackemail">
                                                        {errors.life_time}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', flexDirection: 'column', display: 'flex', marginTop: '-3vw' }}>
                                <Row className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.general.life_time_chatbot')}</p>
                                            <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('configurations_ticket.general.life_time_chatbot_description')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} >
                                                <InputGroup className="mb-3" style={{ width: '6.5vw' }}>
                                                    <FormControl className="form-control-Default  text-center"
                                                        placeholder={""}
                                                        aria-label={""}
                                                        aria-describedby={""}
                                                        type='number'
                                                        id="life_time_level_one"
                                                        name="life_time_level_one"
                                                        maxLength={2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.life_time_level_one}
                                                        isInvalid={showFeedbackTimeLevelOne}
                                                    >
                                                    </FormControl>
                                                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '1.4vh', marginLeft: '5px' }}>{t('configurations_ticket.general.hours')}</p>
                                                    <Form.Control.Feedback type="invalid" style={{ display: 'block', whiteSpace: 'nowrap' }}>
                                                        {errors.life_time_level_one}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Row style={allStyles.textFontGlay} className="d-flex">
                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                    <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('configurations_ticket.general.cancel')}</Button>
                                </Col>
                                <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.general.save')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            }
        </Row>
    )
}

export default TicketFinalization;