import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { IModalEditTemplateMessage } from './indexModal';
import allStyles from './styles';
import FormOfModalsTemplateMessageController from '../formOfModalsTemplateMessage/indexController';
import { useSelector } from 'react-redux';
import { getShowTemplateTagModal } from '../../../../../../store/internal';
import CreateTagController from '../formOfModalsTemplateMessage/createTagModal/indexController';

const ModalEditTemplateMessage: FC<IModalEditTemplateMessage> = (props) => {
    return (
        <>
            <Modal show={props.showModal} onHide={() => props.hide()} aria-labelledby="" size="lg" centered={true} >
                {useSelector(getShowTemplateTagModal)?
                    <CreateTagController
                        tags={props.tags} 
                        setTags={props.setTags} 
                        hideTagModal={props.hide} 
                    />:
                    <></>
                }
                <>
                    <Modal.Header hidden={useSelector(getShowTemplateTagModal)} closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                        <Modal.Title style={allStyles.textBlack}>
                            Editar Template Message
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body hidden={useSelector(getShowTemplateTagModal)} style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                        <FormOfModalsTemplateMessageController 
                            onSubmit={props.onSubmit} 
                            hide={props.hide} 
                            initialValues={{ title: props.template?.title, message: props.template?.message, phone_number: props.preSelectedWhatsapp? props.preSelectedWhatsapp: "", image_id: props.template?.image_id, image_url: props.template?.image_url, tags: props.template?.tags, content_sid: props.template?.content_sid || '' }} 
                            preSelectedWhatsapp={props.preSelectedWhatsapp} 
                            templateId={props.template?.id} 
                            currentCompany={props.currentCompany} 
                            tags={props.tags} 
                            setTags={props.setTags} 
                            previewImage={props.template?.image_url ? { filename: props?.filename, _id: props.template.image_id, url: props.template.image_url } : null}
                        />
                    </Modal.Body>
                </>
            </Modal>
        </>
    );
}

export default ModalEditTemplateMessage;