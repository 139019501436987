import './styles.scss';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ModalConsumer from '.';
import { ConsumerFormInterface, ModalConsumerControllerInterface, ResponsePostConsumer } from './indexModel';
import { ConsumerInterface, FetchConsumerInterface, UserConsumerInterface } from '../../listOfConsumers/indexModel';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../services/user-service';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { CustomFieldInterface, FetchCustomFieldsInterface, FetchEmployeesInterface, FetchTagsInterface, FetchUserConsumerInterface, FormatDataSelectType } from '../../../homeTickets2/viewTicket/components/detailsComponent/indexModel';
import { CustomField, CustomFieldEntity } from '../../../../../core/enums/custom-field-type';
import emitter from '../../../../../core/shared/emitter';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { Popover } from 'react-bootstrap';
import { ErrorCode, useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { CustomFile } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/components/toolbarFileUploader/indexModel';
import { getToken } from '../../../../../store/token';
import { verifyCode } from '../../../../../services/codeCxpressInterface';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 20;
const MAX_FILE_SIZE = 20971520; // 20MB

const ModalConsumerController: FC<ModalConsumerControllerInterface> = (props) => {
  const { t } = useTranslation('ModalConsumer');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userToken = useSelector(getToken);

  const [dataEmitter, setDataEmitter] = useState<{ userId: string | null }>({ userId: undefined });
  const [consumer, setConsumer] = useState<ConsumerInterface>();
  const [isCreatedByEmployee, setIsCreatedByEmployee] = useState(false);
  const [loadingConsumer, setLoadingConsumer] = useState(false);
  const [loadingEmployeeAssigned, setLoadingEmployeeAssigned] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [mode, setMode] = useState<'create' | 'edit' | 'duplicated' | 'unification-analysis' | 'finish-unification'>('create');
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [consumerValuesObject, setConsumerValuesObject] = useState<ConsumerInterface>();
  const [modalTab, setModalTab] = useState<'single' | 'multiple'>('single');
  const [showMenuDownload, setShowMenuDownload] = useState(false);

  // Employees
  const [employeesList, setEmployeesList] = useState<CustomSelectOptionType[]>([]);
  const [currentEmployeesListPage, setCurrentEmployeesListPage] = useState(1);
  const [hasMoreEmployeesList, setHasMoreEmployeesList] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<CustomSelectOptionType>(null);
  const [isLoadingEmployeesSelect, setIsLoadingEmployeesSelect] = useState(false);
  const [isLoadingEmployeesList, setIsLoadingEmployeesList] = useState(false);

  // Tags
  const [tagsList, setTagsList] = useState<CustomSelectOptionType[]>([]);
  const [currentTagsListPage, setCurrentTagsListPage] = useState(1);
  const [hasMoreTagsList, setHasMoreTagsList] = useState(false);
  const [selectedTags, setSelectedTags] = useState<CustomSelectOptionType[]>(null);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);
  const [isLoadingTagsList, setIsLoadingTagsList] = useState(false);

  // CustomFields
  const [formattedCustomFields, setFormattedCustomFields] = useState([]);
  const [customFieldsList, setCustomFieldsList] = useState<CustomFieldInterface[]>([]);
  const [customFieldsValuesObject, setCustomFieldsValuesObject] = useState<any>({});
  const [isLoadingCustomFieldsList, setIsLoadingCustomFieldsList] = useState(false);

  const isValidEmailRef = useRef(true);
  // const isValidDocumentRef = useRef(true);
  const isValidPhoneRef = useRef(true);
  const isValidWhatsappRef = useRef(true);

  // Arquivo de consumidores
  const [selectedFileConsumers, setSelectedFileConsumers] = useState<{ id: string, name: string}>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentConsumersFileSize, setCurrentConsumersFileSize] = useState(0);
  const [isLoadingUploadConsumersFile, setIsLoadingUploadConsumersFile] = useState(false);

  // Similar data
  const [consumerForm, setConsumerForm] = useState<ConsumerFormInterface>();
  const [duplicatedConsumers, setDuplicatedConsumers] = useState<ConsumerInterface[]>([]);
  const [similarConsumers, setSimilarConsumers] = useState<ConsumerFormInterface[]>([]);
  const [expandedConsumerInfo, setExpandedConsumerInfo] = useState<{[key: string]: boolean}>({});
  const [similarForm, setSimilarForm] = useState<{[key: string]: any}>({});
  const [idsConsumersDuplicates, setIdsConsumersDuplicates] = useState<string[]>([]);
  const [itemHovered, setItemHovered] = useState<string | null>(null);
  const [finalSimilarConsumer, setFinalSimilarConsumer] = useState<{[key: string]: any}>({});

  useEffect(() => {
    emitter.on('open-modal-consumer', data => {
      setDataEmitter(data);
    });

    emitter.on('close-action-popover-file-model-file-consumers', () => {
      setShowMenuDownload(false);
    });

    return () => {
      emitter.off('open-modal-consumer');
      emitter.off('close-action-popover-file-model-file-consumers');
    }
  }, []);

  useEffect(() => {
    setSelectedFileConsumers(null);
    if (dataEmitter?.userId) {
      setMode('edit');

      fetchConsumer(dataEmitter?.userId).then(consumer => {
        setConsumer(consumer);
        const newValues: ConsumerInterface = Object.assign({}, consumer);
        setIsCreatedByEmployee(consumer.created_by_employee === 'true');

        setConsumerValuesObject(newValues);
        if (newValues.employee_user_id) {
          fetchEmployeeAssigned(newValues.employee_user_id).then(agent => {
            setSelectedEmployee({ id: newValues.employee_user_id, value: agent.name });
          });
        }

        if (newValues.tags?.length > 0) {
          const tags: CustomSelectOptionType[] = [];
          newValues.tags.forEach(item => {
            if (item) {
              tags.push({ id: item?.id, value: item?.name });
            }
          });
          setSelectedTags(tags);
        }
      });
    } else {
      setIsCreatedByEmployee(false);
      setMode('create');
    }
  }, [dataEmitter?.userId]);

  useEffect(() => {
    fetchEmployees(false, 1);
    fetchTags(false, 1);
    fetchCustomFields(false, 1);
  }, []);

  useEffect(() => {
    if (customFieldsList?.length > 0) {
      const newArray = [];
      const newValues = {};

      customFieldsList.forEach(item => {
        const customField = {
          id: item.id,
          name: item.name,
          options: formatCustomFieldOptions(item.custom_field_type_id, item.options),
          placeholder: item.placeholder,
          required: !!item.required,
          custom_field_type_id: item.custom_field_type_id,
          value: '', //formatCustomFieldValue(item.custom_field_type_id, [item.default_value]),
          setValue: setCustomFieldValue
        }

        if (item.custom_field_type_id === CustomField.Dropdown && customField.value) {
          customField.value = customField.value[0];
        }

        newArray.push(customField);
        newValues[item.id] = customField.value;
      });

      setFormattedCustomFields(newArray);
      setCustomFieldsValuesObject(newValues);
    }
  }, [customFieldsList]);

  useEffect(() => {
    if (consumer?.custom_fields?.length > 0 && customFieldsList?.length > 0) {
      consumer.custom_fields.forEach(item => {
        const formattedValue = formatCustomFieldValue(item.custom_field_id, item.value);
        setCustomFieldValue(item.custom_field_id, formattedValue);
      });
    }
  }, [consumer?.custom_fields]);

  useEffect(() => {
    if (selectedEmployee) {
      setConsumerValue('employee_user_id', selectedEmployee?.id);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (consumerForm) {
      const temp = { ...expandedConsumerInfo };
      if (consumerForm.id) {
        temp[consumerForm.id] = false;
      } else {
        temp['000'] = false;
      }
      setExpandedConsumerInfo(temp);
    }
  }, [consumerForm]);

  useEffect(() => {
    const temp = { ...expandedConsumerInfo };
    duplicatedConsumers?.forEach(item => {
      temp[item.user_id] = false;
    });
    setExpandedConsumerInfo(temp);
  }, [duplicatedConsumers]);

  useEffect(() => {
    if (consumer && duplicatedConsumers?.length > 0) {
      const form = {};

      Object.keys(consumer).forEach(key => {
        if (!['employee_user_id', 'tags'].includes(key)) {
          if (key === 'custom_fields') {
            form['custom_fields'] = {};

            customFieldsList.forEach(item => {
              form['custom_fields'][item.id] = consumer.id;
            });
          } else {
            form[key] = consumer.id;
          }
        }
      });

      setSimilarForm(form);
    } else {
      const form = {
        name: '000',
        lastname: '000',
        document_number: '000',
        email: '000',
        phone: '000',
        address: '000',
        observations: '000',
        whatsapp: '000',
        instagram_user: '000',
        twitter: '000',
        facebook_user: '000',
        youtube: '000',
        custom_fields: {}
      }

      customFieldsList.forEach(item => {
        form['custom_fields'][item.id] = '000';
      });

      setSimilarForm(form);
    }
  }, [consumer, duplicatedConsumers]);

  const clickConsumerInfo = (id: string) => {
    const temp = { ...expandedConsumerInfo };
    temp[id] = !temp[id];
    setExpandedConsumerInfo(temp);
  }

  const changeMode = (mode: 'create' | 'edit' | 'duplicated' | 'unification-analysis' | 'finish-unification') => {
    setMode(mode);

    if (mode === 'finish-unification') {
      proccessFinalConsumer();
    }
  }

  const changeModalTab = (tab: 'single' | 'multiple') => {
    if (tab !== modalTab) {
      setModalTab(tab);
    }
  }

  const fetchConsumer = async (id: string): Promise<ConsumerInterface> => {
    const headers = UserService.getHeaders();
    const config = { headers };

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/consumer/${id}`, config,
        (response: Object) => {},
        (response: FetchConsumerInterface) => {
          if (response.status === 200 && response.data?.consumers.length > 0) {
            resolve(response.data.consumers[0]);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          if (error?.message === "canceled") {
            reject(error);
          } else {
            if (error?.response?.status === 401) {
              if (error?.response?.data?.code_cxpress === 1011) {
                // return;
                // reject(error);
              }
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else if (error?.response?.data) {
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            }
            reject(error);
          }
        }, navigate, dispatch, setLoadingConsumer, { }
      );
    });
  }

  const fetchEmployees = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentEmployeesListPage };
    const headers = UserService.getHeaders();

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setCurrentEmployeesListPage(newPage);

    await AppRequesterConst.Get(
      `/agent`,
      config,
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data?.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreEmployeesList(true);
          } else {
            setHasMoreEmployeesList(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...employeesList, ...formatDataSelect(response.data.employees, 'employee')];
            setEmployeesList(new_array);
          } else {
            setEmployeesList(formatDataSelect(response.data.employees, 'employee') || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setEmployeesList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingEmployeesSelect, { }
    );
  }

  const fetchEmployeesPagination = async () => {
    if (hasMoreEmployeesList) {
      const newPage = currentEmployeesListPage + 1;
      setCurrentEmployeesListPage(newPage);

      await fetchEmployees(true, newPage);
    }
  }

  const fetchSearchEmployees = (term: string) => {
    if (term) {
      fetchEmployees(false, 1, term);
    } else {
      fetchEmployees(false, 1, '');
    }
  }

  const fetchTags = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage, active: true };
    const headers = UserService.getHeaders();

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setCurrentTagsListPage(newPage);
    await AppRequesterConst.Get(
      `/consumer-tag`,
      config,
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data?.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTagsList(true);
          } else {
            setHasMoreTagsList(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...tagsList, ...formatDataSelect(response.data.tags, 'tags')];
            setTagsList(new_array);
          } else {
            setTagsList(formatDataSelect(response.data.tags, 'tags') || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTagsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingTagsSelect, { }
    );
  }

  const fetchTagsPagination = async () => {
    if (hasMoreTagsList) {
      const newPage = currentTagsListPage + 1;
      setCurrentTagsListPage(newPage);

      await fetchTags(true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  const fetchCustomFields = async (isInfiniteScroll: boolean, newPage: number) => {
    // const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage };
    const headers = UserService.getHeaders();
    const config = { headers };

    // setCurrentCustomFieldsListPage(newPage);
    await AppRequesterConst.Get(
      `/custom-fields/entity-type/${CustomFieldEntity.Consumer}`,
      config,
      (_response: any) => {},
      (response: FetchCustomFieldsInterface) => {
        if (response.status === 200 && response.data?.custom_fields.length > 0) {
          setCustomFieldsList(response.data.custom_fields);
        } else {
          setCustomFieldsList([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingCustomFieldsList, { }
    );
  }

  const fetchEmployeeAssigned = async (id: string): Promise<UserConsumerInterface> => {
    const headers = UserService.getHeaders();
    const config = { headers };

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/user/${id}`, config,
        (response: any) => {},
        (response: FetchUserConsumerInterface) => {
          if (response.status === 200 && response.data?.users.length > 0) {
            resolve(response.data.users[0]);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          if (error?.message === "canceled") {
            reject(error);
          } else {
            if (error?.response?.status === 401) {
              if (error?.response?.data?.code_cxpress === 1011) {
                // return;
                // reject(error);
              }
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else if (error?.response?.data) {
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            }
            reject(error);
          }
        }, navigate, dispatch, setLoadingEmployeeAssigned, { }
      );
    });
  }

  const isValid = () => {
    let isValid = true;

    const NAME_MAX_LENGTH = 50;
    const ADDRESS_MAX_LENGTH = 100;
    const OBSERVATIONS_MAX_LENGTH = 200;
    const INSTAGRAM_MAX_LENGTH = 30;
    const TWITTER_MAX_LENGTH = 50;
    const FACEBOOK_MAX_LENGTH = 50;
    const YOUTUBE_MAX_LENGTH = 50;

    if (modalTab === 'single') {
      if (!consumerValuesObject?.name || consumerValuesObject?.name?.length > NAME_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.lastname?.length > NAME_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.email && !isValidEmailRef?.current) {
        isValid = false;
      }
      if (consumerValuesObject?.phone?.length > 0 && !isValidPhoneRef.current) {
        isValid = false;
      }
      if (consumerValuesObject?.address?.length > ADDRESS_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.observations?.length > OBSERVATIONS_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.instagram_user?.length > INSTAGRAM_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.twitter?.length > TWITTER_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.facebook_user?.length > FACEBOOK_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.youtube?.length > YOUTUBE_MAX_LENGTH) {
        isValid = false;
      }
      if (consumerValuesObject?.whatsapp?.length > 0 && !isValidWhatsappRef.current) {
        isValid = false;
      }
    } else {
      if (!selectedFileConsumers?.name) {
        isValid = false;
      }
    }

    return isValid;
  }

  /**
   * Implementar submit e outras lógicas do modal de consumidor
   * Input de telefone
   * Lógica do campo documento, para exibir o documento correto e validação
   */
  const handleSubmit = () => {
    if (isValid() && !isCreatedByEmployee) {
      // const form: ConsumerFormInterface = {
      const form = {
        name: consumerValuesObject.name
      }

      if (consumerValuesObject.lastname) {
        form['lastname'] = consumerValuesObject.lastname;
      }
      if (consumerValuesObject.email) {
        form['email'] = consumerValuesObject.email;
      }
      if (consumerValuesObject.document_number) {
        form['document_number'] = consumerValuesObject.document_number;
      }
      if (consumerValuesObject.phone && consumerValuesObject.phone.length > 3) {
        form['phone'] = consumerValuesObject.phone;
      }
      if (consumerValuesObject.address) {
        form['address'] = consumerValuesObject.address;
      }
      if (consumerValuesObject.observations) {
        form['observations'] = consumerValuesObject.observations;
      }
      if (consumerValuesObject.whatsapp && consumerValuesObject?.whatsapp.length > 3) {
        form['whatsapp'] = consumerValuesObject.whatsapp;
      }
      if (consumerValuesObject.instagram_user) {
        form['instagram_user'] = consumerValuesObject.instagram_user;
      }
      if (consumerValuesObject.twitter) {
        form['twitter'] = consumerValuesObject.twitter;
      }
      if (consumerValuesObject.facebook_user) {
        form['facebook_user'] = consumerValuesObject.facebook_user;
      }
      if (consumerValuesObject.youtube) {
        form['youtube'] = consumerValuesObject.youtube;
      }

      if (selectedEmployee?.id) {
        form['employee_user_id'] = selectedEmployee.id;
      }
      if (selectedTags?.length > 0) {
        form['tags'] = selectedTags.map(item => item.id);
      }

      if (customFieldsList.length > 0) {
        Object.keys(customFieldsValuesObject).map(key => {
          if (!form['custom_fields']) {
            form['custom_fields'] = [{ custom_field_id: key, value: customFieldsValuesObject[key] ? [customFieldsValuesObject[key]] : [] }];
          } else {
            form['custom_fields'].push({ custom_field_id: key, value: customFieldsValuesObject[key] ? [customFieldsValuesObject[key]] : [] });
          }
        });
      }

      const headers = UserService.getHeaders();
      const config = { headers };

      setConsumerForm(null);

      if (mode === 'create') {
        setConsumerForm(form);
        AppRequesterConst.Post(
          '/consumer', form, config,
          () => {},
          (response: ResponsePostConsumer) => {
            if (response.data.ConsumersDuplicates) { // Consumidor duplicado
              // props.hide(false, { current: form as ConsumerInterface, duplicated: response.data.ConsumersDuplicates });
              setDuplicatedConsumers(response.data.ConsumersDuplicates);
              const ids = response.data.ConsumersDuplicates.map(item => item.id);
              setIdsConsumersDuplicates(ids);

              const similarTemp: ConsumerFormInterface[] = [];

              similarTemp.push({
                id: '000',
                name: form['name'],
                lastname: form['lastname'],
                email: form['email'],
                document_number: form['document_number'],
                phone: form['phone'],
                address: form['address'],
                observations: form['observations'],
                whatsapp: form['whatsapp'],
                instagram_user: form['instagram_user'],
                twitter: form['twitter'],
                facebook_user: form['facebook_user'],
                youtube: form['youtube'],
                custom_fields: form['custom_fields']
              });

              response.data.ConsumersDuplicates.forEach(item => {
                similarTemp.push({
                  id: item.id,
                  name: item['name'],
                  lastname: item['lastname'],
                  email: item['email'],
                  document_number: item['document_number'],
                  phone: item['phone'],
                  address: item['address'],
                  observations: item['observations'],
                  whatsapp: item['whatsapp'],
                  instagram_user: item['instagram_user'],
                  twitter: item['twitter'],
                  facebook_user: item['facebook_user'],
                  youtube: item['youtube'],
                  custom_fields: item['custom_fields']
                });
              });

              setSimilarConsumers(similarTemp);

              changeMode('duplicated');
            } else { // Criado com sucesso
              dispatch(setShowAlertFeedback({ message: t('alert.successfully_create'), visibility: true, signalIcon: true }));
              props.hide(true);
            }
          },
          (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
            if (error.response?.data?.code_cxpress) {
              dispatch(setShowAlertFeedback({ message: t('alert.error_create', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
            } else {
              if (error.response.data?.message[0]) {
                let error_field = error.response.data?.message[0]?.context?.key;

                if (error_field === 'document_number') {
                  if (error.response.data.message[0]?.type === 'doc.character-invalid') {
                    error_field = 'document_number_invalid_char';
                  }
                }

                dispatch(setShowAlertFeedback({ message: t(error_field ? `alert.error_${error_field}` : 'alert.error'), visibility: true, signalIcon: false }));
              } else {
                dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
              }
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      } else {
        form['id'] = consumer.user_id;
        setConsumerForm(form);

        AppRequesterConst.Put(
          '/consumer', form, config,
          () => {},
          (response: ResponsePostConsumer) => {
            if (response.data.ConsumersDuplicates) { // Consumidor duplicado
              // props.hide(false, { current: { ...form, id: consumer.id } as ConsumerInterface, duplicated: response.data.ConsumersDuplicates });
              setDuplicatedConsumers(response.data.ConsumersDuplicates);
              const ids = [consumer.id, ...response.data.ConsumersDuplicates.map(item => item.id)];
              setIdsConsumersDuplicates(ids);

              const similarTemp: ConsumerFormInterface[] = [];

              similarTemp.push({
                id: consumer.id,
                name: form['name'],
                lastname: form['lastname'],
                email: form['email'],
                document_number: form['document_number'],
                phone: form['phone'],
                address: form['address'],
                observations: form['observations'],
                whatsapp: form['whatsapp'],
                instagram_user: form['instagram_user'],
                twitter: form['twitter'],
                facebook_user: form['facebook_user'],
                youtube: form['youtube'],
                custom_fields: form['custom_fields']
              });

              response.data.ConsumersDuplicates.forEach(item => {
                similarTemp.push({
                  id: item.id,
                  name: item['name'],
                  lastname: item['lastname'],
                  email: item['email'],
                  document_number: item['document_number'],
                  phone: item['phone'],
                  address: item['address'],
                  observations: item['observations'],
                  whatsapp: item['whatsapp'],
                  instagram_user: item['instagram_user'],
                  twitter: item['twitter'],
                  facebook_user: item['facebook_user'],
                  youtube: item['youtube'],
                  custom_fields: item['custom_fields']
                });
              });

              setSimilarConsumers(similarTemp);

              changeMode('duplicated');
            } else { // Criado com sucesso
              dispatch(setShowAlertFeedback({ message: t('alert.successfully_edit'), visibility: true, signalIcon: true }));
              props.hide(true);
              if (window.location.href.includes("/tickets")) {
                props?.fetchDataConsumer(props?.currentTicket?.user_id)
                props?.fetchData()
              }
            }
          },
          (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
            if (error.response?.data?.code_cxpress) {
              dispatch(setShowAlertFeedback({ message: t('alert.error_edit', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
            } else {
              if (error.response.data?.message[0]) {
                let error_field = error.response.data?.message[0]?.context?.key;

                if (error_field === 'document_number') {
                  if (error.response.data.message[0]?.type === 'doc.character-invalid') {
                    error_field = 'document_number_invalid_char';
                  }
                }
                dispatch(setShowAlertFeedback({ message: t(error_field ? `alert.error_${error_field}` : 'alert.error'), visibility: true, signalIcon: false }));
              } else {
                dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
              }
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      }      
    }
  }

  const handleImport = () => {
    uploadFiles(acceptedFiles);
  }

  // =============================================
  // FILE UPLOADER

  const validator = useCallback(<T extends File>(file: T) => {
    const validateArray = [
      'application/octet-stream', 
      'application/x-msdownload', 
      'vnd.microsoft.portable-executable',
      'application/x-msi',
      'application/x-ms-dos-executable',
      'application/vnd.debian.binary-package',
    ];

    if (file) {
      if (!file.type || validateArray.includes(file.type)) {
        return { message: t('chat_editor.unknown_file'), code: ErrorCode.FileInvalidType };
      }
    }
    return null;
  }, []);

  const onDrop = useCallback(<T extends File>(acceptedFiles: T[], fileRejections?: FileRejection[], event?: DropEvent) => {
    setIsDragging(false);
    try {
      if (acceptedFiles.length > 0) {
        setSelectedFileConsumers({ id: null, name: acceptedFiles[0].name });
        // uploadFiles(acceptedFiles);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onDropRejected = (fileRejections: FileRejection[], event?: DropEvent) => {
    if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_file_length'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooSmall) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_empty_files'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileInvalidType) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_only_csv'), signalIcon: false }));
    }
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: MAX_FILE_SIZE,
    disabled: !!selectedFileConsumers?.name,
    noClick: !!selectedFileConsumers?.name,
    noDrag: !!selectedFileConsumers?.name,
    validator,
    accept: { 'text/csv': ['.csv'] },
    onDrop,
    onDropRejected,
    onDragEnter: () => { setIsDragging(true); },
    onDragLeave: () => { setIsDragging(false); },
  });

  const uploadFiles = (files: CustomFile[]) => {
    if (files && files.length > 0) {
      const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        'Authorization': 'Bearer ' + userToken
      };

      const formData = new FormData();

      let files_size = 0;
  
      formData.append('file', files[0]);

      const currentSize = currentConsumersFileSize || 0;
      const updatedSize = currentSize + files_size;
      setCurrentConsumersFileSize(updatedSize);
  
      AppRequesterConst.Post(
        '/consumer/import-csv-consumers', formData, { headers },
        () => { },
        (response: any) => {
          if (response.status === 200) {
            setSelectedFileConsumers({ id: response.data.id, name: files[0].name });
          }
  
          dispatch(setShowAlertFeedback({ message: t('alert.successfully_import'), visibility: true, signalIcon: true }));
          props.hide(true);
        },
        (error: {response: {status: number; data: { message: any []; code_cxpress: number}}}) => {
          if (error.response?.data?.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
          } else if (error.response?.data?.message && error.response?.data?.message[0]) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message[0], visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }))
          }
        }, navigate, dispatch, setIsLoadingUploadConsumersFile,
      );
    }
  }

  const removeSelectedFile = () => {
    setSelectedFileConsumers(null);
    acceptedFiles.pop();
  }

  // ======================================================================

  const formatDataSelect = (data: any[], type: FormatDataSelectType): { id: string, value: string}[] => {
    const formatted = [];

    data.forEach(item => {
      if (type === 'tags') {
        formatted.push({ id: item.id, value: item.name })
      }
      if (type === 'employee') {
        formatted.push({ id: item.user_id, value: item.name });
      }
      if (type === 'custom-field') {
        formatted.push({ id: item, value: item });
      }
    });

    return formatted;
  }

  const formatCustomFieldOptions = (custom_field_type_id: string, options: any[]) => {
    const selectType = [CustomField.Dropdown.toString(), CustomField.Multiselect.toString()];
    if (selectType.includes(custom_field_type_id) && options) {
      return formatDataSelect(options, 'custom-field');
    } else {
      return null;
    }
  }

  const formatCustomFieldValue = (custom_field_type_id: string, value: any[]) => {
    const selectType = [CustomField.Dropdown.toString(), CustomField.Multiselect.toString()];
    if (selectType.includes(custom_field_type_id) && value) {
      if (value[0]) {
        return formatDataSelect(value, 'custom-field');
      } else {
        return null;
      }
    } else {
      return value[0];
    }
  }

  const setCustomFieldValue = (custom_field_id: string, value: any) => {
    setCustomFieldsValuesObject((customFieldsValuesObject: any) => ({
      ...customFieldsValuesObject,
      [custom_field_id]: value,
    }));
  }

  const setConsumerValue = (property: string, value: any) => {
    setConsumerValuesObject((consumerValuesObject: any) => ({
      ...consumerValuesObject,
      [property]: value,
    }));
  }

  const getCustomFieldValueSimilar = (customFields: { custom_field_id: string; value: any[] }[], custom_field_id: string) => {
    const item = customFields.find(item => item.custom_field_id === custom_field_id);

    return item?.value[0] || null;
  }

  const setSimilarField = (type: 'default' | 'custom_field', field: string, value: string) => {
    const temp = Object.assign({}, similarForm);

    if (type === 'custom_field' && temp.custom_fields) {
      temp.custom_fields[field] = value;
    } else {
      temp[field] = value;
    }

    setSimilarForm(temp);
  }

  const handleMouseEnterItem = (field: string) => {
    setItemHovered(field);
  };

  const handleMouseLeaveItem = () => {
    setItemHovered(null);
  };

  const getSimilarLabelClass = (field: string) => {
    if (itemHovered === field) {
      return 'modal-consumer-unification-analysis-fields-values-item fields-values-item-hovered';
    } else {
      return 'modal-consumer-unification-analysis-fields-values-item';
    }
  }

  const selectAllFromUser = (consumer_id: string) => {
    const tempForm = Object.assign({}, similarForm);
    Object.keys(tempForm).forEach(key => {
      if (key !== 'custom_fields') {
        tempForm[key] = consumer_id;
      } else {
        Object.keys(tempForm.custom_fields).forEach(field => {
          tempForm.custom_fields[field] = consumer_id;
        });
      }
    });

    setSimilarForm(tempForm);
  }

  const proccessFinalConsumer = () => {
    const finalForm = Object.assign({}, similarForm);

    const consumersData: { [key: string]: ConsumerFormInterface } = {};

    if (consumer) {
      consumersData[consumer.id] = consumerForm;
    } else {
      consumersData['000'] = consumerForm;
    }

    duplicatedConsumers.forEach(item => {
      consumersData[item.id] = {
        name: item.name,
        lastname: item.lastname,
        email: item.email,
        document_number: item.document_number,
        phone: item.phone,
        address: item.address,
        observations: item.observations,
        whatsapp: item.whatsapp,
        instagram_user: item.instagram_user,
        twitter: item.twitter,
        facebook_user: item.facebook_user,
        youtube: item.youtube,
        custom_fields: item.custom_fields
      };
    });

    Object.keys(similarForm).forEach(key => {
      if (key !== 'custom_fields') {
        finalForm[key] = consumersData[similarForm[key]][key];
      } else {
        finalForm['custom_fields'] = [];

        customFieldsList.forEach(item => {
          finalForm['custom_fields'].push({
            custom_field_id: item.id,
            value: []
          });
        });

        Object.keys(similarForm['custom_fields']).forEach(custom_field_id => {
          const consumer_id = similarForm['custom_fields'][custom_field_id];

          finalForm['custom_fields'].forEach(item => {
            if (item.custom_field_id === custom_field_id) {
              item.value = consumersData[consumer_id].custom_fields.filter(item => item.custom_field_id === custom_field_id)[0]?.value || [];
            }
          });
        });
      }

      if (['id', 'user_id', 'facebook', 'instagram', 'created_by_employee', 'webchat'].includes(key)) {
        delete finalForm[key];
      }
    });

    setFinalSimilarConsumer(finalForm);
  }

  const handleUnification = () => {
    const form = {
      consumer: finalSimilarConsumer,
      idsConsumersDuplicates
    }

    const headers = UserService.getHeaders();
    const config = { headers };

    AppRequesterConst.Post(
      '/consumer/unification', form, config,
      () => {},
      (response: ResponsePostConsumer) => {
        dispatch(setShowAlertFeedback({ message: t('alert.successfully_unification'), visibility: true, signalIcon: true }));
        props.hide(true);
      },
      (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
        if (error.response?.data?.code_cxpress) {
          dispatch(setShowAlertFeedback({ message: t('alert.error_edit', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
        } else {
          if (error.response.data?.message[0]) {
            let error_field = error.response.data?.message[0]?.context?.key;

            if (error_field === 'document_number') {
              if (error.response.data.message[0]?.type === 'doc.character-invalid') {
                error_field = 'document_number_invalid_char';
              }
            }
            dispatch(setShowAlertFeedback({ message: t(error_field ? `alert.error_${error_field}` : 'alert.error'), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          }
        }
      },
      navigate, dispatch, setLoadingRequest
    );
  }

  const isSomeLoading = (): boolean => {
    return loadingConsumer || loadingEmployeeAssigned || isLoadingCustomFieldsList || isLoadingEmployeesList || isLoadingTagsList;
  }

  const getEmployeeSelect = () => {
    return {
      employeesList,
      selectedEmployee,
      setSelectedEmployee,
      isLoadingEmployeesSelect,
      fetchEmployeesPagination,
      fetchSearchEmployees,
      hasMoreEmployeesList,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList,
      selectedTags,
      setSelectedTags,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTagsList,
    };
  }

  const getCustomFieldsData = () => {
    return {
      customFieldsList,
      formattedCustomFields,
      setCustomFieldValue,
      customFieldsValuesObject,
    }
  }

  const handleOpenPopover = () => {
    setShowMenuDownload(true);
  }

  const handlePopoverFileModelAction = (language: string) => {
    emitter.emit('close-action-popover-file-model-file-consumers');
    setShowMenuDownload(false);
    switch(language) {
      case 'pt-br': {
        window.open('https://storage.googleapis.com/cxpress-assets/files/import-consumers/csv-modelo-importar-consumidores-ptbr.csv');
        break;
      }
      case 'pt-pt': {
        window.open('https://storage.googleapis.com/cxpress-assets/files/import-consumers/csv-modelo-importar-consumidores-ptpt.csv');
        break;
      }
      case 'es': {
        window.open('https://storage.googleapis.com/cxpress-assets/files/import-consumers/csv-modelo-importar-consumidores-es.csv');
        break;
      }
      case 'uk': {
        window.open('https://storage.googleapis.com/cxpress-assets/files/import-consumers/csv-model-template-import-consumers-uk.csv');
        break;
      }
      case 'fr': {
        window.open('https://storage.googleapis.com/cxpress-assets/files/import-consumers/csv-modele-importer-consommateurs-fr.csv');
        break;
      }
    }
  }

  const popoverFileModel = () => {
    return (
      <Popover bsPrefix='popover-custom-action-menu popover-file-model-file-consumers'>
        <Popover.Body>
          <span className='popover-item' onClick={(e) => handlePopoverFileModelAction('pt-br')}>
            Português (BR)
          </span>
          <span className='popover-item' onClick={(e) => handlePopoverFileModelAction('pt-pt')}>
            Português (PT)
          </span>
          <span className='popover-item' onClick={(e) => handlePopoverFileModelAction('es')}>
            Español
          </span>
          <span className='popover-item' onClick={(e) => handlePopoverFileModelAction('uk')}>
            English
          </span>
          <span className='popover-item' onClick={(e) => handlePopoverFileModelAction('fr')}>
            Français
          </span>
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ModalConsumer
      t={t}
      consumer={consumer}
      isCreatedByEmployee={isCreatedByEmployee}
      loading={isSomeLoading()}
      loadingRequest={loadingRequest}
      show={props.show}
      hide={props.hide}
      mode={mode}
      changeMode={changeMode}
      modalTab={modalTab}
      changeModalTab={changeModalTab}
      getEmployeeSelect={getEmployeeSelect}
      getTagsSelect={getTagsSelect}
      getCustomFieldsData={getCustomFieldsData}
      handleSubmit={handleSubmit}
      handleImport={handleImport}
      isValid={isValid}
      showCustomFields={showCustomFields}
      setShowCustomFields={setShowCustomFields}
      consumerValuesObject={consumerValuesObject}
      setConsumerValue={setConsumerValue}
      isValidEmailRef={isValidEmailRef}
      // isValidDocumentRef={isValidDocumentRef}
      isValidPhoneRef={isValidPhoneRef}
      isValidWhatsappRef={isValidWhatsappRef}
      popoverFileModel={popoverFileModel}
      showMenuDownload={showMenuDownload}
      handleOpenPopover={handleOpenPopover}

      selectedFileConsumers={selectedFileConsumers}
      isLoadingUploadConsumersFile={isLoadingUploadConsumersFile}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      isDragging={isDragging}
      removeSelectedFile={removeSelectedFile}

      consumerForm={consumerForm}
      duplicatedConsumers={duplicatedConsumers}
      similarConsumers={similarConsumers}
      clickConsumerInfo={clickConsumerInfo}
      expandedConsumerInfo={expandedConsumerInfo}
      getCustomFieldValueSimilar={getCustomFieldValueSimilar}
      similarForm={similarForm}
      setSimilarField={setSimilarField}
      handleMouseEnterItem={handleMouseEnterItem}
      handleMouseLeaveItem={handleMouseLeaveItem}
      getSimilarLabelClass={getSimilarLabelClass}
      selectAllFromUser={selectAllFromUser}
      finalSimilarConsumer={finalSimilarConsumer}
      handleUnification={handleUnification}
    />
  );
}

export default ModalConsumerController;