import React, { forwardRef } from 'react'
//import styles from './styles';
import "./styles.scss"
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import SelectComponentController from '../../../../components/selectComponent/indexController';
import DatePicker from 'react-datepicker';
import { t } from 'i18next';
import { IconCalendarEvent } from '@tabler/icons-react';
import ModalExportDataController from './ModalExportData/indexController';
import Loading from '../../../../../../components/loading';

const DateInput = forwardRef<any, any>((props, ref) => (
    <Col className='export-date-calendar' ref={ref} onClick={props.onClick} aria-labelledby="Data">
        <span className='tabler-icon-calendar'>
            <IconCalendarEvent />
        </span>
        <input type='text' className={`input-calendar ${!props.isInvalid ? "error-selected" : ""}`} placeholder={t("configurations.chatbots.view.tab_collected_data.date_select_placeholder")} onChange={props.onChange} value={props.value} />
    </Col>
));

const BotCollectedData = ({
    hasData,
    fieldsOptions,
    selectedFieldOptions,
    setSelectedFieldOptions,
    startDate,
    endDate,
    onChangeDate,
    calculateMaxDate,
    isDateSelectable,
    handleOpenModalExport,
    handleCloseModalExport,
    showModal,
    isLoading,
    botId,
    disableButtonExport,
    validDate
}) => {
    
    return (
        <Container className='nopadding m-2'>
            <Loading visibility={isLoading}></Loading>

            <ModalExportDataController visibility={showModal} handleCloseModalExport={handleCloseModalExport} botId={botId} endDate={endDate} startDate={startDate} fields={selectedFieldOptions} />

            <Row className='nopadding'>
                <p className='title-collected'>{t("configurations.chatbots.view.tab_collected_data.title_tab")}</p> 
            </Row>

            <Row className='nopadding'>
                <p className='subtitle-collected'>{t("configurations.chatbots.view.tab_collected_data.subtitle_tab_part_1")}</p>
                <p className='subtitle-collected'>{t("configurations.chatbots.view.tab_collected_data.subtitle_tab_part_2")}</p>
                <p className='subtitle-collected'>{t("configurations.chatbots.view.tab_collected_data.subtitle_tab_part_3")}</p>
            </Row>

            {
                hasData ?
                    <Row className='nopadding mt-4' style={{width: '40%'}}>
                        <Row >
                            <Col className='nopadding'>
                                <Form.Label htmlFor="title" className='label-title'>{t("configurations.chatbots.view.tab_collected_data.fields_select_title")}<span style={{color: 'red', fontWeight: 400}}> *</span></Form.Label>
                                <SelectComponentController
                                    className={`selected-fields ${selectedFieldOptions.length === 0 ? "error-selected" : ""}`}
                                    options={fieldsOptions} 
                                    setOptions={() => {}} 
                                    selecteds={selectedFieldOptions} 
                                    setSelecteds={setSelectedFieldOptions} 
                                    singleSelect={false} 
                                    isFormNoFilter={true} 
                                    title={"Campos"} 
                                    overrideStrings={{
                                        "allItemsAreSelected": t("configurations.chatbots.view.tab_collected_data.fields_select.allItemsAreSelected"),
                                        "clearSearch": t("configurations.chatbots.view.tab_collected_data.fields_select.clearSearch"),
                                        "clearSelected": t("configurations.chatbots.view.tab_collected_data.fields_select.clearSelected"),
                                        "noOptions": t("configurations.chatbots.view.tab_collected_data.fields_select.noOptions"),
                                        "search": t("configurations.chatbots.view.tab_collected_data.fields_select.search"),
                                        "selectAll": t("configurations.chatbots.view.tab_collected_data.fields_select.selectAll"),
                                        "selectAllFiltered": t("configurations.chatbots.view.tab_collected_data.fields_select.selectAllFiltered"),
                                        "selectSomeItems": t("configurations.chatbots.view.tab_collected_data.fields_select.selectSomeItems"),
                                        "create": t("configurations.chatbots.view.tab_collected_data.fields_select.create")
                                    }} 
                                    hasCreateItem={false} 
                                    hasNullOption={false} 
                                    hasAllOption={true} 
                                    disableSearch
                                />
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col className='nopadding'>
                                <Form.Label htmlFor="title" className='label-title'>{t("configurations.chatbots.view.tab_collected_data.date_title")}<span style={{color: 'red', fontWeight: 400}}> *</span></Form.Label>
                                <Row className='nopadding' /* style={{width: "33%"}} */>
                                    <DatePicker
                                    dateFormat={"dd/MM/yyy"}
                                    placeholderText={"dd/mm/yyy"}
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChangeDate}
                                    maxDate={calculateMaxDate(startDate)}
                                    filterDate={isDateSelectable}
                                    allowSameDay={false}
                                    customInput={<DateInput isInvalid={validDate}/>}
                                    onCalendarClose={() => {
                                        if (!endDate) /* se não tiver a data final, zerar a data de inicio */
                                            onChangeDate([null, null])
                                    }}
                                />
                                </Row>
                            </Col>
                        </Row>

                        <Row /* className='nopadding' */>
                            <Col className="nopadding mt-4">
                                <Button className="buttonBlue button-export" onClick={handleOpenModalExport} disabled={disableButtonExport()}>{t("configurations.chatbots.view.tab_collected_data.button_export")}</Button>
                            </Col>
                        </Row>
                    </Row>
                    
                : /* caso onde não há dados */
                    <Row className='nopadding d-flex align-content-center empty-collected mt-4'>
                        <p className='nopadding' style={{textAlign: 'center'}}>{t("configurations.chatbots.view.tab_collected_data.empty_collected_data")}</p>
                    </Row>
            }

        </Container>
    )
}

export default BotCollectedData;