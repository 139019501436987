import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import ToolbarFileUploader from './index';
import { CustomFile, CustomFileAttributes, FetchAttachmentData, ToolbarFileUploaderControllerInterface, getDropzoneAccept, getFileDetailsFromURL, getIconFileType } from './indexModel';
import { ErrorCode, useDropzone, FileWithPath, FileRejection, DropEvent } from 'react-dropzone';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAlertFeedback } from '../../../../../../../../store/internal';
import { Channels } from '../../../../../../../../core/enums/channels';
import { Popover } from 'react-bootstrap';
import { getToken } from '../../../../../../../../store/token';
import { AppRequesterController } from '../../../../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { verifyCode } from '../../../../../../../../services/codeCxpressInterface';
import { TemplateMessageAttachmentInfoType } from '../popupTemplateMessages/indexModel';

const MAX_FILE_SIZE = 15728640; // 15MB

const ToolbarFileUploaderController: FC<ToolbarFileUploaderControllerInterface> = (props) => {
  const { t } = useTranslation('ToolbarFileUploader');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AppRequester = new AppRequesterController();

  const {
    currentTicket,
  } = useViewTicketContext();

  const userToken = useSelector(getToken);

  const [currentAttachmentSize, setCurrentAttachmentSize] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [_isLoading, setIsLoading] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [previewFirstFileUpload, setPreviewFirstFileUpload] = useState<FileWithPath & CustomFileAttributes>(null);
  const [previewTemplateMessageAttachment, setPreviewTemplateMessageAttachment] = useState<TemplateMessageAttachmentInfoType>(null);

  const fileUploaderRef = useRef<HTMLDivElement>(null);
  const removeUploadRef = useRef<HTMLDivElement>(null);
  const acceptedMimetypesRef = useRef<{[key: string]: string[]}>(null);

  const validator = useCallback(<T extends File>(file: T) => {
    const validateArray = [
      'application/octet-stream', 
      'application/x-msdownload', 
      'vnd.microsoft.portable-executable',
      'application/x-msi',
      'application/x-ms-dos-executable',
      'application/vnd.debian.binary-package',
    ];

    if (file) {
      if (!file.type || validateArray.includes(file.type)) {
        return { message: t('chat_editor.unknown_file'), code: ErrorCode.FileInvalidType };
      }
    }
    return null;
  }, []);

  // const filesValidator = useCallback(<T extends File>(files: T[]): { message: string, code: string }[] => {
  //   const validateArray = [
  //     'application/octet-stream', 
  //     'application/x-msdownload', 
  //     'vnd.microsoft.portable-executable',
  //     'application/x-msi',
  //     'application/x-ms-dos-executable',
  //     'application/vnd.debian.binary-package',
  //   ];

  //   const validators: { message: string, code: string }[] = [];

  //   files.forEach(file => {
  //     if (!file.type || validateArray.includes(file.type)) {
  //       validators.push({ message: t('chat_editor.unknown_file'), code: ErrorCode.FileInvalidType });
  //     } else {
  //       validators.push(null);
  //     }
  //   });

  //   return validators;
  // }, []);

  const onDrop = useCallback(<T extends File>(acceptedFiles: T[], fileRejections?: FileRejection[], event?: DropEvent) => {
    setIsDragging(false);
    try {
      const sizeFiles = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
      if ((currentAttachmentSize + sizeFiles) > MAX_FILE_SIZE) {
        const newFiles = props.files.filter((item) => item.path !== acceptedFiles[0].name);
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.all_files_length'), signalIcon: false }));
        props.setFiles(newFiles);
      } else {
        uploadFiles(acceptedFiles);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onDropRejected = (fileRejections: FileRejection[], event?: DropEvent) => {
    if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.file_length'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooSmall) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.empty_files'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileInvalidType) {
      if (currentTicket.channel_id === Channels.Instagram) {
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.image_files_only'), signalIcon: false }));
      } else if (currentTicket.channel_id === Channels.WhatsApp) {
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.media_files_only'), signalIcon: false }));
      } else if (currentTicket.channel_id === Channels.Webchat) {
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.document_files_only'), signalIcon: false }));
      } else {
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.files_not_supported'), signalIcon: false }));
      }
    }
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: props.hasTriggerTemplate ? false : true,
    minSize: 1,
    maxSize: MAX_FILE_SIZE,
    noClick: props.hasTriggerTemplate && props.files.length === 1,
    noDrag: props.hasTriggerTemplate && props.files.length === 1,

    validator,
    accept: acceptedMimetypesRef.current,
    onDrop,
    onDropRejected,
    onDragEnter: () => { setIsDragging(true); },
    onDragLeave: () => { setIsDragging(false); },
  });

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (fileUploaderRef?.current && !fileUploaderRef?.current?.contains(event.target) &&
        props.attachmentIconRef?.current && !props.attachmentIconRef?.current?.contains(event.target)
      ) {
        props.setShowFileUploader(false);
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.setShowFileUploader(false);
      }
    }

    if (props.showFileUploader) {
      if (props.fileUploadSpecificTypes) {
        acceptedMimetypesRef.current = props.fileUploadSpecificTypes;
      } else {
        acceptedMimetypesRef.current = getDropzoneAccept(props.typeChat === 'message-trigger' ? 'message-trigger' : currentTicket?.channel_id);
      }

      if (fileUploaderRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.showFileUploader]);

  useEffect(() => {
    if (props.files.length > 0) {
      const actualSize = props.files.reduce((accumulator, current) => accumulator + current.size, 0);
      setCurrentAttachmentSize(actualSize);
      props.setHasFileUploader(true);
    } else {
      props.setHasFileUploader(false);
    }
  }, [props.files]);

  useEffect(() => {
    let allFiles = props.files.concat(acceptedFiles);
    allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];

    allFiles.forEach((file: FileWithPath & CustomFileAttributes) => {
      if (!file.preview) {
        const returnedTarget = Object.assign(file, { 
          isLoading: true,
          preview: URL.createObjectURL(file)
        });
        file = returnedTarget;
      }
    });

    props.setFiles(allFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (!showFilePreview) {
      setPreviewFirstFileUpload(null);
    }
  }, [showFilePreview]);

  // Controlar upload de arquivos com CTRL + V no editor
  useEffect(() => {
    proccessExternalFile(props.filesPasted, 'onpaste');
  }, [props.filesPasted]);

  useEffect(() => {
    proccessExternalFile(props.audioRecordedFiles, 'audiorecorder');
  }, [props.audioRecordedFiles]);

  useEffect(() => {
    if (props.selectedTemplateMessage?.quick) {
      const info = getFileDetailsFromURL(props.selectedTemplateMessage.quick.quick_message_attachment);
      setPreviewTemplateMessageAttachment(info);
    } else if (props.selectedTemplateMessage?.whatsapp) {
      const info = getFileDetailsFromURL(props.selectedTemplateMessage.whatsapp.image_url);
      setPreviewTemplateMessageAttachment(info);
    }
  }, [props.selectedTemplateMessage]);

  // const uploadFile = (file: FileWithPath & CustomFileAttributes) => {
  //   if (file) {
  //     const headers = {
  //       'Content-Type': 'multipart/form-data; charset=utf-8',
  //       'Authorization': 'Bearer ' + userToken
  //     };

  //     const formData = new FormData();
  
  //     formData.append('file', file);

  //     if (props.typeChat === 'view-ticket') {
  //       formData.append('channel_id', currentTicket?.channel_id);
  //     } else {
  //       formData.append('channel_id', props.createSelectedChannel);
  //     }

  //     if (file.type.includes('audio')) {
  //       formData.append('audio_whatsapp', 'true');
  //     } else {
  //       formData.append('audio_whatsapp', 'false');
  //     }

  //     const currentSize = currentAttachmentSize || 0;
  //     const updatedSize = currentSize + file.size;
  //     setCurrentAttachmentSize(updatedSize);
  
  //     AppRequester.Post(
  //       '/chat/upload/attachment', formData, { headers },
  //       (response: any) => { },
  //       (response: { data: { id: string; name: string; }; }) => {
  //         props.setFiles(prevFiles => {
  //           return prevFiles.map((item: FileWithPath & CustomFileAttributes) => {
  //             if (item.name === file.name) {
  //               item.isLoading = false;
  //               item.dateUploaded = new Date().toISOString();
  //               item.bucketId = response.data.id;
  //               item.errorUpload = false;
  //             }
  //             return item;
  //           });
  //         });
  
  //         dispatch(setShowAlertFeedback({ message: t('chat_editor.upload_success'), visibility: true, signalIcon: true }));
  //       },
  //       (error: {response: {status: number; data: { message: any []; code_cxpress: number}}}) => {
  //         props.setFiles(prevFiles => {
  //           return prevFiles.map((item: FileWithPath & CustomFileAttributes) => {
  //             if (item.name === file.name) {
  //               item.isLoading = false;
  //               item.errorUpload = true;
  //             }
  //             return item;
  //           });
  //         });
  //         if (error.response?.data?.message && error.response.data.message[0]) {
  //           dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
  //         } else {
  //           dispatch(setShowAlertFeedback({ message: t('error.TicketErrorMessage'), visibility: true, signalIcon: false }))
  //         }
  //       }, navigate, dispatch, setIsLoading,
  //     );
  //   }
  // }

  const uploadFiles = (files: CustomFile[]) => {
    if (files?.length > 0) {
      const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        'Authorization': 'Bearer ' + userToken
      };

      const formData = new FormData();

      let has_audio_file = false;
      let files_size = 0;
  
      files.forEach(file => {
        if (!file.bucketId) {
          formData.append('file', file);
          files_size += file.size;
          if (file.type.includes('audio')) {
            has_audio_file = true;
          }
        }
      });

      if (props.typeChat === 'view-ticket') {
        formData.append('channel_id', currentTicket?.channel_id);
      } else {
        formData.append('channel_id', props.createSelectedChannel);
      }

      if (has_audio_file) {
        formData.append('audio_whatsapp', 'true');
      } else {
        formData.append('audio_whatsapp', 'false');
      }

      const currentSize = currentAttachmentSize || 0;
      const updatedSize = currentSize + files_size;
      setCurrentAttachmentSize(updatedSize);
  
      AppRequester.Post(
        '/chat/upload/attachment', formData, { headers },
        (response: any) => { },
        (response: FetchAttachmentData) => {
          if (response.status === 200) {
            const returnedFiles = {};

            response.data.files.forEach(file => {
              returnedFiles[`${file.name}`] = file.id;
            });

            props.setFiles(prevFiles => {
              return prevFiles.map((item: CustomFile) => {
                const normalizedFileName = item.name.split('.')[0].replace(/\s/g, '_');
                const matchFileName = Object.keys(returnedFiles).find(apiFileName => apiFileName.startsWith(normalizedFileName));

                if (matchFileName) {
                  item.isLoading = false;
                  item.dateUploaded = new Date().toISOString();
                  item.bucketId = returnedFiles[matchFileName];
                  item.errorUpload = false;
                }

                return item;
              });
            });
          }
  
          dispatch(setShowAlertFeedback({ message: t('chat_editor.upload_success'), visibility: true, signalIcon: true }));
        },
        (error: {response: {status: number; data: { message: any []; code_cxpress: number}}}) => {
          const returnedFiles = {};
            
          files.forEach(file => {
            returnedFiles[file.name] = file.preview;
          });

          props.setFiles(prevFiles => {
            return prevFiles.map((item: CustomFile) => {
              if (returnedFiles[item.name]) {
                item.isLoading = false;
                item.errorUpload = true;
              }
              return item;
            });
          });
          if (error.response?.data?.message && error.response.data.message[0]) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('error.TicketErrorMessage'), visibility: true, signalIcon: false }))
          }
        }, navigate, dispatch, setIsLoading,
      );
    }
  }

  const bytesToSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) {
      return '0';
    } else {
      const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
      if (i === 0) return `${bytes.toLocaleString('pt-BR', { maximumFractionDigits: 1 })} ${sizes[i]}`;
      return `${(bytes / (1024 ** i)).toLocaleString('pt-BR', { maximumFractionDigits: 1 })} ${sizes[i]}`;
    }
  }

  const getFileInfo = (filename: string): { name: string, extension: string } => {
    const splitted = filename.split('.');
    const extension = `${splitted.pop()}`;
    return {
      name: splitted.join('.'),
      extension
    };
  }

  const formatDate = (date: string | number, type: 'date' | 'time' | 'datetime'): string => {
    if (type === 'date') {
      return new Date(date).toLocaleDateString();
    } else if (type === 'time') {
      return new Date(date).toLocaleTimeString();
    } else {
      return new Date(date).toLocaleString();
    }
  }

  const getFilePreview = (file: FileWithPath & CustomFileAttributes, previewTemplateMessageAttachment?: TemplateMessageAttachmentInfoType) => {
    return (
      <Popover id={`popover-toolbar-file-uploader-details`}>
        <Popover.Header as="h3">{t('file_details')}</Popover.Header>
        <Popover.Body>
          <div className="popover-toolbar-file-uploader-info">
            <span className="popover-toolbar-file-uploader-info-item">
              <span className="popover-toolbar-file-uploader-info-label">{t('name')}</span>
              <span className="popover-toolbar-file-uploader-info-value">{file?.name || previewTemplateMessageAttachment?.fileName}</span>
            </span>
            { file?.size &&
              <span className="popover-toolbar-file-uploader-info-item">
                <span className="popover-toolbar-file-uploader-info-label">{t('size')}</span>
                <span className="popover-toolbar-file-uploader-info-value">{bytesToSize(file.size)}</span>
              </span>
            }
            { file?.type &&
              <span className="popover-toolbar-file-uploader-info-item">
                <span className="popover-toolbar-file-uploader-info-label">{t('file_type')}</span>
                <span className="popover-toolbar-file-uploader-info-value">{file?.type || previewTemplateMessageAttachment?.mimeType}</span>
              </span>
            }
            { file?.lastModified &&
              <span className="popover-toolbar-file-uploader-info-item">
                <span className="popover-toolbar-file-uploader-info-label">{t('last_modified')}</span>
                <span className="popover-toolbar-file-uploader-info-value">{formatDate(file.lastModified, 'datetime')}</span>
              </span>
            }
            { file && (file.dateUploaded || file.errorUpload || file.isLoading) &&
              <span className="popover-toolbar-file-uploader-info-item">
                <span className="popover-toolbar-file-uploader-info-label">{t('upload_date')}</span>
                { (!file.isLoading && file.dateUploaded) && 
                  <span className="popover-toolbar-file-uploader-info-value">{formatDate(file.dateUploaded, 'datetime')}</span> 
                }
                { (file.isLoading && !file.dateUploaded) && 
                  <span className="popover-toolbar-file-uploader-info-value">{t('in_progress')}</span> 
                }
                { (!file.isLoading && file.errorUpload) && 
                  <span className="popover-toolbar-file-uploader-info-value">{t('upload_failure')}</span> 
                }
              </span>
            }
            { (file?.type?.includes('image') || previewTemplateMessageAttachment?.mimeType?.includes('image')) &&
              <span className="popover-toolbar-file-uploader-info-item popover-toolbar-image-preview">
                <span className="popover-toolbar-file-uploader-info-label">{t('preview')}</span>
                <span className="popover-toolbar-file-uploader-info-value">
                  <img src={file?.preview || previewTemplateMessageAttachment?.url} alt="" />
                </span>
              </span>
            }
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const removeFile = (fileToDelete: FileWithPath & CustomFileAttributes, event: MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    const newFiles = props.files.filter(currentFile => currentFile.name !== fileToDelete.name);
    props.setFiles(newFiles);
    URL.revokeObjectURL(fileToDelete.preview);

    let newAttachmentSize = 0;

    newFiles.forEach(file => {
      newAttachmentSize += file.size;
    });

    setCurrentAttachmentSize(newAttachmentSize);
  };

  const previewFile = (file: FileWithPath & CustomFileAttributes, event: MouseEvent<HTMLSpanElement, MouseEvent>, templateMessageAttachment?: TemplateMessageAttachmentInfoType) => {
    event.stopPropagation();
    if (file) {
      if (file?.preview && !file?.type?.includes('image')) {
        window.open(file.preview, '_blank');
      } else {
        setShowFilePreview(true);
        setPreviewFirstFileUpload(file);
      }
    } else if (templateMessageAttachment) {
      if (!templateMessageAttachment.mimeType.includes('image')) {
        window.open(templateMessageAttachment.url, '_blank');
      } else {
        setShowFilePreview(true);
      }
    }
  }

  const proccessExternalFile = (files: File[], type: 'onpaste' | 'audiorecorder') => {

    if (files && files?.length > 0) {
      // const invalid: { code: string, message: string } = validator(files[0]);
      // const invalidFiles = filesValidator(files).filter(invalid => !!invalid);
      // const acceptedFiles = files.map(file => accept[file.type]).filter(type => !!type);
      // console.log({ invalidFiles, acceptedFiles});
      // const accepted = accept[files[0].type];

      let hasInvalid = false;
      let hasAccepted = false;
      let invalid: { message: string; code: ErrorCode; } = { code: null, message: null };

      const filteredFiles = Object.assign([], files);
      
      files.forEach((file, index) => {
        const isAccepted = acceptedMimetypesRef?.current[file.type];
        const invalidType = validator(file);

        if (isAccepted) {
          hasAccepted = true;
        }

        if (invalidType) {
          filteredFiles.splice(index, 1);
          hasInvalid = true;
          invalid = invalidType;
        }
      });

      if (hasAccepted) {
        if (hasInvalid) {
          if (invalid.code === ErrorCode.FileTooLarge) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.file_length'), signalIcon: false }));
          } else if (invalid.code === ErrorCode.FileTooSmall) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.empty_files'), signalIcon: false }));
          } else if (invalid.code === ErrorCode.FileInvalidType) {
            if (currentTicket.channel_id === Channels.Instagram) {
              dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.image_files_only'), signalIcon: false }));
            } else if (currentTicket.channel_id === Channels.WhatsApp) {
              dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.media_files_only'), signalIcon: false }));
            } else if (currentTicket.channel_id === Channels.Webchat) {
              dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.document_files_only'), signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.files_not_supported'), signalIcon: false }));
            }
          }
          if (type === 'onpaste') {
            props.setFilesPasted([]);
          } else if (type === 'audiorecorder') {
            props.setAudioRecordedFiles([]);
          }
        } 

        let allFiles = props.files.concat(filteredFiles);
        allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];

        allFiles.forEach((file: FileWithPath & CustomFileAttributes) => {
          if (!file.preview) {
            const returnedTarget = Object.assign(file, { 
              isLoading: true,
              preview: URL.createObjectURL(file)
            });
            file = returnedTarget;
          }
        });

        props.setFiles(allFiles);
        onDrop(allFiles);

        if (type === 'onpaste') {
          props.setFilesPasted([]);
        } else if (type === 'audiorecorder') {
          props.setAudioRecordedFiles([]);
        }
      } else {
        dispatch(setShowAlertFeedback({ visibility: true, message: t('chat_editor.files_not_supported'), signalIcon: false }));
      }
    }
  }

  const getSelectedTemplateMessageInfo = () => {
    let attachment_url = '';

    if (props.selectedTemplateMessage.quick) {
      attachment_url = props.selectedTemplateMessage.quick.quick_message_attachment;
    } else if (props.selectedTemplateMessage.whatsapp) {
      attachment_url = props.selectedTemplateMessage.whatsapp.image_url;
    }

    const info = getFileDetailsFromURL(attachment_url);

    return info;
  }

  return (
    <ToolbarFileUploader 
      t={t}
      fileUploaderRef={fileUploaderRef}
      removeUploadRef={removeUploadRef}
      showFileUploader={props.showFileUploader}
      hasFileUploader={props.hasFileUploader}
      files={props.files}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      currentAttachmentSize={currentAttachmentSize}
      bytesToSize={bytesToSize}
      getFileInfo={getFileInfo}
      formatDate={formatDate}
      getFilePreview={getFilePreview}
      removeFile={removeFile}
      previewFile={previewFile}
      getIconFileType={getIconFileType}
      isDragging={isDragging}
      showFilePreview={showFilePreview}
      setShowFilePreview={setShowFilePreview}
      previewFirstFileUpload={previewFirstFileUpload}
      hasTemplateMessageAttachment={props.hasTemplateMessageAttachment}
      selectedTemplateMessage={props.selectedTemplateMessage}
      getSelectedTemplateMessageInfo={getSelectedTemplateMessageInfo}
      previewTemplateMessageAttachment={previewTemplateMessageAttachment}
      hasTriggerTemplate={props.hasTriggerTemplate}
      editorDisabled={props.editorDisabled}
    />
  );
};

export default ToolbarFileUploaderController;
