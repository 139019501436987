import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ListConfigVozxpress from '.';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback, setShowConfirmationModal } from '../../../../../store/internal';
import { TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../../store/token';
import { Button, Popover } from 'react-bootstrap';

const ListConfigVozxpressController = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [idAccount, setIdAccount] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const funtionConfirmation = async (id: string, delete_vx) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/panel/vozxpress/account/${id}?delete_in_vx=${delete_vx}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (delete_vx) {
                    dispatch(setShowAlertFeedback({ message: "Integração entre agente e atendente desfeita e atendente excluída com sucesso.", visibility: true, signalIcon: true }))
                } else {
                    dispatch(setShowAlertFeedback({ message: "Integração entre agente do CX e atendente do VX desfeita com sucesso", visibility: true, signalIcon: true }))
                }
                props.updateListAfterDelete();
                props.getAccountsVx();
            },
            (error: { response: { status: number } }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading,
        )
    }

    const deleteAccount = (data, delete_vx = true) => {
        let id = data?.vozxpress_account.id;
        let message = delete_vx ? 'Tem certeza que deseja remover integração e excluir o atendente no VX?' : 'Tem certeza que deseja remover integração?';
        let title = delete_vx ? 'Remover integração e excluir atendente' : 'Remover integração';

        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": title, "body": message, "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => funtionConfirmation(id, delete_vx) }));
    }

    const handleOpenModalEdit = () => {
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    // const getAccountById = async (id) => {
    //     setIdAccount(id)
    //     const headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         "Authorization": "Bearer " + values.token.value
    //     };

    //     await AppRequesterConst.Get(
    //         `/panel/vozxpress/account/${id}`, { headers },
    //         (response: Object) => {},
    //         (data: any) => {
    //             if(data?.status === 200) {
    //                 setCurrentAgentData(data?.data);
    //             }
    //         },
    //         (error: { response: { status: number } }) => {
    //             if (error.response.status === 400) {

    //             }
    //             if (error.response.status === 401) {

    //             }
    //         }, navigate, dispatch, setIsLoading, { }
    //     )
    // }

    const openMoreInfo = (data) => {
        let info = {
            account: {
                email: data?.vozxpress_account?.email,
                extension: data?.vozxpress_account?.extension,
                type: 'Account'
            },
            user: !data?.is_vx_user ? undefined :
                {
                    email: data?.vozxpress_user?.email,
                    type: 'User'
                }
        }
        props.setMoreInfo(info);
        props.setShowMoreInfo(true);
    }

    const openIntegrateAccount = (data) => {
        props.setMoreInfo(data);
        props.setShowIntegrateAccount(true)
    }

    const modalIntegrateWithToken = (data) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Integrar no CX', "body": 'Ao clicar em confirmar será criado ou atualizado o token de integração entre as plataformas, VX e CX', "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => integrateWithToken(data) }));
    }

    const integrateWithToken = async (data) => {

        let body: Object;

        if (data?.vozxpress_account?._id !== undefined && data?.vozxpress_user?._id !== undefined) {
            body = {
                vx_user_id: data?.vozxpress_user?._id,
                vx_account_id: data?.vozxpress_account?._id
            }
        } else if (data?.vozxpress_account?._id !== undefined && data?.vozxpress_user?._id == undefined) {
            body = {
                vx_account_id: data?.vozxpress_account?._id
            }
        } else if (data?.vozxpress_account?._id == undefined && data?.vozxpress_user?._id !== undefined) {
            body = {
                vx_user_id: data?.vozxpress_user?._id
            }
        }

        await Promise.all([integrateWithTokenReq(body)])
            .then(_ => {
                dispatch(setShowAlertFeedback({ message: "Token de integração sem senha criado ou atualizado com sucesso.", visibility: true, signalIcon: true }));
            })
            .catch(err => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
            })

        // if (data.is_vx_user) {
        //     let body = {
        //         // type: 'user',
        //         vx_user_id: data?.vozxpress_user?._id
        //     }
        //     let body2 = {
        //         // type: 'account',
        //         vx_account_id: data?.vozxpress_account?._id
        //     }



        // } else {
        //     // let body = {
        //     //     type: data.is_vx_user ? 'user': 'account',
        //     //     vx_id: data.is_vx_user ? data?.vozxpress_user?._id : data?.vozxpress_account?._id
        //     // }

        //     let body = {
        //         // type: 'user',
        //         vx_user_id: data?.vozxpress_user?._id
        //     }

        //     let body2 = {
        //         // type: 'account',
        //         vx_account_id: data?.vozxpress_account?._id
        //     }

        //     await Promise.all([integrateWithTokenReq(body), integrateWithTokenReq(body2)])
        //     .then(_ => {
        //         dispatch(setShowAlertFeedback({ message: "Token de integração sem senha criado ou atualizado com sucesso.", visibility: true, signalIcon: true}));
        //     })
        //     .catch(err => {
        //         dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
        //     })

        // }
    }

    const integrateWithTokenReq = async (body) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };
        await AppRequesterConst.Put(
            `/panel/vozxpress/update-token-integration`, body, { headers },
            (response: Object) => { },
            (data: any) => {
                props.getAgentsWithAccount();
            },
            (error: { response: { status: number } }) => {
            }, navigate, dispatch, setIsLoading,
        )
    }

    const modalHandleCreateAccount = (data) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Criar atendente', "body": 'Ao clicar em confirmar será criado um atendente no VX (caso ainda nao exista um atendente com o e-mail do agente) e feita a integração', "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => handleCreateAccount(data) }));
    }

    const handleCreateAccount = async (data) => {
        const JsonSend = {
            "user_id": data.user_id,
            "user_type": 'account',
            "token_integration": undefined
        };

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        AppRequesterConst.Post(
            '/panel/vozxpress/account',
            JsonSend,
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Atendente criada e integrada com sucesso.", visibility: true, signalIcon: true }))
                props.updateListAfterDelete();
                props.getAccountsVx();
            },
            (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        if (error.response.data.code_cxpress === 1812) {
                            dispatch(setShowAlertFeedback({ message: "Não foi possível atualizar o token de autenticação", visibility: true, signalIcon: false }));
                        } else if (error.response.data.code_cxpress === 1835) {
                            dispatch(setShowAlertFeedback({ message: "Empresa não possui integração ativa com o Vozxpress", visibility: true, signalIcon: false }));
                        }
                    }
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading);
    }

    const popoverOptions = (data) => {
        const disableCreate = props.accountsVx.find(item => item.email === data.email);
        return (
            <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {
                        !data?.vozxpress_account
                            ?
                            <>
                                <Button onClick={() => openIntegrateAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Integrar com atendente (VX)</Button>
                                {
                                    !disableCreate &&
                                    <div>
                                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                        <Button onClick={() => modalHandleCreateAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Criar Atendente (VX)</Button>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <Button onClick={() => modalIntegrateWithToken(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Atualizar Token</Button>
                                <div>
                                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                    <Button onClick={() => deleteAccount(data, false)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Remover Integração</Button>
                                </div>
                                <div>
                                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                    <Button onClick={() => deleteAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir Atendente</Button>
                                </div>
                                <div>
                                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                    <Button onClick={() => openMoreInfo(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Mais informações</Button>
                                </div>
                            </>
                    }
                </Popover.Body>
            </Popover>
        )
    }

    return <ListConfigVozxpress
        isLoading={isLoading}
        isEditAllowed={props?.isEditAllowed}
        datas={props?.datas}
        popoverOptions={popoverOptions}
    />
};

export default ListConfigVozxpressController;