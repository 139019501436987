import { useEffect } from "react"
import InputTimeNew from "."

const InputTimeControllerNew = ({
    index, 
    setStartTime, 
    setEndTime,
    start_time,
    end_time,
    removeItemDataBase,
    idNewItem,
    setInputId,
    t
}: { 
    index: number, 
    removeItemDataBase: any, 
    setStartTime: any,
    setEndTime: any,
    start_time?: string, 
    end_time?: string,
    idNewItem?: any, 
    setInputId?: any, 
    t?: any 
}) => {
    
    useEffect(() => {
        setInputId(idNewItem)
    }, [idNewItem])

    const handleStartTime = ({ target: { value } }) => setStartTime(value)
    const handleEndTime = ({ target: { value } }) => setEndTime(value)

    return (
        <InputTimeNew
            index={index}
            start_time={start_time}
            end_time={end_time}
            removeItemDataBase={removeItemDataBase}
            idNewItem={idNewItem}
            handleStartTime={handleStartTime}
            handleEndTime={handleEndTime}
            t={t}
        />
    )
}

export default InputTimeControllerNew