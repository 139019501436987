import { useEffect, useRef, useState } from "react";
import Consumers from "./"
import { useTranslation } from "react-i18next";
import UserService from "../../../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../../../services/appRequester/appRequesterController";
import { useDispatch } from "react-redux";

const AppRequesterConst = new AppRequesterController();

const ConsumersController = ({
  callcenterContext,
  setScene
}) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [filteredLength, setFilteredLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreInformation, setHasMoreInformation] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);


  useEffect(() => {
    if (debouncedSearch || debouncedSearch === '') {
      setCurrentPage(1);
      getConsumers();
    }
  }, [debouncedSearch]);

  const getConsumers = async (page = 1) => {
    const headers = UserService.getHeaders();

    const params = {
      is_for_dialer: true,
      search: searchTerm ? searchTerm : undefined,
      page,
      limit: 50
    };

    const config = { headers, params };
    await AppRequesterConst.Get(
        'consumer',
        config,
        (response: Object) => {
        },
        (response: any) => {
          let list = []; //page === 1 ? [] : data;
          if (response.status === 200) { 
            let hasNextPage = response?.data?.pagination?.nextPage ? true : false 
            setHasMoreInformation(hasNextPage);
            const consumers = response?.data?.consumers || [];
            consumers?.forEach((item, index) => {
              list.push({
                id: `${index}_${item.user_id}`,
                name: item.name || 'N/A',
                phone: item?.phone || 'N/A'
              })
            })

          } 
          else {
            list = [];
          }

          if (page === 1 ){
            setData(list); 
          } else {
            setData(data.concat(list)); 
          }

        },
        (error: Object) => {
        }, null, dispatch, setIsLoading, {}
    );
  }

  const updateListfunction = () => {
    setCurrentPage(currentPage + 1);
    getConsumers(currentPage + 1);
  };

  const selectNumber = (number: string) => {
    callcenterContext.instance.setCallInfoDisplay("0" + number);
    callcenterContext.instance.startCall();
    setScene('keyboard');
  }

  return (
    <Consumers 
      data={data}
      isLoading={isLoading} 
      t={t} 
      searchTerm={searchTerm} 
      setSearchTerm={setSearchTerm}
      filteredLength={filteredLength}
      selectNumber={selectNumber}
      updateListfunction={updateListfunction}
      hasMoreInformation={hasMoreInformation}
    />
  )
}

export default ConsumersController