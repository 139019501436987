import { FC } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModalConsumerIntertface } from './indexModel';
import { IconAlertCircle, IconChevronDown, IconCircle, IconCircleCheckFilled, IconFileDownload, IconFileTypeCsv, IconUser, IconUserCircle, IconX } from '@tabler/icons-react';
import CustomInputController from '../../../../../components/customInput/indexController';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import { CustomField } from '../../../../../core/enums/custom-field-type';
import Loading from '../../../../../components/loading2';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const ModalConsumer: FC<ModalConsumerIntertface> = (props) => {
  const showCustomFieldsClass = props.showCustomFields ? 'modal-consumer-show-custom-fields' : '';
  const modalTabSingleSelectedClass = props.modalTab === 'single' ? 'modal-consumer-tab-item-selected' : '';
  const modalTabMultiSelectedClass = props.modalTab === 'multiple' ? 'modal-consumer-tab-item-selected' : '';
  const fileUploaderSelectedClass = props.selectedFileConsumers?.name ? 'modal-consumer-import-selected' : '';

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      backdrop='static' // Evitar de fechar o modal com clique acidental fora dele
      aria-labelledby=""
      size="lg"
      centered={true}
      fullscreen={'sm-down'}
      dialogClassName='modal-consumer-component'
    >
      <Modal.Header>
        { props.mode === 'edit' && !props.isCreatedByEmployee && <Modal.Title>{props.t('title_edit')}</Modal.Title>}
        { props.mode === 'edit' && props.isCreatedByEmployee && <Modal.Title>{props.t('title_info')}</Modal.Title>}
        { props.mode === 'create' && props.modalTab === 'single' && <Modal.Title>{props.t('title_new')}</Modal.Title>}
        { props.mode === 'create' && props.modalTab === 'multiple' && <Modal.Title>{props.t('title_new_multi')}</Modal.Title>}
        { props.mode === 'duplicated' && <Modal.Title>{props.t('title_duplicated')}</Modal.Title>}
        { props.mode === 'unification-analysis' && <Modal.Title>{props.t('title_analysis')}</Modal.Title>}
        { props.mode === 'finish-unification' && <Modal.Title>{props.t('title_finish_unification')}</Modal.Title>}
        <div className="modal-consumer-header-actions">
          { props.mode === 'create' && !['duplicated', 'unification-analysis'].includes(props.mode) &&
            <div className="modal-consumer-header-actions-tabs-select">
              <span className={`modal-consumer-tab-item ${modalTabSingleSelectedClass}`} onClick={() => props.changeModalTab('single')}>
                {props.t('tab_single')}
              </span>
              <span className={`modal-consumer-tab-item ${modalTabMultiSelectedClass}`}  onClick={() => props.changeModalTab('multiple')}>
                {props.t('tab_multi')}
              </span>
            </div>
          }
          <IconX className='modal-consumer-header-actions-close' onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      { props.loading &&
        <Modal.Body className='modal-body-loading'>
          <Loading blur />
          <div className="modal-body-loading-info">
            <IconUser />
            <span className="modal-body-loading-info-message">
              {props.t('loading_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { props.loadingRequest && <Loading blur /> }
      { !props.loading && props.modalTab === 'single' && !['duplicated', 'unification-analysis', 'finish-unification'].includes(props.mode) &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.name_label')}</span>
                  <span className="modal-consumer-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.name || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.name_placeholder')}
                  required={true}
                  id={'name'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.lastname_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.lastname || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.lastname_placeholder')}
                  id={'lastname'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.email_label')}</span>
                </div>
                <CustomInputController 
                  type='email'
                  value={props.consumerValuesObject?.email || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.email_placeholder')}
                  id={'email'}
                  isValidInputRef={props.isValidEmailRef}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.email?.length > 0 && !props.isValidEmailRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.email_invalid')}</span>
                }
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.document_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.document_number || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.document_placeholder')}
                  id={'document_number'}
                  disabled={props.isCreatedByEmployee}
                />
                {/* { props.consumerValuesObject?.phone?.length > 0 && !props.isValidDocumentRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.document_invalid')}</span>
                } */}
              </div>
            </div>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.phone_label')}</span>
                </div>
                <CustomInputController 
                  type='phone'
                  value={props.consumerValuesObject?.phone || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.phone_placeholder')}
                  id={'phone'}
                  isValidInputRef={props.isValidPhoneRef}
                  phoneInputSettings={{
                    enableSearch: true,
                    searchPlaceholder: props.t('form.phone_country_search'),
                    searchNotFound: props.t('form.phone_country_not_found'),
                    preferredCountries: ['br', 'pt', 'es', 'fr', 'gb']
                  }}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.phone?.length > 0 && !props.isValidPhoneRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.phone_invalid')}</span>
                }
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.address_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.address || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.address_placeholder')}
                  id={'address'}
                  inputLengthMax={100}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.note_label')}</span>
              </div>
              <CustomInputController 
                type='textarea'
                value={props.consumerValuesObject?.observations || ''}
                setCustomValue={props.setConsumerValue}
                placeholder={props.t('form.note_placeholder')}
                id={'observations'}
                inputLengthMax={200}
                disabled={props.isCreatedByEmployee}
              />
            </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-margin-top-10">
            <span className="modal-consumer-section-title">{props.t('form.section_addictional')}</span>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.assigned_agent_label')}</span>
              </div>
              <CustomSelectController 
                type='pagination'
                hasFilter={true}
                placeholder={props.t('form.assigned_agent_placeholder')}
                placeholderFilter={props.t('form.assigned_agent_search')}
                options={props.getEmployeeSelect().employeesList || []}
                selected={props.getEmployeeSelect().selectedEmployee}
                setSelected={props.getEmployeeSelect().setSelectedEmployee}
                fetchMore={props.getEmployeeSelect().fetchEmployeesPagination}
                hasMore={props.getEmployeeSelect().hasMoreEmployeesList}
                loading={props.getEmployeeSelect().isLoadingEmployeesSelect}
                search={props.getEmployeeSelect().fetchSearchEmployees}
                dropdownPosition='body'
                disabled={props.isCreatedByEmployee}
              />
            </div>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.tags_label')}</span>
              </div>
              <CustomSelectController 
                type='pagination'
                hasFilter={true}
                isMultiselect={true}
                placeholder={props.t('form.tags_placeholder')}
                placeholderFilter={props.t('form.tags_search')}
                options={props.getTagsSelect().tagsList || []}
                selectedMulti={props.getTagsSelect().selectedTags}
                setSelectedMulti={props.getTagsSelect().setSelectedTags}
                fetchMore={props.getTagsSelect().fetchTagsPagination}
                hasMore={props.getTagsSelect().hasMoreTagsList}
                loading={props.getTagsSelect().isLoadingTagsSelect}
                search={props.getTagsSelect().fetchSearchTags}
                dropdownPosition='body'
                disabled={props.isCreatedByEmployee}
              />
            </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-margin-top-10">
            <span className="modal-consumer-section-title">{props.t('form.section_social')}</span>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.whatsapp_label')}</span>
                </div>
                <CustomInputController 
                  type='phone'
                  value={props.consumerValuesObject?.whatsapp || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.whatsapp_label')}
                  id={'whatsapp'}
                  isValidInputRef={props.isValidWhatsappRef}
                  phoneInputSettings={{
                    enableSearch: true,
                    searchPlaceholder: props.t('form.phone_country_search'),
                    searchNotFound: props.t('form.phone_country_not_found'),
                    preferredCountries: ['br', 'pt', 'es', 'fr', 'gb']
                  }}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.whatsapp?.length > 0 && !props.isValidWhatsappRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.phone_invalid')}</span>
                }
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.instagram_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.instagram_user || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.instagram_placeholder')}
                  id={'instagram_user'}
                  inputLengthMax={30}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.twitter_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.twitter || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.twitter_placeholder')}
                  id={'twitter'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.facebook_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.facebook_user || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.facebook_placeholder')}
                  id={'facebook_user'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.youtube_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.youtube || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.youtube_placeholder')}
                  id={'youtube'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-custom-fields modal-consumer-section-margin-top-10">
            <span className={`modal-consumer-section-title ${showCustomFieldsClass}`} onClick={() => props.setShowCustomFields(!showCustomFieldsClass)}>
              {props.t('form.section_custom_fields')}
              <IconChevronDown />
            </span>
            { props.showCustomFields && props.getCustomFieldsData().formattedCustomFields.length > 0 && 
              <div className="modal-consumer-custom-fields">
                {props.getCustomFieldsData().formattedCustomFields.map(item => {
                  return (
                    <div className="modal-consumer-item" key={item.id}>
                      <div className="modal-consumer-item-header">
                        <span className="modal-consumer-item-label" title={item.name}>{item.name}</span>
                        { item.required && <span className="modal-consumer-item-label-required">*</span>}
                      </div>
                      {
                        item.custom_field_type_id === CustomField.Text &&
                        <CustomInputController 
                          type='input'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Textarea &&
                        <CustomInputController 
                          type='textarea'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Dropdown &&
                        <CustomSelectController 
                          type='simple'
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selected={props.getCustomFieldsData().customFieldsValuesObject[item.id]}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Multiselect &&
                        <CustomSelectController 
                          type='simple'
                          isMultiselect={true}
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selectedMulti={props.getCustomFieldsData().customFieldsValuesObject[item.id] || []}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                    </div>
                  );
                })}
              </div>
            }
            { props.showCustomFields && props.getCustomFieldsData().formattedCustomFields.length === 0 && 
              <div className="modal-consumer-custom-fields">
                <span className="modal-consumer-custom-fields-empty">
                  <IconAlertCircle />
                  {props.t('form.custom_fields_empty')}
                </span>
              </div>
            }
          </div>
        </Modal.Body>
      }
      { !props.loading && props.modalTab === 'multiple' && !['duplicated', 'unification-analysis', 'finish-unification'].includes(props.mode) &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <span className="modal-consumer-section-title">
              {props.t('import.label')}
              <OverlayTrigger 
                trigger={['click']} 
                placement='right' 
                overlay={props.popoverFileModel()}
                show={props.showMenuDownload}
                rootClose={true}
              >
                <span className='ticket-chat-header-action' onClick={() => props.handleOpenPopover()}>
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('import.download_tooltip')}</Tooltip>}>
                    <IconFileDownload className='modal-consumer-import-download-icon' />
                  </OverlayTrigger>
                </span>
              </OverlayTrigger>
            </span>
            <div {...props.getRootProps({ className: `modal-consumer-import-area ${fileUploaderSelectedClass}` })}>
                <input {...props.getInputProps({})} />
                { !props.selectedFileConsumers &&
                  <span className="modal-consumer-import-area-message">
                    { <>{props.t(props.isDragging ? 'import.message_dragging' : 'import.message')}</> }
                  </span>
                }
                { props.selectedFileConsumers?.name &&
                  <span className="modal-consumer-import-area-selected-file">
                    <IconFileTypeCsv />
                    { props.selectedFileConsumers.name }
                    { !props.isLoadingUploadConsumersFile &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('import.remove_file')}</Tooltip>}>
                        <IconX className='modal-consumer-import-delete-file' onClick={props.removeSelectedFile} />
                      </OverlayTrigger>
                    }
                    { props.isLoadingUploadConsumersFile &&
                      <span className="circle-loader"></span>
                    }
                  </span>
                }
              </div>
          </div>
        </Modal.Body>
      }
      { props.mode === 'duplicated' &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <div className="modal-consumer-section-duplicated">
              <span className="modal-consumer-section-message">{props.t(props.consumer ? 'similar_form.header_edit' : 'similar_form.header_create')}</span>
              <span className="modal-consumer-section-message-alert">{props.t('similar_form.subtitle')}</span>
            </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-margin-top-10">
            <span className="modal-consumer-section-title">{props.t('similar_form.consumers')}</span>
            <div className="modal-consumer-section-duplicated">
              <div className="modal-consumer-section-duplicated-item">
                <span className="modal-consumer-section-duplicated-item-label">
                  {props.t('similar_form.current_consumer_label')}
                </span>
                { props.consumerForm &&
                  <div className="modal-consumer-section-duplicated-item-info" onClick={() => props.clickConsumerInfo(props.consumerForm?.id || '000')}>
                    <div className="modal-consumer-section-duplicated-item-info-main">
                      <IconUserCircle />
                      <div className="modal-consumer-section-duplicated-item-info-main-details">
                        <span className="modal-consumer-section-duplicated-item-info-main-details-name">
                          {props.consumerForm.lastname ? `${props.consumerForm.name} ${props.consumerForm.lastname}` : props.consumerForm.name}
                        </span>
                        <span className="modal-consumer-section-duplicated-item-info-main-details-email">
                          {props.consumerForm.email}
                        </span>
                      </div>
                    </div>
                    <div className={`modal-consumer-section-duplicated-item-info-secondary ${props.expandedConsumerInfo[props.consumerForm?.id || '000'] ? 'show-info-secondary' : 'hidden-info-secondary'}`}>
                      <div className="duplicated-info-secondary-item">
                        <span className="duplicated-info-secondary-item-label">{props.t('form.name_label')}</span>
                        <OverlayTrigger overlay={<Tooltip>{props.consumerForm?.name || '—'}</Tooltip>}>
                          <span className="duplicated-info-secondary-item-value">{props.consumerForm?.name || '—'}</span>
                        </OverlayTrigger>
                      </div>
                      <div className="duplicated-info-secondary-item">
                        <span className="duplicated-info-secondary-item-label">{props.t('form.lastname_label')}</span>
                        <OverlayTrigger overlay={<Tooltip>{props.consumerForm?.lastname || '—'}</Tooltip>}>
                          <span className="duplicated-info-secondary-item-value">{props.consumerForm?.lastname || '—'}</span>
                        </OverlayTrigger>
                      </div>
                      <div className="duplicated-info-secondary-item">
                        <span className="duplicated-info-secondary-item-label">{props.t('form.email_label')}</span>
                        <OverlayTrigger overlay={<Tooltip>{props.consumerForm?.email || '—'}</Tooltip>}>
                          <span className="duplicated-info-secondary-item-value">{props.consumerForm?.email || '—'}</span>
                        </OverlayTrigger>
                      </div>
                      <div className="duplicated-info-secondary-item">
                        <span className="duplicated-info-secondary-item-label">{props.t('form.document_label')}</span>
                        <OverlayTrigger overlay={<Tooltip>{props.consumerForm?.document_number || '—'}</Tooltip>}>
                          <span className="duplicated-info-secondary-item-value">{props.consumerForm?.document_number || '—'}</span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="modal-consumer-section-duplicated-list">
                <span className="modal-consumer-section-duplicated-list-label">
                  {props.t('similar_form.list_consumers_label')}
                </span>
                <div className="modal-consumer-section-duplicated-list-container">
                  {props.duplicatedConsumers.map(consumer => {
                    return (
                      <div className="modal-consumer-section-duplicated-item" key={consumer.user_id}>
                        <div className="modal-consumer-section-duplicated-item-info" onClick={() => props.clickConsumerInfo(consumer.user_id)}>
                          <div className="modal-consumer-section-duplicated-item-info-main">
                            <IconUserCircle />
                            <div className="modal-consumer-section-duplicated-item-info-main-details">
                              <span className="modal-consumer-section-duplicated-item-info-main-details-name">
                                {consumer.lastname ? `${consumer.name} ${consumer.lastname}` : consumer.name}
                              </span>
                              <span className="modal-consumer-section-duplicated-item-info-main-details-email">
                                {consumer.email}
                              </span>
                            </div>
                          </div>
                          <div className={`modal-consumer-section-duplicated-item-info-secondary ${props.expandedConsumerInfo[consumer.user_id] ? 'show-info-secondary' : 'hidden-info-secondary'}`}>
                            <div className="duplicated-info-secondary-item">
                              <span className="duplicated-info-secondary-item-label">{props.t('form.name_label')}</span>
                              <OverlayTrigger overlay={<Tooltip>{consumer.name || '—'}</Tooltip>}>
                                <span className="duplicated-info-secondary-item-value">{consumer.name || '—'}</span>
                              </OverlayTrigger>
                            </div>
                            <div className="duplicated-info-secondary-item">
                              <span className="duplicated-info-secondary-item-label">{props.t('form.lastname_label')}</span>
                              <OverlayTrigger overlay={<Tooltip>{consumer.lastname || '—'}</Tooltip>}>
                                <span className="duplicated-info-secondary-item-value">{consumer.lastname || '—'}</span>
                              </OverlayTrigger>
                            </div>
                            <div className="duplicated-info-secondary-item">
                              <span className="duplicated-info-secondary-item-label">{props.t('form.email_label')}</span>
                              <OverlayTrigger overlay={<Tooltip>{consumer.email || '—'}</Tooltip>}>
                                <span className="duplicated-info-secondary-item-value">{consumer.email || '—'}</span>
                              </OverlayTrigger>
                            </div>
                            <div className="duplicated-info-secondary-item">
                              <span className="duplicated-info-secondary-item-label">{props.t('form.document_label')}</span>
                              <OverlayTrigger overlay={<Tooltip>{consumer.document_number || '—'}</Tooltip>}>
                                <span className="duplicated-info-secondary-item-value">{consumer.document_number || '—'}</span>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      }
      { props.mode === 'unification-analysis' &&
        <Modal.Body>
          <div className="modal-consumer-unification-analysis-header">
            <span className="modal-consumer-section-message">
              {props.t('similar_form.unification_analysis_message')}
            </span>
          </div>
          <div className="modal-consumer-unification-analysis">
            <div className="modal-consumer-unification-analysis-fields">
              <span className="modal-consumer-unification-analysis-fields-label"></span>
              <div className="modal-consumer-unification-analysis-fields-values">
                <span className="modal-consumer-unification-analysis-fields-values-separator">{props.t('similar_form.main_fields_label')}</span>
                <span className={props.getSimilarLabelClass('name')}>{props.t('form.name_label')}</span>
                <span className={props.getSimilarLabelClass('lastname')}>{props.t('form.lastname_label')}</span>
                <span className={props.getSimilarLabelClass('email')}>{props.t('form.email_label')}</span>
                <span className={props.getSimilarLabelClass('document_number')}>{props.t('form.document_label')}</span>
                <span className={props.getSimilarLabelClass('phone')}>{props.t('form.phone_label')}</span>
                <span className={props.getSimilarLabelClass('address')}>{props.t('form.address_label')}</span>
                <span className={props.getSimilarLabelClass('observations')}>{props.t('form.note_label')}</span>

                {/* <span className="modal-consumer-unification-analysis-fields-values-separator">{props.t('form.section_addictional')}</span> */}
                {/* <span className={props.getSimilarLabelClass('assigned_employee_user')}>{props.t('form.assigned_agent_label')}</span> */}
                {/* <span className={props.getSimilarLabelClass('tags')}>{props.t('form.tags_label')}</span> */}

                <span className="modal-consumer-unification-analysis-fields-values-separator">{props.t('form.section_social')}</span>
                <span className={props.getSimilarLabelClass('whatsapp')}>{props.t('form.whatsapp_label')}</span>
                <span className={props.getSimilarLabelClass('instagram_user')}>{props.t('form.instagram_label')}</span>
                <span className={props.getSimilarLabelClass('twitter')}>{props.t('form.twitter_label')}</span>
                <span className={props.getSimilarLabelClass('facebook_user')}>{props.t('form.facebook_label')}</span>
                <span className={props.getSimilarLabelClass('youtube')}>{props.t('form.youtube_label')}</span>

                { props.getCustomFieldsData().customFieldsList?.length > 0 &&
                  <>
                    <span className="modal-consumer-unification-analysis-fields-values-separator">{props.t('form.section_custom_fields')}</span>
                    {props.getCustomFieldsData().customFieldsList?.map(item => {
                      return (
                        <span key={item.id} className={props.getSimilarLabelClass(item.id || '000')}>{item.name}</span>
                      );
                    })}
                  </>
                }
              </div>
            </div>
            <div className="modal-consumer-unification-analysis-data">
              { props.similarConsumers?.map(consumer => {
                return (
                  <div key={`consumer-${consumer.id}`} className="modal-consumer-unification-analysis-data-item">
                    <span className="modal-consumer-unification-analysis-data-item-name">
                      <OverlayTrigger overlay={<Tooltip>{props.t('similar_form.unification_select_all_tooltip')}</Tooltip>}>
                        <span onClick={() => props.selectAllFromUser(consumer.id)}>{consumer.name || '—'}</span>
                      </OverlayTrigger>
                    </span>
                    <div className="modal-consumer-unification-analysis-data-item-values">
                      <span className="modal-consumer-unification-analysis-data-item-values-separator"></span>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'name', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('name')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['name'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.name_tooltip', { name: consumer.name || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.name || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'lastname', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('lastname')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['lastname'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.lastname_tooltip', { lastname: consumer.lastname || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.lastname || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'email', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('email')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['email'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.email_tooltip', { email: consumer.email || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.email || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'document_number', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('document_number')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['document_number'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.document_number_tooltip', { document_number: consumer.document_number || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.document_number || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'phone', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('phone')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['phone'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.phone_tooltip', { phone: consumer.phone ? formatPhoneNumberIntl(`+${consumer.phone}`) : '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.phone ? formatPhoneNumberIntl(`+${consumer.phone}`) : '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'address', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('address')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['address'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.address_tooltip', { address: consumer.address || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.address || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'observations', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('observations')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['observations'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.observations_tooltip', { observations: consumer.observations || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.observations || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>

                      {/* <span className="modal-consumer-unification-analysis-data-item-values-separator"></span> */}
                      {/* <div className="modal-consumer-unification-analysis-data-item-values-field-value">
                        <IconCircleCheckFilled />
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.employee_assigned_tooltip', { employee_user_id: consumer.employee_user_id || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.employee_user_id || '—'}
                          </span>
                        </OverlayTrigger>
                      </div> */}
                      {/* <div className="modal-consumer-unification-analysis-data-item-values-field-value">
                        <IconCircle />
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.phone_tooltip', { phone: consumer.tags?.toString() || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.tags?.toString() || '—'}
                          </span>
                        </OverlayTrigger>
                      </div> */}

                      <span className="modal-consumer-unification-analysis-data-item-values-separator"></span>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'whatsapp', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('whatsapp')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['whatsapp'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.whatsapp_tooltip', { whatsapp: consumer.whatsapp ? formatPhoneNumberIntl(`+${consumer.whatsapp}`) : '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.whatsapp ? formatPhoneNumberIntl(`+${consumer.whatsapp}`) : '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'instagram_user', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('instagram_user')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['instagram_user'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.instagram_tooltip', { instagram: consumer.instagram_user || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.instagram_user || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'twitter', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('twitter')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['twitter'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.twitter_tooltip', { twitter: consumer.twitter || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.twitter || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'facebook_user', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('facebook_user')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['facebook_user'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.facebook_tooltip', { facebook: consumer.facebook_user || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.facebook_user || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-consumer-unification-analysis-data-item-values-field-value" onClick={() => props.setSimilarField('default', 'youtube', consumer.id)}
                        onMouseEnter={() => props.handleMouseEnterItem('youtube')} onMouseLeave={props.handleMouseLeaveItem}>
                        { props.similarForm['youtube'] === consumer.id ?
                          <IconCircleCheckFilled /> :
                          <IconCircle />
                        }
                        <OverlayTrigger placement='right' overlay={<Tooltip>{props.t('similar_form.youtube_tooltip', { youtube: consumer.youtube || '—'})}</Tooltip>}>
                          <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                            {consumer.youtube || '—'}
                          </span>
                        </OverlayTrigger>
                      </div>

                      { props.getCustomFieldsData().customFieldsList?.length > 0 &&
                        <>
                          <span className="modal-consumer-unification-analysis-data-item-values-separator"></span>
                          {props.getCustomFieldsData().customFieldsList?.map(customField => {
                            return (
                              <div key={`custom-field-${consumer.id}-${customField.id}`} className="modal-consumer-unification-analysis-data-item-values-field-value" 
                                onClick={() => props.setSimilarField('custom_field', customField.id, consumer.id)}
                                onMouseEnter={() => props.handleMouseEnterItem(customField.id)} onMouseLeave={props.handleMouseLeaveItem}>
                                { props.similarForm['custom_fields'] && props.similarForm['custom_fields'][customField.id] === consumer.id ?
                                  <IconCircleCheckFilled /> :
                                  <IconCircle />
                                }
                                <OverlayTrigger placement='right' overlay={<Tooltip>{`${customField.name}: ${props.getCustomFieldValueSimilar(consumer.custom_fields, customField.id) || '—'}`}</Tooltip>}>
                                  <span className='modal-consumer-unification-analysis-data-item-values-field-value-content'>
                                    {props.getCustomFieldValueSimilar(consumer.custom_fields, customField.id) || '—'}
                                  </span>
                                </OverlayTrigger>
                              </div>
                            );
                          })}
                        </>
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      }
      { props.mode === 'finish-unification' &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <div className="modal-consumer-section-duplicated">
              <span className="modal-consumer-section-message-title">{props.t('similar_form.final_consumer')}</span>
              <span className="modal-consumer-section-message-alert">{props.t('similar_form.final_subtitle')}</span>
            </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-margin-top-10">
            <div className="modal-consumer-section-final-unification">
              <div className="modal-consumer-section-final-unification-form">
                <div className="modal-consumer-section-final-unification-form-section">
                  <span className="modal-consumer-section-final-unification-form-section-label">{props.t('similar_form.main_fields_label')}</span>
                  <div className="modal-consumer-section-final-unification-form-group">
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.name_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.name || '—'}</span>
                    </div>
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.lastname_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.lastname || '—'}</span>
                    </div>
                  </div>
                  <div className="modal-consumer-section-final-unification-form-group">
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.email_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.email || '—'}</span>
                    </div>
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.document_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.document_number || '—'}</span>
                    </div>
                  </div>
                  <div className="modal-consumer-section-final-unification-form-group">
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.phone_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">
                        {props.finalSimilarConsumer?.phone ? formatPhoneNumberIntl(`+${props.finalSimilarConsumer?.phone}`) : '—'}
                      </span>
                    </div>
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.address_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.address || '—'}</span>
                    </div>
                  </div>
                  <div className="modal-consumer-section-final-unification-form-item">
                    <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.note_label')}</span>
                    <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.observations || '—'}</span>
                  </div>
                </div>
                <div className="modal-consumer-section-final-unification-form-section">
                  <span className="modal-consumer-section-final-unification-form-section-label">{props.t('form.section_social')}</span>
                  <div className="modal-consumer-section-final-unification-form-group">
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.whatsapp_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">
                        {props.finalSimilarConsumer?.whatsapp ? formatPhoneNumberIntl(`+${props.finalSimilarConsumer?.whatsapp}`) : '—'}
                      </span>
                    </div>
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.instagram_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.instagram_user || '—'}</span>
                    </div>
                  </div>
                  <div className="modal-consumer-section-final-unification-form-group">
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.twitter_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.twitter || '—'}</span>
                    </div>
                    <div className="modal-consumer-section-final-unification-form-item">
                      <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.facebook_label')}</span>
                      <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.facebook_user || '—'}</span>
                    </div>
                  </div>
                  <div className="modal-consumer-section-final-unification-form-item">
                    <span className="modal-consumer-section-final-unification-form-item-label">{props.t('form.youtube_label')}</span>
                    <span className="modal-consumer-section-final-unification-form-item-value">{props.finalSimilarConsumer?.youtube || '—'}</span>
                  </div>
                </div>
                { props.getCustomFieldsData().customFieldsList?.length > 0 &&
                  <div className="modal-consumer-section-final-unification-form-section">
                    <span className="modal-consumer-section-final-unification-form-section-label">{props.t('form.section_custom_fields')}</span>
                    {props.getCustomFieldsData().customFieldsList?.map(customField => {
                      return (
                        <div key={customField.id} className="modal-consumer-section-final-unification-form-item">
                          <span className="modal-consumer-section-final-unification-form-item-label">{customField.name}</span>
                          <span className="modal-consumer-section-final-unification-form-item-value">
                            {props.getCustomFieldValueSimilar(props.finalSimilarConsumer.custom_fields, customField.id) || '—'}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        { !['duplicated', 'unification-analysis', 'finish-unification'].includes(props.mode) &&
          <button className='modal-consumer-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        }
        { props.modalTab === 'single' && !props.isCreatedByEmployee && !['duplicated', 'unification-analysis', 'finish-unification'].includes(props.mode) &&
          <button className='modal-consumer-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
            {props.t(props.mode === 'edit' ? 'btn_save' : 'btn_create')}
          </button>
        }
        { props.modalTab === 'multiple' && !['duplicated', 'unification-analysis', 'finish-unification'].includes(props.mode) &&
          <button className='modal-consumer-btn-save' onClick={() => props.handleImport()} disabled={!props.isValid() || props.isLoadingUploadConsumersFile}>
            {props.t('btn_import')}
          </button>
        }
        { props.mode === 'duplicated' &&
          <button className='modal-consumer-btn-cancel' onClick={() => props.changeMode(props.consumer ? 'edit' : 'create')}>
            {props.t('btn_back')}
          </button>
        }
        { props.mode === 'unification-analysis' &&
          <button className='modal-consumer-btn-cancel' onClick={() => props.changeMode('duplicated')}>
            {props.t('btn_back')}
          </button>
        }
        { props.mode === 'finish-unification' &&
          <button className='modal-consumer-btn-cancel' onClick={() => props.changeMode('unification-analysis')}>
            {props.t('btn_back_analysis')}
          </button>
        }
        { props.mode === 'duplicated' &&
          <button className='modal-consumer-btn-save' onClick={() => props.changeMode('unification-analysis')}>
            {props.t('btn_compare')}
          </button>
        }
        { props.mode === 'unification-analysis' &&
          <button className='modal-consumer-btn-save' onClick={() => props.changeMode('finish-unification')}>
            {props.t('btn_next')}
          </button>
        }
        { props.mode === 'finish-unification' &&
          <button className='modal-consumer-btn-save' onClick={props.handleUnification}>
            {props.t('btn_unify')}
          </button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConsumer;