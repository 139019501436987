import { FC, useEffect, useState } from 'react';
import ModalActiveTriggerReport from './index';
import { I_FetchMessageTriggerReport, I_MessageTriggerReport, ModalActiveTriggerReportControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import emitter, { T_EmitterOpenModalReportActiveTrigger } from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { IconMail, IconPhone, IconBrandWhatsapp, IconBrandFacebook, IconBrandInstagram, IconMessages, IconHelp } from '@tabler/icons-react';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../../components/customTableList/indexModel';
import { jsPDF, jsPDFOptions } from 'jspdf';
import autoTable from 'jspdf-autotable';


const AppRequesterConst = new AppRequesterController();

const NAME_SIZE = '150px';
const CONTACT_SIZE = '250px';
const STATUS_SIZE = '300px';

const ModalActiveTriggerReportController: FC<ModalActiveTriggerReportControllerInterface> = (props) => {
  const { t } = useTranslation('ModalActiveTriggerReport');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<T_EmitterOpenModalReportActiveTrigger>(null);
  const [reportData, setReportData] = useState<I_MessageTriggerReport>(null);
  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [loadingReport, setLoadingReport] = useState(false);
  const [errorLoadingReport, setErrorLoadingReport] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-report-active-trigger', data => {
      if (data?.id) {
        setData(data);
        fetchReport(data.id).then(report => {
          setReportData(report);
          processPaginationDetails({
            total: report.messages.length,
            lastPage: 1,
            prevPage: null,
            nextPage: null,
            perPage: report.messages.length,
            currentPage: 1,
            from: 0,
            to: report.messages.length
          });
        }).catch(() => {
          dispatch(setShowAlertFeedback({ message: t('alert.error_loading'), visibility: true, signalIcon: false }));
        });
      }
    });

    return () => {
      emitter.off('open-modal-report-active-trigger');
    }
  }, []);

  useEffect(() => {
    setListHeaders([
      { title: t("list_headers.name"), field: 'consumer_name', width: NAME_SIZE },
      { title: t("list_headers.contact"), field: 'to', width: CONTACT_SIZE, hasTooltip: true },
      { title: t("list_headers.status"), field: 'status', width: STATUS_SIZE },
    ]);
  }, []);

  const fetchReport = async (id: string): Promise<I_MessageTriggerReport> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingReport(true);
    setErrorLoadingReport(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/message-sending/${id}/report`, config,
        () => {},
        (response: I_FetchMessageTriggerReport) => {
          setErrorLoadingReport(false);
          if (response.status === 200) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingReport(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingReport, { }
      );
    });
  }

  const downloadFile = async () => {
    setGeneratingPDF(true);
    const options: jsPDFOptions = {
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    };

    const doc = new jsPDF(options);

    const image = new Image();
    image.src = '/LogoFullBlue.jpeg';
    image.onload = () => {
      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');
      doc.text(t('pdf_texts.title'), 14, 20);

      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');

      doc.text(`${t('labels.title')}: ${data.title || 'N/A'}`, 14, 30);
      doc.text(`${t('labels.type')}: ${data.type}`, 14, 36);
      doc.text(`${t('labels.channel')}: ${getChannel(data.channel_id).title}`, 14, 42);
      doc.text(`${t('labels.date_scheduled')}: ${formatDateScheduled(data.date_scheduled)}`, 14, 48);

      doc.addImage(image, 'JPEG', 145, 13, 50.8, 10.1); // 192px ~= 50.8mm - 38px ~= 10.1mm

      const infoData = [
        [
          t('labels.recipients_plural'), // Destinatários
          t('labels.sent_total_plural'), // Envios
          t('labels.sent_success_plural'),  // Sucessos
          t('labels.sent_fail_plural'), // Falhas
          t('labels.tickets_plural') // Tickets
        ],
        [
          data.number_of_recipients, 
          reportData.total_sent, 
          reportData.sent_success, 
          reportData.sent_failure, 
          reportData.total_answers
        ]
      ];

      doc.setFontSize(16);

      autoTable(doc, {
        head: [infoData[0]],
        body: infoData.slice(1),
        startY: 54,
        theme: 'grid',
        headStyles: { fillColor: [12, 36, 97] },
        bodyStyles: { textColor: [113, 113, 113], fontSize: 14, fontStyle: 'bold' },
      });

      if (reportData.messages?.length > 0) {
        const tableData = [
          [
            '#', 
            t('list_headers.name'), // Nome
            t('list_headers.contact'), // Contato
            t('list_headers.status') // Status
          ]
        ];
  
        reportData.messages.forEach((item, index) => {
          let status = t('pdf_texts.success_message');
  
          if (item.message_erro) {
            status = `${t('pdf_texts.failure_message')} (${item.message_erro})`;
          }
  
          tableData.push([String(index + 1), item.consumer_name, item.to, status ]);
        });
  
        doc.setFontSize(13);
  
        autoTable(doc, {
          head: [tableData[0]],
          body: tableData.slice(1),
          startY: 76,
          theme: 'grid',
          headStyles: { fillColor: [12, 36, 97] },
          bodyStyles: { textColor: [50, 50, 50], fontSize: 12 },
          didDrawPage: function () {
            const totalPages = doc.getNumberOfPages();
            const text = t('pdf_texts.page_info', { totalPages });
  
            // if (typeof doc.putTotalPages === 'function') {
            //   text = text + ` de #REGEX_TOTAL_PAGES_COUNT#`;
            // }
  
            doc.setFontSize(8);
            const pageSize = doc.internal.pageSize
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(text, 14, pageHeight - 10)
  
          }
        });
  
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages('#REGEX_TOTAL_PAGES_COUNT#');
        }
      } else {
        doc.setFontSize(12);
        doc.text(t('no_sent'), 14, 80);
      }

      setTimeout(() => {
        doc.save(t('pdf_texts.filename', { timestamp: Date.now()}));
        setGeneratingPDF(false);
        dispatch(setShowAlertFeedback({ message: t('alert.successfully_download'), visibility: true, signalIcon: true }));
      }, 1000);
    }
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    const size = 20;
    const color = '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("labels.channels_name.email"), icon: <IconMail stroke={1} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("labels.channels_name.telephony"), icon: <IconPhone stroke={1} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("labels.channels_name.whatsapp"), icon: <IconBrandWhatsapp stroke={1} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("labels.channels_name.facebook"), icon: <IconBrandFacebook stroke={1} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("labels.channels_name.instagram"), icon: <IconBrandInstagram stroke={1} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("labels.channels_name.webchat"), icon: <IconMessages stroke={1} size={size} color={color} />};
      default:
        return { title: 'N/A', icon: <IconHelp stroke={1} size={size} color={color} />}
    }
  }

  const formatDateScheduled = (date_scheduled: string) => {
    const date = new Date(date_scheduled);
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders;
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }
  
  return (
    <ModalActiveTriggerReport 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      reportData={reportData}
      listHeaders={getListHeaders}
      paginationDetails={paginationDetails}
      loadingReport={loadingReport}
      errorLoadingReport={errorLoadingReport}
      generatingPDF={generatingPDF}
      downloadFile={downloadFile}
      getChannel={getChannel}
      formatDateScheduled={formatDateScheduled}
    />
  );
};

export default ModalActiveTriggerReportController;
