import { t } from "i18next";

export enum CustomField {
    Text = 'd31ebd9e-af9f-4559-b04a-729439218a6f',
    Textarea = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b',
    Dropdown = '74bf4b70-3415-4e8a-ac81-7ebe435c1424',
    Multiselect = '49d495a8-50d4-4d61-9d62-e1cc03227573',
    General = '03bf352a-c95f-4d05-927b-4380eb3f407f',
    Number = 'cbb362a5-c6ab-4acb-b2c8-f67a49770987',
    Text1 = '4aaa9e26-2277-4827-a085-e8f3404d358a'
}

export const Inputs = [
    { label: t('configurations_consumers.modal_edit_custom_field.simple_text_label'), value: CustomField.Text },
    { label: t('configurations_consumers.modal_edit_custom_field.multiline_text_label'), value: CustomField.Textarea },
    { label: t('configurations_consumers.modal_edit_custom_field.simple_select_label'), value: CustomField.Dropdown },
    { label: t('configurations_consumers.modal_edit_custom_field.multiple_select_label'), value: CustomField.Multiselect },
    { label: t('configurations_consumers.modal_register_custom_field.general_type'), value: CustomField.General },
    { label: t('configurations_consumers.modal_register_custom_field.number_type'), value: CustomField.Number },
    { label: t('configurations_consumers.modal_register_custom_field.text_type'), value: CustomField.Text1 }

]

export enum CustomFieldEntity {
    Ticket = '250204ce-44eb-4cdc-865a-f54eed9c6496',
    Consumer = 'b93b0740-a77d-4474-aad9-7aa0044d5654'
}

export enum CustomFieldsDataType {
    ALPHANUMERIC = '03bf352a-c95f-4d05-927b-4380eb3f407f',
    NUMERIC = 'cbb362a5-c6ab-4acb-b2c8-f67a49770987',
    TEXT = '4aaa9e26-2277-4827-a085-e8f3404d358a',
}

export enum CustomFieldsType {
    DropdownMultiselect = '49d495a8-50d4-4d61-9d62-e1cc03227573',
    Dropdown = '74bf4b70-3415-4e8a-ac81-7ebe435c1424',
    Text = 'd31ebd9e-af9f-4559-b04a-729439218a6f',
    Textarea = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b',
}