import { FC, useEffect, useState } from "react";
import { FormValues, IFormOfModalsTemplateMessageController } from "./indexModel";
import FormOfModalsTemplateMessage from ".";
import { TokenInterfaceControlPanel } from "../../../../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../../store/token";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { useNavigate } from "react-router-dom";
import { setShowAlertFeedback, setShowTemplateTagModal } from "../../../../../../store/internal";
import { useTranslation } from "react-i18next";
import { Brokers } from "../../../../../../core/enums/brokers";

const FormOfModalsTemplateMessageController: FC<IFormOfModalsTemplateMessageController> = (props) => {
    const [showFeedbackTitle, setShowFeedbackTitle] = useState(false);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [showFeedbackPhone, setShowFeedbackPhone] = useState(false);
    const [attachmentIds, setAttachmentIds] = useState([]);
    const [showFeedbackContentSid, setShowFeedbackContentSid] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [whatsappKeys, setWhatsappKeys] = useState([]);
    const [selectedWhatsappKey, setSelectedWhatsappKey] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    // const [phone, setPhone] = useState("");

    const { t } = useTranslation();

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [language, setLanguage] = useState([{label: t('home_departments.template_message_tab.pt_br'), value: 'pt_BR'}, {label: t('home_departments.template_message_tab.en'), value: 'en_GB'}, {label: t('home_departments.template_message_tab.pt'), value: 'pt_PT'}, {label: t('home_departments.template_message_tab.fr'), value: 'fr'}, {label: t('home_departments.template_message_tab.es'), value: 'es'}]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);

    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        const controller = new AbortController();

        if (props.currentCompany) {
            if (props?.createInCompany) {
                getCompanyWhatsappsSmarters(controller, props.currentCompany.company_id);
                getCategoriesSmarters(controller, props.currentCompany.company_id);
            } else {
                getCompanyWhatsapps(controller, props.currentCompany.company_id);
            }
        }

    }, [props.currentCompany]);

    useEffect(() => {
        if (props.preSelectedWhatsapp)
            setSelectedWhatsappKey([props.preSelectedWhatsapp]);
    }, [props.preSelectedWhatsapp]);


    const getCompanyWhatsapps = async (controller: AbortController, companyId: string) => {
        const signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await appRequesterConst.Get(
            `/channel-whatsapp/whatsapp-keys/${companyId}`, { headers, signal },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setWhatsappKeys((numbers) => {
                        numbers = data.data.whatsAppKeys.map((item) => {
                            return { label: item.phone_number, value: item.id, broker_id: item.broker_id }
                        });
                        return numbers;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, () => {}
        )
    }

    const getCompanyWhatsappsSmarters = async (controller: AbortController, companyId: string) => {
        const signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await appRequesterConst.Get(
            `/channel-whatsapp/whatsapp-numbers?broker_id=${Brokers.Smarters}`, { headers, signal },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setWhatsappKeys((numbers) => {
                        numbers = data.data.whatsapp_numbers.map((item) => {
                            return { label: item.phone_number, value: item.id, broker_id: item.broker_id }
                        });
                        return numbers;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, () => {}
        )
    }

    const getCategoriesSmarters = async (controller: AbortController, companyId: string) => {
        const signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await appRequesterConst.Get(
            `/template-message/categories/${Brokers.Smarters}`, { headers, signal },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setCategories((categoriesReq) => {
                        categoriesReq = data.data.categories.map((item) => {
                            return { label: item.category, value: item.id }
                        });
                        return categoriesReq;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, () => {}
        )
    }

    const validate = (values: FormValues) => {
        let errors = {};

        if (values.message?.length > 1024) {
            errors['message'] = "Mensagem não atende ao limite de 1024 caracteres.";
            setShowFeedbackMessage(true);
        } else if (values.message?.length === 0) {
            errors['message'] = t('home_departments.template_message_tab.field_not_empty');
            setShowFeedbackMessage(true);
        } else if (props.tags?.length > 0) { // restringindo uso das tags com {{nome}} e permitindo somente com {{1}}
            props.tags.map((tag, index) => {
                if (values?.message.includes(`{{${tag.alias}}}`)) {
                    errors['message'] = `Coloque o número {{${index+1}}} da variável, não o seu nome {{${tag.alias}}}`;
                    setShowFeedbackMessage(true);
                } else {
                    setShowFeedbackMessage(false);
                }

                if (!values?.message.includes(`{{${index+1}}}`)) {
                    errors['message'] = `${t('home_departments.template_message_tab.add_number')} {{${index+1}}} ${t('home_departments.template_message_tab.add_number_2')} {{${tag.alias}}}`;
                    setShowFeedbackMessage(true);
                } else {
                    setShowFeedbackMessage(false);
                }
            });
        } else {
            setShowFeedbackMessage(false);
        }

        const regex_title = /^[a-z0-9_]+$/;
        
        if (values.title?.length > 512) {
            errors['title'] = t('home_departments.template_message_tab.size_not_allowed');
            setShowFeedbackTitle(true);
        } else if (values.title?.length === 0) {
            errors['title'] = t('home_departments.template_message_tab.field_not_empty');
            setShowFeedbackTitle(true);
        } else if (props.createInCompany && !regex_title.test(values.title)) {
            errors['title'] = t('home_departments.template_message_tab.title_not_valid');
            setShowFeedbackTitle(true);
        } else {
            setShowFeedbackTitle(false);
        }

        if (selectedWhatsappKey[0]?.broker_id === "ba3a545f-a77d-44bf-8344-ba05f54f5324" && values.content_sid.length < 1) {
            errors['content_sid'] = "O campo Content Sid não pode estar vazio";
            setShowFeedbackContentSid(true);
        } else {
            setShowFeedbackContentSid(false);
        }

        return errors;
    }

    const attachmentIdsCallback = (ids: []) => {
        setAttachmentIds(ids);
    }

    const onSubmit = (valuesForm: FormValues) => {
        let form = {
            message: valuesForm.message,
            title: valuesForm.title,
        }
        if (valuesForm?.content_sid.length > 0) {
            form['content_sid'] = valuesForm.content_sid;
        }

        if (attachmentIds.length > 0)
            form['image_id'] = attachmentIds[0];

        if (props.tags?.length > 0) {
            let tags = props.tags.map((tag, index) => {
                return  {
                    "tag_number": index + 1,
                    "tag": tag.tag,
                    "alias": tag.alias,
                };
            });

            form['tags'] = tags;
        }

        if (props?.createInCompany) {
            if (props.examples?.length > 0) {
                form['example'] = props.examples;
            }
            if (selectedWhatsappKey[0]?.label)
                form['whatsapp_keys_id'] = selectedWhatsappKey[0].value;

            if (selectedLanguage?.length > 0)
                form['language'] = selectedLanguage[0].value;

            if (selectedCategories?.length > 0)
                form['category_id'] = selectedCategories[0].value;
        } else {
            if (selectedWhatsappKey[0]?.label)
                form['phone_number'] = selectedWhatsappKey[0].label;
        }

        if (selectedWhatsappKey.length === 0) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.select_wpp'), visibility: true, signalIcon: false }));
        } else if (props.createInCompany && selectedCategories.length === 0) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.select_category'), visibility: true, signalIcon: false }));
        } else if (props.createInCompany && selectedLanguage.length === 0) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.select_language'), visibility: true, signalIcon: false }));
        }
        else {
            // verificando se é de edição ou criação
            if (props.templateId){
                props.onSubmit(form, props.templateId);
            } else {
                setDisabledSubmit(true)
                props.onSubmit(form, setDisabledSubmit);
            }
        }
    }

    const hideTagModal = () => {
        dispatch(setShowTemplateTagModal(false));
    }

    const showCreateTagModal = () => {
        dispatch(setShowTemplateTagModal(true));
    }

    return <FormOfModalsTemplateMessage 
        validate={validate}
        onSubmit={onSubmit}
        hide={props.hide}
        initialValues={props.initialValues}
        showFeedbackTitle={showFeedbackTitle}
        showFeedbackMessage={showFeedbackMessage}
        showFeedbackPhone={showFeedbackPhone}
        attachmentIdsCallback={attachmentIdsCallback} 
        currentCompany={props.currentCompany} 
        whatsappKeys={whatsappKeys} 
        setWhatsappKeys={setWhatsappKeys} 
        selectedWhatsappKey={selectedWhatsappKey} 
        setSelectedWhatsappKey={setSelectedWhatsappKey}  
        tags={props.tags}
        setTags={props.setTags}  
        hideTagModal={hideTagModal}
        showCreateTagModal={showCreateTagModal}
        previewImage={props.previewImage}
        isLoading={isLoading}
        createInCompany={props?.createInCompany}
        t={t}
        categories={categories}
        setCategories={setCategories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        language={language}
        setLanguage={setLanguage}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        setExamples={props.setExamples}
        disabledSubmit={disabledSubmit}
        showFeedbackContentSid={showFeedbackContentSid}
    />
}

export default FormOfModalsTemplateMessageController;