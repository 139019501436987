import { useEffect, useState } from 'react'
import { 
    Row, 
    Col, 
    OverlayTrigger, 
    Tooltip, 
    Button, 
    Form, 
    InputGroup, 
    FormControl,
    Tabs,
    Tab
} from "react-bootstrap"
import allStyles from "./styles"
import SelectComponent from "../selectComponent"
import { ContractStatus } from '../../../../core/enums/contract-status'
import { Formik } from 'formik'
import InputMask from 'react-input-mask';
import MaskController from '../../../../core/shared/mask-controller'
import { Brokers } from '../../../../core/enums/brokers'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setShowAlertFeedback } from '../../../../store/internal'
import ListConfigWhatsappController from './listConfigWhatsapp/indexController'
import ModalEditWhatsapp from './modalEditWhatsapp'
import ModalRegisterWhastappController from './modalRegisterWhatsapp/indexController'
import FormConfigTemplateMessagesControlPanelController from './formConfigTemplateMessages/indexController'


const AppRequesterConst = new AppRequesterController()

const FormConfigWhatsapp = ({ data, company_data, token, company_id, getWhatsAppById, isEditAllowed }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <>  
            <ModalRegisterWhastappController getWhatsAppById={getWhatsAppById} company_data={company_data} show={show} onHide={handleClose}/>
            <Row className='mt-1 noppadding' style={{ display: "flex", alignItems: "center" }}>
                <Col style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: '#0C2461', opacity: '1', display: 'flex', alignItems: 'center' }} md={5} className='mt-2'>
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configurações do Whatsapp
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Tabs defaultActiveKey={"whatsapp_numbers"} id="channelsTabs" style={{ padding: 0, margin: '3vh 0px 0px 0px' }}>
                <Tab eventKey={"whatsapp_numbers"} title="Números" >
                    <Row style={allStyles.textFontGlay} className='d-flex justify-content-flex-start mt-2'>
                        {
                            isEditAllowed &&
                            <Col sm={true} md={3}>
                                <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                    {
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                    Adicionar número
                                                </Tooltip>
                                            }
                                        >
                                            <Button size="lg" className='buttonWhite' style={{ width: '100%', marginTop: '1.5vh' }} onClick={() => handleShow()}>Adicionar número</Button>
                                        </OverlayTrigger>
                                    }
                                </p>
                            </Col>
                        }
                    </Row>
                    <ListConfigWhatsappController data={data} getWhatsAppById={getWhatsAppById} isEditAllowed={isEditAllowed} />
                </Tab>
                <Tab eventKey={"whatsapp_templates"} title="Templates" >
                    <FormConfigTemplateMessagesControlPanelController currentCompany={company_data} isEditAllowed={isEditAllowed} />
                </Tab>
            </Tabs>
        </>
    )
    
    
}

export default FormConfigWhatsapp