import './styles.scss';
import { FC } from 'react';
import { PopupTemplateMessagesInterface } from './indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconInfoCircle, IconMessage2Off, IconMessageBolt, IconPaperclip, IconSearch, IconX } from '@tabler/icons-react';
import FadeInOut from '../../../../../../../../components/fadeInOut';
import Loading from '../../../../../../../../components/loading2';
import PopupTemplateMessagesGeneralVariableController from './components/popupTemplateMessagesGeneralVariable/indexController';

const PopupTemplateMessages: FC<PopupTemplateMessagesInterface> = (props) => {
  const showPopupClass = props.showPopup ? 'popup-template-messages-component-popup-opened' : '';
  const quickIsActiveClass = props.activeTab === 'quick' ? 'popup-template-messages-header-tab-active' : '';
  const whatsappIsActiveClass = props.activeTab === 'whatsapp' ? 'popup-template-messages-header-tab-active' : '';

  return (
    <div className="popup-template-messages-component" ref={props.popupRef}>
      {props.showModalGeneralType && <PopupTemplateMessagesGeneralVariableController 
        show={props.showModalGeneralType}
        hide={props.handleOpenCloseModalGeneralType}
        generalTypesVariables={props.generalTypesVariables}
        auxTemplateMessage={props.auxTemplateMessage}
        setAuxTemplateMessage={props.setAuxTemplateMessage}
      />}
      <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('popup_title')}</Tooltip>} show={props.showPopup ? false : undefined}>
        <span className={`popup-template-messages-trigger ${showPopupClass}`} onClick={() => props.handleShowPopup()}>
          <IconMessageBolt />
        </span>
      </OverlayTrigger>
      <FadeInOut duration={200} show={props.showPopup} style={{ zIndex: 20 }}>
        <div className="popup-template-messages">
          <div className="popup-template-messages-header">
            <span className="popup-template-messages-header-title">
              <span className="popup-template-messages-header-title-value">
                {props.t('popup_title')}
              </span>
              <OverlayTrigger overlay={<Tooltip>{props.t('popup_tooltip')}</Tooltip>}>
                <IconInfoCircle />
              </OverlayTrigger>
            </span>
            <div className="popup-template-messages-header-tabs">
              { props.quickMessagesAllowed && 
                <span 
                  className={`popup-template-messages-header-tab-item ${quickIsActiveClass}`} 
                  onClick={() => props.handleChangeTab('quick')}>
                    {props.t('tab_quick')}
                </span>
              }
              { props.whatsappMessagesAllowed &&
                <span 
                  className={`popup-template-messages-header-tab-item ${whatsappIsActiveClass}`}
                  onClick={() => props.handleChangeTab('whatsapp')}>
                    {props.t('tab_whatsapp')}
                </span>
              }
            </div>
          </div>
          <div className="popup-template-messages-search">
            <input type="text" placeholder={props.t('search')} onChange={props.handleInputChange} value={props.searchTerm} onKeyDown={(e) => props.handleEnterSearch(e)} />
            <IconSearch className='search-icon-label' />
            { props.isFiltered && 
              <OverlayTrigger overlay={<Tooltip>{props.t('clear_search')}</Tooltip>}>
                <IconX className='search-icon-clear' onClick={props.clearSearch} />
              </OverlayTrigger>
            }
          </div>
          { props.activeTab === 'quick' &&
            <div className="popup-template-messages-body" ref={props.popupBodyRef}>
              { props.isLoading && <Loading blur={props.quickMessages.length > 0} /> }
              { !props.isLoading && props.quickMessages?.length === 0 &&
                <div className="popup-template-messages-empty">
                  <IconMessageBolt />
                  <span>{props.t('empty_search')}</span>
                </div>
              }
              { props.quickMessages?.length > 0 &&
                props.quickMessages.map(item => {
                  const selectedClass = props.selectedTemplateMessage.quick?.id === item.id ? 'template-message-selected' : '';

                  return (
                    <div key={item.id} className={`popup-template-message-body-item ${selectedClass}`} onClick={() => props.handleQuickMessageClick(item)}>
                      <span className="popup-template-message-body-item-header">
                        <span className="popup-template-message-body-item-header-title">{item.title}</span>
                        { item.quick_message_attachment && 
                          <span className="popup-template-message-body-item-header-attachment">
                            <OverlayTrigger overlay={<Tooltip>{props.t('attachment_included')}</Tooltip>}>
                              <IconPaperclip />
                            </OverlayTrigger>
                          </span>
                        }
                      </span>
                      <span className="popup-template-message-body-item-message">{item.message}</span>
                      <span className="popup-template-message-body-item-footer">
                        <span className="popup-template-message-body-item-date">
                          {props.getFormattedDate(item.created_at, true)}
                        </span>
                      </span>
                    </div>
                  );
                })
              }
            </div>
          }
          { props.activeTab === 'whatsapp' &&
            <div className="popup-template-messages-body" ref={props.popupBodyRef}>
              { props.isLoading && <Loading blur={props.whatsappMessages.length > 0} /> }
              { !props.isLoading && props.whatsappMessages?.length === 0 &&
                <div className="popup-template-messages-empty">
                  <IconMessageBolt />
                  <span>{props.t('empty_search')}</span>
                </div>
              }
              { props.whatsappMessages?.length > 0 &&
                props.whatsappMessages.map(item => {
                  const selectedClass = props.selectedTemplateMessage.whatsapp?.id === item.id ? 'template-message-selected' : '';

                  return (
                    <div key={item.id} className={`popup-template-message-body-item ${selectedClass}`} onClick={() => props.handleWhatsappMessageClick(item)}>
                      <span className="popup-template-message-body-item-header">
                        <span className="popup-template-message-body-item-header-title">{item.title_and_number || item.title}</span>
                        { item.image_url && 
                          <span className="popup-template-message-body-item-header-attachment">
                            <OverlayTrigger overlay={<Tooltip>{props.t('attachment_included')}</Tooltip>}>
                              <IconPaperclip />
                            </OverlayTrigger>
                          </span>
                        }
                      </span>
                      <span className="popup-template-message-body-item-message">{item.message}</span>
                      <span className="popup-template-message-body-item-footer">
                        <span className="popup-template-message-body-item-date">
                          {props.getFormattedDate(item.created_at, true)}
                        </span>
                      </span>
                    </div>
                  );
                })
              }
            </div>
          }
          { (props.selectedTemplateMessage.quick || props.selectedTemplateMessage.whatsapp) &&
            <div className="popup-template-messages-footer">
              <span className="popup-template-messages-footer-action" onClick={props.handleClearSelected}>
                <span className="popup-template-messages-footer-action-text">{props.t('clear_selected')}</span>
                <IconMessage2Off />
              </span>
            </div>
          }
        </div>
      </FadeInOut>
    </div>
  );
};

export default PopupTemplateMessages;
