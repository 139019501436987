import './styles.scss'
import { IconClockEdit } from "@tabler/icons-react"
import { IconX } from '@tabler/icons-react'
import { Form } from 'react-bootstrap'

const InputServiceNew = ({
    dayData,
    dayOfWeek,
    disabled,
    id,
    dayActivated,
    openModalCheckbox,
    openModal,
    t
}) => {
    return (
        <div className='container-input-service'>
            <div className='container-checkbox'>
                <Form.Check
                    type='checkbox'
                    id={id}
                    checked={dayActivated}
                    disabled={!disabled}
                    onClick={() => openModalCheckbox(id)}
                />
            </div>
            <div className='container-title-icon'>
                <div className={!disabled ? 'container-day-disabled' : 'container-day'}>
                    <div className={!disabled ? 'container-title-disabled':'container-title'}>
                        {dayOfWeek}
                    </div>
                </div>
                <div className='container-schedules'>
                    <div className='subcontainer-schedules'>
                        {dayData.length > 0 ?
                            dayData.map((item) => {
                                return (
                                    <div className='container-hours'>
                                        {`${item.start_time} - ${item?.end_time}`}
                                    </div>
                                )
                            })
                            :
                            <div className='container-not-schedules'>
                                {t("home_departments.schedule_service_tab.no_schedule")}
                            </div>
                        }
                    </div>
                </div>
                <div>
                    {!disabled ?
                        <></>
                        :
                        <div className='container-icon' onClick={() => openModal(id)}>
                            <IconClockEdit size={30}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default InputServiceNew