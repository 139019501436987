import { useEffect, useState } from 'react';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormConfigVozxpress from '.';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/token';


const FormConfigVozxpressController = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let { state } = useLocation()
    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const [agents, setAgents] = useState([]);
    const [accountsVx, setAccountsVx] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [moreInfo, setMoreInfo] = useState({});

    const [showModalRegister, setShowModalRegister] = useState(false);
    const handleOpenCloseModal = () => {
        setShowModalRegister(!showModalRegister);
    };
    const [showModalChangeUser, setShowModalChangeUser] = useState(false);
    const handleOpenCloseModalChange = () => {
        setShowModalChangeUser(!showModalChangeUser);
        getAgentsWithAccount();
    };

    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const handleOpenCloseMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    }

    const [showIntegrateAccount, setShowIntegrateAccount] = useState(false);
    const handleOpenCloseIntegrateAccount = () => {
        setShowIntegrateAccount(!showIntegrateAccount);
    }

    useEffect(() => {
        getAgentsWithAccount();
        getAccountsVx();

    }, [props?.new_data, setAgents])


    const getAgentsWithAccount = async () => {
        setAgents([]);
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/panel/vozxpress/account/${state.id}/employees-accounts`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setAgents([])
                    let agents = data.data.employees
                        .sort((a, b) => {
                            const nameA = a.name ? a.name.toLowerCase() : 'n/a';
                            const nameB = b.name ? b.name.toLowerCase() : 'n/a';
                            return nameA.localeCompare(nameB);
                        });
                    setAgents(agents)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const getAccountsVx = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/panel/vozxpress/list-account-VX/${state?.user_id_vx}?company_id=${state.id}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setAccountsVx([])
                    let accounts = data.data.accountsVX.filter(i => !i.integrated);
                    setAccountsVx(accounts)
                }

            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    return <FormConfigVozxpress
        isLoading={isLoading}
        isEditAllowed={props?.isEditAllowed}
        handleOpenCloseModalChange={handleOpenCloseModalChange}
        showModalRegister={showModalRegister}
        handleOpenCloseModal={handleOpenCloseModal}
        updateListAfterDelete={props?.updateListAfterDelete}
        showModalChangeUser={showModalChangeUser}
        showMoreInfo={showMoreInfo}
        handleOpenCloseMoreInfo={handleOpenCloseMoreInfo}
        moreInfo={moreInfo}
        showIntegrateAccount={showIntegrateAccount}
        handleOpenCloseIntegrateAccount={handleOpenCloseIntegrateAccount}
        accountsVx={accountsVx}
        getAccountsVx={getAccountsVx}
        getAgentsWithAccount={getAgentsWithAccount}
        setShowIntegrateAccount={setShowIntegrateAccount}
        setMoreInfo={setMoreInfo}
        setShowMoreInfo={setShowMoreInfo}
        agents={agents}
    />
};

export default FormConfigVozxpressController;