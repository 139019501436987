import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, useEffect, useState } from 'react';
import AudioPlayer from './index';
import { AudioPlayerControllerInterface } from './indexModel';
import { useDispatch } from "react-redux";
import { setShowAlertFeedback } from "../../../../../../../../store/internal";

const AudioPlayerController: FC<AudioPlayerControllerInterface> = (props) => {
  const { t } = useTranslation('AudioPlayer');
  const dispatch = useDispatch();
  
  const [audio, setAudio] = useState<HTMLAudioElement>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [showVolume, setShowVolume] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);

  useEffect(() => {
    if (props.currentUrlPlaying !== props.audioUrl) {
      audio?.pause();
      setIsPlaying(false);
    }
  }, [props.currentUrlPlaying, props.audioUrl]);

  useEffect(() => {
    let isMounted = true;
    const newAudio = new Audio(props.audioUrl);
    
    function listenerLoadMetadata() {
      setErrorLoad(false);
      if (newAudio.duration !== Infinity) {
        setDuration(newAudio.duration);
      } else {
        setDuration(props.duration || 0);
      }
    }

    function listenerTimeUpdate() {
      setCurrentTime(newAudio.currentTime);
    }

    function listenerEnded() {
      setCurrentTime(0);
      setIsPlaying(false);
    }

    function listenerProgress() {
      
    }

    function listenerError() {
      setErrorLoad(true);
    }


    if (isMounted) {
      newAudio.volume = volume;
  
      if (newAudio) {
        setErrorLoad(false);

        newAudio.addEventListener('loadedmetadata', listenerLoadMetadata);
        newAudio.addEventListener('timeupdate', listenerTimeUpdate);
        newAudio.addEventListener('ended', listenerEnded);
        newAudio.addEventListener('progress', listenerProgress);
        newAudio.addEventListener('error', listenerError);
      }
  
      setAudio(newAudio);
    }

    return () => {
      isMounted = false;
      newAudio.removeEventListener('loadedmetadata', listenerLoadMetadata);
      newAudio.removeEventListener('timeupdate', listenerTimeUpdate);
      newAudio.removeEventListener('ended', listenerEnded);
      newAudio.removeEventListener('progress', listenerProgress);
      newAudio.removeEventListener('error', listenerError);
    }
  }, []);

  const handlePlayPause = () => {
    if (audio && !errorLoad) {
      if (isPlaying) {
        audio.pause();
        props.setCurrentAudioPlayingUrl(null);
      } else {
        audio.play();
        props.setCurrentAudioPlayingUrl(props.audioUrl);
      }
      setIsPlaying(!isPlaying);
    }
  }

  const handleProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (audio) {
      const newTime = Number(event.target.value);
      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = Number(event.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
    setAudio(audio);
  };

  const handleCloseVolume = (event: React.MouseEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setShowVolume(false);
    }, 300);
  }

  const getFileInfo = (filename: string): { name: string, extension: string } => {
    const splitted = filename.split('.');
    const extension = `.${splitted.pop()}`;
    return {
      name: splitted.join('.'),
      extension
    };
  }

  const handleDownloadFile = () => {
    if (props.audioUrl) {
      const link = document.createElement('a');
      link.href = props.audioUrl;
      link.setAttribute('download', props.filename);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <AudioPlayer 
      t={t}
      filename={props.filename}
      duration={duration}
      currentTime={currentTime}
      isPlaying={isPlaying}
      errorLoad={errorLoad}
      volume={volume}
      showVolume={showVolume}
      setShowVolume={setShowVolume}
      handlePlayPause={handlePlayPause}
      handleProgressChange={handleProgressChange}
      handleVolumeChange={handleVolumeChange}
      getFileInfo={getFileInfo}
      handleDownloadFile={handleDownloadFile}
      handleCloseVolume={handleCloseVolume}
    />
  );
};

export default AudioPlayerController;
