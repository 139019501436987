import { FC } from 'react';
import SingleTabDetection from './index';
import { SingleTabDetectionControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';

const SingleTabDetectionController: FC<SingleTabDetectionControllerInterface> = () => {
  const { t } = useTranslation('SingleTabDetection');
  const TAB_KEY = 'activeTab';

  const handleClear = () => {
    localStorage.removeItem(TAB_KEY); // Limpa o localStorage com o id da aba atual
    setTimeout(() => {
      window.location.reload(); // Recarrega a página para fazer esta aba ser a ativa
    }, 1000);
  };

  return (
    <SingleTabDetection
      t={t}
      handleClear={handleClear}
    />
  );
};

export default SingleTabDetectionController;
