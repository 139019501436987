import { Button, Col, FormControl, FormLabel, InputGroup, Modal, Row } from "react-bootstrap";
import styles from "../styles";
import { useTranslation } from "react-i18next";

const ChangeSector = (props) => {

  const { t } = useTranslation();

  return (
    <Modal show={props.show} onHide={props.handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>{t("home_tickets.view_ticket.change_sector_confirm.title")}</Modal.Title>      
      </Modal.Header>
      <Modal.Body className='mx-3 mb-2'>
        <Row className="d-flex justify-content-left mb-2">
          <Col>
              <span>{t("home_tickets.view_ticket.change_sector_confirm.alert_text")}</span>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          <Col xs={6} className="d-flex justify-content-start">
              <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.handleCloseModal} >{t("home_tickets.view_ticket.change_sector_confirm.cancel")}</Button>
          </Col>
          
          <Col xs={6} className="d-flex justify-content-end">
            <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} onClick={() => props.updateTicket()}>{t("home_tickets.view_ticket.change_sector_confirm.confirm")}</Button>
          </Col>
      </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ChangeSector;