import { IconPhone, IconUsers } from '@tabler/icons-react';
import Loading from '../../../../../../../../components/loading';
import './styles.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Utils from '../../../../../../../../core/shared/utils';
import { ListName } from '../../../../../../../../core/enums/order-sort-by';

const Consumers = ({
  data,
  isLoading,
  t,
  searchTerm,
  setSearchTerm,
  filteredLength,
  selectNumber,
  updateListfunction,
  hasMoreInformation
}) => {

  return (
    <div className="scene-consumers" >
      <span className='scene-title'>{t('phone_callcenter.main_screen.consumers.title')}</span>
      {isLoading && data.length === 0 && <Loading visibility={isLoading} noBootstrap={true} customStyle={{ position: 'relative' }} />}
      { ((data.length > 0) || (!isLoading && searchTerm)) &&
        <div className="search-agent">
          <input 
            type="text" 
            className="form-control" 
            placeholder={t('phone_callcenter.main_screen.agents_extension.search')}
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)} 
          />
        </div>
      }
      {
        !isLoading && data.length === 0 && 
        <div className='scene-list-empty'>
          <IconUsers size={42} color="#4a69bd" />
          <span className='empty-text'>{t('phone_callcenter.main_screen.consumers.no_consumer_found')}</span>
        </div>
      }
      {
        data.length > 0 &&
        <div id="scrollableDiv" className="scrollableDiv">
          <InfiniteScroll
            dataLength={data.length}
            next={updateListfunction}
            hasMore={hasMoreInformation}
            loader={null}
            scrollThreshold="10px"
            scrollableTarget="scrollableDiv"
          >
            <div className="scene-list" style={{overflowY: 'auto'}}>
              {data.map((item, index) => {
                let itemClass = 'scene-list-item item-scroll';
                
                return (
                  <div className={itemClass} key={`option_${item.id}_${index}`} >
                    <div className="list-item-details">
                      <span className="details-consumer">{ item.phone }</span>
                      <span className="details-name">{ item.name }</span>
                    </div>
                    {
                      <span className="list-item-action">
                        <OverlayTrigger placement="left" overlay={<Tooltip>{t('phone_callcenter.main_screen.consumers.call_to_this_number')}</Tooltip>}>
                          <IconPhone onClick={() => selectNumber(item.phone)} />
                        </OverlayTrigger>
                      </span>
                    }
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      }
    </div>
  )
}

export default Consumers