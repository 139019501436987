import './styles.scss'
import { IconX } from '@tabler/icons-react'
import { IconPlus } from '@tabler/icons-react'

const ServiceModalNew = ({
    show,
    items,
    addItems,
    dayOfWeek,
    daysOfWeek,
    handleCloseSave,
    warning,
    onHide,
    t,
    blockButtom
}) => {
    return(
        <div className={show ? 'container-modal' : 'none'}>
            <div className='container-body-modal'>
                <div className='container-header-modal'>
                    <div className='container-header-title'>
                        {t("home_departments.schedule_service_tab.opening_hours")}
                        <div className='container-header-subtitle'>{t("home_departments.schedule_service_tab.select_schedule")}</div>
                    </div>
                    <div className='container-header-close-button' onClick={() => onHide()}>
                        <IconX stroke={2} />
                    </div>
                </div>
                <div className='container-day-of-week-row'>
                    <div className='container-day'>
                        {daysOfWeek(dayOfWeek)}
                    </div>
                </div>
                <div className='container-button-row'>
                    <div className='container-button-row-title'>
                        {t("home_departments.schedule_service_tab.hours")}
                    </div>
                    <div className='container-button-row-add'>
                        <button className='container-button-add' disabled={blockButtom} onClick={() => addItems()}>
                            <IconPlus stroke={1}/>
                        </button>
                    </div>
                </div>
                <div className='container-list-schedules' id="listOfSchedules">
                    {items}
                </div>
                <div className='container-wanings'>
                    {warning ? 
                        <div className='container-warning'>
                            {t("home_departments.schedule_service_tab.registered_timetable")}
                        </div>
                        :
                        <div className='container-warning-none'>

                        </div>
                    }
                </div>
                <div className='container-row-buttons-modal'>
                    <div className='container-cancel'>
                        <button className='container-button cancel' onClick={() => onHide()}>{t("home_departments.schedule_service_tab.cancel")}</button>
                    </div>
                    <div className='container-save'>
                        <button className='container-button save' disabled={blockButtom} onClick={() => handleCloseSave()}>{t("home_departments.schedule_service_tab.save")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceModalNew