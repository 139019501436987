import { useEffect, useState } from "react"
import { useSocketV2Context } from "../core/context/socket-context-v2";


const useBotCxpressUpdatedFiles = () => {

    const { socketInitialized, registerSocketChatbotEvent, unregisterSocketChatbotEvent } = useSocketV2Context();

    const [updatedFiles, setUpdatedFiles] = useState<any>()

    useEffect(() => {
        registerSocketChatbotEvent('botcxpress-updated-files', (data) => setUpdatedFiles(data.botCxpress));

        return () => {
            unregisterSocketChatbotEvent('botcxpress-updated-files')
        }
    }, [socketInitialized])

    return { updatedFiles }
}

export default useBotCxpressUpdatedFiles