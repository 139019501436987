import './styles.scss';
import { FC } from 'react';
import { ModalDeleteConsumerInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import { Trans } from 'react-i18next';

const ModalDeleteConsumer: FC<ModalDeleteConsumerInterface> = (props) => {
  let consumerI18nKey = 'ModalDeleteConsumer:message_single';

  if (props.hasConsumerTickets) {
    consumerI18nKey = props.consumerTickets > 1 ? 'ModalDeleteConsumer:message_single_with_tickets' : 'ModalDeleteConsumer:message_single_with_ticket';
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-delete-consumer-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.mode === 'single' ? props.t('title') : props.t('title_multi')}
        </Modal.Title>
        <div className="modal-delete-consumer-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingDelete) && <Loading blur={true}/>}
        { props.data && props.data.length === 1 &&
          <span className="modal-delete-consumer-message">
            <Trans 
              i18nKey={consumerI18nKey}
              values={{ consumerName: props.data[0].consumerName, consumerTickets: props.consumerTickets }}
              components={[ <span />, <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data && props.data.length > 1 &&
          <span className="modal-delete-consumer-message">
            <Trans 
              i18nKey={props.hasConsumerTickets ? 'ModalDeleteConsumer:message_multi_with_ticket' : 'ModalDeleteConsumer:message_multi'}
              values={{ deletedCount: props.data.length }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-delete-consumer-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-delete-consumer-btn-delete' disabled={props.loadingDelete} onClick={() => props.handleSubmit()}>{props.t('btn_delete')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteConsumer;
