import React, { useEffect, useState } from "react"
import ScheduleServiceTabNew from "./indexnew"
import { useTranslation } from "react-i18next"
import UserService from "../../../../../services/user-service"
import { DataCompanyInterface, TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../../../../store/company"
import { getToken } from "../../../../../store/token"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { DateObject, DatePickerProps } from "react-multi-date-picker"
import { getChatbot } from "../../../../../store/chatbot"

const AppRequesterConst = new AppRequesterController();

const ScheduleServiceTabControllerNew = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken)
        }
    }

    const datePickerRef = React.createRef<DatePickerProps>()

    const [openingHours, setOpeningHours] = useState<boolean>();
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * as informações dos inputs são primeiramente 
     * armazenadas aqui antes de serem organizadas 
     * por dia da semana
     */
    const [schedules, setSchedules] = useState([])


    /**
     * Dias do mês sem atendimento
     */
    const [scheduleDays, setScheduleDays] = useState<DateObject[]>([]);


    /**
     * Pega as informações digitadas 
     * nos inputs do modal de cadastro
     * de horário de atendimento
     */
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    
    const [items, setItems] = useState([])
    const [index, setIndex] = useState(0)
    const [dayOfWeek, setDayOfWeek] = useState('');

    const [updateForm, setUpdatedForm] = useState(false)

    /**
     * armazena os novos horários e envia para o array setSchedules 
     * quando o usuário fechar o modal
     */
    const [sendSchedulesData, setSendSchedulesData] = useState([])

    /**
     * Transferência do horário de serviço
     */
    const [transferService, setTransferService] = useState(false);

    /**
     * Pega as informações dos inputs de 
     * mensagem fora do serviço, ou messagem dentro 
     * do horário de serviço
     */
    const [messageOffService, setMessageOffService] = useState("Olá! Estamos fora do horário de atendimento. Voltaremos {proxHorario}. Quando nosso time iniciar as atividades, um dos nossos atendentes entrará em contato.");
    const [messageOnService, setMessageOnService] = useState("Olá! Nossa equipe está disponível, em breve um dos nossos atendentes entrará em contato. Por favor, aguarde.");
    const [showFeedbackMessageOffService, setShowFeedbackMessageOffService] = useState(false);
    const [showFeedbackMessageOnService, setShowFeedbackMessageOnService] = useState(false);

    const [inputId, setInputId] = useState('')
    /**
     * Envia os dados para o modal de 
     * cadastro de horários
     */
    const [schedulesByDay, setSchedulesByDay] = useState({})
    const [warning, setWarning] = useState(false)
    const [blockButtom, setBlockButtom] = useState(false)

    /**
     * Pega as informações do checkbox de cada dia 
     * da semana (ativo ou inativo)
     */
    const [mondayCheck, setMondayCheck] = useState(false)
    const [tuesdayCheck, setTuesdayCheck] = useState(false)
    const [wednesdayCheck, setWednesdayCheck] = useState(false)
    const [thursdayCheck, setThursdayCheck] = useState(false)
    const [fridayCheck, setFridayCheck] = useState(false)
    const [saturdayCheck, setSaturdayCheck] = useState(false)
    const [sundayCheck, setSundayCheck] = useState(false)

    /**
     * Pegando as informações de cada input
     * de acordo com o dia da semana (OBS: as informações
     * dos inputs armazenadas aqui após retiradas do hook schedules)
     */
    const [monday, setMonday] = useState([])
    const [tuesday, setTuesday] = useState([])
    const [wednesday, setWednesday] = useState([])
    const [thursday, setThursday] = useState([])
    const [friday, setFriday] = useState([])
    const [saturday, setSaturday] = useState([])
    const [sunday, setSunday] = useState([])

    const hasBot = useSelector(getChatbot);

    /**
     * Abre e fecha o modal de horários
     * @returns 
     */
    const handleClose = () => {
        setShow(false)
        setIndex(0)
        setItems([])
        // setSchedules([])
        // setSchedulesByDay([])
        setSendSchedulesData([])
        setWarning(false)
        setBlockButtom(false)

        const getterDaySchedules = getGetStateDay(dayOfWeek)
        const setter = getSetStatesCheck(dayOfWeek)

        if (sendSchedulesData?.length <= 0 && getterDaySchedules?.length <= 0) {
            setter(false)
        }
    };

    /**
     * Salva os novo horário adicionados
     */
    const handleCloseSave = () => {
        setShow(false)
        postSchedulesDay()

        const getter = getGetStateCheckboxDay(dayOfWeek)
        const setter = getSetStatesCheck(dayOfWeek)

        if (items?.length <= 0) {
            setter(!getter)
        }
    }

    /**
     * Abrir modal de horários
     * @returns 
     */
    const handleShow = () => setShow(true);

    /**
     * Realiza a requisição sempre que entrar na aba
     * de Configurações
     */
    useEffect(() => {
        const controller = new AbortController();
        getScheduleService(controller)

        return () => {
            controller.abort()
        }
    }, [])

    /**
     * Desativa o warning e desbloqueia 
     * os botões de adicionar horário e 
     * adicionar
     */
    useEffect(() => {
        if (startTime == '__:__' && !endTime) {
            setWarning(false)
        }
    }, [startTime, endTime])


    /**
     * Faz a verificação dos campos startTime e endTime,
     * caso os dados atendam aos requisitos o horário e não
     * sobreponha outro horários, será 
     * adicionado ao setSchedules
     */
    useEffect(() => {
        if (isValidTime(startTime) && isValidTime(endTime) && isEndTimeAfterStartTime(startTime, endTime)) {
            let newSchedule = {days_of_week: dayOfWeek, schedules: { start_time: startTime, end_time: endTime }, id: inputId}
            addSchedule(newSchedule, schedulesByDay[dayOfWeek])
        }
    }, [startTime, endTime])

    /**
     * Adiciona os horário de cada dia da semana para enviar para o body
     * da requisição PUT
     */
    useEffect(() => {
        if (schedules?.length > 0) {
            const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            days.forEach((day) => {
                const setState = getSetState(day)
                updateDaySchedules(day, schedules, setState)
            })
        }
    }, [schedules])

    /**
     * Função auxiliar para mapear dias aos
     * seus respectivos setters de estado
     * @param day dia da semana
     */
    const getSetState = (day) => {
        const dayStateMap = {
            sunday: setSunday,
            monday: setMonday,
            tuesday: setTuesday,
            wednesday: setWednesday,
            thursday: setThursday,
            friday: setFriday,
            saturday: setSaturday,
        }
        return dayStateMap[day]
    }

    /**
     * Função auxiliar para pegar os dias
     * da semana dos hooks de dias da semana
     */
    const getGetStateDay = (day) => {
        const dayStateMap = {
            sunday: sunday,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday
        }
        return dayStateMap[day]
    }

    /**
     * Função auxiliar para pegar os dias
     * habilitados 
     * @param day 
     * @returns 
     */
    const getGetStateCheckboxDay = (day) => {
        const daysStateMapChackbox = {
            sunday: sundayCheck,
            monday: mondayCheck,
            tuesday: tuesdayCheck,
            wednesday: wednesdayCheck,
            thursday: thursdayCheck,
            friday: fridayCheck,
            saturday: saturdayCheck
        }
        return daysStateMapChackbox[day]
    }

    /**
     * Função auxiliar para mapear os dias
     * que estão habilitados para horário 
     * de atendimento
     * @param day dia da semana
     * @returns 
     */
    const getSetStatesCheck = (day) => {
        const dayStateMap = {
            sunday: setSundayCheck,
            monday: setMondayCheck,
            tuesday: setTuesdayCheck,
            wednesday: setWednesdayCheck,
            thursday: setThursdayCheck,
            friday: setFridayCheck,
            saturday: setSaturdayCheck
        }
        return dayStateMap[day];
    }

    /**
     * Separa os dados de acordo com o dia da semana e 
     * envia para o body da requisição PUT /company
     */
    const updateDaySchedules = (dayOfWeek, schedules, setDayState) => {
        // Filtra os horários correspondentes ao dia da semana recebido
        const filteredSchedules = schedules
            .filter((item) => item.days_of_week === dayOfWeek)
            .map((item) => item.schedules);

        // Remove duplicadas antes de atualizar o estado
        setDayState((prevList) => {
            const uniqueSchedules = filteredSchedules.filter(
                (newSchedule) =>
                    !prevList?.some(
                        (existingSchedule) =>
                            existingSchedule.start_time === newSchedule.start_time &&
                            existingSchedule.end_time === newSchedule.end_time
                    )
            );

            return [...prevList, ...uniqueSchedules];
        })
    }

    /**
     * Faz a validação para saber se o campo de hora foi
     * realmente preenchido
     * 
     * @param time string
     * @returns string
     */
    const isValidTime = (time) => {
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; //Horas de 00:00 a 23:59;
        return timeRegex.test(time)
    }

    /**
     * Verifica se o horário que está sendo adicionado
     * já existe no array de schedule antes de adicioná-lo 
     * ao array de schedule, quando não existe nenhum horário cadastrado
     * no banco de dados para o dia selecionado, e caso existam horários
     * cadastrados no banco de dados, ele impede que seja cadastrado
     * novamente
     */
    const addSchedule = (newSchedule, existSchedule) => {
        /**
         * Verifica se o horário recem cadastrado existe
         * no array de newSchedule
         */
        const exists = schedulesByDay[dayOfWeek]?.some(
            (schedule) => 
                schedule.days_of_week === newSchedule.days_of_week && 
                schedule.schedules.start_time === newSchedule.schedules.start_time &&
                schedule.schedules.end_time === newSchedule.schedules.end_time
        )

        /**
         * Verifica se existe um horário no banco que seja
         * igual ao horário recem cadastrado
         */
        const isMatch = existSchedule?.some((schedule) => 
            schedule?.start_time === newSchedule?.schedules?.start_time &&
            schedule?.end_time === newSchedule?.schedules?.end_time
        )

        const validSchedule = isOverlapping(newSchedule)

        const validScheduleDataBase = isOverlappingDataBase(newSchedule, existSchedule)

        if (!exists && !validSchedule && !isMatch && !validScheduleDataBase) {
            setSchedules((prevSchedules) => [...prevSchedules, newSchedule])
            // setSendSchedulesData((prevSchedules => [...prevSchedules, newSchedule]))
        } else {
            setWarning(true)
            setBlockButtom(true)
        }
    }
    
    /**
     * Pega os elementos que estão no id do botão de 
     * deletar do input
     * @param event 
     */
    const handleClick = (event) => {
        const id = event.currentTarget.id;
        const idParts = id.split("*ID*")[1].split('_');
        const startTime = idParts[0]
        const endTime = idParts[1]
        const idNewItem = idParts[2]

        if (startTime.toString() != "undefined" && endTime.toString() != "undefined") {
            removeItemFromDays(startTime, endTime, idNewItem)
        } else {
            removeItemFromArrays(idNewItem)
        }
    }

    /**
     * Remove elementos dos arrays referentes 
     * a cada dia da semana, ou seja, remove
     * também o que for recebido via rota GET do
     * banco de dados
     * @param startTime 
     * @param endTime 
     */
    const removeItemFromDays = (startTime, endTime, idNewItem) => {
        const setDays = getSetState(dayOfWeek)
        setDays((prevItem) => prevItem.filter((item) => item.start_time != startTime && item.end_time != endTime))
        setItems((prevItems) => prevItems.filter((item) => item.props.idNewItem != idNewItem.toString()))
        setWarning(false)
        setBlockButtom(false)
    }

    /**
     * Remove um elemento que já esteja cadastrado
     * no array de items, ou seja, não estão vindo 
     * da rota GET da API
     * @param index index dos elementos adicionados
     * 
     * tenho que verificar o schedules e o set de dia 
     */
    const removeItemFromArrays = (idNewItem) => {
        setItems((prevItems) => prevItems.filter((item) => item.props.idNewItem != idNewItem.toString()));
        setSendSchedulesData((prevItems) => prevItems.filter((item) => item.id != idNewItem.toString()))
        setWarning(false)
        setBlockButtom(false)
    }

    /**
     * Compara os dados salvos no banco com os dados 
     * recem adicionados e verifica se existe alguma sobreposição de 
     * horários antes de adicionar ao array de schedules
     * @param newSchedule objeto recem adicionado no input de horários
     * @param existSchedule array com horários já cadastrados no bancp
     */
    const isOverlappingDataBase = (newSchedule, existSchedule) => {
        return existSchedule?.some((schedule) => {
            // Converte horários para minutos
            const [startA, endA] = [toMinutes(schedule.start_time), toMinutes(schedule.end_time)];
            const [startB, endB] = [toMinutes(newSchedule.schedules.start_time), toMinutes(newSchedule.schedules.end_time)];

            // Verifica sobreposição de horários
            return (
                (startB >= startA && startB < endA) || // Início do novo dentro do antigo
                (endB > startA && endB <= endA) ||    // Fim do novo dentro do antigo
                (startB <= startA && endB >= endA)    // Novo engloba o antigo
            )
        })
    }

    /**
     * Converte a string de horas em um number de minutos
     * @param time 
     */
    const toMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes
    }


    /**
     * Verifica se existe alguma sobreposição de horários
     * antes de adicionar ao array de schedules (não salvo no banco)
     * 
     * @param newSchedule horários cadastrados
     * @returns boolean
     */
    const isOverlapping = (newSchedule) => {
        return schedulesByDay[dayOfWeek]?.some((schedule) => {
            if (schedule.days_of_week !== newSchedule.days_of_week) {
                return false; // Diferente dia da semana, sem conflito
            }

            const [startA, endA] = [schedule.schedules.start_time, schedule.schedules.end_time];
            const [startB, endB] = [newSchedule.schedules.start_time, newSchedule.schedules.end_time];

            // Verifica sobreposição
            return (
                (startB >= startA && startB < endA) || // Início do novo dentro do antigo
                (endB > startA && endB <= endA) || // Fim do novo dentro do antigo
                (startB <= startA && endB >= endA) // Novo engloba antigo
            )
        })
    }

    /**
     * Verifica se o horário de início é menor do que 
     * o horário do fim
     * 
     * @param startTime string
     * @param endTime string
     * @returns boolean
     */
    const isEndTimeAfterStartTime = (startTime, endTime) => {
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        // Converte para minutos totais e campara
        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;

        return endTotalMinutes > startTotalMinutes
    }

    /**
     * Realiza requisição PUT de /company
     * para habilitar os horários de 
     * atendimento
     * @param event 
     */
    const reOpeningHours = (event) => {
        const newValue =  event?.target?.checked;
        setOpeningHours(newValue)
        putSchedulesDay(newValue)
    }

    /**
     * Realiza a requisição e retorna os dados da rota 
     * GET /company
     * @param controller
     */
    const getScheduleService = async (controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await AppRequesterConst.Get(
            '/company', { headers, signal },
            (response: Object) => {},
            (data: DataCompanyInterface) => {
                if (data.status == 200) {
                    if (data?.data?.companies) {
                        setOpeningHours(data.data.companies[0].use_opening_hours)
                        processOpeningHours(data.data.companies[0].opening_hours)
                        processIsEnabled(data.data.companies[0].opening_hours)
    
                        if (data.data.companies[0].non_attendance_days !== null) {
                            let non_days = [];
                            data.data.companies[0].non_attendance_days.map((day) => {
                                let dateDay = new Date(day.replaceAll('Z', ""));
                                let formatedDay = new DateObject(dateDay);
                                non_days.push(formatedDay)
                            });
    
                            setScheduleDays(non_days)
                        }
    
                        setMessageOffService(data.data.companies[0].message_opening_hours);
                        setMessageOnService(data.data.companies[0].message_in_attendance)
                    }
                }

                setUpdatedForm(true);
            },
            (error: any) => {
                console.log(error.response)
            }, navigate, dispatch, setIsLoading, { values }
        )
    }

    /**
     * Requisição PUT /company
     * @param newValue 
     */
    const putSchedulesDay = async (newValue) => {
        const headers = UserService.getHeaders();
        const controller = new AbortController()

        const JsonSend = {
            "id": values.company.id,
            "use_opening_hours": newValue,
            "message_opening_hours": messageOffService,
            "message_in_attendance": messageOnService,
            "transfer_outside_opening_hours": transferService
        }

        await AppRequesterConst.Put(
            '/company', JsonSend, { headers },
            () => { },
            (data: DataCompanyInterface) => {
                if (data.status === 200) {
                    getScheduleService(controller)
                    dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved') }))
                }
            },
            (error: any) => {
                getScheduleService(controller)
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }));
            }, navigate, dispatch, props.setIsLoading
        )
    }

    /**
     * Requisição post do modal de cadastro de 
     * horário
     */
    const postSchedulesDay = async () => {
        const headers = UserService.getHeaders()
        const controller = new AbortController()

        let daysSchedules = []

        const getterDaySchedules = getGetStateDay(dayOfWeek)
        const getterCheckDay = getGetStateCheckboxDay(dayOfWeek)

        daysSchedules.push(...getterDaySchedules)

        sendSchedulesData.map((item) => {
            daysSchedules.push(item.schedules)
        })

        const JsonSend = {
            day: dayOfWeek,
            schedules: daysSchedules,
            is_enabled: getterCheckDay
        }

        await AppRequesterConst.Post(
            `/company/day-opening-hour`, JsonSend, { headers },
            () => {},
            (data: any) => {
                getScheduleService(controller)
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved') }))
            },
            (error: any) => {
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }))
            }, navigate, dispatch, setIsLoading
        )
    }

    /**
     * Recebe o array de horário da requisição
     * GET /company e distribui o valor de 
     * is_enabled refente a cada dia
     */
    const processIsEnabled = (openingHours) => {
        openingHours.forEach((item) => {
            const setter = getSetStatesCheck(item.day) //Pega o setter correspondente ao dia
            if (setter) {
                setter(item.is_enabled)
            } 
        })
    }

    
    /**
     * Recebe o array de horário da 
     * requisição GET /company e retorna 
     * um objeto com os horários separados por 
     * dia da semana.
     * 
     * @param openingHours array de horários
     */
    const processOpeningHours = (openingHours) => {
        // Cria um objeto para armazenar os horários por dia da semana
        const scheduleByDay = {};
    
        openingHours.forEach((entry) => {
            const { day, schedules } = entry;
    
            // Verifica se o dia está habilitado e possui horários
            if (schedules?.length > 0) {
                scheduleByDay[day] = schedules.map((schedule) => {
                    return {
                        start_time: schedule.start_time,
                        end_time: schedule.end_time,
                    };
                });
            } else {
                scheduleByDay[day] = []; // Define como vazio caso o dia não esteja habilitado
            }
        });
        setSchedulesByDay(scheduleByDay)
        updateStatesByDay(scheduleByDay)
    };

    /**
     * Vai receber o Objeto scheduleByDay e vai distribuir as informações
     * de acordo com cada dia;
     * @param schedulesByDay vai receber o objeto scheduleByDay
     */
    const updateStatesByDay = (schedulesByDay) => {
        Object.entries(schedulesByDay).forEach(([day, schedules]) => {
            const setState = getSetState(day);
            if (setState) {
                setState(schedules);
            }
        });
    };

    /**
     * Pega as informações do campo de 
     * mensagem fora do horário de atendimento
     * 
     * @param e 
     * @param handleChange 
     */
    const handleMessageOffService = (e, handleChange) => {
        setMessageOffService(e.target.value)
        handleChange(e)
    }

    /**
     * Pega as informações do campo de 
     * mensagem no horário de atendimento
     * @param e 
     * @param handleChange 
     */
    const handleMessageOnService = (e, handleChange) => {
        setMessageOnService(e.target.value);
        handleChange(e);
    }

    /**
     * Habilita a transferência de atendimento
     * humano fora do horário de atendimento
     */
    const enableTransferService = () => {
        setTransferService(true);
    }

    /**
     * Desabilita a transferência de atendimento 
     * humano fora do horário de atendimento
     */
    const disableTransferService = () => {
        setTransferService(false);
    }

    return (
        <ScheduleServiceTabNew
            openingHours={openingHours}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            t={t}
            setItems={setItems}
            items={items}
            setIndex={setIndex}
            index={index}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setDayOfWeek={setDayOfWeek}
            dayOfWeek={dayOfWeek}
            setMondayCheck={setMondayCheck}
            setTuesdayCheck={setTuesdayCheck}
            setWednesdayCheck={setWednesdayCheck}
            setThursdayCheck={setThursdayCheck}
            setFridayCheck={setFridayCheck}
            setSaturdayCheck={setSaturdayCheck}
            setSundayCheck={setSundayCheck}
            setScheduleDays={setScheduleDays}
            scheduleDays={scheduleDays}
            datePickerRef={datePickerRef}
            hasBot={hasBot}
            transferService={transferService}
            disableTransferService={disableTransferService}
            enableTransferService={enableTransferService}
            handleMessageOffService={handleMessageOffService}
            handleMessageOnService={handleMessageOnService}
            monday={monday}
            tuesday={tuesday}
            wednesday={wednesday}
            thursday={thursday}
            friday={friday}
            saturday={saturday}
            sunday={sunday}
            mondayCheck={mondayCheck}
            tuesdayCheck={tuesdayCheck}
            wednesdayCheck={wednesdayCheck}
            thursdayCheck={thursdayCheck}
            fridayCheck={fridayCheck}
            saturdayCheck={saturdayCheck}
            sundayCheck={sundayCheck}
            values={values}
            messageOffService={messageOffService}
            messageOnService={messageOnService}
            AppRequesterConst={AppRequesterConst}
            dispatch={dispatch}
            navigate={navigate}
            props={props}
            handleClickDeleteDataBase={handleClick}
            handleCloseSave={handleCloseSave}
            warning={warning}
            setMessageOffService={setMessageOffService}
            setMessageOnService={setMessageOnService}
            setShowFeedbackMessageOffService={setShowFeedbackMessageOffService}
            setShowFeedbackMessageOnService={setShowFeedbackMessageOnService}
            showFeedbackMessageOffService={showFeedbackMessageOffService}
            showFeedbackMessageOnService={showFeedbackMessageOnService}
            setInputId={setInputId}
            reOpeningHours={reOpeningHours}
            updateForm={updateForm}
            blockButtom={blockButtom}
        />
    )
}

export default ScheduleServiceTabControllerNew