import InputServiceNew from "."

const InputServiceControllerNew = ({
    dayOfWeek,
    setDayOfWeek,
    dayActivated,
    dayData,
    activeDay,
    id,
    disabled,
    handleShow,
    t
}) => {

     /**
     * Abre o modal sempre que o checkbox é clicado
     * 
     * @param id string que é o nome do dia da semana
     */
    const openModalCheckbox = (id) => {
        let active = document.getElementById(id) as HTMLInputElement;
        if (active.checked) {
            handleShow()
            setDayOfWeek(id)
            activeDay(true)
        } else {
            activeDay(false)
        }
    }

    /**
     * Abre o modal quando o ícone de ações é clicado
     * 
     * @param id string que é o nome do dia da semana
     */
    const openModal = (id) => {
        handleShow()
        setDayOfWeek(id)
        activeDay(true)
    }

    /**
     * Organiza o array de maneira crescente, para que o 
     * menor horário seja sempre o primeiro a ser exibido 
     * na tela
     */
    const sortedSchedules = dayData.sort((a, b) => {
        // Converte os horários para valores numéricos para comparação
        const timeA = parseInt(a?.start_time?.replace(":", ""));
        const timeB = parseInt(b?.start_time?.replace(":", ""));

        // Prdena em ordem crescente
        return timeA - timeB
    })

    return (
        <InputServiceNew
            dayOfWeek={dayOfWeek}
            disabled={disabled}
            id={id}
            dayData={sortedSchedules}
            dayActivated={dayActivated}
            openModalCheckbox={openModalCheckbox}
            openModal={openModal}
            t={t}
        />
    )
}

export default InputServiceControllerNew