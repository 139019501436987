import { FC, useEffect, useState } from 'react';
import PopupTemplateMessagesGeneralVariable from './index';
import { PopupTemplateMessagesGeneralVariableControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { setGeneralVariables } from '../../../../../../../../../../store/internal';
import { useDispatch } from 'react-redux';

const PopupTemplateMessagesGeneralVariableController: FC<PopupTemplateMessagesGeneralVariableControllerInterface> = (props) => {
  const { t } = useTranslation('PopupTemplateMessagesGeneralVariable');
  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);
  let auxMessage = ''

  useEffect(() => {
    if(props.generalTypesVariables.length > 0) {
      setData(props.generalTypesVariables);
    }
  }, [props.generalTypesVariables]);

  const isValid = () => {
    let valid = 0
    
    data.map((item: any) => {
      if(item.tag_value === '' || item.tag_value === null || item.tag_value === undefined) {
        valid++
      }
    })
    if(valid > 0) {
      return false;
    } else {
      return true;
    }
  }
  
  const handleSubmit = () => {
    let generalVariables = [];
    auxMessage = props.auxTemplateMessage;
    props.generalTypesVariables.forEach((item: any) => {
      auxMessage = auxMessage.replace(`{{${item.alias}}}`, item.tag_value).replace(`{{${item.tag_number}}}`, item.tag_value);
      generalVariables.push({id: item.id, name: item.alias, value: item.tag_value});
    })
    dispatch(setGeneralVariables(generalVariables));
    props.setAuxTemplateMessage(auxMessage);
    props.hide();
  }

  const setDataValue = (id: string, value: any) => {
    setData((prevState) => {
      prevState.forEach((item: any) => {
        if(id === item.id) {
          item.tag_value = value
        }
      })
      return [...prevState];
    })
  }

  return (
    <PopupTemplateMessagesGeneralVariable 
      t={t}
      data={data}
      show={props.show}
      hide={props.hide}
      isValid={isValid}
      handleSubmit={handleSubmit}
      setDataValue={setDataValue}
    />
  );
};

export default PopupTemplateMessagesGeneralVariableController;
