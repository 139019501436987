import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import CustomTableList from './index';
import { CustomTableListControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import emitter from '../../core/shared/emitter';
import { TicketStatus } from '../../core/enums/ticket-status';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import i18n from '../../i18next';
import { ListConsumerInterface } from '../../pages/homeApp/homeConsumers2/listOfConsumers/indexModel';
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconCircleFilled, IconHelp, IconMail, IconMessages, IconPhone, IconUserBolt } from '@tabler/icons-react';
import { EmployeeInterface } from '../../pages/homeApp/homeAgents2/listOfAgents/indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StatusAgent } from '../../core/enums/status-agent';
import { UserProfile } from '../../core/enums/user-profile';
import { MessageTriggerStatus } from '../../core/enums/message-trigger-status';

const CustomTableListController: FC<CustomTableListControllerInterface> = (props) => {
  const { t } = useTranslation('CustomTableList');

  const tableListRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [showPopupPages, setShowPopupPages] = useState(false);
  const [openedPopoverId, setOpenedPopoverId] = useState<string>(null);

  useEffect(() => {
    const divList = document.getElementById('table-list-items');

    if (divList) {
      setHasVerticalScrollbar(divList.scrollHeight > divList.clientHeight);
    } else {
      setHasVerticalScrollbar(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (showPopupPages) {
      const handleClickOutside = (event: any) => {
        if (paginationRef?.current && !paginationRef?.current?.contains(event.target)) {
          setShowPopupPages(false);
        }
      }
  
      if (paginationRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showPopupPages]);

  useEffect(() => {
    if (props.component === 'modal-tasks') {
      emitter.on('close-item-action-popover-modal-list-tasks', () => {
        setOpenedPopoverId(null);
      });
    }
    if (props.component === 'tasks') {
      emitter.on('close-item-action-popover-list-tasks', () => {
        setOpenedPopoverId(null);
      });
    }
    if (props.component === 'agents') {
      emitter.on('close-item-action-popover-list-agents', () => {
        setOpenedPopoverId(null);
      });
    }
    if (props.component === 'modal-agent-consumers') {
      emitter.on('close-item-action-popover-modal-list-agent-consumers', () => {
        setOpenedPopoverId(null);
      });
    }

    return () => {
      props.component === 'modal-tasks' && emitter.off('close-item-action-popover-modal-list-tasks');
      props.component === 'tasks' && emitter.off('close-item-action-popover-list-tasks');
      props.component === 'agents' && emitter.off('close-item-action-popover-list-agents');
      props.component === 'modal-agent-consumers' && emitter.off('close-item-action-popover-modal-list-agent-consumers');
    }
  }, []);

  const util_formatValue = (value: any, field: string, type?: string): { value: any, icon?: any, class?: string, element?: any } => {
    const result: { value: any, icon?: any, class?: string, element?: any } = { value: undefined };

    if (Array.isArray(value)) {
      if (props.component === 'tasks' && field === 'tags' && value.length > 0) {
        result.value = value.map(item => item.name).join(', ');
      } else if (props.component === 'sectors') {
        if (field === 'keyword') {
          result.value = value.join(', ');
          result.element = <>{value.map((item, index) => (<span key={`list-item-value-badge-${index}`} className='table-list-item-value-badge'>{item}</span>))}</>
        } else if (field === 'agents') {
          result.value = value.map(item => item.name).join(', ');
          result.element = <>{value.map((item, index) => (<span key={`list-item-value-badge-${index}`} className='table-list-item-value-badge'>{item.name}</span>))}</>
        }
      } else {
        result.value = 'N/A';
      }
    } else {
      result.value = value;
    }

    if (value?.toString()) {
      const DATE_FIELDS = ['date_limit', 'date_scheduled', 'created_at'];

      if (DATE_FIELDS.includes(field)) {
        if (field === 'date_limit') {
          const [date, hours] = value.replace('Z', '').split('T');
          const formattedDate = new Date(`${date} ${hours}`);
  
          result.value = formatDate(formattedDate.toISOString(), type || 'full', false);
        } else {
          result.value = formatDate(value, 'full', false);
        }
      } else if (field === 'requester_user') {
        result.value = value.name || 'N/A';
      } else if (field === 'requester_employee_name') {
        if (props.component !== 'tasks') {
          result.value = value.requester_employee_name || 'N/A';
        }
      } else if (field === 'description') {
        result.value = value?.slice(0, 200);
      } else if (field === 'phone') {
        result.value = formatPhoneNumberIntl(`+${value}`);
      } else if (field === 'status_employee_id') {
        result.value = value;
      } else if (field === 'profile_id') {
        if (value === UserProfile.Owner) {
          result.value = t('agent_profile.owner');
        } else if (value === UserProfile.Admin) {
          result.value = t('agent_profile.admin');
        } else {
          result.value = t('agent_profile.agent');
        }
      } else if (props.component === 'messages-trigger-schedules') {
        if (field === 'status_id') {
          switch (value) {
            case MessageTriggerStatus.Triggered: {
              result.value = t('message_trigger_status.triggered');
              result.class = 'custom-status-green';
              break;
            }
            case MessageTriggerStatus.Canceled: {
              result.value = t('message_trigger_status.canceled');
              result.class = 'custom-status-orange';
              break;
            }
            default: {
              result.value = t('message_trigger_status.scheduled');
              result.class = 'custom-status-blue';
              break;
            }
          }
        } else if (field === 'channel_id') {
          const channel = getChannel(value);
          result.value = channel.title;
          result.icon = channel.icon;
        }
      } else if (props.component === 'messages-trigger-types' || props.component === 'sectors') {
        if (field === 'active') {
          result.value = value ? t('trigger_type_status.active') : t('trigger_type_status.inactive');
          result.class = value ? 'custom-status-blue' : 'custom-status-orange';
        }
      } else if (props.component.includes('tags-')) {
        if (field === 'reference_id') {
          result.value = `#${value}`;
        } else if (field === 'active') {
          result.value = value ? t('tag_status.active') : t('tag_status.inactive');
          result.class = value ? 'custom-status-blue' : 'custom-status-orange';
        }
      }
    } else {
      if (field === 'number_of_ticket_conversions') {
        result.value = '0';
      } else {
        result.value = 'N/A';
      }
    }

    return result;
  }

  const formatDate = (date: string, type?: string, seconds?: boolean): string => {
    const newDate = new Date(date);
    if (type === 'short') {
      return `${newDate.toLocaleDateString()}`;
    } else {
      return `${newDate.toLocaleDateString()} - ${seconds ? newDate.toLocaleTimeString() : newDate.toLocaleTimeString().slice(0,5)}`;
    }
  }

  const util_getStatusIcon = (status_id: string): { tooltip: string, symbol?: string, color?: string, icon?: JSX.Element } => {
    if (props.component === 'agents') {
      switch (status_id) {
        case StatusAgent.Online:
          return { tooltip: t('agent_status.online'), icon: <IconCircleFilled className="icon-info-status-agent icon-info-status-agent-online" /> };
        case StatusAgent.Ausente:
          return { tooltip: t('agent_status.away'), icon: <IconCircleFilled className="icon-info-status-agent icon-info-status-agent-away" /> };
        case StatusAgent.Offline:
          return { tooltip: t('agent_status.offline'), icon: <IconCircleFilled className="icon-info-status-agent icon-info-status-agent-offline" /> };
        default: {
          return { tooltip: t('agent_status.offline'), icon: <IconCircleFilled className="icon-info-status-agent icon-info-status-agent-offline" /> };
        }
      }
    } else {
      switch (status_id) {
        case TicketStatus.Open: 
          return { tooltip: t("ticket_status.open"), symbol: t('ticket_status_symbol.open'), color: '#4A69BD' };
        case TicketStatus.Resolved: 
          return { tooltip: t("ticket_status.resolved"), symbol: t('ticket_status_symbol.resolved'), color: '#707070' };
        case TicketStatus.Pending: 
          return { tooltip: t("ticket_status.pending"), symbol: t('ticket_status_symbol.pending'), color: '#DE2450' };
        case TicketStatus.OnHold: 
          return { tooltip: t("ticket_status.on_hold"), symbol: t('ticket_status_symbol.on_hold'), color: '#FBBD4E' };
        case TicketStatus.New: 
          return { tooltip: t("ticket_status.new"), symbol: t('ticket_status_symbol.new'), color: '#17a589' };
        case TicketStatus.Abandoned: 
          return { tooltip: t("ticket_status.abandoned"), symbol: t('ticket_status_symbol.abandoned'), color: '#2D3233' };
        case TicketStatus.OverdueTicketTask: 
          return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
        default:
          return { tooltip: 'N/A', symbol: 'N/A', color: '#707070' };
      }
    }
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    const size = 18;
    const color = '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("channels_names.email"), icon: <IconMail stroke={1} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("channels_names.telephony"), icon: <IconPhone stroke={1} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("channels_names.whatsapp"), icon: <IconBrandWhatsapp stroke={1} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("channels_names.facebook"), icon: <IconBrandFacebook stroke={1} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("channels_names.instagram"), icon: <IconBrandInstagram stroke={1} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("channels_names.webchat"), icon: <IconMessages stroke={1} size={size} color={color} />};
      default:
        return { title: 'N/A', icon: <IconHelp stroke={1} size={size} color={color} />}
    }
  }

  const fetchMore = (page?: number) => {
    if (props.fetchMore) {
      props.fetchMore(page);
    }
  }

  const scrollTop = () => {
    const listElement = document.getElementById('table-list-component-content');

    if (listElement) {
      listElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleClickPage = (page: number) => {
    setShowPopupPages(false);
    if (props.paginationDetails.currentPage !== page) {
      scrollTop();
      props.fetchMore(page);
    }
  }

  const handlePopoverMenuAction = (event: MouseEvent<HTMLDivElement, MouseEvent>, itemId: string) => {
    event.stopPropagation();
    const popoverElement = document.querySelector('.popover-custom-action-menu') as HTMLDivElement;
    if (popoverElement) {
      if (!popoverElement.classList.contains('show')) {
        popoverElement.classList.add('show');
        popoverElement.style.visibility = '';
        setOpenedPopoverId(itemId);
      } else {
        setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
      }
    } else {
      setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
    }
  }

  const getItemId = (item: any, isPopover?: boolean): string => {
    const componentsUserId = ['consumers', 'agents', 'modal-agent-consumers'];

    if (componentsUserId.includes(props.component)) {
      return isPopover ? `${props.component}_${item.user_id}` : item.user_id;
    } else if (props.component === 'modal-messages-trigger-report') {
      return item._id;
    } else if (props.component === 'sectors') {
      return item.sector_id;
    } else {
      return isPopover ? `${props.component}_${item.id}` : item.id;
    }
  }

  const util_formatNumberValue = (value: number) => {
    return new Intl.NumberFormat(i18n.language?.toLocaleLowerCase() || 'pt').format(value);
  }

  const getItemCustomClass = (item: any) => {
    if (props.component === 'consumers') {
      const consumer = item as ListConsumerInterface;
      if (consumer.created_by_employee && consumer.created_by_employee === 'true') {
        return 'table-list-item-disabled';
      }
    } else if (props.component === 'agents') {
      const agent = item as EmployeeInterface;
      if (agent.profile_id === UserProfile.Owner) {
        return 'table-list-item-emphasis';
      }
    }

    return '';
  }

  const showAvailableCheck = (item: any) => {
    if (props.component === 'consumers') {
      const consumer = item as ListConsumerInterface;
      return consumer.created_by_employee && consumer.created_by_employee !== 'true';
    } else if (props.component === 'agents') {
      const agent = item as EmployeeInterface;
      return agent.profile_id !== UserProfile.Owner;
    }
    return true;
  }

  const showIconInfo = (item: any) => {
    if (props.component === 'consumers') {
      const consumer = item as ListConsumerInterface;
      return consumer.created_by_employee && consumer.created_by_employee === 'true';
    } else if (props.component === 'agents') {
      const agent = item as EmployeeInterface;
      return agent.profile_id === UserProfile.Owner || agent.profile_id === UserProfile.Admin;
    }
    return false;
  }

  const getIconInfo = () => {
    if (props.component === 'consumers') {
      return (
        <OverlayTrigger show={props.itemIconInfoTooltip ? undefined : false} overlay={<Tooltip>{props.itemIconInfoTooltip}</Tooltip>}>
          <IconUserBolt />
        </OverlayTrigger>
      );
    }
  }

  const checkOffItem = (item: any) => {
    if (props.component === 'consumers') {
      const consumer = item as ListConsumerInterface;
      return consumer.created_by_employee && consumer.created_by_employee === 'true';
    } else if (props.component === 'agents') {
      const agent = item as EmployeeInterface;
      return agent.profile_id === UserProfile.Owner;
    }
  }

  return (
    <CustomTableList
      t={t}
      component={props.component}
      text_empty_list={props.text_empty_list}
      text_loading_list={props.text_loading_list}
      text_error_list={props.text_error_list}
      icon_entity_list={props.icon_entity_list}
      itemWrap={props.itemWrap}
      scrollbarThin={props.scrollbarThin}
      tableListRef={tableListRef}
      paginationRef={paginationRef}
      headers={props.headers}
      data={props.data}
      reloadData={props.reloadData ? props.reloadData : () => {}}
      paginationDetails={props.paginationDetails}
      fetchMore={fetchMore}
      handleClickPage={handleClickPage}
      isLoading={props.isLoading}
      errorLoading={props.errorLoading}
      showPopupPages={showPopupPages}
      setShowPopupPages={setShowPopupPages}
      openedPopoverId={openedPopoverId}
      setOpenedPopoverId={setOpenedPopoverId}
      handlePopoverMenuAction={handlePopoverMenuAction}
      popoverItemAction={props.popoverItemAction} 
      util_formatValue={util_formatValue}
      util_getStatusIcon={util_getStatusIcon}
      util_formatNumberValue={util_formatNumberValue}
      hasVerticalScrollbar={hasVerticalScrollbar}
      handleItemClick={props.handleItemClick || (() => {})}
      itemClickDisabled={props.itemClickDisabled}
      getItemId={getItemId}
      hasItemCheckSelection={props.hasItemCheckSelection}
      selectItem={props.selectItem}
      selectAllItems={props.selectAllItems}
      isSelectedItem={props.isSelectedItem ? props.isSelectedItem : () => false}
      hasSomeSelected={props.hasSomeSelected ? props.hasSomeSelected : () => false}
      hasSomeSelectedCheck={props.hasSomeSelectedCheck ? props.hasSomeSelectedCheck : () => false}
      isAllSelectedItems={props.isAllSelectedItems ? props.isAllSelectedItems : () => false}
      selectedItemsLength={props.selectedItemsLength}
      hasItemIconInfo={props.hasItemIconInfo}
      itemIconInfoTooltip={props.itemIconInfoTooltip}
      getItemCustomClass={getItemCustomClass}
      showAvailableCheck={showAvailableCheck}
      showIconInfo={showIconInfo}
      getIconInfo={getIconInfo}
      checkOffItem={checkOffItem}
      hasExportAll={props.hasExportAll}
      exportAll={props.exportAll}
      hasExportSelected={props.hasExportSelected}
      exportSelected={props.exportSelected}
      hasDeleteSelected={props.hasDeleteSelected}
      deleteSelected={props.deleteSelected}
      hasActivateSelected={props.hasActivateSelected}
      activateSelected={props.activateSelected}
      hasInactivateSelected={props.hasInactivateSelected}
      inactivateSelected={props.inactivateSelected}
      clearSelected={props.clearSelected}
      clearPageSelected={props.clearPageSelected}
    />
  );
};

export default CustomTableListController;
