import { useEffect, useState } from "react";
import { t, use } from "i18next";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../store/company";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../../../../services/user-service";
import { getShowAcquirePlatformComponent, getShowConfirmationModal, getShowEditAgentsModal, getShowEditTicketsModal, getShowRegisterAgentsModal, setShowAlertFeedback } from "../../../../../store/internal";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import NotificationTicketTab from ".";
import { AgentData } from "../../../homeAgents/indexModel";
import BackendConstants from "../../../../../core/constants/backend-constants";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";



const NotificationTicketTabController = ({
    company
}) => {
    const AppRequesterConst = new AppRequesterController();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            quantityAgents: useSelector(getShowAcquirePlatformComponent).quantityAgents,
            getShowRegisterAgentsModal: useSelector(getShowRegisterAgentsModal),
            getShowEditAgentsModal: useSelector(getShowEditAgentsModal),
        }
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [/* isLoading */, setIsLoading] = useState(false);

    const [ableNotification, setAbleNotification] = useState(false);

    const [currentParams, SetCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, SetCurrentPage] = useState(1);
    const [currentAgents, setCurrentAgents] = useState(undefined);
    const [currentState, setCurrentState] = useState("");
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limit);
    const [/*currentAgent*/, setCurrentAgent] = useState([]);
    const [currentHasMoreInformation, /*SetCurrentHasMoreInformation*/] = useState(true);
    const [creatingAgent, setCreatingAgent] = useState(false);
    const [employes, setEmployes] = useState([])
    const [allMarked, setAllMarked] = useState(false)

    const validate = (values) => {
        let errors = {};
        return errors;
    }

    useEffect(() => {
        setEmployes([])
    }, []);

    useEffect(() => {
        setAbleNotification(company?.configs?.send_email_on_new_ticket)
    }, [company]);

    const onSubmit = async () => {
        let JsonSend = {
            id: values.company.id,
            send_email_on_new_ticket: ableNotification
        }

        let JsonSend1 = {
            employees: employes,
        }

        if (allMarked) {
            JsonSend1['enableAll'] = allMarked
        }
        else if (!allMarked && employes.length === 0) {
            JsonSend1['enableAll'] = false
        }

        const headers = UserService.getHeaders();
        await AppRequesterConst.Put(
            '/company', JsonSend, { headers: headers },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data?.data?.company) {
                }
            },
            (error: any) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error'), visibility: true, signalIcon: false }));
            }, navigate, dispatch, setIsLoading,
        );

        await AppRequesterConst.Patch(
            '/agent/update/send_email_notification', JsonSend1, { headers: headers },
            (response: Object) => { },
            (data) => {
                if (data.status === 200) {


                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.message'), visibility: true, signalIcon: true }));
                }
            },
            (error: any) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error'), visibility: true, signalIcon: false }));
            }, navigate, dispatch, setIsLoading,
        );
    }

    const getAllEmployees = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/agent', { headers, signal, params: params },
            (response: Object) => { },
            (data: AgentData) => {
                if (data.status === 200) {
                    setAux((state) => {

                        if (!state) {
                            state = [];
                        }

                        let aux = state.concat(data.data.employees);
                        setAux(aux);
                        setCurrentAgents(aux);
                        return state;
                    });
                } else if (aux.length === 0/*  && data.status !== 204 */) {
                    setCurrentAgents(undefined);
                }

            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllAgentsToPagination = (params: { limit: number; page: number; }, controller: AbortController) => {
        getAllEmployees(params, controller);
    }

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            SetCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllAgentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
                    return state;
                });
                return params;
            });
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    const callbackModalAgents = () => {
        SetCurrentPage(1);
        setAux([]);

        const controller = new AbortController();
        getAllAgentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
    }

    const callbackOrderByEmployees = (column: any, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }

        if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }

        setCurrentState(column + order);
        SetCurrentParams(auxObj);
        document.getElementById("ListOfAgents").scrollTop = 0;
    }

    const setCurrentAgentFunction = (value: any[]) => {
        setCurrentAgent(value);
    }

    const updateListAfterDelete = () => {
        setAux([]);
        SetCurrentPage(1);

        setCurrentState("deleting");

        if (currentState === "deleting") {
            setCurrentState("");
        }
    };

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    const showCreateAgent = () => {
        setCreatingAgent(true);
    }


    return (
        <NotificationTicketTab
            onSubmit={onSubmit}
            validate={validate}
            ableNotification={ableNotification}
            setAbleNotification={setAbleNotification}
            callbackModalAgents={callbackModalAgents}
            currentAgents={currentAgents}
            currentHasMoreInformation={currentHasMoreInformation}
            setCurrentAgentFunction={setCurrentAgentFunction}
            callbackOrderByEmployees={callbackOrderByEmployees}
            updateListAfterDelete={updateListAfterDelete}
            updateListfunction={updateListfunction}
            showCreateAgent={showCreateAgent}
            setEmployes={setEmployes}
            setAllMarked={setAllMarked}
        />
    )
}

export default NotificationTicketTabController;