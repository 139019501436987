import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import allStyles from "../styles";
import ModalEditWhatsappController from "../modalEditWhatsapp/indexController";
import ModalEditBrokerController from "../modalEditBroker/indexController";
import ModalActiveBaseController from "../modalActiveBase/indexController";

const ListConfigWhatsapp = ({
    data,
    show,
    handleClose,
    handleShow,
    showBroker,
    handleCloseBroker,
    currentWhatsappData,
    getWhatsAppById,
    isEditAllowed,
    userPermissions,
    brokerTyper,
    formatDate,
    popover,
    botId,
    onCloseActiveBase,
    currentConfig,
    currentWhatsappId,
    currentWhatsAppInfo,
    isLoading
}) => {
    return (
        <>
            <ModalActiveBaseController botId={botId} onCloseActiveBase={onCloseActiveBase} />
            <ModalEditWhatsappController show={show} onHide={handleClose} currentWhatsappData={currentWhatsappData} getWhatsAppById={getWhatsAppById} isLoading={isLoading} />
            {showBroker && <ModalEditBrokerController show={showBroker} onHide={handleCloseBroker} currentData={currentConfig} currentWhatsappId={currentWhatsappId} />}
            <div className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.textRowCollums}>
                        <Row name="ListOfAgentsHead">
                            <Col md={3} className="nopadding"><p>Número</p></Col>
                            <Col md={2} className="nopadding"><p>Possui bot?</p></Col>
                            <Col md={3} className="nopadding"><p>Data de criação</p></Col>
                            <Col md={3} className="nopadding"><p>Broker</p></Col>
                            <Col md={1} className="nopadding">{isEditAllowed && <p>Ações</p>}</Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {data.length !== 0 && (
                    <div id="ListOfAgents" style={{ overflowY: 'auto', height: '45vh' }}>
                        <ListGroup variant="flush" className="TicketsRow">
                            {data.map((item, index) => {
                                const date = formatDate(item.created_at, 'pt-BR');
                                return (
                                    <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                        <Row className="TicketsRowHeight">
                                            <Col md={3} className="align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                <div className="oneLineClampEllipsis me-3">
                                                    <OverlayTrigger key='top' placement="top" overlay={
                                                        <Tooltip id="tooltip">
                                                            {item?.phone_number != null ? item?.phone_number : 'N/A'}
                                                        </Tooltip>
                                                    }>
                                                        <span>{item?.phone_number}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                            <Col md={2} className="align-self-end nopadding" style={{ cursor: 'pointer' }}>
                                                <div className="oneLineClampEllipsis me-3">
                                                    <OverlayTrigger key='top' placement="top" overlay={
                                                        <Tooltip id="tooltip">
                                                            {item?.has_bot ? 'SIM' : 'NÃO'}
                                                        </Tooltip>
                                                    }>
                                                        <span>{item?.has_bot ? 'SIM' : 'NÃO'}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                            <Col md={3} className="d-flex justify-content-start align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                <div className="oneLineClampEllipsis me-3">
                                                    <OverlayTrigger key='top' placement="top" overlay={
                                                        <Tooltip id="tooltip">
                                                            {date}
                                                        </Tooltip>
                                                    }>
                                                        <span>{date}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                            <Col md={3} className="d-flex justify-content-start align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                <div className="oneLineClampEllipsis me-3">
                                                    <OverlayTrigger key='top' placement="top" overlay={
                                                            <Tooltip id="tooltip">
                                                                {brokerTyper(item.broker_id)}
                                                            </Tooltip>
                                                        }>
                                                        <span>{brokerTyper(item.broker_id)}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                            <Col md={1} className="d-flex justify-content-center align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                {isEditAllowed && (
                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item)}>
                                                        <Button id={item.id + "*ID*" + item.phone_number + "*ID*"} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }} onClick={(e) => currentWhatsAppInfo(e)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="1" />
                                                                <circle cx="12" cy="19" r="1" />
                                                                <circle cx="12" cy="5" r="1" />
                                                            </svg>
                                                        </Button>
                                                    </OverlayTrigger>
                                                )}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                )}
            </div>
        </>
    );
};

export default ListConfigWhatsapp;