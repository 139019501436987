import { useSelector } from 'react-redux'
import { Row, Col, Button, Tab, Tabs, OverlayTrigger, Tooltip, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent,
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import CallNotificationBar from '../../components/callNotificationBar';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ListOfCustomFieldsConsumersController from './listOfCustomFieldsConsumers/indexController';
import ModalRegisterCustomFieldsController from '../../components/modalRegisterCustomFieldConsumer/indexController';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import HomeCustomFieldsController from './customFields/indexController';
import ModalRegisterCustomFieldTicketsController from '../../components/modalRegisterCustomFieldTickets/indexController';
import ModalRegisterCustomFieldConsumersController from '../../components/modalRegisterCustomFieldConsumer/indexController';

import ListOfCustomFieldsTicketsController from './listOfCustomFieldsTickets/indexController';
import { useEffect } from 'react';

const HomeConsumersConfig = ({
    isLoading,
    showCustomFieldConsumerModalRegister,
    handleCloseCustomFieldConsumerModalRegister,
    handleShowCustomFieldConsumerModalRegister,
    showCustomFieldTicketModalRegister,
    handleCloseCustomFieldTicketModalRegister,
    handleShowCustomFieldTicketModalRegister,
    callBackModalCustomField,
    updateListFunction,
    // custom,
    customDataTicket,
    customDataConsumer,
    t
}) => {   
    return (
        <Container fluid className="nopadding">
            <Loading visibility={isLoading}></Loading>
            {/* <ModalRegisterCustomFieldsController show={showCustomFieldTicketModalRegister} handleClose={handleCloseCustomFieldConsumerModalRegister} callback={callBackModalCustomField} /> */}
            <div className="wrapper">
                {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubConsumers} /> */}
                <div className="content ps-0 pt-0">
                    <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                    <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                    {/* <Row className="nopadding mt-3 ps-3">
                        <Col md={7} style={{ marginBottom: '2vh' }}>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('configurations_consumers.general.welcome_main_custom_fields')}</div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('configurations_consumers.general.welcome_sub')}</div>
                            </Row>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ButtonListNotificationController />
                            <ButtonStatusAgent />
                        </Col>
                    </Row> */}
                    <Row className="mx-4">
                        <Tabs defaultActiveKey={"ticket"} id="custom-fields" style={{ padding: 0 }}>
                            {/* <Tab eventKey={"custom_fields"} title={"ticket"}>
                                <HomeCustomFieldsController />
                            </Tab> */}
                            <Tab eventKey={"ticket"} title={t("configurations_consumers.general.ticket_tab")}>
                                <Container fluid className="noppading">
                                    <ModalRegisterCustomFieldTicketsController show={showCustomFieldTicketModalRegister} handleClose={handleCloseCustomFieldTicketModalRegister} callback={callBackModalCustomField} />
                                    <Row>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh', marginBottom: '1vh' }}>
                                            {t('configurations_ticket.custom_fields.title')}
                                            {
                                                <OverlayTrigger
                                                    key='top'
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tootip" className="tooltip-arrow-Top">
                                                            {t('configurations_ticket.custom_fields.custom_fields_tooltip')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={handleShowCustomFieldTicketModalRegister}>
                                                        <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                            <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                            <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                        </p>
                                    </Row>
                                    <Row>
                                        <ListOfCustomFieldsTicketsController currentPage={updateListFunction} updateListfunction={() => { }} isLoading={isLoading} datas={customDataTicket} callBackModalCustomField={callBackModalCustomField} HasMoreInformation={true} showCreateCustomField={handleShowCustomFieldTicketModalRegister} />
                                    </Row>
                                </Container>
                            </Tab>

                            <Tab eventKey={"consumer"} title={t("configurations_consumers.general.consumers_tab")} >
                                <Container fluid className="noppading">
                                    <ModalRegisterCustomFieldConsumersController show={showCustomFieldConsumerModalRegister} handleClose={handleCloseCustomFieldConsumerModalRegister} callback={callBackModalCustomField} />
                                    <Row>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh', marginBottom: '1vh' }}>
                                            {t('configurations_ticket.custom_fields.title')}
                                            {
                                                <OverlayTrigger
                                                    key='top'
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tootip" className="tooltip-arrow-Top">
                                                            {t('configurations_ticket.custom_fields.custom_fields_tooltip')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={handleShowCustomFieldConsumerModalRegister}>
                                                        <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                            <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                            <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                        </p>
                                    </Row>
                                    <Row>
                                        <ListOfCustomFieldsConsumersController currentPage={updateListFunction} updateListfunction={() => { }} isLoading={isLoading} datas={customDataConsumer} callBackModalCustomField={callBackModalCustomField} HasMoreInformation={true} showCreateCustomField={handleShowCustomFieldConsumerModalRegister} />
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>
                    </Row>
                </div >
            </div >
        </Container>
    );
}

export default HomeConsumersConfig;
