import React, { useState, useContext, useEffect } from "react";
import Loading from "../../../../../components/loading";
import { Col, Container, Row, Tab, Tabs, Spinner } from "react-bootstrap";
import CallNotificationBar from "../../../components/callNotificationBar";
import { useSelector } from "react-redux";
import {  getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowUpdatedBot } from "../../../../../store/internal";
import AcquirePlatformNotificationBar from "../../../components/acquirePlatformNotificationBar";
import BotInfo from "./botInfo/indexController";
import TrainBot from "./trainBot/indexController";
import BotConfig from "./botConfig/indexController";
import EditTokenChatbot from "../../../components/modalEditTokenChatbot/indexController";
import TokenChatbot from "../tokenComponent/indexController";
import { ContractStatus } from "../../../../../core/enums/contract-status";
import ButtonStatusAgent from "../../../components/buttonStatusAgent";
import ButtonListNotificationController from "../../../../../components/buttonListNotification/indexController";
import BotCollectedDataController from "./collectedData/indexController";

const ViewBot = ({
  isLoading,
  values,
  dataBot,
  returnChannel,
  goToListOfBots,
  setInfoTabClassname,
  setTrainTabClassname,
  activeTab,
  inactiveTab,
  infoTabClassname,
  activeTabInfo,
  setIsLoading,
  chatTabClassname,
  activeTabTrain,
  t,
}) => {

  return (
    <>
      <Loading visibility={isLoading}></Loading>
      {/* <EditTokenChatbot token={values.token.value} id={values.company.id} visibility={useSelector(getShowUpdatedBot)}></EditTokenChatbot> */}
      <Container fluid className="nopadding" style={{ overflowY: "hidden" }}>
        <div className="wrapper">
          {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubChatbots} className="nopadding"></SidebarController> */}
          <div className="content ps-0 pt-0">
            <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
            <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
            {/* <Row className="nopadding mt-3 ps-4">
              <Col md={7} style={{ marginRight: '-8vw' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start ps-0">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t("configurations.chatbots.view.welcome")} {dataBot?.state && dataBot?.state?.channel ? returnChannel(dataBot.state?.channel) : ""}</div>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t("configurations.chatbots.view.here")}</div>
                </Row>
              </Col>
              {values.company.contractStatusId === ContractStatus.Active ?
                <Col className="d-flex justify-content-end">
                  <ButtonListNotificationController />
                  <ButtonStatusAgent />
                </Col>
                :
                <Col className="d-flex justify-content-end">
                  <ButtonListNotificationController />
                  <ButtonStatusAgent />
                </Col>
              }
            </Row> */}
            <Row className="ps-4" style={{ marginTop: '1.5vh', marginBottom: '-4.5vh', marginRight: 0 }}>
              <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                <p style={{ font: 'var(--px15_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={goToListOfBots}>
                  <span>
                    <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                      <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                      <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </span>
                  {t("configurations.chatbots.view.return")}
                </p>
              </Col>
            </Row>
            <Row className="ms-0 me-1 ps-4 ContentBotTabs" >
              <Tabs className="botTabs" mountOnEnter={true} unmountOnExit={true} 
                onSelect={(selectedTab) => {
                  if (selectedTab === "info") {
                    setInfoTabClassname(activeTab);
                    setTrainTabClassname(inactiveTab);
                  } else {
                    setInfoTabClassname(inactiveTab);
                    setTrainTabClassname(activeTab);
                  }
                }}
                id="botTabs" defaultActiveKey={dataBot.state && dataBot.state.defaultActiveKey ? dataBot.state.defaultActiveKey : "info"} style={{ padding: 0, margin: '3vh 0px 0px 0px' }}>
                <Tab
                  eventKey={'info'}
                  title={t("configurations.chatbots.view.base")}
                  className='ps-0 ms-0'
                >
                  <BotInfo setIsLoading={setIsLoading} setActiveTab={dataBot?.state?.setActiveTab} />
                </Tab>
                <Tab
                  eventKey={'chat'}
                  title={t("configurations.chatbots.view.chat")}
                  disabled={(dataBot.state && dataBot.state.data) ? false : true}
                  className='ps-0 ms-0'
                >
                  <TrainBot setIsLoading={setIsLoading} isLoading={isLoading} channelId={dataBot.state.channel} />
                </Tab>
                <Tab
                  eventKey={'config'}
                  title={t("configurations.chatbots.view.config")}
                  disabled={(dataBot.state && dataBot.state.data) ? false : true}
                  className='ps-0 ms-0'
                >
                  <BotConfig setIsLoading={setIsLoading}/>
                </Tab>
                <Tab
                  eventKey={'data'}
                  title={t("configurations.chatbots.view.collected_data")}
                  disabled={(dataBot.state && dataBot.state.data) ? false : true}
                  className='ps-0 ms-0'
                >
                  <BotCollectedDataController dataBot={dataBot}/>
                </Tab>
              </Tabs>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ViewBot;
