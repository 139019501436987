import './styles.scss';
import { FC } from 'react';
import { ModalActiveTriggerInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconFileDownload, IconFileTypeCsv, IconInfoCircle, IconSend, IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import CustomInputController from '../../../../../components/customInput/indexController';
import { Channels } from '../../../../../core/enums/channels';
import ChatEditorComponentController from '../../../homeTickets2/viewTicket/components/chatEditorComponent/indexController';
import { T_ToolbarItems } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/indexModel';

const ModalActiveTrigger: FC<ModalActiveTriggerInterface> = (props) => {

  let hiddenToolbarItems: T_ToolbarItems[] = ['file-uploader', 'audio-recorder'];
  const fileUploaderInvalidClass = !props.getChannelSelect().selectedChannel?.id ? 'modal-active-trigger-import-area-invalid' : '';
  const fileUploaderSelectedClass = props.selectedFileRecipients?.name ? 'modal-active-trigger-import-selected' : '';
  const switchInputDisabled = props.mode === 'info' ? 'switch-input-disabled' : '';
  let isEditorDisabled = props.mode === 'info';

  if (props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp) {
    if (props.hasTemplateAttachment && !isEditorDisabled) {
      isEditorDisabled = false;
      hiddenToolbarItems = ['bold', 'italic', 'underline', 'text-color', 'background-color', 'list-ordered', 'list-unordered', 'link', 'audio-recorder', 'emoji'];
    } else {
      isEditorDisabled = true;
    }
  }

  if (props.getChannelSelect().selectedChannel?.id === Channels.Email) {
    hiddenToolbarItems = [];
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(props.reloadOnClose)}
      size="lg"
      centered={true}
      dialogClassName="modal-active-trigger-component"
    >
      { props.mode &&
        <Modal.Header>
          { props.mode === 'edit' && <Modal.Title>{props.t('title_edit')}</Modal.Title>}
          { props.mode === 'create' && <Modal.Title>{props.t('title_new')}</Modal.Title>}
          { props.mode === 'info' && <Modal.Title>{props.t('title_info')}</Modal.Title>}
          <div className="modal-active-trigger-header-actions">
            <IconX className="modal-active-trigger-header-actions-close" onClick={() => props.hide(props.reloadOnClose)} />
          </div>
        </Modal.Header>
      }
      { props.loadingActiveTrigger &&
        <Modal.Body className='modal-body-loading'>
          <Loading blur />
          <div className="modal-body-loading-info">
            <IconSend />
            <span className="modal-body-loading-info-message">
              {props.t('loading_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingActiveTrigger && props.errorLoadingActiveTrigger &&
        <Modal.Body className='modal-body-loading'>
          <div className="modal-body-loading-info modal-body-loading-error">
            <IconSend />
            <span className="modal-body-loading-info-message">
              {props.t('loading_error_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingActiveTrigger && !props.errorLoadingActiveTrigger &&
        <Modal.Body>
          { props.loadingRequest && <Loading blur={true} />}
          <div className="modal-active-trigger-section">
            <div className="modal-active-trigger-items-group">
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.title_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.getTriggerTitleInput().title || ''}
                  setValue={props.getTriggerTitleInput().setTitle}
                  placeholder={props.t('form.title_placeholder')}
                  required={props.mode !== 'info'}
                  disabled={props.mode === 'info'}
                />
              </div>
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.type_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.type_placeholder')}
                  placeholderFilter={props.t('form.type_search')}
                  options={props.getTriggerTypesSelect().triggerTypesList || []}
                  selected={props.getTriggerTypesSelect().selectedTriggerType}
                  setSelected={props.getTriggerTypesSelect().setSelectedTriggerType}
                  fetchMore={props.getTriggerTypesSelect().fetchTriggerTypesPagination}
                  hasMore={props.getTriggerTypesSelect().hasMoreTriggerTypes}
                  loading={props.getTriggerTypesSelect().isLoadingTriggerTypesSelect}
                  search={props.getTriggerTypesSelect().fetchSearchTriggerTypes}
                  disabled={props.mode === 'info'}
                  required={props.mode !== 'info'}
                />
              </div>
            </div>
            <div className="modal-active-trigger-items-group">
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.channel_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomSelectController 
                  type='simple'
                  dropdownPosition='body'
                  placeholder={props.t('form.channel_placeholder')}
                  options={props.getChannelSelect().channelsList || []}
                  selected={props.getChannelSelect().selectedChannel}
                  setSelected={props.getChannelSelect().setSelectedChannel}
                  disabled={props.mode === 'info'}
                  required={props.mode !== 'info'}
                />
              </div>
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.sector_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.sector_placeholder')}
                  placeholderFilter={props.t('form.sector_search')}
                  options={props.getSectorsSelect().sectorsList || []}
                  selected={props.getSectorsSelect().selectedSector}
                  setSelected={props.getSectorsSelect().setSelectedSector}
                  fetchMore={props.getSectorsSelect().fetchSectorsPagination}
                  hasMore={props.getSectorsSelect().hasMoreSectors}
                  loading={props.getSectorsSelect().isLoadingSectorsSelect}
                  search={props.getSectorsSelect().fetchSearchSectors}
                  disabled={props.mode === 'info'}
                />
              </div>
            </div>
            <div className="modal-active-trigger-items-group">
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.date_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='datepicker'
                  placeholder={props.t('form.date_placeholder')}
                  datePickerSettings={{ 
                    minDate: new Date(),
                    onChange: props.getDateInput().onSelectDate,
                    selectsRange: false,
                    startDate: props.getDateInput().dateScheduled,
                    valid: props.isValidDate() || props.mode === 'info'
                  }}
                  required={props.mode !== 'info'}
                  disabled={props.mode === 'info'}
                />
              </div>
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.hour_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='timepicker'
                  placeholder={props.t('form.hour_placeholder')}
                  datePickerSettings={{ 
                    minDate: new Date(), 
                    onChange: props.getDateInput().onSelectHour,
                    selectsRange: false,
                    startDate: props.getDateInput().hourScheduled,
                    timeIntervals: 1,
                    timeContainerWidth: 'large',
                    valid: props.isValidDate() || props.mode === 'info'
                  }}
                  required={props.mode !== 'info'}
                  disabled={props.mode === 'info'}
                />
                { props.getDateInput().hourScheduled && (!props.isValidDate() && props.mode !== 'info') &&
                  <span className="modal-active-trigger-item-invalid-message">{props.t('form.hour_invalid')}</span>
                }
              </div>
            </div>
            <div className="modal-active-trigger-item">
              <div className="modal-active-trigger-item-header">
                <span className="modal-active-trigger-item-label">{props.t('form.recipients_label')}</span>
                <span className="modal-active-trigger-item-label-required">*</span>
                {/* { props.getChannelSelect().selectedChannel?.id === Channels.Email &&
                  <OverlayTrigger overlay={<Tooltip>{props.t('form.recipients_file_email')}</Tooltip>}>
                    <IconFileDownload className='modal-active-trigger-item-action' />
                  </OverlayTrigger>
                } */}
                {/* { props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp &&
                  <OverlayTrigger overlay={<Tooltip>{props.t('form.recipients_file_whatsapp')}</Tooltip>}>
                    <IconFileDownload className='modal-active-trigger-item-action' />
                  </OverlayTrigger>
                } */}
              </div>
              <div {...props.getRootProps({ className: `modal-active-trigger-import-area ${fileUploaderInvalidClass} ${fileUploaderSelectedClass}` })}>
                <input {...props.getInputProps({})} />
                { !props.selectedFileRecipients &&
                  <span className="modal-active-trigger-import-area-message">
                    { props.getChannelSelect().selectedChannel?.id && <>{props.t(props.isDragging ? 'form.recipients_dragging' : 'form.recipients_placeholder')}</> }
                    { !props.getChannelSelect().selectedChannel?.id && <>{props.t('form.recipients_alert')}</> }

                    { props.getChannelSelect().selectedChannel?.id === Channels.Email &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('form.recipients_tooltip_email')}</Tooltip>}>
                        <IconInfoCircle />
                      </OverlayTrigger>
                    }
                    { props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('form.recipients_tooltip_whatsapp')}</Tooltip>}>
                        <IconInfoCircle />
                      </OverlayTrigger>
                    }
                  </span>
                }
                { props.selectedFileRecipients?.name &&
                  <span className="modal-active-trigger-import-area-selected-file">
                    <IconFileTypeCsv />
                    { props.selectedFileRecipients.name }
                    { props.mode !== 'info' && !props.isLoadingUploadRecipients &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('form.recipients_remove_file')}</Tooltip>}>
                        <IconX className='modal-active-trigger-import-delete-file' onClick={props.removeSelectedFile} />
                      </OverlayTrigger>
                    }
                    { props.mode !== 'info' && props.isLoadingUploadRecipients &&
                      <span className="circle-loader"></span>
                    }
                  </span>
                }
              </div>
            </div>
            { props.getChannelSelect().selectedChannel?.id === Channels.Email &&
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.sender_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={false}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.sender_placeholder')}
                  placeholderFilter={props.t('form.sender_search')}
                  options={props.getSenderEmailsSelect().senderEmailsList || []}
                  selected={props.getSenderEmailsSelect().selectedSenderEmail}
                  setSelected={props.getSenderEmailsSelect().setSelectedSenderEmail}
                  fetchMore={props.getSenderEmailsSelect().fetchAuthenticatedEmailsPagination}
                  hasMore={props.getSenderEmailsSelect().hasMoreSenderEmails}
                  loading={props.getSenderEmailsSelect().isLoadingSenderEmailsSelect}
                  search={props.getSenderEmailsSelect().fetchSearchAuthenticatedEmails}
                  disabled={props.mode === 'info'}
                  required={props.mode !== 'info'}
                />
              </div>
            }
            { props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp &&
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.template_message_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.template_message_placeholder')}
                  placeholderFilter={props.t('form.template_message_search')}
                  options={props.getTemplatesWhatsappSelect().templatesWhatsappList || []}
                  selected={props.getTemplatesWhatsappSelect().selectedTemplateWhatsapp}
                  setSelected={props.getTemplatesWhatsappSelect().setSelectedTemplateWhatsapp}
                  fetchMore={props.getTemplatesWhatsappSelect().fetchTemplatesWhatsappPagination}
                  hasMore={props.getTemplatesWhatsappSelect().hasMoreTemplatesWhatsapp}
                  loading={props.getTemplatesWhatsappSelect().isLoadingTemplatesWhatsappSelect}
                  search={props.getTemplatesWhatsappSelect().fetchSearchTemplatesWhatsapp}
                  disabled={props.mode === 'info'}
                  required={props.mode !== 'info'}
                />
              </div>
            }
            { props.getPostAttendanceSelect().hasPostScheduleAttendance &&
              <div className="modal-active-trigger-item">
                <div className="modal-active-trigger-item-header">
                  <span className="modal-active-trigger-item-label">{props.t('form.post_schedule_attendance_label')}</span>
                  <span className="modal-active-trigger-item-label-required">*</span>
                </div>
                <CustomSelectController 
                  type='simple'
                  dropdownPosition='body'
                  placeholder={props.t('form.post_schedule_attendance_placeholder')}
                  options={props.getPostAttendanceSelect().postAttendanceTypeList || []}
                  selected={props.getPostAttendanceSelect().selectedPostAttendanceType}
                  setSelected={props.getPostAttendanceSelect().setSelectedPostAttendanceType}
                  disabled={props.mode === 'info'}
                  required={props.mode !== 'info'}
                />
              </div>
            }
            <div className="modal-active-trigger-item">
              <div className="modal-active-trigger-item-header">
                <span className="modal-active-trigger-item-label">{props.t('form.message_label')}</span>
                <span className="modal-active-trigger-item-label-required">*</span>
              </div>
              { ((props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp && props.getTemplatesWhatsappSelect().selectedTemplateWhatsapp?.id) ||
                (props.getChannelSelect().selectedChannel?.id === Channels.Email)) &&
                <div className="modal-active-trigger-message-area">
                    <ChatEditorComponentController 
                      type='message-trigger'
                      editorState={props.editorState} 
                      setEditorState={props.setEditorState}
                      files={props.files}
                      setFiles={props.setFiles}
                      hasFileUploader={props.hasFileUploader}
                      setHasFileUploader={props.setHasFileUploader}
                      audioRecordedFiles={props.audioRecordedFiles}
                      setAudioRecordedFiles={props.setAudioRecordedFiles}
                      currentAudioInfo={props.currentAudioInfo}
                      setCurrentAudioInfo={props.setCurrentAudioInfo}
                      createSelectedChannel={props.getChannelSelect().selectedChannel}
                      hiddenToolbarItems={hiddenToolbarItems}
                      editorDisabled={isEditorDisabled}
                      statusChatEditor={{ blocked: isEditorDisabled, blocked_type: 'not_consumer_message' }}
                      fileUploadSpecificTypes={props.hasTemplateAttachment ? props.templateMimetypesAttachment : null}
                      triggerTemplate={props.hasTemplateAttachment ? props.triggerTemplate : null }
                      existentHTML={props.activeTrigger?.message || null}
                    />
                </div>
              }
              { !props.getChannelSelect().selectedChannel?.id &&
                <div className="modal-active-trigger-message-area modal-active-trigger-message-area-disabled">
                  <span className='modal-active-trigger-message-area-disabled-alert'>{props.t('form.recipients_alert')}</span>
                </div>
              }
              { props.getChannelSelect().selectedChannel?.id === Channels.WhatsApp && !props.getTemplatesWhatsappSelect().selectedTemplateWhatsapp?.id &&
                <div className="modal-active-trigger-message-area modal-active-trigger-message-area-disabled">
                  <span className='modal-active-trigger-message-area-disabled-alert'>{props.t('form.template_message_empty')}</span>
                </div>
              }
            </div>
          </div>
          { props.hasTriage &&
            <div className="modal-active-trigger-section section-margin-top">
              <div className="modal-active-trigger-section-check">
                <div className="modal-active-trigger-section-check-info">
                  <span className="modal-active-trigger-section-check-title">{props.t('form.triage_label')}</span>
                  <span className="modal-active-trigger-section-check-message">{props.t('form.triage_message')}</span>
                </div>
                <div className="modal-active-trigger-section-check-switch">
                  <div className={`switch-input ${switchInputDisabled}`}>
                    <OverlayTrigger overlay={<Tooltip>{props.makeTriage ? props.t('form.triage_active') : props.t('form.triage_inactive')}</Tooltip>}>
                      <label className="switch">
                        <input 
                          type="checkbox" 
                          checked={props.makeTriage} 
                          onChange={() => props.mode !== 'info' ? props.setMakeTriage(!props.makeTriage) : {}} 
                          disabled={props.mode === 'info'} 
                        />
                        <span className="slider round"></span>
                      </label>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
      }
      <Modal.Footer className={props.mode === 'info' ? 'modal-footer-only-close' : ''}>
        <button className='modal-active-trigger-btn-cancel' onClick={() => props.hide(props.reloadOnClose)}>
          {props.t(props.mode === 'info' ? 'btn_close' : 'btn_cancel')}
        </button>
        { props.mode !== 'info' &&
          <button className='modal-active-trigger-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
            {props.t(props.mode === 'edit' ? 'btn_save' : 'btn_create')}
          </button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalActiveTrigger;
