import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  Tab,
  Tabs
} from "react-bootstrap"
import Loading from '../../../../components/loading'
import allStyles from "./styles"
import ModalChangeUser from './modalChangeUser/indexController'
import MoreInfo from './moreInfo/indexController';
import IntegraAccount from './integrateAccount/indexController';
import ListConfigAccounts from './listConfigAccounts/indexController'
import ListConfigVozxpressController from './listConfigVozxpress/indexController'

const FormConfigVozxpress = ({
  isLoading,
  isEditAllowed,
  handleOpenCloseModalChange,
  showModalRegister,
  handleOpenCloseModal,
  updateListAfterDelete,
  showModalChangeUser,
  showMoreInfo,
  handleOpenCloseMoreInfo,
  moreInfo,
  showIntegrateAccount,
  handleOpenCloseIntegrateAccount,
  accountsVx,
  getAccountsVx,
  getAgentsWithAccount,
  setShowIntegrateAccount,
  setMoreInfo,
  setShowMoreInfo,
  agents
}) => {

  return (
    <div>
      <Loading left="0" visibility={isLoading}></Loading>
      <Row className='mt-1 noppadding' style={{ display: "flex", alignItems: "center" }}>
        <Col style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: '#0C2461', opacity: '1', display: 'flex', alignItems: 'center' }} md={5} className='mt-2'>
          <div style={{ paddingRight: "20px", color: "black" }}>
            Configuração do VozXpress
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
      <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start">
        <Col sm={true} md='3'>
          <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
            {isEditAllowed &&
              <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                    Alterar user Vozxpress
                  </Tooltip>
                }
              >
                <Button size="lg" className='buttonWhite' style={{ width: '100%', marginTop: '1.5vh' }} onClick={() => handleOpenCloseModalChange()}>Alterar user Vozxpress</Button>
              </OverlayTrigger>            
            }
          </p>
        </Col>
      </Row>
        <ModalChangeUser  
          show={showModalChangeUser} 
          onHide={handleOpenCloseModalChange}
        />
        <MoreInfo 
          show={showMoreInfo}
          onHide={handleOpenCloseMoreInfo}
          moreInfo={moreInfo}
        />
        <IntegraAccount 
          show={showIntegrateAccount}
          onHide={handleOpenCloseIntegrateAccount}
          accountsVx={accountsVx}
          moreInfo={moreInfo} 
          getAccountsVx={getAccountsVx}
          getAgentsWithAccount={getAgentsWithAccount}
        />
        <Tabs>
          <Tab
            eventKey={'agentes'}
            title={'Agentes do CX'}
            className='ps-0 ms-0'
          >
          <ListConfigVozxpressController 
              showIntegrateAccount={showIntegrateAccount}
              setShowIntegrateAccount={setShowIntegrateAccount}
              setMoreInfo={setMoreInfo} 
              setShowMoreInfo={setShowMoreInfo} 
              handleOpenCloseMoreInfo={handleOpenCloseMoreInfo} 
              datas={agents} 
              updateListAfterDelete={getAgentsWithAccount} 
              getAccountsVx={getAccountsVx}
              HasMoreInformation={false} 
            isEditAllowed={isEditAllowed} 
              getAgentsWithAccount={getAgentsWithAccount} 
              accountsVx={accountsVx}
            />
          </Tab>
          <Tab
            eventKey={'accounts'}
            title={'Atendentes do VX'}
            className='ps-0 ms-0'
          >
            <ListConfigAccounts 
              setMoreInfo={setMoreInfo} 
              setShowMoreInfo={setShowMoreInfo} 
              handleOpenCloseMoreInfo={handleOpenCloseMoreInfo}
              list={accountsVx} 
            isEditAllowed={isEditAllowed} 
              getAccountsVx={getAccountsVx} 
              getAgentsWithAccount={getAgentsWithAccount}
           />
          </Tab>
        </Tabs>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
    </div>

  )
}

export default FormConfigVozxpress